import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { getClientContext } from '@travellr/client/context'
import { NoteId } from '../../entity/Note'
import { TripId } from '../../entity/Trip'
import { CreatePayload, UpdatePayload, contract } from './contract'

export const UpdateNote = () => {
  const { xhr } = getClientContext()
  const updateNote = flow(XhrApiRouteClient(contract.update, xhr), justBody)
  return (noteId: NoteId, body: UpdatePayload) =>
    updateNote({ params: { id: noteId }, body })
}

export const AddNote = () => {
  const { xhr } = getClientContext()
  const addNote = flow(XhrApiRouteClient(contract.add, xhr), justBody)
  return (tripId: TripId, body: CreatePayload) =>
    addNote({ params: { id: tripId }, body })
}
