import { solidRemoteAction } from '@/solidjs/reactivity'
import { constNull, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { Loader } from '@travellr/client/ui-kit/Loader.solid-js'
import { RemoveCotravellr as Model } from './client'

type Props = {
  model: Model
}

export const RemoveCotravellrButton = (props: Props) => {
  const action = solidRemoteAction(props.model)
  return (
    <>
      <button
        class="button danger close"
        onClick={action.trigger}
        disabled={RR.isPending(action.state())}
      />
      {pipe(
        action.state(),
        RR.fold(
          constNull,
          () => (
            <div class="absolute top left h-100 w-100 flex align-center justify-center">
              <Loader />
            </div>
          ),
          () => {
            setTimeout(action.reset, 1000)
            return (
              <div class="absolute top left h-100 w-100 flex align-center justify-center">
                Failed
              </div>
            )
          },
          () => {
            setTimeout(action.reset, 1000)
            return (
              <div class="absolute top left h-100 w-100 flex align-center justify-center">
                <Icon intent="success" />
              </div>
            )
          },
        ),
      )}
    </>
  )
}
