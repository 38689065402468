import { ApiRouteContract } from '@/std/api-contract'
import { C, Cd } from '@/std/codec'
import { list, string, tuple } from '@/std/data'
import { pipe } from '@/std/function'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { ImageSelfHostedStorage } from '@travellr/domain/shared/entity/ImageStorage'
import { Picture } from '../../entity/Picture'
import { TripId } from '../../entity/Trip'

export type Meta = C.TypeOf<typeof MetaCodec>
const MetaCodec = C.struct({
  tripId: TripId,
  caption: C.Option(C.string),
  storage: ImageSelfHostedStorage,
  location: pipe(
    C.string,
    C.map(decodeURIComponent, encodeURIComponent),
    C.map(string.split(','), list.join(',')),
    C.compose(C.tuple([Cd.numberFromString, Cd.numberFromString])),
    C.map(
      ([lat, lng]) => ({ lat, lng }),
      ({ lat, lng }) => tuple(lat, lng),
    ),
    C.Option,
  ),
  timestamp: C.Option(Cd.DateFromString),
})

export const selfHostedContract = ApiRouteContract('POST', '/pictures', {
  searchParams: MetaCodec,
  headers: C.struct({
    'content-type': C.string,
    'content-length': T.numberFromString,
  }),
  body: {
    contentType: MimeType.OctetStream,
    codec: C.instanceOf(Blob),
  },
  response: {
    status: 200,
    codec: Picture,
    contentType: MimeType.Json,
  },
})
