import { O, date, string, struct } from '@/std/data'
import { cnst, flow, pipe } from '@/std/function'
import { ComponentProps, splitProps } from 'solid-js'
import { Except } from 'type-fest'
import { SolidFormControl } from './adapters'

export type DateTimeInputProps<E extends string> = Except<
  ComponentProps<'input'>,
  'type' | 'value'
> & {
  control: SolidFormControl<O.Option<Date>, E>
}

export const DateTimeInput = <E extends string>(
  props: DateTimeInputProps<E>,
) => {
  const [, inputProps] = splitProps(props, ['control'])
  return (
    <input
      {...inputProps}
      type="datetime-local"
      value={pipe(props.control(), O.fold(cnst(''), dateAsValue))}
      onBlur={() => props.control.isTouched.set(true)}
      onChange={flow(
        struct.lookup('currentTarget'),
        struct.lookup('value'),
        O.fromPredicate(string.isNotEmpty),
        O.map(date.utcFromString),
        O.flatMap(O.fromPredicate(date.isValid)),
        props.control.set,
      )}
    />
  )
}
const dateAsValue = (date: Date) => date.toISOString().slice(0, -8)
