import { Locale } from '@travellr/domain/core/i18n'
import { ComponentProps } from 'solid-js'
import { Except } from 'type-fest'
import { getClientContext } from '../context'

export type LocaleControlProps = Except<
  ComponentProps<'select'>,
  'value' | 'onChange'
>

export const LocaleControl = (props: LocaleControlProps) => {
  const { locale } = getClientContext()
  return (
    <select
      value={locale()}
      onChange={(event) => locale.set(event.currentTarget.value as Locale)}
      style="min-width: 7rem"
      {...props}
    >
      <option value="en">English 🇬🇧</option>
      <option value="fr">Français 🇫🇷</option>
    </select>
  )
}
