import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { MimeType } from '@/std/http'
import { Account } from '../../entity/Account'
import { Profile } from '../../entity/Profile'

export const contract = ApiRouteContract('GET', '/auto-sign-in', {
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: C.struct({
      account: Account,
      profile: C.Option(Profile),
      token: C.string,
    }),
  },
})
