import { solidRemoteResource } from '@/solidjs/reactivity'
import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { matchErrOr } from '@/std/error'
import { constNull, flow, pipe } from '@/std/function'
import { matchHttpCode } from '@/std/http'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { formatDate } from '@travellr/client/i18n/date'
import { Failure } from '@travellr/client/ui-kit/Failure.solid-js'
import { Loader } from '@travellr/client/ui-kit/Loader.solid-js'
import { Page } from '@travellr/client/ui-kit/Page.solid-js'
import { NoteDialog } from '@travellr/domain/core/guest/client/pages/SharedTrip/NoteDialog.solid'
import {
  TripPage,
  TripPageTitle,
} from '@travellr/domain/core/shared-components/trip'
import { onCleanup } from 'solid-js'
import { SharedTripModel } from './main'
import { messages } from './main.i18n'

type Props = {
  model: SharedTripModel
}

export const SharedTripPage = (props: Props) => {
  onCleanup(props.model.dispose)
  const sharedTrip = solidRemoteResource(props.model.resource)
  const page = solidState(props.model.page)
  const openedNote = solidState(props.model.openedNote)

  return (
    <div>
      {pipe(
        openedNote(),
        O.fold(constNull, (note) => (
          <NoteDialog
            onClose={() => openedNote.set(O.None())}
            header={
              <>
                {formatDate(note.from)} – {formatDate(note.until)}
              </>
            }
            content={<div innerHTML={note.content} style="min-height: 35vh" />}
            footer={
              <button class="button" onClick={() => openedNote.set(O.None())}>
                Close
              </button>
            }
          />
        )),
      )}

      {pipe(
        page(),
        RR.fold2(
          () => (
            <Page
              contentClass="flex align-center justify-center"
              content={<Loader />}
            />
          ),
          matchErrOr({
            _: renderDefaultError({ onTryAgain: sharedTrip.fetch }),
            HttpError: matchHttpCode({
              _: renderDefaultError({ onTryAgain: sharedTrip.fetch }),
              403: () => <AccessToTripForbidden />,
              404: () => <TripNotFound />,
            }),
          }),
          ({ fullTrip, page }) => (
            <TripPage
              model={page}
              title={<TripPageTitle main={fullTrip.trip.name} />}
              onViewNote={flow(O.Some, openedNote.set)}
            />
          ),
        ),
      )}
    </div>
  )
}

const renderDefaultError =
  (options: { onTryAgain: () => void }) => (error: Error) =>
    (
      <Page
        contentClass="flex align-center justify-center p-m maxw-100"
        content={
          <Failure
            class="maxw-100"
            error={error}
            onTryAgain={options.onTryAgain}
          />
        }
      />
    )

const TripNotFound = () => {
  const { t } = I18n(messages)
  return (
    <Page
      contentClass="flex align-center justify-center p-m maxw-100"
      content={
        <div class="box p-xl vspacer-m text-center">
          <h2>{t('notFound.heading')}</h2>
          <p>{t('notFound.explanation')}</p>
        </div>
      }
    />
  )
}
const AccessToTripForbidden = () => {
  const { t } = I18n(messages)
  return (
    <Page
      contentClass="flex align-center justify-center p-m maxw-100"
      content={
        <div class="box p-xl vspacer-m text-center">
          <h2>{t('forbidden.heading')}</h2>
          <p class="preline">{t('forbidden.solutions')}</p>
        </div>
      }
    />
  )
}
