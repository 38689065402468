import { identity } from '@/std/function'
import { makeI18n } from '@/std/i18n'
import { I18nOptions } from '@/std/i18n/i18n'
import { JSX } from 'solid-js'
import { solidState } from './state'

export const solidjsI18n = <
  Locale extends string,
  Messages extends Record<string, string>,
>(
  options: Omit<I18nOptions<Locale, Messages, JSX.Element>, 'revive'>,
) => {
  const i18n = makeI18n({ ...options, revive: identity<JSX.Element> })
  const signal = solidState(i18n.t)
  type Translate = ReturnType<typeof i18n.t>
  const translate = ((...args) => signal()(...args)) as Translate
  return {
    t: translate,
  }
}
