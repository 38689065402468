export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  details: 'Detail',
  tryAgain: 'Try again',
  unexpected: 'An unexpected error occurred:\n“{message}”',
} as const

const fr = {
  details: 'Détails',
  tryAgain: 'Réessayer',
  unexpected: 'Une erreur inattendue s’est produite:\n“{message}”',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
