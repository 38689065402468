import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { ClientContext, getClientContext } from '@travellr/client/context'
import { TripId } from '../../entity/Trip'
import { contract } from './contract'

export type RemoveCotravellr = ReturnType<typeof RemoveCotravellr>

type Deps = {
  selfTripId: TripId
  peerTripId: TripId
  context?: ClientContext
}
export const RemoveCotravellr = ({
  selfTripId,
  peerTripId,
  context: { xhr } = getClientContext(),
}: Deps) => {
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const remove = () => client({ body: { selfTripId, peerTripId } })
  const action = RemoteAction(remove)
  return action
}
