import { ApiRouteContract } from '@/std/api-contract'
import { C, Cd } from '@/std/codec'
import { MimeType } from '@/std/http'
import { Note, NoteId } from '../../entity/Note'
import { TripId } from '../../entity/Trip'

const CreatePayloadCodec = C.struct({
  content: C.string,
  from: Cd.DateFromString,
  until: Cd.DateFromString,
})
export type CreatePayload = C.TypeOf<typeof CreatePayloadCodec>

const UpdatePayloadCodec = C.struct({ content: C.string })
export type UpdatePayload = C.TypeOf<typeof UpdatePayloadCodec>

export const contract = {
  add: ApiRouteContract('POST', '/notes/:id', {
    params: C.struct({ id: TripId }),
    body: {
      contentType: MimeType.Json,
      codec: CreatePayloadCodec,
    },
    response: {
      status: 200,
      codec: Note,
      contentType: MimeType.Json,
    },
  }),

  update: ApiRouteContract('PATCH', '/notes/:id', {
    params: C.struct({ id: NoteId }),
    body: {
      contentType: MimeType.Json,
      codec: UpdatePayloadCodec,
    },
    response: {
      status: 200,
      codec: Note,
      contentType: MimeType.Json,
    },
  }),
}
