import { C, Cd } from '../codec'
import { R } from '../data'
import { flow, pipe } from '../function'
import { T, TR } from '../remote'
import { HttpClient, patchQuery } from './client'
import { HttpError } from './Error'
import { HttpMethod } from './Method'

export type PendingEvent = ProgressEvent<XMLHttpRequestEventTarget>

export type OnProgress = (event: PendingEvent) => void

type XmlRequestOptions = {
  onProgress?: OnProgress
} & Pick<RequestInit, 'headers' | 'method' | 'mode' | 'credentials'> & {
    body?: XMLHttpRequestBodyInit | null
  }

const toHttpError = (request: XMLHttpRequest) => (err?: unknown) =>
  HttpError({
    code: request.status,
    status: request.statusText,
    cause: String(err),
  })

export const xhr = (url: string, options: XmlRequestOptions) =>
  T.new<R.Result<HttpError, unknown>>((resolve) => {
    const {
      method = HttpMethod.get,
      mode,
      body,
      headers,
      onProgress,
      credentials,
    } = options
    const request = new XMLHttpRequest()
    request.open(method, url, true)

    new Headers(headers).forEach((value, name) => {
      request.setRequestHeader(name, value)
    })

    if (mode === 'cors' || credentials !== 'omit')
      request.withCredentials = true

    request.addEventListener(
      'error',
      flow(toHttpError(request), R.Err, resolve),
    )

    if (onProgress) {
      request.upload.addEventListener('progress', (event) => {
        onProgress(event)
      })
    }

    request.addEventListener('load', () => {
      request.status >= 400
        ? resolve(R.Err(toHttpError(request)()))
        : resolve(R.Ok(request.response))
    })

    request.send(body)
  })

export type XhrClient = typeof xhr

export const makeXhrClient =
  (
    baseUrl?: string,
    getDefaultOptions?: (base: XmlRequestOptions) => Partial<XmlRequestOptions>,
  ): typeof xhr =>
  (path, { headers, ...options }) => {
    const defaultOptions = getDefaultOptions?.({ headers, ...options })
    return xhr(`${baseUrl ?? ''}${path}`, {
      ...defaultOptions,
      ...options,
      headers: {
        ...Object.fromEntries(
          new Headers(defaultOptions?.headers ?? {}).entries(),
        ),
        ...Object.fromEntries(new Headers(headers).entries()),
      },
    })
  }

// Try decoding json, accept that it does not work
const XhrResponseCodec = C.union(Cd.stringifiedJson, C.unknown)

export type XhrHttpClient = HttpClient<HttpError | C.DecodeError>

export const makeXhrHttpClient = (xhr: XhrClient): XhrHttpClient => {
  const requestWithPayload = (method: HttpMethod) =>
    (({ url, ResponseCodec, body, BodyCodec, onProgress, ...rest }) =>
      pipe(
        xhr(patchQuery(url, rest), {
          ...rest,
          method,
          onProgress,
          body:
            BodyCodec &&
            (body instanceof Blob
              ? body
              : JSON.stringify(BodyCodec.encode(body))),
        }),
        TR.mapResult(XhrResponseCodec.decode),
        TR.mapResult(ResponseCodec.decode),
      )) satisfies HttpClient['post']

  return {
    get: (({ url, ResponseCodec, ...rest }) =>
      pipe(
        xhr(patchQuery(url, rest), rest),
        TR.mapResult(XhrResponseCodec.decode),
        TR.mapResult(ResponseCodec.decode),
      )) as XhrHttpClient['get'],
    delete: requestWithPayload(HttpMethod.delete) as XhrHttpClient['delete'],
    patch: requestWithPayload(HttpMethod.patch) as XhrHttpClient['patch'],
    post: requestWithPayload(HttpMethod.post) as XhrHttpClient['post'],
    put: requestWithPayload(HttpMethod.put) as XhrHttpClient['put'],
  }
}
