import { State } from '@/std/reactivity'
import {
  PictureContext,
  setPictureContext,
} from '@travellr/client/picture-store/context'
import { Account } from '../../authentication/entity/Account'
import { Profile } from '../../authentication/entity/Profile'
import { StorageSizeResource } from '../use-case/get-storage-size/client'
import { clearTravellrContext, setTravellrContext } from './context'
import { TravellrAppRouter } from './router'

export type TravellrApp = ReturnType<typeof TravellrApp>
type Init = {
  account: Account
  profile: Profile
  onAccountChange: (account: Account) => void
  onProfileChange: (profile: Profile) => void
  signOut: () => void
}

export const TravellrApp = ({
  account: accountInit,
  profile: profileInit,
  onAccountChange,
  onProfileChange,
  signOut,
}: Init) => {
  setPictureContext(PictureContext(accountInit.id))
  const account = State(accountInit)
  const profile = State(profileInit)
  const storageSize = StorageSizeResource()

  setTravellrContext({
    account,
    profile,
    storageSize,
    signOut,
  })

  const effects = [
    account.onChange(onAccountChange),
    profile.onChange(onProfileChange),
  ]

  const router = TravellrAppRouter()

  return {
    dispose: () => {
      clearTravellrContext()
      effects.forEach((effect) => effect.unlisten())
    },
    storageSize,
    route: router.active,
  }
}
