export const of = <T>(value: T): Set<T> => new Set([value])
export const fromArray = <T>(array: T[]) => new Set(array)
export const toArray = <T>(set: Set<T>) => Array.from(set)

export const has =
  <T>(value: T) =>
  (set: Set<T>) =>
    set.has(value)

export const add =
  <T>(value: T) =>
  (set: Set<T>) => {
    const copy = new Set(set)
    copy.add(value)
    return copy
  }
export const remove =
  <T>(value: T) =>
  (set: Set<T>) => {
    const copy = new Set(set)
    copy.delete(value)
    return set
  }

export const reduce =
  <Acc, T>(initial: Acc, reducer: (acc: Acc, item: T) => Acc) =>
  (set: Set<T>) => {
    let acc = initial
    for (const item of set) acc = reducer(acc, item)
    return acc
  }

type Filter = {
  <T>(predicate: (value: T) => unknown): (set: Set<T>) => Set<T>
  <T, U extends T>(predicate: (value: T) => value is U): (set: Set<T>) => Set<U>
}
export const filter: Filter = (predicate) =>
  reduce(new Set(), (acc, item) => {
    if (predicate(item)) acc.add(item)
    return acc
  })

export const some =
  <T>(predicate: (value: T) => unknown) =>
  (set: Set<T>): boolean => {
    if (set.size === 0) return true
    for (const value of set) if (predicate(value)) return true
    return false
  }

export const every =
  <T>(predicate: (value: T) => unknown) =>
  (set: Set<T>): boolean => {
    if (set.size === 0) return false
    for (const value of set) if (!predicate(value)) return false
    return true
  }

export const map = <T, U>(mapper: (value: T) => U) =>
  reduce(new Set<U>(), (acc, item: T) => {
    acc.add(mapper(item))
    return acc
  })
