import { O, struct } from '@/std/data'
import { FormControl, FormGroup, isTouched, isValid } from '@/std/form-control'
import { pipe } from '@/std/function'
import { solidState } from '../state'

export type SolidFormControl<T, Err extends string = string> = ReturnType<
  typeof solidFormControl<T, Err>
>
export const solidFormControl = <T, Err extends string = string>(
  control: FormControl<T, Err>,
) => {
  const adapted = Object.assign(solidState(control), {
    isTouched: solidState(control.isTouched),
    error: solidState(control.error),
    // Call the signal to make it work!
    isValid: () => O.isNone(adapted.error()),
  })
  return adapted
}

type ControlToSolid<C> = C extends FormControl<infer T, infer Err>
  ? SolidFormControl<T, Err>
  : never

export const solidFormGroup = <
  Controls extends Record<string, FormControl<any, any>>,
>({
  isTouched: _isTouched,
  isValid: _isValid,
  markTouched,
  ...group
}: FormGroup<Controls>) => {
  const controls = pipe(group, struct.map(solidFormControl)) as unknown as {
    [Key in keyof Controls]: ControlToSolid<Controls[Key]>
  }
  return {
    ...controls,
    isValid: () => isValid(controls),
    isTouched: () => isTouched(controls),
    markTouched,
  }
}
