export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'heading': 'Welcome!',
  'tagline': 'We’re glad to have you join us 🤓',
  'submit': 'Let’s gooo !',
  'footer.registeringWithEmail': 'You are registering with the @Underline({email})',
  'footer.wrongEmail?': 'Not the email you wanted to use? @Cta(Send a link to another email)',
  'acceptTermsOfService': 'By registering you accept the terms and conditions of the Travellr product',
  'control.name.label': 'Name / Pseudo / Top secret identity 🕵',
  'control.name.required': 'Required',
  'unauthorized': 'You are currently and unexpectedly unlogged. You can log in @Cta(here)',
  'forbidden': 'Took you some time to fill your profile, your token is expired!\nYou can get @Cta(get another)',
} as const

const fr = {
  'heading': 'Bienvenue !',
  'tagline': 'On est ravis de t’avoir parmi nous 🤓',
  'submit': 'C’est partiii !',
  'footer.registeringWithEmail': 'T’es en train de t’inscrire avec l’email @Underline({email})',
  'footer.wrongEmail?': 'C’est pas le bon ? @Cta(Changer l’email)',
  'acceptTermsOfService': 'En poursuivant, t’acceptes les conditions générales de ventes de Travellr®',
  'control.name.label': 'Nom / Pseudo / Identité secrète 🕵',
  'control.name.required': 'Requis',
  'unauthorized': 'Pour une raison parfaitement inconnue et inattendue, tu n’es pas connecté\nTu peux te connecter @Cta(ici)',
  'forbidden': 'Ça t’en a pris du temps pour remplir ton profil, ton token a expiré!\nTu peux en @Cta(avoir un autre)',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
