export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>
const en = {
  'awaitingConfirmation': 'Awaiting confirmation',
} as const

const fr = {
  "awaitingConfirmation": 'À confirmer',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
