import { Link } from '@/solidjs/router'
import { Page } from '@travellr/client/ui-kit/Page.solid-js'

type Props = {
  redirectTo: string
}

export const NotFound = (props: Props) => {
  return (
    <Page
      contentClass="flex align-center justify-center"
      content={
        <div>
          <h1>Page not found</h1>
          <h6>
            <Link class="a" href={props.redirectTo}>
              Authenticate?
            </Link>
          </h6>
        </div>
      }
    />
  )
}
