import { GuestRouter } from './router'

export type GuestApp = ReturnType<typeof GuestApp>

export const GuestApp = () => {
  const router = GuestRouter()
  return {
    route: router.active,
  }
}
