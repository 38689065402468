import { T } from '@/std/type'
import { AccountId } from './Account'

export type AuthCode = {
  accountId: AccountId
  code: string
  expiryDate: Date
}

export const AuthCodeStruct = {
  accountId: AccountId,
  code: T.string,
  expiryDate: T.union(T.Date, T.DateFromString),
}
const type = T.struct(AuthCodeStruct)
type Ord = { ord: typeof type.ord }

export const AuthCode: T.Type<AuthCode> & Ord = type
