import { O } from '@/std/data'
import { T } from '@/std/type'
import { JsonObject } from '@/std/types'
import { AccountId } from '../../authentication/entity/Account'

export type RuntimeError = {
  source: string
  path: string
  detail: string
  stack: O.Option<string>
  url: string
  accountId: O.Option<AccountId>
}

const type = T.struct({
  source: T.string,
  path: T.string,
  detail: T.string,
  stack: T.option(T.string),
  url: T.string,
  accountId: T.option(AccountId),
})

type Ord = { ord: typeof type.ord }

export const RuntimeError: T.Type<RuntimeError, JsonObject> & Ord = type
