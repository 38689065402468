// @ts-check
import { curry2, identity } from '../function'
import { Curried2 } from '../function.types'
import { Eq } from './eq'

export interface Ord<T> extends Eq<T> {
  compare: Curried2<T, T, number>
}

type FromCompare = <T>(compare: (a: T, b: T) => number) => Ord<T>
const fromCompare: FromCompare = (compare) => ({
  equals: curry2((a, b) => compare(a, b) === 0),
  compare: curry2(compare),
})

const toEq = identity as <T>(ord: Ord<T>) => Eq<T>

type Between = <T>(ord: Ord<T>) => (low: T, high: T) => (value: T) => boolean
const between: Between = (ord) => (low, high) => (value) =>
  gt(ord)(low)(value) && lt(ord)(high)(value)

type Min = <T>(ord: Ord<T>) => (a: T, b: T) => T
const min: Min = (ord) => (a, b) => ord.compare(a, b) <= 0 ? a : b

type Max = Min
const max: Max = (ord) => (a, b) => ord.compare(a, b) >= 0 ? a : b

type Clamp = <T>(ord: Ord<T>) => (low: T, high: T) => (value: T) => T
const clamp: Clamp = (ord) => (low, high) => (value) =>
  Ord.max(ord)(low, Ord.min(ord)(high, value))

type Compare = <T>(ord: Ord<T>) => (control: T) => (value: T) => boolean
const lt: Compare = (ord) => (control) => (value) =>
  ord.compare(value, control) < 0
const lte: Compare = (ord) => (control) => (value) =>
  ord.compare(value, control) <= 0
const gt: Compare = (ord) => (control) => (value) =>
  ord.compare(value, control) > 0
const gte: Compare = (ord) => (control) => (value) =>
  ord.compare(value, control) >= 0

const isOrd = (value: unknown): value is Ord<unknown> =>
  !!value && typeof (value as any).compare === 'function'

export const Ord = {
  fromCompare,
  toEq,
  between,
  min,
  max,
  clamp,
  lt,
  lte,
  gt,
  gte,
  isOrd,
}
