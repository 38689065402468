import { I18n } from '@travellr/client/i18n/I18n'
import { Page } from '@travellr/client/ui-kit/Page.solid-js'
import { UpdateProfile } from '@travellr/domain/core/authentication/use-case/update-profile/client.solid-js'
import { MainNav } from '../../../ui-kit/MainNav.solid-js'
import { Profile as Model } from './page'
import { messages } from './page.i18n'

type Props = {
  model: Model
}

export const Profile = (props: Props) => {
  const { t } = I18n(messages)

  return (
    <Page
      contentClass="flex justify-center align-center"
      content={
        <main class="p-m vspacer-m w-100">
          <div class="box p-m">
            <h1>{t('heading')}</h1>
          </div>

          <UpdateProfile class="box p-m text-left" model={props.model.form} />
        </main>
      }
      nav={
        <MainNav.Slot>
          <MainNav.Home />
          <MainNav.Back />
          <MainNav.AddTrip />
          <MainNav.Burger alignOptions="right" />
        </MainNav.Slot>
      }
    />
  )
}
