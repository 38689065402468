import { Location } from '../../entity/Location'
import { AddCheckpointFormModel } from './AddCheckpointForm'
import { AddCheckpointForm } from './AddCheckpointForm.solid'

type Props = {
  model: AddCheckpointFormModel
  onClose: () => void
  onLocatePicture: (location: Location) => void
}

export const AddCheckpointMapMenu = (props: Props) => {
  return (
    <div class="box relative">
      <h6 class="p-m gap-m flex justify-space-between align-center">
        <span>
          {props.model.location.lat.toFixed(4)},{' '}
          {props.model.location.lng.toFixed(4)}
        </span>
        <button class="discrete small close button" onClick={props.onClose} />
      </h6>

      <hr />

      <AddCheckpointForm
        class="p-m"
        onCancel={props.onClose}
        onSuccess={props.onClose}
        model={props.model}
      />
    </div>
  )
}
