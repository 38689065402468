export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'addNote': 'Add the\nday’s tale',
} as const

const fr = {
  'addNote': 'L’histoire\ndu jour',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
