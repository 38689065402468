import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { MimeType } from '@/std/http'
import { Profile } from '../../entity/Profile'

export const contract = ApiRouteContract('POST', '/register', {
  body: {
    contentType: MimeType.Json,
    codec: C.struct({ name: C.string }),
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: Profile,
  },
})
