export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'notFound.heading': 'This trip does not exist',
  'notFound.explanation': 'Wild guess: The person who sent you this link either deleted his/her trip or his/her account',
  'forbidden.heading': 'Access forbidden',
  'forbidden.solutions': 'This trip exists but you don’t have access to it\nWe advise you to ask for another link to your fellow travellr 😕',
} as const

const fr = {
  'notFound.heading': 'Ce voyage n’existe pas',
  'notFound.explanation': 'Cause probable : La personne qui t’a envoyé ce lien a supprimé son voyage ou son compte',
  'forbidden.heading': 'Accès interdit',
  'forbidden.solutions': 'Ce voyage existe mais t’y as pas accès\nOn te conseille de redemander un lien à ton voyageur préféré 😕',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
