import { list } from '@/std/data'
import { pipe } from '@/std/function'
import { State } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { Checkpoint } from '../../../entity/Checkpoint'
import { FullTrip } from '../../../entity/FullTrip'
import { Note } from '../../../entity/Note'
import { Picture } from '../../../entity/Picture'

export const FullTripThunk = <E>(
  resource: State<RR.RemoteResult<E, FullTrip>>,
  batchDelayInMs: number,
) => {
  let thunk = RR.toUndefined(resource())
  const store = State(resource())
  let timeout: ReturnType<typeof setTimeout> | undefined

  const listener = resource.onChange(
    RR.map((value) => {
      thunk = value
      store.set(RR.Ok(value))
      listener.unlisten()
    }),
  )

  const scheduleChange = (update: (current: FullTrip) => FullTrip) => {
    if (!thunk) throw new Error('full trip is not update-able yet')
    const next = (thunk = update(thunk))
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => store.set(RR.Ok(next)), batchDelayInMs)
  }

  return {
    store,
    dispose: listener.unlisten,
    patchPicture: (picture: Picture) =>
      scheduleChange(patchPictureToFullTrip(picture)),
    patchPictures: (pictures: Picture[]) =>
      scheduleChange(patchPicturesToFullTrip(pictures)),
    removePicture: (picture: Picture) =>
      scheduleChange(removePictureFromFullTrip(picture)),
    patchCheckpoint: (checkpoint: Checkpoint) =>
      scheduleChange(patchCheckpointToFullTrip(checkpoint)),
    removeCheckpoint: (checkpoint: Pick<Checkpoint, 'id'>) =>
      scheduleChange(removeCheckpointFromFullTrip(checkpoint)),
    patchNote: (note: Note) => scheduleChange(patchNoteToFullTrip(note)),
  }
}

/**
 * for each picture:
 * if the picture already exists, it will be updated
 * otherwise it will be added
 */
const patchPicturesToFullTrip = (pictures: Picture[]) => (fullTrip: FullTrip) =>
  pipe(
    pictures,
    list.reduce(fullTrip, (acc, picture) =>
      patchPictureToFullTrip(picture)(acc),
    ),
  )

/**
 * if the picture already exists, it will be updated
 * otherwise it will be added
 */
const patchPictureToFullTrip =
  (picture: Picture) =>
  (fullTrip: FullTrip): FullTrip => {
    const copy = { ...fullTrip }
    copy.pictures = [
      ...fullTrip.pictures.filter(({ id }) => id !== picture.id),
      picture,
    ]

    return copy
  }

const removePictureFromFullTrip =
  (picture: Picture) =>
  (fullTrip: FullTrip): FullTrip => {
    const copy = { ...fullTrip }
    copy.pictures = copy.pictures.filter(({ id }) => id !== picture.id)
    return copy
  }

const patchCheckpointToFullTrip =
  (checkpoint: Checkpoint) => (fullTrip: FullTrip) => ({
    ...fullTrip,
    checkpoints: fullTrip.checkpoints
      .filter(({ id }) => id !== checkpoint.id)
      .concat([checkpoint]),
  })

const removeCheckpointFromFullTrip =
  (checkpoint: Pick<Checkpoint, 'id'>) => (fullTrip: FullTrip) => ({
    ...fullTrip,
    checkpoints: fullTrip.checkpoints.filter(({ id }) => id !== checkpoint.id),
  })

const patchNoteToFullTrip = (note: Note) => (fullTrip: FullTrip) => ({
  ...fullTrip,
  notes: fullTrip.notes.filter(({ id }) => id !== note.id).concat([note]),
})
