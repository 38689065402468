import { solidRemoteResource } from '@/solidjs/reactivity'
import { O, list } from '@/std/data'
import { pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { Failure } from '@travellr/client/ui-kit/Failure.solid-js'
import { Loader } from '@travellr/client/ui-kit/Loader.solid-js'
import { RemoveCotravellrButton } from '../remove-cotravellr/client.solid-js'
import { ListCotravellrsModel as Model } from './client'
import { messages } from './client.i18n'

type CotravellrsProps = {
  model: Model
}
export const Cotravellrs = (props: CotravellrsProps) => {
  const cotravellrs = solidRemoteResource(props.model.list)
  const { t } = I18n(messages)
  return (
    <>
      {pipe(
        cotravellrs(),
        RR.fold2(
          () => <Loader />,
          (error) => <Failure error={error} onTryAgain={cotravellrs.fetch} />,
          list.map(({ cotravellr, profile }) => (
            <div class="p-m flex justify-space-between hspacer-m align-center relative">
              <div style="flex: 1; max-width: 85%">
                <small class="block bold ellipsis">
                  {pipe(
                    profile,
                    O.fold(
                      () => t('awaitingConfirmation'),
                      (profile) => <>{profile.name}</>,
                    ),
                  )}
                </small>
              </div>
              <div>
                <RemoveCotravellrButton
                  model={props.model.RemoveCotravellr(cotravellr)}
                />
              </div>
            </div>
          )),
        ),
      )}
    </>
  )
}
