import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { MimeType } from '@/std/http'
import { Trip } from '../../entity/Trip'

export const contract = ApiRouteContract('GET', '/trips', {
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: C.array(Trip),
  },
})
