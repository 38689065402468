export { matchId as matchErr, matchIdOr as matchErrOr } from './function'

export type Boom<Id extends string, Detail> = {
  readonly tag: 'Boom'
  readonly name: Id
  readonly id: Id
  readonly detail: Detail
  readonly message: string
  toString: () => string
}

/** @deprecated Simply use `ReturnType<typeof MyErrorFactory>` */
export type BoomOf<T> = T extends (...args: any) => Boom<infer Id, infer Detail>
  ? Boom<Id, Detail>
  : never

const ids = new Set<string>()

export const makeBoom = <Id extends string, Detail>(
  id: Id,
  toMessage: (detail: Detail) => string,
) => {
  if (ids.has(id)) throw new Error(`id "${id}" has already been declared`)

  return (detail: Detail): Boom<Id, Detail> => ({
    tag: 'Boom',
    id,
    name: id,
    detail,
    message: toMessage(detail),
    toString: () => toMessage(detail),
  })
}

export const InfraError = makeBoom(
  'InfraError',
  (detail: { kind: string; cause?: unknown }) =>
    `kind: ${detail.kind}, cause: ${String(detail.cause)}`,
)
export type InfraError = ReturnType<typeof InfraError>

export const makeInfraError =
  <Kind extends string>(kind: Kind) =>
  (cause?: unknown) =>
    InfraError({ kind, cause })
