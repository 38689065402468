import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { O, list } from '@/std/data'
import { flow } from '@/std/function'
import { getClientContext } from '@travellr/client/context'
import { I18n } from '@travellr/client/i18n/I18n'
import { formatDate, formatDateTimeShort } from '@travellr/client/i18n/date'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { ComponentProps, mapArray, onCleanup, splitProps } from 'solid-js'
import { Except } from 'type-fest'
import { PictureCrumbImage } from './PictureCrumbImage.solid'
import {
  CheckpointCrumb,
  NoteCrumb,
  PictureCrumb,
  TripCrumb,
} from './TripCrumbs'
import { TripCrumbsCarouselModel } from './TripCrumbsCarousel'
import './TripCrumbsCarousel.css'
import { messages } from './TripCrumbsCarousel.i18n'

type Props = {
  model: TripCrumbsCarouselModel
  range?: { from: Date; until: Date }
  onArchivePicture?: (crumb: PictureCrumb) => unknown
  onEditPicture?: (crumb: PictureCrumb) => unknown
  onArchiveCheckpoint?: (crumb: CheckpointCrumb) => unknown
  onAddNote?: (range: { from: Date; until: Date }) => unknown
  onEditNote?: (note: NoteCrumb) => unknown
  onViewNote?: (note: NoteCrumb) => unknown
}
export const TripCrumbsCarousel = (props: Props) => {
  onCleanup(props.model.dispose)
  const crumbs = solidState(props.model.crumbs)
  const crumbsIncludesNote = flow(
    crumbs,
    list.some((crumb) => crumb.type === 'note'),
  )

  return (
    <div
      class="trip-crumbs-carousel"
      onScrollEnd={(event) =>
        props.model.onContainerScrollEnd(event.currentTarget)
      }
    >
      {mapArray(crumbs, (crumb, index) => {
        // const nextCrumb = crumbs().find(
        //   (crumb, i): crumb is Exclude<TripCrumb, { type: 'note' }> =>
        //     i > index() && crumb.type !== 'note',
        // )
        // const datesBeforeNextCrumb =
        //   nextCrumb && crumb.type !== 'note'
        //     ? date.range({
        //         from: crumb.timestamp,
        //         until: nextCrumb.timestamp,
        //       })
        //     : crumb.type === 'note'
        //     ? []
        //     : [crumb.timestamp]

        return (
          <CarouselCrumbItem
            crumb={crumb}
            onClick={() => props.model.active.set(O.Some(crumb))}
            onArchivePicture={props.onArchivePicture}
            onEditPicture={props.onEditPicture}
            onArchiveCheckpoint={props.onArchiveCheckpoint}
            onEditNote={props.onEditNote}
            onViewNote={props.onViewNote}
          />
        )
      })()}
      {props.onAddNote && props.range && !crumbsIncludesNote() && (
        <CarouselCreateNoteItem
          from={props.range.from}
          until={props.range.until}
          onClick={props.onAddNote}
        />
      )}
      <div class="empty-block" />
    </div>
  )
}

type CarouselCrumbItemProps = {
  crumb: TripCrumb
  onClick: (crumb: TripCrumb) => unknown
  onArchivePicture?: (crumb: PictureCrumb) => unknown
  onEditPicture?: (crumb: PictureCrumb) => unknown
  onArchiveCheckpoint?: (crumb: CheckpointCrumb) => unknown
  onEditNote?: (note: NoteCrumb) => unknown
  onViewNote?: (note: NoteCrumb) => unknown
}
const CarouselCrumbItem = (props: CarouselCrumbItemProps) => {
  switch (props.crumb.type) {
    case 'checkpoint':
      return (
        <CarouselCheckpointItem
          crumb={props.crumb}
          onClick={() => props.onClick(props.crumb)}
          onArchive={props.onArchiveCheckpoint}
        />
      )
    case 'picture':
      return (
        <CarouselPictureItem
          crumb={props.crumb}
          onImageClick={() => props.onClick(props.crumb)}
          onArchive={props.onArchivePicture}
          onEdit={props.onEditPicture}
        />
      )
    case 'note':
      return (
        <CarouselNoteItem
          crumb={props.crumb}
          onEdit={props.onEditNote}
          onView={props.onViewNote}
        />
      )
  }
}

type CarouselPictureProps = {
  crumb: PictureCrumb
  onImageClick: () => void
  // onUpdate?: OnUpdatePicture
  onEdit?: (crumb: PictureCrumb) => void
  onArchive?: (crumb: PictureCrumb) => void
}
const CarouselPictureItem = (props: CarouselPictureProps) => {
  return (
    <CarouselItem crumb={props.crumb} class="p-m">
      <PictureCrumbImage
        crumb={props.crumb}
        onArchive={props.onArchive}
        onEdit={props.onEdit}
        onImageClick={props.onImageClick}
      />
    </CarouselItem>
  )
}

type CarouselCheckpointProps = {
  crumb: Extract<TripCrumb, { type: 'checkpoint' }>
  onClick: () => void
  onArchive?: (crumb: CheckpointCrumb) => unknown
}
const CarouselCheckpointItem = (props: CarouselCheckpointProps) => {
  const { t } = I18n(messages)
  const { config } = getClientContext()
  return (
    <CarouselItem
      crumb={props.crumb}
      onClick={props.onClick}
      class="py-m px-xl flex align-center"
      style={{
        'min-height': `calc(${config.thumbnailHeightPx}px + var(--spaceM) * 2)`,
      }}
    >
      <div class="vspacer-m text-center">
        <h4>
          <Icon intent="checkpoint" />
        </h4>
        <p>{formatDateTimeShort(props.crumb.timestamp)}</p>
        {props.crumb.belongsToSelf && props.onArchive && (
          <footer>
            <button
              class="small sm discrete button"
              type="button"
              onClick={() => props.onArchive?.(props.crumb)}
            >
              {t('verb/remove')}
            </button>
          </footer>
        )}
      </div>
    </CarouselItem>
  )
}

type CarouselNoteItemProps = {
  onEdit?: (crumb: NoteCrumb) => void
  onView?: (crumb: NoteCrumb) => void
  crumb: NoteCrumb
}
const CarouselNoteItem = (props: CarouselNoteItemProps) => {
  const { config } = getClientContext()
  const onClick = props.onEdit || props.onView
  if (!onClick) throw new Error('one of `onView` or `onEdit` must be provided')

  return (
    <CarouselItem
      crumb={props.crumb}
      onClick={() => onClick(props.crumb)}
      class="py-m px-xl flex align-center"
      style={{
        'min-height': `calc(${config.thumbnailHeightPx}px + var(--spaceM) * 2)`,
      }}
    >
      <div class="vspacer-m text-center">
        <h4>
          <Icon intent={props.onEdit ? 'editNote' : 'viewNote'} />
        </h4>

        <span class="nowrap">
          {formatDate(props.crumb.from)}
          <br />–<br />
          {formatDate(props.crumb.until)}
        </span>
      </div>
    </CarouselItem>
  )
}

type CarouselCreateNoteItemProps = {
  from: Date
  until: Date
  onClick: (range: { from: Date; until: Date }) => void
}
const CarouselCreateNoteItem = (props: CarouselCreateNoteItemProps) => {
  const { t } = I18n(messages)
  return (
    <div
      class="pointer flex column align-center justify-center vspacer-m p-xl text-center"
      style="min-height: 12.5rem"
      onClick={() => props.onClick({ from: props.from, until: props.until })}
    >
      <button class="button discrete">
        <Icon intent="add" />
      </button>
      <span class="nowrap">
        {formatDate(props.from)}
        <br />–<br />
        {formatDate(props.until)}
      </span>
      <span class="nowrap">{t('addNote')}</span>
    </div>
  )
}

type CarouselItemProps = Except<ComponentProps<'div'>, 'onClick'> & {
  crumb: { id: string }
  onClick?: () => void
}
const CarouselItem = (props: CarouselItemProps) => {
  const [, rest] = splitProps(props, ['crumb'])
  return (
    <div
      {...rest}
      id={`carousel-crumb-${props.crumb.id}`}
      onClick={props.onClick}
      class={cx('pointer', props.class)}
    />
  )
}
