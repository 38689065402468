import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { O } from '@/std/data'
import { cnst, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { RemoteActionToConfirm } from '@/std/remote/remote-action'
import { ComponentProps, JSX, Show, splitProps } from 'solid-js'
import { Dialog } from './Dialog.solid-js'

type Props = ComponentProps<'dialog'> & {
  action: RemoteActionToConfirm<any, any, any>
  confirmMessage: JSX.Element
  pendingMessage?: JSX.Element
  heading?: JSX.Element
  no?: JSX.Element
  yes?: JSX.Element
  tone?: 'discrete' | 'danger'
}

export const ConfirmActionDialog = (props: Props) => {
  const [, rest] = splitProps(props, [
    'action',
    'confirmMessage',
    'pendingMessage',
    'heading',
    'no',
    'yes',
    'tone',
  ])
  const submitState = solidState(props.action.state)
  const toConfirm = solidState(props.action.toConfirm)
  return (
    <Show when={O.isSome(toConfirm())}>
      <Dialog {...rest} onClose={props.action.cancel} dialogClass="px-m">
        <header class="py-m">
          <h3>{props.heading || '⚠️ Warning'}</h3>
        </header>
        <hr />
        <div class="py-l">
          {pipe(
            submitState(),
            RR.fold(
              cnst(props.confirmMessage),
              () => props.pendingMessage || <div>Farewell, say goodbye…</div>,
              (e) => (
                <div class="flex column gap-s">
                  <div>An error occurred, you can click continue to retry</div>
                  <details>
                    <summary>Detail</summary>
                    {e.id}: {e.message}
                  </details>
                </div>
              ),
              cnst(props.confirmMessage),
            ),
          )}
        </div>
        <hr />
        <footer class="py-m flex justify-end gap-m">
          <button
            class="button"
            type="button"
            onClick={props.action.cancel}
            disabled={RR.isPending(submitState())}
          >
            {props.no || 'Nope 😱'}
          </button>

          <button
            class={cx('button', props.tone)}
            disabled={RR.isPending(submitState())}
            type="button"
            onClick={props.action.confirm}
          >
            {props.yes || 'I am'}
          </button>
        </footer>
      </Dialog>
    </Show>
  )
}
