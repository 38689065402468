import { I18n } from '@travellr/client/i18n/I18n'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { Page } from '@travellr/client/ui-kit/Page.solid-js'
import { MainNav } from '../../../ui-kit/MainNav.solid-js'
import { CreateTripWithPictures } from '../../components/CreateTripWithPictures/main.solid'
import { ImportTrip as Model } from './page'
import { messages } from './page.i18n'

type Props = {
  model: Model
}
export const ImportTrip = (props: Props) => {
  const { t } = I18n(messages)
  const formId = 'importTripForm'

  return (
    <Page
      nav={<CustomMainNav formId={formId} />}
      content={
        <div class="minh-100 flex column">
          <header>
            <h3 class="p-m text-center">{t('header')}</h3>
            <hr />
          </header>

          <div class="flex-1 flex align-center w-100">
            <CreateTripWithPictures
              class="flex-1 p-m maxw-100"
              id={formId}
              model={props.model.form}
            />
          </div>
        </div>
      }
    />
  )
}

type CustomMainNavProps = {
  formId: string
}
const CustomMainNav = (props: CustomMainNavProps) => (
  <MainNav.Slot>
    <MainNav.Home />
    <MainNav.Back />
    <button class="navItem flex-1" form={props.formId} type="submit">
      <Icon intent="next" />
    </button>
    <MainNav.Burger alignOptions="right" />
  </MainNav.Slot>
)
