import { O, date } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { getClientContext } from '@travellr/client/context'
import { I18n } from '@travellr/client/i18n/I18n'
import { usePictureContext } from '@travellr/client/picture-store/context'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { Image } from '@travellr/client/ui-kit/Image.solid-js'
import { Show } from 'solid-js'
import { PictureCrumb } from './TripCrumbs'

type Props = {
  crumb: PictureCrumb
  onImageClick?: () => void
  onArchive?: (crumb: PictureCrumb) => void
  onEdit?: (crumb: PictureCrumb) => void
}
export const PictureCrumbImage = (props: Props) => {
  const { t } = I18n({ en: {}, fr: {} })
  const { config } = getClientContext()
  const pictureStore = usePictureContext()
  return (
    <div class="vspacer-xs pb-s">
      <div class="relative">
        <Image
          class="rounded-s"
          onClick={props.onImageClick}
          loader={() => (
            <div
              class="placeholder"
              style={{
                width: config.thumbnailHeightPx * 1.5 + 'px',
                height: config.thumbnailHeightPx + 'px',
              }}
            />
          )}
          src={pictureStore.url(props.crumb)}
        />
        <div class="absolute top w-100 p-s flex align-start justify-space-between">
          <div
            class="box p-xs"
            classList={{ pointer: !!props.onEdit }}
            onClick={() => props.onEdit?.(props.crumb)}
            role={props.onEdit && 'button'}
          >
            <Icon
              intent={
                O.isNone(props.crumb.location) ? 'noLocation' : 'hasLocation'
              }
            />
          </div>
          <Show when={props.onArchive || props.onEdit}>
            <button
              role="combobox"
              class="relative box overflow-visible px-s py-xs pointer"
            >
              <Icon intent="menu" class="small" />
              <div class="absolute pt-s bottom right translate-self">
                <div class="options">
                  {props.onEdit && (
                    <button
                      role="option"
                      class="w-100 gap-m pointer flex align-center justify-space-between"
                      onClick={() => props.onEdit?.(props.crumb)}
                    >
                      <span>{t('verb/edit')}</span>
                      <Icon intent="edit" />
                    </button>
                  )}
                  {props.onArchive && (
                    <button
                      role="option"
                      type="button"
                      class="w-100 gap-m pointer flex align-center justify-space-between"
                      onClick={() => props.onArchive?.(props.crumb)}
                    >
                      <span class="nowrap">{t('verb/remove')}</span>
                      <Icon intent="remove" />
                    </button>
                  )}
                </div>
              </div>
            </button>
          </Show>
        </div>
      </div>
      {pipe(
        props.crumb.caption,
        O.fold(constNull, (caption) => (
          <b class="block small text-center">{caption}</b>
        )),
      )}
      <em class="block small text-center">
        {pipe(
          props.crumb.timestamp,
          date.format({ day: '2-digit', month: '2-digit', year: '2-digit' }),
        )}
        {' – '}
        {pipe(props.crumb.timestamp, date.format({ timeStyle: 'short' }))}
      </em>
    </div>
  )
}
