import * as remoteData from './remote-data'
import * as remoteResult from './remote-result'
import * as task from './task'
import * as taskResult from './task-result'

export {
  DelayedRemoteAction,
  RemoteAction,
  fakeRemoteAction,
} from './remote-action'
export { RemoteResource, fakeRemoteResource } from './remote-resource'
export { UploadAction } from './upload-action'

export {
  remoteData as RD,
  remoteResult as RR,
  task as T,
  taskResult as TR,
  remoteData,
  remoteResult,
  task,
  taskResult,
}
