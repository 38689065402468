import { R } from '../data'
import { constVoid, pipe } from '../function'
import { State } from '../reactivity'
import { RemoteAction } from './remote-action'
import * as RR from './remote-result'
import * as TR from './task-result'

export const RemoteResource = <E, T>(
  fetcher: () => TR.TaskResult<E, T>,
): RemoteResource<E, T> => {
  const { trigger, state, reset } = RemoteAction(fetcher)
  const mutate = (fn: (value: T) => T) => state.update(RR.map(fn))
  return Object.assign(state, { fetch: trigger, mutate, reset })
}
export type RemoteResource<E, T> = State<RR.RemoteResult<E, T>> & {
  fetch: () => Promise<R.Result<E, T>>
  mutate: (fn: (value: T) => T) => void
  reset: () => void
}

export const fakeRemoteResource = <E, T>(
  current: RR.RemoteResult<E, T>,
): RemoteResource<E, T> => {
  const state = State(current)
  const resource: RemoteResource<E, T> = Object.assign(state, {
    fetch: () =>
      pipe(
        current,
        RR.fold2(
          () => new Promise<R.Result<E, T>>(() => {}),
          (err) => Promise.reject(R.Err(err)),
          (value) => Promise.resolve(R.Ok(value)),
        ),
      ),
    mutate: constVoid,
    reset: () => state.set(RR.NotAsked()),
  })
  return resource
}
