import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { ImageStorage } from '@travellr/domain/shared/entity/ImageStorage'
import type { Opaque } from 'type-fest'
import { AccountId } from '../../authentication/entity/Account'

export type TripId = Opaque<string, 'TripId'>
export type Trip = {
  id: TripId
  accountId: AccountId
  name: string
  createdAt: Date
  imageStorage: ImageStorage
}

const makeId = cast<string, TripId>
export const TripId = pipe(T.string, T.map(makeId, String))

export const TripStruct = {
  id: TripId,
  accountId: AccountId,
  name: T.string,
  createdAt: T.union(T.Date, T.DateFromString),
  imageStorage: ImageStorage,
}
const type = T.struct(TripStruct)
type Ord = { ord: typeof type.ord }

export const Trip: T.Type<Trip> & Ord = type
