export enum MimeType {
  Json = 'application/json',
  Text = 'text/plain',
  Csv = 'text/csv',
  Html = 'text/html',
  Jpeg = 'image/jpeg',
  Png = 'image/png',
  Svg = 'image/svg+xml',
  OctetStream = 'application/octet-stream',
}
