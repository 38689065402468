import { O, number, string, struct } from '@/std/data'
import { cnst, flow, pipe } from '@/std/function'
import { ComponentProps, splitProps } from 'solid-js'
import { Except } from 'type-fest'
import { SolidFormControl } from './adapters'

export type NumberInputProps = Except<
  ComponentProps<'input'>,
  'value' | 'type' | 'onChange'
> & {
  control: SolidFormControl<O.Option<number>>
}

export const NumberInput = (props: NumberInputProps) => {
  const [, inputProps] = splitProps(props, ['control'])
  return (
    <input
      {...inputProps}
      type="number"
      value={pipe(props.control(), O.fold(cnst(''), String))}
      onInput={flow(
        struct.lookup('currentTarget'),
        struct.lookup('value'),
        O.fromPredicate(string.isNotEmpty),
        O.map(Number),
        O.flatMap(O.fromPredicate(number.isNotNaN)),
        props.control.set,
      )}
      onBlur={() => props.control.isTouched.set(true)}
    />
  )
}
