import { Context } from '@/std/context'
import { State } from '@/std/reactivity'
import { Account } from '../../authentication/entity/Account'
import { Profile } from '../../authentication/entity/Profile'
import { StorageSizeResource } from '../use-case/get-storage-size/client'

export type TravellrContext = {
  account: State<Account>
  profile: State<Profile>
  storageSize: StorageSizeResource
  signOut: () => void
}

export const [setTravellrContext, getTravellrContext, clearTravellrContext] =
  Context<TravellrContext>('travellr')
