import { solidState } from '@/solidjs/state'
import { struct } from '@/std/data'
import { matchTypeOr, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { TripPageTitle } from '@travellr/domain/core/shared-components/trip'
import { ShareTripButton } from '../../components/ShareTrip'
import { FixUndatedPicturesButton } from './FixUndatedPicturesButton.solid'
import { TripName } from './TripName.solid'
import { messages } from './TripTitle.i18n'
import { TripModel } from './main'

type Props = {
  model: TripModel
}

export const TripTitle = (props: Props) => {
  const mapView = solidState(props.model.mapView)
  const fullTrip = solidState(props.model.fullTrip)
  const { t } = I18n(messages)

  return (
    <>
      {pipe(
        mapView(),
        matchTypeOr({
          'pick-picture-at-location': () => (
            <TripPageTitle
              main={<>{t('pick-picture-at-location/title')}</>}
              right={<CancelButton onClick={props.model.setDefaultView} />}
            />
          ),
          'locate-on-map': () => (
            <TripPageTitle
              main={<>{t('locate-on-map/title')}</>}
              right={<CancelButton onClick={props.model.setDefaultView} />}
            />
          ),
          _: () => (
            <TripPageTitle
              main={<TripName model={props.model} />}
              left={<FixUndatedPicturesButton model={props.model} />}
              right={
                <ShareTripButton
                  trip={pipe(
                    fullTrip(),
                    RR.map(struct.lookup('trip')),
                    RR.toOption,
                  )}
                />
              }
            />
          ),
        }),
      )}
    </>
  )
}

const CancelButton = (props: { onClick: () => void }) => {
  return (
    <button type="button" class="button" onClick={props.onClick}>
      {'Cancel'}
    </button>
  )
}
