import { pipe } from '../function'

export type {
  Reviver as TranslationReviver,
  Revivers as TranslationRevivers,
  Variables as TranslationVariables,
}

type Variables<
  Key extends string,
  Acc = never,
> = Key extends `{${infer S}}${infer Rest}`
  ? Variables<Rest, Acc | S>
  : Key extends `${string}{${infer S}}`
  ? Variables<`{${S}}`, Acc>
  : Key extends `${infer Rest1}{${infer S}}${infer Rest2}`
  ? Variables<`${Rest1}${Rest2}`, Acc | Variables<`{${S}}`>>
  : Acc

// type Strict = Variables<'{hello}, boy, this {or} that {is} some {world}'>
// type Loose = Variables<string>

// type Part1 = Test<'{hello}, boy, that {is} a {wonderful} some {world}'>
// type Part2 = Test<", boy, that {is} a {wonderful} some {world}">
// type Part3 = Test<"{is} a {wonderful} some {world}">
// type Part4 = Test<" a {wonderful} some {world}">
// type Part5 = Test<"{wonderful} some {world}">
// type Part6 = Test<" some {world}">

const interpolateRegex = /{(\w+)}/g
export const interpolate =
  <TranslationKey extends string>(
    variables: Record<Variables<TranslationKey>, unknown>,
  ) =>
  (translationKey: TranslationKey) => {
    return translationKey.replace(interpolateRegex, (_, key) => {
      return String(variables[key])
    })
  }

type Revivers<
  Key extends string,
  Acc = never,
> = Key extends `${string}@${infer Name}(${infer Rest1})${infer Rest2}`
  ? Revivers<`${Rest1})`, Acc | Name> | Revivers<Rest2, Acc | Name>
  : Acc

// type Check1 = Revivers<'@Link(hello)'>
// type Check2 = Revivers<'@Link(hello) not ending'>
// type Check3 = Revivers<'Not starting @Link(hello)'>
// type Check4 = Revivers<'Not starting @Link(hello) not ending'>
// type Check5 = Revivers<'@Link(@H1(world))'>
// type Check6 = Revivers<'@Link(not started @H1(world))'>
// type Check7 = Revivers<'@Link(@H1(world) not ending)'>
// type Check8 = Revivers<'@Link(not started @H1(world) not ending)'>

export type Reviver<Content> = (c: Content) => Content

export const revive = <TranslationKey extends string, Content>(
  renderers: Record<Revivers<TranslationKey>, (c: Content) => Content>,
  render: (parts: Array<string | Content>) => Content,
) => {
  const reviveRecursive = (part: string) => {
    // shape: "test @Link(hello @H1(world)) etc" for instance
    const match = /@([A-Z][A-z0-9]+)\(([^)]+)\)/g.exec(part)
    // nothing to revive
    if (!match) return [part]
    const [str, renderer, content] = match
    const revived = reviveRecursive(content)
    const parts: (string | Content)[] = [
      match.input.slice(0, match.index),
      renderers[renderer](render(revived)),
      ...reviveRecursive(match.input.slice(match.index + str.length)),
    ]
    return parts
  }

  return (translationKey: TranslationKey) =>
    render(reviveRecursive(translationKey))
}

// type KeyWithVariables = `${string}{${string}}${string}`
// type KeyWithRevivers = `${string}@${string}(${string})${string}`

// export const makeNamespace = <
//   Messages extends Record<string, string>,
//   Content,
// >(init: {
//   messages: Messages
//   revive: (parts: Array<string | Content>) => Content
//   onValueMissing?: (key: string) => void
// }) => {
//   type Ns = Namespace<Extract<keyof Messages, string>>

//   const namespace = <N extends Ns>(ns: N): Translate<N> => {
//     const translate = (key: string, options?: Record<string, unknown>) => {
//       const fullKey = `${ns}:${key}`
//       const message = init.messages[fullKey]
//       if (!message) init.onValueMissing?.(fullKey)
//       return pipe(
//         message ?? fullKey,
//         interpolate(options ?? {}),
//         revive(options ?? {}, init.revive),
//       )
//     }
//     return translate as Translate<N>
//   }

//   return namespace

//   type Key = Extract<keyof Messages, string>
//   type Namespace<Key extends string> = Key extends `${infer N}:${string}`
//     ? N
//     : never
//   type Message<Ns extends string, K extends NsKey<Ns>> = Messages[Extract<
//     `${Ns}:${K}`,
//     keyof Messages
//   >]
//   type NsKey<Ns extends string> = Replace<
//     Extract<Key, `${Ns}:${string}`>,
//     `${Ns}:`,
//     ''
//   >
//   interface Translate<Ns extends string> {
//     <Key extends NsKey<Ns>, M extends Message<Ns, Key>>(
//       key: Key,
//       options?: Record<Variables<M>, unknown> &
//         Record<Revivers<M>, Reviver<Content>>,
//     ): Content
//   }
// }

export const makeTranslate = <
  Messages extends Record<string, string>,
  Content,
>(init: {
  messages: Messages
  revive: (parts: Array<string | Content>) => Content
  onValueMissing?: (key: string) => void
}) => {
  const translate = (key: string, options?: Record<string, unknown>) => {
    const message = init.messages[key]
    if (!message) init.onValueMissing?.(key)
    return pipe(
      message ?? key,
      interpolate(options ?? {}),
      revive(options ?? {}, init.revive),
    )
  }
  return translate as Translate<Messages, Content>
}

export interface Translate<Messages extends Record<string, string>, Content> {
  <K extends keyof Messages, M extends Messages[K]>(
    key: K,
    options?: Record<Variables<M>, unknown> &
      Record<Revivers<M>, Reviver<Content>>,
  ): Content
}

// const messages = {
//   'AuthApp.Register:usernameTaken': 'User name @Bold({name}) is already taken',
//   test1: 'User name @Bold("John") is @Bold(already) taken',
//   test2: 'User name @Bold("John" is @Bold(already)) taken',
//   'TravellrApp.SharedRoute:toto': 'Totooo',
// } as const

// const translate = makeTranslate({
//   messages,
//   revive: (parts) => parts.join(''),
// })
// const namespace = makeNamespace({
//   messages,
//   revive: (parts) => parts.join(''),
// })

// const t = {
//   register: namespace('AuthApp.Register'),
//   shared: namespace('TravellrApp.SharedRoute'),
// }

// const test1 = t.register('usernameTaken', {
//   name: 'Hello',
//   Bold: (c) => `<b>${c}</b>`,
// })
// const test2 = t.shared('toto', {})
// const test3 = translate('AuthApp.Register:usernameTaken', {
//   name: 'test',
//   Bold: (c) => `<b>${c}</b>`,
// })
// const test4 = t.register('test', {
//   Bold: (c) => `<b>${c}</b>`,
// })
// console.info({ test1, test2, test3 })
// console.info({
//   test1: translate('test1', { Bold: (c) => `<b>${c}</b>` }),
//   test2: translate('test2', { Bold: (c) => `<b>${c}</b>` }),
// })
