import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { Location } from '../../entity/Location'

type Props = {
  location: Location
  onClose: () => void
  onLocatePicture: (location: Location) => void
  onAddCheckpoint: (location: Location) => void
}

export const MapMenuAtLocation = (props: Props) => {
  return (
    <div class="box relative">
      <h6 class="p-m gap-m flex justify-space-between align-center">
        <span>
          {props.location.lat.toFixed(4)}, {props.location.lng.toFixed(4)}
        </span>
        <button class="discrete small close button" onClick={props.onClose} />
      </h6>

      <hr />

      <div
        role="option"
        class="p-m pointer flex gap-m align-center"
        onClick={() => props.onAddCheckpoint(props.location)}
      >
        <Icon intent="checkpoint" />
        <span>{'Add a checkpoint here'}</span>
      </div>
      <div
        role="option"
        class="p-m pointer flex gap-m align-center"
        onClick={() => props.onLocatePicture(props.location)}
      >
        <Icon intent="image" />
        <span>{'Locate uploaded picture here'}</span>
      </div>
    </div>
  )
}
