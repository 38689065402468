import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { I18n } from '@travellr/client/i18n/I18n'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { MainNav } from '../../../ui-kit/MainNav.solid-js'
import {
  AddPicturesToUploadButton,
  UploadPicturesModel,
} from '../../components/UploadPictures'
import { messages } from './TripMainNav.i18n'
import { TripModel } from './main'

type Props = {
  class?: string
  model: TripModel
}

export const TripPageMainNav = (props: Props) => {
  const uploadPicturesModel = solidState(props.model.uploadPictures)
  return (
    <MainNav.Slot class={props.class}>
      <MainNav.Home />
      <MainNav.Back />
      <TripMenu
        onRefresh={props.model.resource.fetch}
        onShowCotravellrs={props.model.openCotravellrsModal}
      />
      <Actions
        uploadPicturesModel={uploadPicturesModel()}
        // onAddNote={props.onAddNote}
      />
    </MainNav.Slot>
  )
}

type NavItemTripMenuProps = {
  onShowCotravellrs: () => void
  onRefresh: () => void
}
const TripMenu = (props: NavItemTripMenuProps) => {
  const { t } = I18n(messages)
  return (
    <MainNav.Burger
      optionsToAdd={[
        <MainNav.BurgerOption
          class="nowrap"
          onClick={props.onShowCotravellrs}
          icon={<Icon intent="cotraveler" />}
          label={t('cotravellrs')}
        />,
        <MainNav.BurgerOption
          onClick={props.onRefresh}
          icon={<Icon intent="refresh" />}
          label={t('verb/refresh')}
        />,
      ]}
    />
  )
}

type NavItemMapActionsProps = {
  uploadPicturesModel: O.Option<UploadPicturesModel>
  // onAddNote: () => void
}
const Actions = (props: NavItemMapActionsProps) => {
  // const onAddNote = flow(
  //   disabled,
  //   boolean.fold(constUndefined, cnst(props.onAddNote)),
  // )

  return (
    <div class="navItem relative addActions" role="combobox">
      <button class="block h-100 w-100 pointer">
        <Icon intent="add" />
      </button>
      <div class="options p-s absolute z-3 vspacer-m">
        {pipe(
          props.uploadPicturesModel,
          O.fold(constNull, (model) => (
            <AddPicturesToUploadButton
              model={model}
              role="option"
              tabIndex={0}
              class="h3 shadowed"
            >
              <Icon intent="gallery" />
            </AddPicturesToUploadButton>
          )),
        )}

        {/* <h3
          role="option"
          tabIndex={0}
          class="shadowed"
          onClick={onAddNote()}
          aria-disabled={disabled()}
        >
          <Icon intent="note" />
        </h3> */}
      </div>
    </div>
  )
}
