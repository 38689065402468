import { struct } from '@/std/data'
import { flow } from '@/std/function'
import { ComponentProps, For, JSX, splitProps } from 'solid-js'
import { Except } from 'type-fest'
import { SolidFormControl } from './adapters'

export type SelectInputProps<T extends string> = Except<
  ComponentProps<'select'>,
  'value' | 'onChange'
> & {
  control: SolidFormControl<T>
  options: Array<{ label: JSX.Element; value: T }>
  placeholder?: string
}

export const SelectInput = <T extends string>(props: SelectInputProps<T>) => {
  const [, selectProps] = splitProps(props, [
    'control',
    'options',
    'placeholder',
  ])
  return (
    <select
      {...selectProps}
      value={props.control()}
      onChange={flow(
        struct.lookup('currentTarget'),
        // @ts-ignore I'm smarter
        struct.lookup('value'),
        props.control.set,
      )}
      onBlur={() => props.control.isTouched.set(true)}
    >
      <option value="">{props.placeholder ?? 'Select'}</option>
      <For each={props.options}>
        {(option) => <option value={option.value}>{option.label}</option>}
      </For>
    </select>
  )
}
