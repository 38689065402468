import { O, list } from '@/std/data'
import { flow, pipe } from '@/std/function'
import { State, mapState } from '@/std/reactivity'
import { RR, TR } from '@/std/remote'
import {
  PictureCrumb,
  TripPageModel,
} from '@travellr/domain/core/shared-components/trip'
import { LeafletMouseEventHandlerFn } from 'leaflet'
import { FullTrip } from '../../../entity/FullTrip'
import { Location } from '../../../entity/Location'
import { Note } from '../../../entity/Note'
import { Picture } from '../../../entity/Picture'
import { TripId } from '../../../entity/Trip'
import { ArchiveCheckpointAction } from '../../../use-case/archive-checkpoint/client'
import { ArchivePictureAction } from '../../../use-case/archive-picture/client'
import { AddNote, UpdateNote } from '../../../use-case/edit-diary-note/client'
import { GetTripResource } from '../../../use-case/get-trip/client'
import { UpdatePicture } from '../../../use-case/update-picture/client'
import { AddCheckpointFormModel } from '../../components/AddCheckpointForm'
import { FixUndatedPicturesDialogModel } from '../../components/FixUndatedPicturesDialog'
import { NoteFormModel } from '../../components/NoteForm'
import { PickPictureModel } from '../../components/PickPicture'
import { PictureFormModel } from '../../components/PictureForm'
import { UploadPicturesModel } from '../../components/UploadPictures'
import { CotravellrsModalModel } from './CotravellrModal'
import { FullTripThunk } from './thunk'

export type TripModel = ReturnType<typeof TripModel>
type Position = { x: number; y: number }

type MapView =
  | { type: 'menu-at-location'; location: Location; position: Position }
  | { type: 'locate-on-map'; onDone: (location: Location) => unknown }
  | {
      type: 'add-checkpoint'
      model: AddCheckpointFormModel
      position: Position
    }
  | {
      type: 'pick-picture-at-location'
      location: Location
      model: PickPictureModel
    }
  | { type: 'default' }

type Deps = {
  tripId: TripId
}
export const TripModel = ({ tripId }: Deps) => {
  const resource = GetTripResource(tripId)

  const thunk = FullTripThunk(resource, 1500)
  const fullTripAsOption = mapState(thunk.store, RR.toOption)

  const onMapClick: LeafletMouseEventHandlerFn = (event) => {
    const current = mapView()
    if (current.type === 'locate-on-map') {
      current.onDone(event.latlng)
      model.setDefaultView()
    }
  }
  const onMapLongClick: LeafletMouseEventHandlerFn = (event) => {
    const current = mapView()
    if (current.type === 'default') {
      const { clientX, clientY } = event.originalEvent
      model.setMenuAtLocationView({
        location: event.latlng,
        position: { x: clientX, y: clientY },
      })
    }
  }

  const mapView = State<MapView>({ type: 'default' })
  const archivePictureAction = ArchivePictureAction()
  const archiveCheckpointAction = ArchiveCheckpointAction()
  const updatePicture = flow(
    UpdatePicture(),
    TR.tap(thunk.patchPicture),
    TR.tap(() => model.tripPictureForm.set(O.None())),
  )
  const updateNote = flow(
    UpdateNote(),
    TR.tap(thunk.patchNote),
    TR.tap(() => model.tripNoteForm.set(O.None())),
  )
  const addNote = flow(
    AddNote(),
    TR.tap(thunk.patchNote),
    TR.tap(() => model.tripNoteForm.set(O.None())),
  )

  const effects = [
    archivePictureAction.state.onChange(RR.map(thunk.removePicture)),
    archiveCheckpointAction.state.onChange(RR.map(thunk.removeCheckpoint)),
  ]

  const model = {
    dispose: () => {
      thunk.dispose()
      effects.forEach((effect) => effect.unlisten())
    },
    resource,
    fullTrip: thunk.store,
    page: TripPageModel({
      fullTrip: fullTripAsOption,
      onMapClick,
      onMapLongClick,
    }),
    mapView,
    setDefaultView: () => {
      mapView.set({ type: 'default' })
    },
    setLocateOnMapView: (onDone: (location: Location) => unknown) => {
      mapView.set({ type: 'locate-on-map', onDone })
    },
    setAddCheckpointView: (options: {
      location: Location
      position: Position
    }) => {
      mapView.set({
        type: 'add-checkpoint',
        position: options.position,
        model: AddCheckpointFormModel({
          location: options.location,
          tripId,
          onSuccess: thunk.patchCheckpoint,
        }),
      })
    },
    setMenuAtLocationView: (options: {
      location: Location
      position: Position
    }) => {
      mapView.set({
        type: 'menu-at-location',
        location: options.location,
        position: options.position,
      })
    },
    setPickPictureAtLocationView: (location: Location) => {
      mapView.set({
        type: 'pick-picture-at-location',
        location,
        model: PickPictureModel({
          pictures: pipe(
            fullTripAsOption(),
            O.map(({ pictures }) => pictures),
            O.unwrapOr(() => []),
          ),
          submit: (picture: Picture) => {
            return pipe(
              updatePicture(picture.id, {
                ...picture,
                location: O.Some(location),
              }),
              TR.tap(thunk.patchPicture),
              TR.tap(model.setDefaultView),
            )
          },
        }),
      })
    },
    cotravellrsModal: State(O.None<CotravellrsModalModel>()),
    openCotravellrsModal: () => {
      model.cotravellrsModal.set(O.Some(CotravellrsModalModel({ tripId })))
    },
    tripPictureForm: State(O.None<PictureFormModel>()),
    tripNoteForm: State(
      O.None<{ form: NoteFormModel; type: 'add' | 'edit' }>(),
    ),
    archivePictureAction,
    archiveCheckpointAction,
    showCreateTripNoteForm: (range: { from: Date; until: Date }) => {
      const form = NoteFormModel({
        submit: (values) => addNote(tripId, { ...values, ...range }),
      })
      model.tripNoteForm.set(O.Some({ form, type: 'add' }))
    },
    showUpdateTripNoteForm: (note: Note) => {
      const form = NoteFormModel({
        initialValues: { content: note.content },
        submit: (values) => updateNote(note.id, { ...note, ...values }),
      })
      model.tripNoteForm.set(O.Some({ form, type: 'edit' }))
    },
    showTripPictureForm: (crumb: PictureCrumb) => {
      const form = PictureFormModel({
        initialValues: {
          caption: crumb.caption,
          timestamp: O.Some(crumb.timestamp),
          location: crumb.location,
        },
        submit: (values) => updatePicture(crumb.id, values),
      })
      model.tripPictureForm.set(O.Some(form))
    },

    uploadPictures: mapState(
      fullTripAsOption,
      O.map(() => {
        return UploadPicturesModel({
          tripId,
          onSubmitSuccess: thunk.patchPictures,
        })
      }),
    ),
    fixUndatedPictures: {
      model: mapState(
        fullTripAsOption,
        flow(
          O.flatMap(getTimestampedPictures),
          O.map((pictures) =>
            FixUndatedPicturesDialogModel({
              pictures,
              onUpdated: thunk.patchPicture,
              onArchived: thunk.removePicture,
            }),
          ),
        ),
      ),
    },
  }

  return model
}

const getTimestampedPictures = (fullTrip: FullTrip) => {
  return pipe(
    fullTrip.pictures,
    list.filter((picture) => O.isNone(picture.timestamp)),
    list.toNonEmpty,
  )
}
