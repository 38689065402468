import { solidRemoteResource } from '@/solidjs/reactivity'
import { boolean } from '@/std/data'
import { pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { ComponentProps, createSignal, onCleanup, splitProps } from 'solid-js'
import { Except } from 'type-fest'
import { nativeShareResource, shareNatively } from './ShareOrCopyButton'

type ShareOrCopyProps = Except<ComponentProps<'button'>, 'children'> & {
  data: string
  input: () => HTMLInputElement | undefined
}
export const ShareOrCopyButton = (props: ShareOrCopyProps) => {
  const hasNativeShareCapacity = solidRemoteResource(nativeShareResource)

  return (
    <>
      {pipe(
        hasNativeShareCapacity(),
        RR.fold2(
          () => <>&nbsp;</>,
          () => <CopyButton {...props} />,
          boolean.fold(
            () => <CopyButton {...props} />,
            () => <ShareButton {...props} />,
          ),
        ),
      )}
    </>
  )
}

const CopyButton = (props: ShareOrCopyProps) => {
  const [, rest] = splitProps(props, ['data'])
  const [copied, setCopied] = createSignal(false)
  let timeout: ReturnType<typeof setTimeout> | undefined

  const onClick = () => {
    const input = props.input()
    if (!input) return
    input.select()
    document.execCommand('copy')
    setCopied(true)
    timeout = setTimeout(() => setCopied(false), 3000)
  }
  onCleanup(() => {
    if (timeout) clearTimeout(timeout)
  })
  return (
    <button {...rest} onClick={onClick}>
      <Icon intent={copied() ? 'success' : 'copy'} />
    </button>
  )
}

const ShareButton = (props: ShareOrCopyProps) => {
  const [, rest] = splitProps(props, ['data'])
  const onClick = () => {
    shareNatively(props.data)
  }
  return (
    <button {...rest} onClick={onClick}>
      <Icon intent="share" />
    </button>
  )
}
