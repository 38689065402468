import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { constNull, flow, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Dialog } from '@travellr/client/ui-kit/Dialog.solid-js'
import { Show } from 'solid-js'
import { PictureForm } from '../../components/PictureForm.solid'
import { TripModel } from './main'

type Props = {
  model: TripModel
}
export const TripPictureFormModal = (props: Props) => {
  const form = solidState(props.model.tripPictureForm)
  const mapView = solidState(props.model.mapView)

  return (
    <Show when={mapView().type !== 'locate-on-map'}>
      {pipe(
        form(),
        O.fold(constNull, (form) => {
          const hide = () => props.model.tripPictureForm.set(O.None())
          const submitState = solidState(form.state)
          const formId = 'trip-picture-form'
          return (
            <Dialog onClose={hide} dialogClass="px-m">
              <header class="py-m">
                <h3>Update Picture</h3>
              </header>

              <hr />

              <div class="py-m">
                <PictureForm
                  id={formId}
                  model={form}
                  hideSubmitButton
                  onPickLocation={() => {
                    props.model.setLocateOnMapView(
                      flow(O.Some, form.controls.location.set),
                    )
                  }}
                />
              </div>

              <hr />

              <footer class="py-m flex justify-end gap-m">
                <button class="discrete button" type="button" onClick={hide}>
                  Cancel
                </button>

                <button
                  class="button"
                  type="submit"
                  form={formId}
                  disabled={RR.isPending(submitState())}
                >
                  Save
                </button>
              </footer>
            </Dialog>
          )
        }),
      )}
    </Show>
  )
}
