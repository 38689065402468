import { createClickAwayEffect } from '@/solidjs/clickaway'
import { onCleanup, onMount } from 'solid-js'
import { JSX } from 'solid-js/jsx-runtime'
import { createContextMenuMarker } from '../../../shared-components/trip'
import { Location } from '../../entity/Location'

type Position = {
  x: number
  y: number
}

type Props = {
  onClose: () => void
  children: JSX.Element
  position: Position
  location: Location
  map: L.Map
}

export const MapContextMenu = (props: Props) => {
  let divRef: HTMLDivElement | undefined
  createClickAwayEffect(() => divRef, props.onClose)
  const marker = createContextMenuMarker({ location: props.location })

  onMount(() => marker.addTo(props.map))
  onCleanup(() => marker.remove())

  return (
    <div
      ref={divRef}
      class="absolute z-3 pt-m w-100 flex justify-center text-left"
      style={{ top: `${props.position.y}px` }}
    >
      {props.children}
    </div>
  )
}
