import { metrics } from '@opentelemetry/api'

export const makeCounter = (meterName: string, counterName: string) => {
  const meter = metrics.getMeter(meterName)
  const counter = meter.createCounter(counterName)
  return (
    attributes?: Record<string, string | number | boolean | undefined>,
    increment?: number,
  ) => {
    counter.add(increment ?? 1, attributes)
  }
}
export const Metrics = {
  makeCounter,
}
