export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'control/email.invalid': 'Invalid email 😕',
  'control/email.label': 'Email',
  'control/email.required': 'An email is required 🙏',

  'verb/abort': 'Abort',
  'verb/add': 'Add',
  'verb/cancel': 'Cancel',
  'verb/confirm': 'Confirm',
  'verb/close': 'Close',
  'verb/edit': 'Edit',
  'verb/refresh': 'Refresh',
  'verb/retry': 'Retry',
  'verb/remove': 'Remove',
  'verb/save': 'Save',
  'verb/signOut': 'Sign out',

  'Main/reloading.message':
    'Hold on a sec, a newer version has been detected\n\nReloading...',
} as const

const fr = {
  'control/email.invalid': 'Email invalide 😕',
  'control/email.label': 'Email',
  'control/email.required': 'Un email est requis 🙏',

  'verb/abort': 'Annuler',
  'verb/add': 'Ajouter',
  'verb/cancel': 'Annuler',
  'verb/confirm': 'Confirmer',
  'verb/close': 'Fermer',
  'verb/edit': 'Éditer',
  'verb/refresh': 'Rafraîchir',
  'verb/retry': 'Réessayer',
  'verb/remove': 'Supprimer',
  'verb/save': 'Sauvegarder',
  'verb/signOut': 'Déconnexion',

  'Main/reloading.message':
    'Une nouvelle version a été détectée\n\nRechargement...',
} satisfies MessagesShape as unknown as Messages

export const messages = { fr, en }
