import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { preventDefault } from '@/std/browser'
import { Email, O } from '@/std/data'
import { flow } from '@/std/function'
import { State } from '@/std/reactivity/state'
import { RemoteAction, TR } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { pathToTravellr } from '@travellr/domain/core/travellr/client'
import { pathTo } from '../../client/client-router'
import { Account, AccountId } from '../../entity/Account'
import { Profile } from '../../entity/Profile'
import { contract } from './contract'

type Deps = {
  email: Email
  accountId: AccountId
  account: State<O.Option<Account>>
  profile: State<O.Option<Profile>>
  initialCode?: string
}
export type SigninWithCode = ReturnType<typeof SigninWithCode>
export const SigninWithCode = ({
  email,
  accountId,
  account,
  profile,
  initialCode,
}: Deps) => {
  const { xhr, config, history } = getClientContext()
  const client = XhrApiRouteClient(contract, xhr)
  const signInWithCode = flow(
    (code: string) => client({ body: { accountId, code } }),
    TR.tap(({ body }) => {
      account.set(O.Some(body.account))
      profile.set(body.profile)
    }),
  )

  const redirectToMyTrip = () => history.replace(pathToTravellr.myTrips)

  const action = RemoteAction(flow(signInWithCode, TR.tap(redirectToMyTrip)))
  const code = State('')

  const submit = preventDefault(() => action.trigger(code()))
  const effects = [
    code.onChange((value) => {
      if (value.length === 6) submit()
    }),
  ]
  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },
    init: () => {
      if (initialCode) code.set(initialCode)
    },
    email,
    code,
    submitState: action.state,
    isDemoAccount: config.demoEmails.includes(email),
    link: {
      sendLinkToSignIn: pathTo.sendLinkToSignin(email),
    },
  }
}
