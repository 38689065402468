import { History } from '@/std/browser'
import { Context } from '@/std/context'
import { XhrClient } from '@/std/http'
import { Logger } from '@/std/logger'
import { State } from '@/std/reactivity'
import { Config } from '@travellr/client/config'
import { Locale } from '@travellr/domain/core/i18n'

export type ClientContext = {
  xhr: XhrClient
  logger: Logger
  history: History
  config: Config
  locale: State<Locale>
}

export const [setClientContext, getClientContext] =
  Context<ClientContext>('client')
