import { apply } from '../function'

export type Listener<T> = (value: T) => void
export type Listen<T> = (listener: Listener<T>) => { unlisten: () => void }
export type SingleEventTarget<T> = {
  listen: Listen<T>
  notify: (value: T) => void
  destroy: () => void
}

export const SingleEventTarget = <T>(): SingleEventTarget<T> => {
  const listeners = new Set<Listener<T>>()

  const target = {
    get listeners() {
      return listeners
    },
    listen: (listener) => {
      listeners.add(listener)
      return {
        unlisten: () => {
          listeners.delete(listener)
        },
      }
    },
    notify: (value) => listeners.forEach(apply(value)),
    destroy: () => {
      listeners.clear()
    },
  }
  return target
}

// export const composeSingleEventTarget = <T extends TupleOf<any>, U>(targets: { [Key in keyof T]: SingleEventTarget<T[Key]> }, map: (...values: T) => U): SingleEventTarget<U> => {
//   const listeners: Array<{ unlisten: () => void }> = []
//   const target: SingleEventTarget<U> = {
//     listen: (listener) => {
//       listeners.push()
//     }
//   }
// }
