import pell from 'pell'
import 'pell/dist/pell.css'
import { ComponentProps, createEffect, splitProps } from 'solid-js'
import { Except } from 'type-fest'
import { defaultPellActions } from './config'

export type PellEditorProps = Except<ComponentProps<'div'>, 'onChange'> & {
  value: string
  onChange: (content: string) => void
  config?: Partial<
    Except<pell.pellConfig<HTMLDivElement>, 'element' | 'onChange'>
  >
}

export const PellEditor = (props: PellEditorProps) => {
  const [, rest] = splitProps(props, ['value', 'onChange', 'config'])
  const editor = pell.init({
    ...props.config,
    element: (<div {...rest} />) as HTMLDivElement,
    actions: props.config?.actions ?? defaultPellActions,
    onChange: props.onChange,
  })
  createEffect(() => {
    const current = props.value
    if (current !== editor.content.innerHTML) editor.content.innerHTML = current
  })
  return editor
}
