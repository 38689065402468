import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { struct } from '@/std/data'
import { pipe } from '@/std/function'
import { MimeType } from '@/std/http'
import { LocaleCodec } from '@travellr/domain/core/i18n'
import { AccountId, AccountStruct } from '../../entity/Account'

export const contract = ApiRouteContract('POST', '/request-signin', {
  body: {
    contentType: MimeType.Json,
    codec: pipe(AccountStruct, struct.pick('email'), C.struct),
  },
  headers: C.struct({ locale: LocaleCodec }),
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: C.struct({ accountId: AccountId }),
  },
})
