import { struct } from '@/std/data'
import { flow } from '@/std/function'
import { ComponentProps, splitProps } from 'solid-js'
import { Except } from 'type-fest'
import { SolidFormControl } from './adapters'

export type TextInputProps = Except<
  ComponentProps<'input'>,
  'value' | 'type' | 'onChange'
> & {
  control: SolidFormControl<string, any>
}

export const TextInput = (props: TextInputProps) => {
  const [, inputProps] = splitProps(props, ['control'])
  return (
    <input
      {...inputProps}
      value={props.control()}
      onInput={flow(
        struct.lookup('currentTarget'),
        struct.lookup('value'),
        props.control.set,
      )}
      onBlur={() => props.control.isTouched.set(true)}
    />
  )
}
