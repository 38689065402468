import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { struct } from '@/std/data'
import { pipe } from '@/std/function'
import { MimeType } from '@/std/http'
import { Checkpoint, CheckpointStruct } from '../../entity/Checkpoint'

const PayloadCodec = pipe(CheckpointStruct, struct.omit('id'), C.struct)
export type Payload = C.TypeOf<typeof PayloadCodec>

export const contract = ApiRouteContract('POST', '/checkpoint', {
  body: {
    contentType: MimeType.Json,
    codec: PayloadCodec,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: Checkpoint,
  },
})
