import { T } from '@/std/type'

export const ImageSelfHostedStorage = T.literal('inMemory', 'postgres')
export type ImageSelfHostedStorage = ReturnType<typeof ImageSelfHostedStorage>

export const ImageS3Storage = T.literal('storj')
export type ImageS3Storage = ReturnType<typeof ImageS3Storage>

export type ImageStorage = ImageSelfHostedStorage | ImageS3Storage
export const ImageStorage: T.Type<ImageStorage, string> = T.union(
  ImageSelfHostedStorage,
  ImageS3Storage,
)

export const isImageSelfHostedStorage = ImageSelfHostedStorage.is
export const isImageS3Storage = ImageS3Storage.is
export const isImageStorage = ImageStorage.is

export const makeThumbnailFileName = (fileName: string) =>
  `${fileName}-thumbnail`
