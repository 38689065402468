import { ApiRouteContract } from '@/std/api-contract'
import { C, Cd } from '@/std/codec'
import { MimeType } from '@/std/http'
import { Account } from '../../entity/Account'
import { Profile } from '../../entity/Profile'

export type Payload = C.TypeOf<typeof PayloadCodec>
const PayloadCodec = C.struct({
  name: C.string,
  email: Cd.Email,
})

export const contract = ApiRouteContract('PATCH', '/profile', {
  body: {
    contentType: MimeType.Json,
    codec: PayloadCodec,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: C.struct({
      account: Account,
      profile: Profile,
    }),
  },
})
