export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'addNoteTitle': 'Add Note',
  'editNoteTitle': 'Edit Note',
} as const

const fr = {
  'addNoteTitle': 'Ajouter une note',
  'editNoteTitle': 'Modifier une note',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
