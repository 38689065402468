import { preventDefault } from '@/std/browser'
import { O, string } from '@/std/data'
import { FormControl, FormGroup } from '@/std/form-control'
import { pipe } from '@/std/function'
import { RemoteAction, TR } from '@/std/remote'
import { Location } from '../../entity/Location'

type FormValues = {
  timestamp: O.Option<Date>
  location: O.Option<Location>
  caption: O.Option<string>
}

export type PictureFormModel = ReturnType<typeof PictureFormModel>

type Deps = {
  initialValues?: FormValues
  submit: (values: FormValues) => TR.TaskResult<unknown, unknown>
}
export const PictureFormModel = ({ initialValues, submit }: Deps) => {
  const controls = FormGroup({
    timestamp: FormControl(initialValues?.timestamp ?? O.None()),
    location: FormControl(initialValues?.location ?? O.None()),
    caption: FormControl(
      O.toUndefined(initialValues?.caption ?? O.None<string>()) ?? '',
    ),
  })

  const action = RemoteAction(submit)

  return {
    controls,
    state: action.state,
    submit: preventDefault(async () => {
      await action.trigger({
        caption: pipe(controls.caption(), O.fromPredicate(string.isNotEmpty)),
        timestamp: controls.timestamp(),
        location: controls.location(),
      })
    }),
  }
}
