import { Link } from '@/solidjs/router'
import { Page } from '@travellr/client/ui-kit/Page.solid-js'

export const NotFound = () => (
  <Page contentClass="flex align-center justify-center" content={<Main />} />
)

const Main = () => (
  <div>
    <h1>Page not found</h1>
    <h6>
      Go back to the{' '}
      <Link class="a" href="/">
        home page
      </Link>
    </h6>
  </div>
)
