import { makeInfraError } from '@/std/error'
import { Server } from 'node:http'
import { O } from '../data'
import { flow } from '../function'
import { TR } from '../remote'
import { ApiServer } from './server'

export const nodeServerToApiServer =
  (infraName: string) =>
  (server: Server): ApiServer => ({
    listen: ({ path, ...options }) => {
      const listen = () =>
        new Promise<void>((resolve) => server.listen(options, resolve))
      return TR.try(listen, makeInfraError(`${infraName} listen error`))
    },
    unlisten: () =>
      TR.new((resolve, reject) => {
        server.close(
          flow(
            O.fromNullable,
            O.map(makeInfraError(`${infraName} close error`)),
            O.fold(resolve, reject),
          ),
        )
      }),
  })
