export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'name.label': 'Name',
  'name.required': 'Required',
} as const

const fr = {
  'name.label': 'Nom',
  'name.required': 'Requis',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
