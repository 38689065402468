import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { Cotravellr } from '../../entity/Cotravellr'

export const contract = ApiRouteContract('POST', '/cotravellr', {
  body: {
    contentType: MimeType.Json,
    codec: Cotravellr,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: Cotravellr,
  },
})
