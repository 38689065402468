import { date } from '@/std/data'

/**
 * @deprecated use `date.format` instead
 */
export const format = date.format

export const formatDateTimeShort = date.format({
  dateStyle: 'short',
  timeStyle: 'short',
})
export const formatDate = date.format({ dateStyle: 'medium' })
