import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteResource } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { TripId } from '../../entity/Trip'
import { RemoveCotravellr } from '../remove-cotravellr/client'
import { contract } from './contract'

type Init = {
  tripId: TripId
}

export type ListCotravellrsModel = ReturnType<typeof ListCotravellrsModel>
export const ListCotravellrsModel = ({ tripId }: Init) => {
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const list = RemoteResource(() => client({ params: { selfTripId: tripId } }))

  return {
    list,
    RemoveCotravellr: RemoveCotravellr,
  }
}
