export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'day': 'Day',
  'week': 'Week',
  'month': 'Month',
  'full': 'All trip',
} as const

const fr = {
  'day': 'Jour',
  'week': 'Semaine',
  'month': 'Mois',
  'full': 'Tout',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
