// @ts-check
import deepmerge from 'ts-deepmerge'
import { Except } from 'type-fest'
import { Eq } from './eq'
import * as O from './option'
import { Ord } from './ord'
import { TupleOf } from './tuple'

type AnyStruct = Record<string, any>
type Mapper<Struct extends AnyStruct, Out> = <K extends keyof Struct>(
  value: Struct[K],
  key: K,
) => Out
type Predicate<Struct extends AnyStruct> = Mapper<Struct, unknown>

type FindFirstMap = <S extends AnyStruct, O>(
  mapper: Mapper<S, O.Option<O>>,
) => (struct: S) => O.Option<O>
export const findFirstMap: FindFirstMap = (mapper) => (struct) => {
  for (const key in struct) {
    const mapped = mapper(struct[key], key)
    if (O.isSome(mapped)) return mapped
  }
  return O.None()
}

type Lookup = <S extends AnyStruct, K extends keyof S>(
  key: K,
) => (struct: S) => S[K]
export const lookup: Lookup = (key) => (struct) => struct[key]

type BindTo = <K extends string>(
  property: K,
) => <T>(value: T) => { [Key in K]: T }
export const bindTo: BindTo =
  (property) =>
  (value): any => ({ [property]: value })

type Keys = <S extends AnyStruct>(r: S) => (keyof S)[]
export const keys = Object.keys as Keys

type Values = <S extends AnyStruct>(struct: S) => S[keyof S][]
export const values = Object.values as Values

type Entries = <S extends AnyStruct>(r: S) => [keyof S, S[keyof S]][]
export const entries = Object.entries as Entries
type FromEntries = <V, K extends PropertyKey>(
  entries: Iterable<[K, V]>,
) => { [Key in K]: V }
export const fromEntries = Object.fromEntries as FromEntries

type Reduce = <S extends AnyStruct, O>(
  initial: O,
  reducer: <K extends keyof S>(acc: O, value: S[K], key: K) => O,
) => (struct: S) => O
export const reduce: Reduce = (initial, reducer) => (struct) => {
  let acc = initial
  for (const key in struct) acc = reducer(acc, struct[key], key)
  return acc
}

type Map = <T extends AnyStruct, O>(
  mapper: Mapper<T, O>,
) => (struct: T) => Record<keyof T, O>

export const map: Map = (mapper) =>
  reduce({} as any, (acc, value, key) =>
    Object.assign(acc, { [key]: mapper(value, key) }),
  )

type Some = <S extends AnyStruct>(
  predicate: Predicate<S>,
) => (struct: S) => boolean
export const some: Some = (predicate) => (struct) => {
  for (const key in struct) if (predicate(struct[key], key)) return true
  return false
}

type PickBy = <S extends AnyStruct>(
  predicate: Predicate<S>,
) => (struct: S) => Partial<S>

export const pickBy: PickBy = (predicate) =>
  reduce({} as any, (acc, value, key) =>
    predicate(value, key) ? Object.assign(acc, { [key]: value }) : acc,
  )

type Omit = <S extends AnyStruct, Keys extends TupleOf<keyof S>>(
  ...omit: Keys
) => (struct: S) => Except<S, Keys[number]>
export const omit: Omit = (...omit): any =>
  pickBy((_, key) => !omit.includes(key))

type PickFn = <S extends AnyStruct, Keys extends TupleOf<keyof S>>(
  ...keys: Keys
) => (struct: S) => Pick<S, Keys[number]>
export const pick: PickFn = (...keys): any =>
  pickBy((_, key) => keys.includes(key))

type Invert = <T extends Record<PropertyKey, PropertyKey>>(
  struct: T,
) => Inverted<T>
export const invert: Invert = reduce({} as any, (acc, value, key) =>
  Object.assign(acc, { [value]: key }),
)

type Merge = <T extends any[]>(...args: T) => ReturnType<typeof deepmerge<T>>
export const merge = deepmerge as Merge

export const ord = <Key extends string, Value>(key: Key, ord: Ord<Value>) =>
  Ord.fromCompare<{ [K in Key]: Value }>((a, b) => ord.compare(a[key], b[key]))

type Inverted<T extends Record<PropertyKey, PropertyKey>> = {
  [Key in keyof T]: { [K in T[Key]]: Key }
}[keyof T]

export const eq = <T extends AnyStruct>(
  eqStruct: Partial<{
    [Key in keyof T]: Eq<T[Key]>
  }>,
): Eq<T> =>
  Eq.fromEquals((a, b) => {
    for (const key in eqStruct) {
      const eq = eqStruct[key]
      if (eq && !eq.equals(a[key], b[key])) return false
    }
    return true
  })
