import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { FixUndatedPictures } from '../../components/FixUndatedPicturesDialog.solid'
import { TripModel } from './main'

type Props = {
  model: TripModel
}
export const FixUndatedPicturesButton = (props: Props) => {
  const model = solidState(props.model.fixUndatedPictures.model)

  return (
    <>
      {pipe(
        model(),
        O.fold(constNull, (model) => (
          <>
            <FixUndatedPictures.Button class="h5" model={model} />
            <FixUndatedPictures.Dialog model={model} />
          </>
        )),
      )}
    </>
  )
}
