import { solidRemoteResource } from '@/solidjs/reactivity'
import { Link } from '@/solidjs/router'
import { O, nonEmptyList } from '@/std/data'
import { flow } from '@/std/function'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { Failure } from '@travellr/client/ui-kit/Failure.solid-js'
import { Loader } from '@travellr/client/ui-kit/Loader.solid-js'
import { pathTo } from '../../client/router'
import { Trip } from '../../entity/Trip'
import { ArchiveTrip } from '../archive-trip/client'
import { ArchiveTrip as ArchiveButton } from '../archive-trip/client.solid-js'
import { ListTrips as Model } from './client'
import { messages } from './client.i18n'

type Props = {
  model: Model
}

export const ListTrips = (props: Props) => {
  const { t } = I18n(messages)
  const trips = solidRemoteResource(props.model.trips)
  return (
    <>
      {flow(
        trips,
        RR.map(nonEmptyList.fromList),
        RR.fold2(
          () => (
            <div class="minh-100 flex align-center justify-center p-m">
              <Loader />
            </div>
          ),
          (error) => (
            <div class="minh-100 flex align-center justify-center p-m">
              <Failure
                class="maxw-100 box p-m"
                error={error}
                onTryAgain={trips.fetch}
              />
            </div>
          ),
          O.fold(
            () => <NoTrips />,
            (trips) => (
              <div class="vspacer-m p-m">
                <div
                  class="box inverted p-xl text-center flex align-center justify-center"
                  style={{
                    background:
                      'url(/assets/woodhut.jpg) no-repeat 0px 70% / cover',
                    'min-height': 'clamp(8rem, 16rem, 20vh)',
                  }}
                >
                  <h2 class="weight-normal cursive text-center">
                    {t('title')}
                  </h2>
                </div>

                {trips.map((trip) => (
                  <TripCard
                    trip={trip}
                    archive={props.model.ArchiveTrip({ trip })}
                  />
                ))}
              </div>
            ),
          ),
        ),
      )}
    </>
  )
}

const NoTrips = () => {
  const { t } = I18n(messages)
  return (
    <div class="p-m">
      <div
        class="main box inverted p-xl text-center flex justify-center align-center"
        style={{
          background: 'url(/assets/road.jpg) center / cover',
          'min-height': '70vh',
        }}
      >
        <h2
          class="weight-normal cursive text-center"
          style={{ 'font-size': '2.25rem' }}
        >
          {t('noTripsMessage')}
        </h2>
      </div>
    </div>
  )
}

type TripCardProps = {
  trip: Trip
  archive: ArchiveTrip
}
const TripCard = (props: TripCardProps) => (
  <div class="box flex align-center justify-space-between">
    <div class="flex align-center px-m py-l">
      <ArchiveButton model={props.archive} />
    </div>
    <Link class="vspacer-m px-m py-l" href={pathTo.trip(props.trip.id)}>
      <h4 class="cursive">{props.trip.name}</h4>
    </Link>
    <Link class="px-m py-l flex align-center" href={pathTo.trip(props.trip.id)}>
      »
    </Link>
  </div>
)
