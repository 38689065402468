import { Email, O } from '@/std/data'
import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import type { Opaque } from 'type-fest'

export type AccountId = Opaque<string, 'AccountId'>
const fromString = cast<string, AccountId>
export const AccountId = pipe(T.string, T.map(fromString, String))

export type AccountPayload = {
  email?: string
  termsAcceptedAt?: Date
  storageLimitInBytes?: number
}

export type Account = {
  id: AccountId
  email: Email
  termsAcceptedAt: O.Option<Date>
  storageLimitInBytes: number
  createdAt: Date
  lastUpdate: Date
}

const date = T.union(T.DateFromString, T.Date)
// const date = T.DateFromString

export const AccountStruct = {
  id: AccountId,
  email: T.Email,
  termsAcceptedAt: T.option(date),
  storageLimitInBytes: T.number,
  createdAt: date,
  lastUpdate: date,
  // might be updated later with billing info or whatever.
}
const type = T.struct(AccountStruct)
type Ord = { ord: typeof type.ord }

export const Account: T.Type<Account> & Ord = type
