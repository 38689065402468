import { LogLevel, Logger } from '.'
import { applyLogLevel } from './utils'

export const ConsoleLogger = (level: LogLevel): Logger => {
  const nowAsStr = () =>
    `[${new Date().toISOString().replace('T', ' ').slice(0, -5)}]`

  return applyLogLevel(level, {
    debug: (...args: any[]) => console.debug(nowAsStr(), ...args),
    info: (...args: any[]) => console.info(nowAsStr(), ...args),
    warn: (...args: any[]) => console.warn(nowAsStr(), ...args),
    error: (...args: any[]) => console.error(nowAsStr(), ...args),
  })
}
