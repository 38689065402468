import { O } from '@/std/data'
import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { ImageStorage } from '@travellr/domain/shared/entity/ImageStorage'
import { Opaque } from 'type-fest'
import { Location } from './Location'
import { TripId } from './Trip'

export type PictureId = Opaque<string, 'PictureId'>
export type Picture = {
  id: PictureId
  tripId: TripId
  timestamp: O.Option<Date>
  location: O.Option<Location>
  caption: O.Option<string>
  storage: ImageStorage
  sizeInBytes: number
}

export const makePictureId = cast<string, PictureId>
export const PictureId = pipe(T.string, T.map(makePictureId, String))

export const PictureStruct = {
  id: PictureId,
  tripId: TripId,
  timestamp: T.option(T.union(T.Date, T.DateFromString)),
  location: T.option(Location),
  caption: T.option(T.string),
  storage: ImageStorage,
  sizeInBytes: T.number,
}

const type = T.struct(PictureStruct)
type Ord = { ord: typeof type.ord }

export const Picture: T.Type<Picture> & Ord = type
