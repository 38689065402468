import { Router } from '@/std/browser/router'
import { lazy } from '@/std/function'
import { getClientContext } from '@travellr/client/context'
import { TripId } from '../entity/Trip'
import { ImportTrip } from './pages/ImportTrip/page'
import { MyTrips } from './pages/MyTrips/page'
import { Profile } from './pages/Profile/page'
import { StartTrip } from './pages/StartTrip/page'
import { TripModel } from './pages/Trip/main'

export { Route as TravellrAppRoute }
type Route =
  | { route: 'AddTrip' }
  | { route: 'MyTrips'; model: () => MyTrips }
  | { route: 'ImportTrip'; model: () => ImportTrip }
  | { route: 'Profile'; model: () => Profile }
  | { route: 'StartTrip'; model: () => StartTrip }
  | { route: 'Trip'; id: string; model: () => TripModel }

export const TravellrAppRouter = () => {
  const { history } = getClientContext()

  return Router<Route>({ history, isSameRoute })({
    '/': () => ({ route: 'MyTrips', model: lazy(() => MyTrips()) }),
    '/add-trip': () => ({ route: 'AddTrip' }),
    '/trip/:id': ({ params }) => ({
      route: 'Trip',
      id: params.id,
      model: lazy(() => TripModel({ tripId: params.id as TripId })),
    }),
    '/start-trip': () => ({
      route: 'StartTrip',
      model: lazy(() => StartTrip()),
    }),
    '/import-trip': () => ({
      route: 'ImportTrip',
      model: lazy(() => ImportTrip()),
    }),
    '/profile': () => ({ route: 'Profile', model: lazy(() => Profile()) }),
  })
}

const isSameRoute = (a: Route, b: Route) => {
  switch (a.route) {
    case 'Trip':
      return b.route === 'Trip' && a.id === b.id
    default:
      return a.route === b.route
  }
}

export const pathTo = {
  // home: '/',
  myTrips: '/',
  trip: (id: TripId) => `/trip/${id}`,
  addTrip: '/add-trip',
  startTrip: '/start-trip',
  importTrip: '/import-trip',
  profile: '/profile',
}
