import { Router } from '@/std/browser/router'
import { getClientContext } from '@travellr/client/context'

export type SandboxAppRoute = { route: 'Sandbox' }

export const SandboxRouter = () => {
  const { history } = getClientContext()
  return Router<SandboxAppRoute>({ history })({
    '/sandbox': () => ({ route: 'Sandbox' }),
  })
}

export const pathTo = {
  sandbox: '/sandbox',
}
