import { cx } from '@/std/classNames'
import { ComponentProps, Show, splitProps } from 'solid-js'
import { JSX } from 'solid-js/jsx-runtime'
import { Except } from 'type-fest'
import './Page.css'

export type PageProps = Except<ComponentProps<'div'>, 'children'> & {
  map?: JSX.Element
  nav?: JSX.Element
  content: JSX.Element
  contentClass?: string
  // mapOverlays: JSX.Element;
}
export const Page = (props: PageProps) => {
  const [, divProps] = splitProps(props, [
    'map',
    'nav',
    'content',
    'contentClass',
  ])
  const hasMap = () => !!props.map
  return (
    <div
      {...divProps}
      class={cx('Page', divProps.class)}
      classList={{ WithMap: hasMap() }}
    >
      <Show when={props.nav}>
        <div class="PageNav">{props.nav}</div>
      </Show>

      <Show when={hasMap()}>
        <div class={cx('PageContent box overflow-visible', props.contentClass)}>
          {props.content}
        </div>
        <div class="PageMap">{props.map}</div>
      </Show>

      <Show when={!hasMap()}>
        <div class={cx('PageContent', props.contentClass)}>{props.content}</div>
      </Show>
    </div>
  )
}
