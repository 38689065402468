import { C } from '@/std/codec'
import { pipe } from '@/std/function'
import { fetchClient, parseJsonResponse } from '@/std/http'
import { State } from '@/std/reactivity/state'
import { TR } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { config } from './config'

// @ts-ignore defined by bundler
export const version: string = __VERSION__

export type VersionWatcher = ReturnType<typeof VersionWatcher>
export const VersionWatcher = () => {
  const { logger } = getClientContext()
  const reloading = State(false)
  createVersionWatcher({
    onVersionChanged: () => {
      config.env === 'dev'
        ? logger.debug('version changed')
        : (reloading.set(true), globalThis.location.reload())
    },
  })
  return { reloading }
}

export type VersionWatcherOptions = {
  onVersionChanged: () => void
}
const createVersionWatcher = (options: VersionWatcherOptions) => {
  const current = version

  document.addEventListener('visibilitychange', () => {
    if (document.hidden) return
    const task = pipe(
      getDeployInfo,
      TR.tap(({ version }) => {
        if (version !== current) options.onVersionChanged()
      }),
    )
    void task()
  })
}

const getDeployInfo = pipe(
  fetchClient('/build/deploy.json', { cache: 'no-store' }),
  TR.flatMap(parseJsonResponse(C.struct({ version: C.string }))),
)
