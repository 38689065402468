import { O } from '@/std/data'
import { State } from '@/std/reactivity'
import { Account } from '../entity/Account'
import { Profile } from '../entity/Profile'
import { SignInWithToken } from '../use-case/signin-with-token/client'
import { AuthRouter } from './client-router'

type Deps = {
  account: State<O.Option<Account>>
  profile: State<O.Option<Profile>>
}
export type AuthApp = ReturnType<typeof AuthApp>
export const AuthApp = ({ account, profile }: Deps) => {
  const autoSignIn = SignInWithToken({ account, profile })

  // NOTE: handle auto signin pending state.
  return {
    init: async () => {
      await autoSignIn.trySigninInWithToken()
    },
    router: AuthRouter({ account, profile }),
  }
}
