import { I18n } from '@travellr/client/i18n/I18n'
import { makeConfirmAction } from '@travellr/client/ui-kit/ConfirmDialog.solid-js'
import { ArchiveTrip as Model } from './client'
import { messages } from './client.i18n'

type Props = {
  model: Model
}

export const ArchiveTrip = (props: Props) => {
  const { t } = I18n(messages)
  const Confirm = makeConfirmAction(props.model)
  return (
    <>
      <Confirm.Button class="discrete close button" />
      <Confirm.Dialog
        message={t('confirmDeletion', { name: props.model.trip.name })}
      />
    </>
  )
}
