import { O, R, list, number, string } from '@/std/data'
import { BoomOf, makeBoom } from '@/std/error'
import { flow, pipe } from '@/std/function'
import { T, TR } from '@/std/remote'
import { gps, parse } from 'exifr'
import { date } from '../std/data'

export const ExifLocationError = makeBoom('ExifLocationError', String)
/**
 * @typedef {import('@/std/error').BoomOf<typeof Ex>} ExifLocationError
 */
export type ExifLocationError = BoomOf<typeof ExifLocationError>

export const ExifTimestampError = makeBoom('ExifTimestampError', String)
export type ExifTimestampError = BoomOf<typeof ExifTimestampError>

// const getThumbnailDataUrl = (buffer: Buffer) =>
//   pipe(
//     TR.try(() => thumbnailUrl(buffer), ExifError('getThumbnailDataUrl')),
//     TR.map(O.fromNullable),
//   )

const optionalNumberNotNaN = (n: number | undefined) =>
  pipe(n, O.fromNullable, O.flatMap(O.fromPredicate(number.isNotNaN)))

const getLocation = (buffer: Blob | Buffer) =>
  pipe(
    TR.try(() => gps(buffer), ExifLocationError),
    TR.map((gps) =>
      O.struct({
        lat: optionalNumberNotNaN(gps?.latitude),
        lng: optionalNumberNotNaN(gps?.longitude),
      }),
    ),
    T.map(O.fromResult),
    T.map(O.flatten),
    T.map(R.Ok),
  )

const getTimestamp = (buffer: Blob | Buffer) =>
  pipe(
    TR.try(
      () =>
        parse(buffer, {
          pick: [
            'DateTimeOriginal',
            'CreateDate',
            'GPSDateStamp',
            'GPSTimeStamp',
            'ModifyDate',
          ],
          reviveValues: false,
        }),
      ExifTimestampError,
    ),
    TR.map(O.fromNullable),
    TR.map(
      O.flatMap((meta) =>
        pipe(
          [
            meta.DateTimeOriginal,
            meta.CreateDate,
            meta.GPSDateStamp &&
              meta.GPSTimeStamp &&
              `${meta.GPSDateStamp} ${meta.GPSTimeStamp.join(':')}`,
            meta.ModifyDate,
          ],
          list.findFirstMap(flow(O.fromNullable, O.map(reviveDate))),
        ),
      ),
    ),
    T.map(O.fromResult),
    T.map(O.flatten),
    T.map(R.Ok),
    TR.map(O.flatMap(O.refine(date.isDate))),
  )

// shape is yyyy:mm:dd hh:mm:ss, convert to yyyy-mm-dd hh:mm:ss Z
const reviveDate = flow(
  string.replaceFirst(':', '-'),
  string.replaceFirst(':', '-'),
  date.utcFromString,
)

export const Exif = {
  // getThumbnailDataUrl,
  getLocation,
  getTimestamp,
}
