import { O, struct } from '@/std/data'
import { debounce, flow, pipe } from '@/std/function'
import { State } from '@/std/reactivity/state'
import { RuntimeError } from '../domain/core/admin/entity/RuntimeError'
import {
  Account,
  AccountId,
} from '../domain/core/authentication/entity/Account'

type Init = {
  account: State<O.Option<Account>>
}

export type ErrorBoundary = ReturnType<typeof ErrorBoundary>
export const ErrorBoundary = ({ account }: Init) => {
  const report = State(O.None<RuntimeError>())
  const accountId = flow(account, O.map(struct.lookup('id')))

  window.addEventListener(
    'error',
    pipe((event) => {
      const where = `${event.filename}:${event.lineno}:${event.colno}`
      const detail = [event.error?.toString?.(), where]
        .filter(Boolean)
        .join(' at ')

      const r = makeErrorReport({
        accountId,
        source: 'error',
        detail,
        path: event.composedPath(),
        stack: stackOf(event.error),
      })
      report.set(O.Some(r))
    }, debounce(250)),
  )

  window.addEventListener(
    'unhandledrejection',
    pipe((event) => {
      const detail = event.reason?.toString?.()
      console.error(event)
      const r = makeErrorReport({
        accountId,
        source: 'unhandledrejection',
        detail,
        path: event.composedPath(),
        stack: stackOf(event.reason),
      })
      report.set(O.Some(r))
    }, debounce(250)),
  )

  return { report }
}

const stackOf = (e: unknown) => (e instanceof Error ? e.stack : undefined)

const makeErrorReport = (options: {
  accountId: () => O.Option<AccountId>
  source: 'error' | 'unhandledrejection'
  detail: string
  path: EventTarget[]
  stack: string | undefined
}): RuntimeError => {
  const path = options.path.map(mapEventTarget).join(' > ')
  const report = {
    accountId: options.accountId(),
    source: options.source,
    path,
    detail: options.detail,
    stack: O.fromNullable(options.stack),
    url: location.href,
  }
  return report
}

const mapEventTarget = (target: EventTarget) => {
  if (target instanceof Element)
    return [
      target.nodeName.toLowerCase(),
      target.id && `#${target.id}`,
      target.className && `.${target.className.split(' ').join('.')}`,
    ]
      .filter(Boolean)
      .join('')
  else if (target instanceof Node)
    return [
      target.nodeName.toLowerCase(),
      target.textContent && `"${target.textContent}"`,
    ]
      .filter(Boolean)
      .join(' ')
  return target.constructor?.name
}
