// export type CacheControlRequestHeader = {
//   'max-age': unknown
//   'max-stale': unknown
//   'min-fresh': unknown
//   'no-cache': unknown
//   'no-store': unknown
//   'no-transform': unknown
//   'only-if-cached': unknown
//   'stale-if-error': unknown
// }

import { durationIn } from '../data/duration'

// export type CacheControlResponseHeader = {
//   'max-age': number // in seconds
//   's-maxage': number // in seconds. Same as max-age but for shared caches (CDNs, etc.). Can exist with max-age

//   'no-cache': boolean // cache but always revalidate before use.
//   'no-store': boolean // don't cache.
//   'must-revalidate': boolean // cached response can be used if not stale. Otherwise revalidate with server
//   'proxy-revalidate': boolean // same as 'must-revalidate' for shared caches
//   'stale-while-revalidate': number // in seconds, superior to max-age. A duration during which cached response can be used while revalidating with the server.

//   'stale-if-error': unknown
//   // 'no-transform': unknown // avoid transforming images for instance.
//   // 'must-understand': unknown // too complicated for me.

//   private: boolean
//   public: boolean

//   immutable: boolean // do not update response if fresh. To be coupled with max-age
// }

export const CacheControl = {
  maxAge: {
    maxAdvisedValue: () => durationIn('seconds').days(365),
  },
}

type CacheAccess = 'public' | 'private'
export type CacheControlResponseHeader =
  | NoCacheStrategy
  | CacheStrategy
  | RevalidateStrategy
  | StaleWhileRevalidateStrategy

export const serializeCacheControlResponseHeader = (
  value: CacheControlResponseHeader,
) => {
  switch (value.strategy) {
    case 'no-cache':
    case 'no-store':
      return value.strategy
    case 'must-revalidate':
      return `max-age=${value.maxAge}, must-revalidate`
    case 'proxy-revalidate':
      return `s-maxage=${value.maxAge}, proxy-revalidate`
    case 'private':
    case 'public':
      return [
        value.strategy,
        value.maxAge && `max-age=${value.maxAge}`,
        value.immutable && 'immutable',
      ]
        .filter(Boolean)
        .join(', ')
    case 'stale-while-revalidate':
      return [
        value.access,
        `max-age=${value.maxAge}`,
        `stale-while-revalidate=${value.maxAge + value.maxStaleAfterMaxAge}`,
      ]
        .filter(Boolean)
        .join(', ')
  }
}

export type NoCacheStrategy = {
  strategy: 'no-store' | 'no-cache'
}

export type CacheStrategy = {
  strategy: CacheAccess
  maxAge?: number
  immutable?: boolean
}
export type RevalidateStrategy = {
  strategy: 'must-revalidate' | 'proxy-revalidate'
  maxAge: number // in seconds
}
export type StaleWhileRevalidateStrategy = {
  strategy: 'stale-while-revalidate'
  maxAge: number
  maxStaleAfterMaxAge: number
  access?: CacheAccess
}
