import { not } from '../function'
import { Eq } from './eq'
import { Ord } from './ord'
import { strictEquals } from './strict-equals'

const ord = Ord.fromCompare<number>((a, b) => a - b)
export const number = {
  eq: Eq.fromEquals<number>(strictEquals),
  ord,
  isNotNaN: not(Number.isNaN) as IsNotNaN,
  isBetween: Ord.between(ord),
  min: Ord.min(ord),
  max: Ord.max(ord),
  clamp: Ord.clamp(ord),
  lt: Ord.lt(ord),
  lte: Ord.lte(ord),
  gt: Ord.gt(ord),
  gte: Ord.gte(ord),
}

type IsNotNaN = (value: number) => value is number
