import { Link } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { matchErrOr } from '@/std/error'
import { pipe } from '@/std/function'
import { matchHttpCode } from '@/std/http'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { Failure } from '@travellr/client/ui-kit/Failure.solid-js'
import { Loader } from '@travellr/client/ui-kit/Loader.solid-js'
import { onCleanup, onMount } from 'solid-js'
import { JSX } from 'solid-js/jsx-runtime'
import { Page } from '../../ui-kit/Page.solid-js'
import { SigninWithCode as Model } from './client'
import { messages } from './client.i18n'

type Props = {
  model: Model
}

export const SigninWithCode = (props: Props) => {
  onMount(props.model.init)
  onCleanup(props.model.dispose)
  const { link, email, isDemoAccount } = props.model
  const submitState = solidState(props.model.submitState)
  const code = solidState(props.model.code)
  const { t } = I18n(messages)

  return (
    <Page>
      {pipe(
        submitState(),
        RR.fold(
          () => (
            <div class="vspacer-m text-center">
              <h1>{t('heading')}</h1>
              {isDemoAccount ? (
                <>
                  <p>{t('loggingWithDemoAccount.message', { email, Bold })}</p>
                  <button class="button" type="submit">
                    {t('loggingWithDemoAccount.confirm')}
                  </button>
                </>
              ) : (
                <div class="vspacer-m">
                  <p>{t('loggingWithEmail', { email, Bold })}</p>
                  <input
                    class="input"
                    onInput={(event) => code.set(event.currentTarget.value)}
                  />
                </div>
              )}

              <p>{t('changeEmail', { Cta: LinkToSignin })}</p>
            </div>
          ),
          () => <Loader />,
          (err) => (
            <div class="maxw-100">
              {pipe(
                err,
                matchErrOr({
                  _: (error) => <Failure error={error} />,
                  HttpError: matchHttpCode({
                    _: (error) => <Failure error={error} />,
                    401: () => (
                      <h3 class="text-center preline">
                        {t('unauthorized', { Cta: LinkToSignin })}
                      </h3>
                    ),
                  }),
                }),
              )}
            </div>
          ),
          () => t('success'),
        ),
      )}
    </Page>
  )

  function LinkToSignin(text: JSX.Element) {
    return (
      <Link class="a" href={link.sendLinkToSignIn}>
        {text}
      </Link>
    )
  }
}

const Bold = (text: JSX.Element) => <b>{text}</b>
