export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'heading': 'Uploading Pictures 📸',
  'name.label': 'Baptize dat trip',
  'name.hint': 'IE: “{year} - Paris”',
  'name.required': 'Required',
  'pictures.label': 'Add my pictures',
  'pictures.required': 'Required',
  'submitWithoutPictures': 'I don’t have any pictures yet',
  'submit': 'Let’s go!',
  'startingTrip': 'Starting your trip “{name}” 🚀',
} as const

const fr = {
  heading: 'Téléchargement 📸',
  'name.label': 'Un p’tit nom de voyage siouplé?',
  'name.hint': 'IE: “{year} - Paris”',
  'name.required': 'Requis',
  'pictures.label': 'Ajouter mes images',
  'pictures.required': 'Requis',
  'submitWithoutPictures': 'J’ai pas encore d’image',
  'submit': 'C’est parti !',
  'startingTrip': 'Création de ton voyage “{name}”... 🚀',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
