import { Storage, makeStorage } from '@/std/browser'
import { C } from '@/std/codec'
import { O, R, date, list, record, struct } from '@/std/data'
import { FormControl } from '@/std/form-control'
import { flow, not, pipe } from '@/std/function'
import {
  ReadonlyState,
  State,
  computed,
  flattenState,
  mapState,
} from '@/std/reactivity/state'
import { RR, RemoteAction } from '@/std/remote'
import { Picture, PictureId } from '../../entity/Picture'
import { ArchivePicture } from '../../use-case/archive-picture/client'
import { UpdatePictureAction } from '../../use-case/update-picture/client'

type Init = {
  pictures: list.NonEmpty<Picture>
  onArchived: (picture: Picture) => void
  onUpdated: (picture: Picture) => void
  closedOnceStorage?: Storage<boolean>
}

const closedOnceStorageKey = 'undatedDialogClosed'
export type FixUndatedPicturesDialogModel = ReturnType<
  typeof FixUndatedPicturesDialogModel
>
export const FixUndatedPicturesDialogModel = ({
  pictures,
  onArchived,
  onUpdated,
  closedOnceStorage = makeStorage({ type: 'cookie', Codec: C.bool }),
}: Init) => {
  const initiallyOpened = pipe(
    closedOnceStorage.find(closedOnceStorageKey),
    R.unwrapOr(O.None),
    O.isNone,
  )
  const dialogOpened = State(initiallyOpened)
  const closeDialog = () => {
    dialogOpened.set(false)
    const expires = pipe(date.now(), date.addDays(1))
    closedOnceStorage.set(closedOnceStorageKey, true, { expires })
  }

  const current = State(0)
  const goToNextPicture = () => current.set(current() + 1)
  const goToPreviousPicture = () => current.set(current() - 1)
  const dateControl = FormControl(O.None<Date>())

  type Action = {
    state: ReadonlyState<RR.RemoteResult<unknown, unknown>>
    trigger: () => Promise<void>
  }

  const scheduledActions = State<Record<PictureId, Action>>({})
  const scheduleToSaveDate = (picture: Picture, date: Date) => {
    const action = UpdatePictureAction()
    scheduledActions.set({
      ...scheduledActions(),
      [picture.id]: {
        state: action.state,
        trigger: () =>
          action
            .trigger(picture.id, {
              caption: picture.caption,
              location: picture.location,
              timestamp: O.Some(date),
            })
            .then(R.tap(onUpdated)),
      },
    })
  }

  const scheduleToArchive = (picture: Picture) => {
    const action = RemoteAction(ArchivePicture())
    scheduledActions.set({
      ...scheduledActions(),
      [picture.id]: {
        state: action.state,
        // FIXME!
        trigger: () => action.trigger(picture).then(R.tap(onArchived)),
      },
    })
  }

  const scheduledActionStates = pipe(
    mapState(
      scheduledActions,
      flow(record.values, list.map(struct.lookup('state')), (s) =>
        computed(s, (...s) => s),
      ),
    ),
    flattenState,
  )
  const scheduledActionsCount = mapState(scheduledActions, record.size)
  const isAtLeastOneScheduledActionSubmitted = mapState(
    scheduledActionStates,
    list.some(not(RR.isNotAsked)),
  )
  const successfulActionsCount = mapState(
    scheduledActionStates,
    flow(list.filter(RR.isOk), list.size),
  )
  const submitActions = flow(
    scheduledActions,
    record.map((action) => action.trigger()),
    record.values,
    Promise.all,
  )
  const retry = flow(
    scheduledActions,
    record.map(({ state, trigger }) => {
      if (RR.isErr(state())) trigger()
    }),
  )

  const effects = [
    current.onChange(dateControl.reset),
    successfulActionsCount.onChange((successfulCount) => {
      if (successfulCount !== scheduledActionsCount()) return
      dialogOpened.set(false)
    }),
  ]

  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },
    pictures,
    dialogOpened,
    closeDialog,
    current,
    dateControl,
    scheduleToArchive,
    scheduleToSaveDate,
    goToNextPicture,
    goToPreviousPicture,
    submitActions,
    scheduledActionStates,
    successfulActionsCount,
    scheduledActionsCount,
    isAtLeastOneScheduledActionSubmitted,
    retry,
  }
}
