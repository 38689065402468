import { TupleOf } from '../data'
import { apply } from '../function'
import { isDeepEqual } from '../is-deep-equal'
import { Listen, Listener, SingleEventTarget } from './event-target'

export type State<T> = {
  (): T
  set: (next: T) => void
  update: (mapper: (value: T) => T | void) => void
  onChange: Listen<T>
}

type StateOptions<T> = {
  equals?: (a: T, b: T) => boolean
  // name?: string;
}

export interface MakeState {
  <T>(initial: T): State<T>
  <T>(initial: T, options?: StateOptions<T>): State<T>
  <T>(initial?: T, options?: StateOptions<T>): State<T | undefined>
}

const strictEq = <T>(a: T, b: T) => a === b

export const State: MakeState = (initial: any, options: any = {}) => {
  const eq = options.equals || strictEq
  const target = SingleEventTarget<typeof initial>()
  let current = initial
  const state = Object.assign(() => current, {
    set: (next) => {
      !eq(current, next) && target.notify((current = next))
    },
    update: (mapper) => {
      const mutable = structuredClone(current)
      state.set(mapper(mutable) ?? mutable)
    },
    onChange: target.listen,
    get listeners() {
      // @ts-ignore hidden api
      return target.listeners
    },
  }) satisfies State<typeof initial>
  return state
}

export interface ReadonlyState<T> extends Omit<State<T>, 'set' | 'update'> {
  (): T
}

export const readonly = <T>(state: State<T>): ReadonlyState<T> =>
  Object.assign(() => state(), { onChange: state.onChange })

export const computed = <States extends TupleOf<any> | any[], T>(
  watched: { [Key in keyof States]: ReadonlyState<States[Key]> },
  mapper: (...values: States) => T,
  isEqual: (a: T, b: T) => boolean = isDeepEqual,
): ReadonlyState<T> => {
  const listeners = new Set<Listener<T>>()
  const map = () => mapper(...(watched.map((s) => s()) as States))
  let previous = map()
  const maybeNotifyChange = () => {
    const next = map()
    if (isEqual(previous, next)) return
    previous = next
    listeners.forEach(apply(next))
  }

  let watchedStatesListeners: Array<{ unlisten: () => void }>
  const setWatchedStatesListeners = () => {
    watchedStatesListeners = watched.map((state) => {
      return state.onChange(maybeNotifyChange)
    })
  }
  const removeWatchedStatesListeners = () => {
    watchedStatesListeners.forEach((l) => l.unlisten())
    watchedStatesListeners = []
  }

  return Object.assign(() => previous, {
    onChange: (listener: Listener<T>) => {
      listeners.add(listener)
      if (listeners.size === 1) setWatchedStatesListeners()

      return {
        unlisten: () => {
          listeners.delete(listener)
          if (listeners.size === 0) removeWatchedStatesListeners()
        },
      }
    },
  })
}

type SubStateAdapters<T, U> = {
  map: (value: T) => U
  lift: (next: U, current: T) => T
}
export const subState = <T, U>(
  state: State<T>,
  adapters: SubStateAdapters<T, U>,
): State<U> => {
  const get = () => adapters.map(state())
  return Object.assign(get, {
    set: (next) => state.set(adapters.lift(next, state())),
    update: (setter) =>
      state.update((current) => adapters.lift(setter(get()), current)),
    onChange: (listener) =>
      state.onChange((next) => listener(adapters.map(next))),
  })
}

export const mapState = <T, U>(
  state: ReadonlyState<T>,
  mapper: (value: T) => U,
  isEqual?: (a: U, b: U) => boolean,
) => computed([state], mapper, isEqual)

export const previous = <T>(state: State<T>): ReadonlyState<T> => {
  let history = [state(), state()] as [T, T]
  return Object.assign(() => history[0], {
    onChange: (listener) =>
      state.onChange((next) => {
        history = [history[1], next]
        listener(history[0])
      }),
  })
}

export const flattenState = <T>(
  state: ReadonlyState<ReadonlyState<T>>,
): ReadonlyState<T> => {
  const get = () => state()()
  return Object.assign(get, {
    onChange: (listener) => {
      let childSub = state().onChange(listener)
      const parentSub = state.onChange((parent) => {
        childSub.unlisten()
        listener(parent())
        childSub = parent.onChange(listener)
      })
      const unlisten = () => (childSub.unlisten(), parentSub.unlisten())
      return { unlisten }
    },
  })
}

// NOTE: not sure this is super useful…
// const effect = <T>(state: State<T>, mapper: (value: T) => void) => {
//   mapper(state())
//   return state.onChange(mapper)
// }

// NOTE: not sure this is super useful…
const lookup = <T extends Record<string, any>, K extends keyof T>(
  state: State<T>,
  key: K,
) =>
  subState(state, {
    map: (obj) => obj[key],
    lift: (next, current) => ({ ...current, [key]: next }),
  })

export const state = {
  map: mapState,
  previous,
  readonly,
  lookup,
  // effect,
}
