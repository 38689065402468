import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { RuntimeError } from '../../entity/RuntimeError'

export const contract = ApiRouteContract('POST', '/report/runtime-error', {
  body: {
    contentType: MimeType.Json,
    codec: RuntimeError,
  },
  response: { status: 204 },
})
