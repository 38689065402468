import { O, date, list, struct } from '@/std/data'
import { constTrue, pipe } from '@/std/function'
import { State, computed } from '@/std/reactivity'
import { TR } from '@/std/remote'
import { RemoteActionToConfirm } from '@/std/remote/remote-action'
import { Picture } from '../../entity/Picture'

export type PickPictureModel = ReturnType<typeof PickPictureModel>

type Init = {
  pictures: Picture[]
  submit: (picture: Picture) => TR.TaskResult<unknown, unknown>
}
export const PickPictureModel = (init: Init) => {
  const pictures = State(init.pictures)
  const filter = State<PictureFilter>('unlocated')
  const model = {
    pictures: computed([filter, pictures], (filter, pictures) =>
      pipe(
        pictures,
        list.filter(predicateByFilter[filter]),
        list.sort(struct.ord('timestamp', O.ord(date.ord))),
      ),
    ),
    filter,
    action: RemoteActionToConfirm(init.submit),
  }
  return model
}

type PictureFilter = 'unlocated' | 'all'
const predicateByFilter: Record<PictureFilter, (picture: Picture) => boolean> =
  {
    all: constTrue,
    unlocated: (picture) => O.isNone(picture.location),
  }
