import { lazyNamed } from '@/solidjs/lazy-named'
import { O } from '@/std/data'
import { match, panic, pipe } from '@/std/function'
import { GuestRoute } from './router'

const SharedTripPage = lazyNamed(
  () => import('./pages/SharedTrip/main.solid'),
  'SharedTripPage',
)
const Credits = lazyNamed(
  () => import('../use-case/credits/client.solid-js'),
  'Credits',
)

type Props = {
  route: O.Option<GuestRoute>
}

export const Router = (props: Props) => (
  <>
    {pipe(
      props.route,
      O.map(
        match('route')({
          Credits: () => <Credits />,
          SharedTrip: ({ model }) => <SharedTripPage model={model} />,
        }),
      ),
      O.unwrapOr(() => panic('guest app should have a route')),
    )}
  </>
)
