export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'add': 'Add',
  'peerTripId.error.required': 'Required',
  'peerTripId.error.ownToken': 'Your cotravellr’s token cannot be your own token 😑',
  'peerTripId.label': 'Your cotravellr’s token',
  'from.label': 'From',
  'until.label': 'Until',

  'HowItWorks/howToShareToken': 'Share the following token with your cotravellr, s-he will add it on his/her side:',
  'HowItWorks/whenItWillBeActive': 'They will share their token with you too, as soon you’ll have added each other’s token you’ll be all set.',
  'HowItWorks/optionalDates': 'Additionally, you can fill in specific dates if you’re meeting half-way or whatever 🤓',
} as const

const fr = {
  'add': 'Ajouter',
  'peerTripId.error.required': 'Requis',
  'peerTripId.error.ownToken': 'Le jeton de ton co-voyageur peut pas être ton propre token 😑',
  'peerTripId.label': 'Jeton de co-voyageur-se',
  'from.label': 'Depuis',
  'until.label': 'Jusqu’à',

  'HowItWorks/howToShareToken': 'Partage ce jeton avec ton/ta co-voyageur-se, il/elle l’ajoutera de son côté',
  'HowItWorks/whenItWillBeActive': 'Il/Elle partagera son jeton avec toi aussi, dès que chacun de vous aura ajouté le jeton de l’autre c’est plié.',
  'HowItWorks/optionalDates': 'Vous pouvez aussi indiquer des dates spécifiques si vous vous rencontrez à mi-chemin par exemple',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
