import { curry2 } from '../function'
import { Curried2 } from '../function.types'
import { TupleOf } from './tuple'

type FromEquals = <T>(eq: (a: T, b: T) => boolean) => Eq<T>
const fromEquals: FromEquals = (eq) => ({ equals: curry2(eq) })

type EqOf<T> = T extends Eq<infer U> ? U : never

type Union = <T extends TupleOf<Eq<any>>>(
  ...eqs: T
) => Eq<{ [Key in keyof T]: EqOf<T[Key]> }[number]>
const union: Union = (...types) =>
  fromEquals<any>((a, b) =>
    types.some((type) => {
      try {
        return type.equals(a, b)
      } catch {
        return false
      }
    }),
  )

const intersect =
  <B>(bEq: Eq<B>) =>
  <A>(aEq: Eq<A>) =>
    Eq.fromEquals<A & B>((a, b) => aEq.equals(a, b) && bEq.equals(a, b))

export const Eq = {
  fromEquals,
  union,
  intersect,
}

export type Eq<T> = {
  equals: Curried2<T, T, boolean>
}
