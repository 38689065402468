import { solidRemoteResource } from '@/solidjs/reactivity'
import { pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { Control } from '@travellr/client/ui-kit/Control.solid-js'
import { Failure } from '@travellr/client/ui-kit/Failure.solid-js'
import { Loader } from '@travellr/client/ui-kit/Loader.solid-js'
import { guestPathTo } from '@travellr/domain/core/guest/client'
import { ShareToken } from '../../../entity/ShareTripOptions'
import { ShareOrCopyButton } from './ShareOrCopyButton.solid'
import { messages } from './i18n'
import { ShareTripModel } from './model'

type Props = {
  model: ShareTripModel
}
export const DialogContent = (props: Props) => {
  const { t } = I18n(messages)
  const tokenForTripWithCotravellrs = solidRemoteResource(
    props.model.tokenForTripWithCotravellrs,
  )
  const tokenForTripWithoutCotravellrs = solidRemoteResource(
    props.model.tokenForTripWithoutCotravellrs,
  )

  return (
    <div class="vspacer-xl">
      {pipe(
        tokenForTripWithCotravellrs(),
        RR.fold2(
          () => <Loader />,
          (error) => (
            <Failure
              error={error}
              onTryAgain={tokenForTripWithCotravellrs.fetch}
            />
          ),
          ({ token }) => (
            <Control
              label={<span class="h6">{t('cotravellrsIncluded.label')}</span>}
              control={<ShareControl token={token} />}
              hint={
                <small class="feedback hint bold">
                  {t('cotravellrsIncluded.hint')}
                </small>
              }
            />
          ),
        ),
      )}
      {pipe(
        tokenForTripWithoutCotravellrs(),
        RR.fold2(
          () => <Loader />,
          (error) => (
            <Failure
              error={error}
              onTryAgain={tokenForTripWithoutCotravellrs.fetch}
            />
          ),
          ({ token }) => (
            <Control
              label={<span class="h6">{t('cotravellrsExcluded.label')}</span>}
              control={<ShareControl token={token} />}
              hint={
                <small class="feedback hint bold">
                  {t('cotravellrsExcluded.hint')}
                </small>
              }
            />
          ),
        ),
      )}
    </div>
  )
}

const ShareControl = (props: { token: ShareToken }) => {
  const href = () =>
    new URL(guestPathTo.sharedTrip(props.token), window.location.href).href
  let inputRef: HTMLInputElement | undefined

  return (
    <div class="flex hspacer-s">
      <input
        ref={inputRef}
        class="box p-m block w-100 text-center"
        value={href()}
        readonly
      />
      <ShareOrCopyButton
        class="button discrete"
        input={() => inputRef}
        data={href()}
      />
    </div>
  )
}
