import L from 'leaflet'
import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.css'
import 'leaflet.visualclick'
import 'leaflet.visualclick/dist/L.VisualClick.css'
import 'leaflet/dist/leaflet.css'

export type CreateMapInit = {
  element: HTMLElement
  options?: L.MapOptions
  longClickDelay?: number
  onLongClick: L.LeafletMouseEventHandlerFn
}
export const createLeafletMap = (init: CreateMapInit) => {
  const map = L.map(init.element, init.options)

  map.zoomControl.setPosition('bottomright')
  L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>',
    maxZoom: 19,
  }).addTo(map)
  // L.tileLayer('https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png', {
  //   maxZoom: 20,
  //   attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
  // }).addTo(map)
  // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //   attribution:
  //     '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  // }).addTo(map)

  // @ts-ignore I could not set up typings properly
  L.control.locate({ position: 'bottomright' }).addTo(map)

  let mousedownTimeout
  const clearMouseDownTimeout = () => {
    if (mousedownTimeout) clearTimeout(mousedownTimeout)
  }

  const { onLongClick, longClickDelay = 500 } = init

  const longClickFromClickListener: L.LeafletMouseEventHandlerFn = (event) => {
    clearMouseDownTimeout()
    map.on('mouseup', clearMouseDownTimeout)
    map.on('dragstart', clearMouseDownTimeout)
    mousedownTimeout = setTimeout(() => {
      onLongClick(event)
      map.off('mouseup', clearMouseDownTimeout)
      map.off('dragstart', clearMouseDownTimeout)
    }, longClickDelay)
  }

  map.on('mousedown', longClickFromClickListener)
  map.on('contextmenu', onLongClick)

  return map
}
