// @ts-check
import { R, date, isEmail, parseJson, string, stringifyJson } from '../data'
import { cnst, constFalse, constTrue, identity, pipe } from '../function'
import * as C from './base'

export const Email = pipe(C.string, C.refine('Email', isEmail))

export const DateFromString = pipe(
  C.string,
  C.map(date.fromString, date.toIsoString),
  C.refine('Date', date.isValid),
)

export const DateFromNumber = pipe(
  C.number,
  C.map(date.make, (d) => d.valueOf()),
  C.refine('Date', date.isValid),
)

export const trimmedString = pipe(C.string, C.map(string.trim, string.trim))

export const numberFromString = pipe(
  C.string,
  C.map((str) => str.trim(), identity),
  C.map(Number, String),
  // @ts-ignore
  C.refine('numberFromString', (n) => !Number.isNaN(n)),
)

export const boolFromNumberString = pipe(
  numberFromString,
  C.map(Boolean, Number),
)

export const boolFromString = C.union(
  pipe(C.literal('true'), C.map(constTrue, cnst<'true'>('true'))),
  pipe(C.literal('false'), C.map(constFalse, cnst<'false'>('false'))),
)

export const Url = pipe(
  C.string,
  C.compose({
    tag: 'Url',
    decode: (href: string) =>
      R.try(
        () => new URL(href),
        (cause) => C.makeDecodeError('invalid href', href, undefined, cause),
      ),
    encode: (url) => url.href,
  }),
)

export const stringifiedJson = pipe(
  C.string,
  C.compose({
    tag: 'JSON',
    decode: (str: string) =>
      pipe(
        str,
        parseJson,
        R.mapErr((cause) => C.makeDecodeError('JSON', str, undefined, cause)),
      ),
    encode: stringifyJson(),
  }),
)
