import { Router } from '@/std/browser/router'
import { getClientContext } from '@travellr/client/context'
import { ShareToken } from '../../travellr/entity/ShareTripOptions'
import { SharedTripModel } from './pages/SharedTrip/main'

export type GuestRoute =
  | { route: 'SharedTrip'; model: SharedTripModel }
  | { route: 'Credits' }

export const GuestRouter = () => {
  const { history } = getClientContext()
  return Router<GuestRoute>({ history })({
    '/shared-trip/:token': ({ params }) => ({
      route: 'SharedTrip',
      model: SharedTripModel({ token: params.token as ShareToken }),
    }),
    '/credits': () => ({ route: 'Credits' }),
  })
}

export const pathTo = {
  sharedTrip: (token: string) => `/shared-trip/${token}`,
  credits: '/credits',
}
