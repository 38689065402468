import { InputFeedback, TextInput, solidFormGroup } from '@/solidjs/form'
import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { O, list, struct } from '@/std/data'
import { constNull, flow, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { CarouselModel } from '@travellr/client/ui-kit'
import { Carousel } from '@travellr/client/ui-kit/Carousel.solid-js'
import { Control } from '@travellr/client/ui-kit/Control.solid-js'
import { Dialog } from '@travellr/client/ui-kit/Dialog.solid-js'
import { Failure } from '@travellr/client/ui-kit/Failure.solid-js'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { Loader } from '@travellr/client/ui-kit/Loader.solid-js'
import { Show, mapArray, onCleanup } from 'solid-js'
import { fileListToPictures } from './Gallery'
import { FilePicker, Thumbnail } from './Gallery.solid'
import { UploadPicturesDialog } from './UploadPicturesDialog.solid'
import { CreateTripWithPictures as Model } from './main'
import { messages } from './main.i18n'

type Props = {
  id?: string
  class?: string
  model: Model
}
export const CreateTripWithPictures = (props: Props) => {
  onCleanup(props.model.dispose)
  const { t } = I18n(messages)
  const submitState = solidState(props.model.submitState)
  const uploadState = solidState(props.model.uploadState)
  const controls = solidFormGroup(props.model.controls)
  const carousel = CarouselModel({
    noGutter: true,
    onActiveChange: (isActive, node) =>
      isActive && carousel.scrollTo(`#${node.id}`),
  })

  return (
    <>
      <form
        class={cx('vspacer-xl', props.class)}
        onSubmit={props.model.submit}
        id={props.id}
      >
        <div class="box p-m">
          <Control
            label={t('name.label')}
            hint={
              <small class="block feedback">
                {t('name.hint', {
                  year: new Date().getFullYear(),
                })}
              </small>
            }
            feedback={<InputFeedback control={controls.name} />}
            control={<TextInput class="input" control={controls.name} />}
          />
        </div>

        <div>
          <Show when={controls.pictures().length > 0}>
            <div class="pb-m">
              <Carousel class="pb-m" model={carousel}>
                {mapArray(controls.pictures, (picture, index) => (
                  <div
                    class="px-m"
                    style="max-width: 80%"
                    id={`picture-${index() + 1}`}
                  >
                    <Thumbnail
                      imgClass="block"
                      height="300px"
                      picture={picture}
                      onImageClick={() =>
                        carousel.scrollTo(`#picture-${index() + 1}`)
                      }
                      onRemove={props.model.removePicture}
                      onCaptionChange={props.model.setPictureCaption}
                    />
                  </div>
                ))()}
              </Carousel>
            </div>
          </Show>

          <div class="box text-center p-m vspacer-m">
            <FilePicker
              // accept="image/*"
              multiple
              onChange={flow(
                struct.lookup('currentTarget'),
                struct.lookup('files'),
                fileListToPictures,
                props.model.addPictures,
              )}
            >
              <button class="button lg" type="button">
                <Icon intent="add" /> {t('pictures.label')}
              </button>
            </FilePicker>
            <InputFeedback control={controls.pictures} />
          </div>
        </div>

        <div class="text-center vspacer-l pb-xl">
          <button class="button lg" type="submit">
            {list.isEmpty(controls.pictures()) && !props.model.picturesRequired
              ? t('submitWithoutPictures')
              : t('submit')}
          </button>
        </div>
      </form>

      {pipe(
        submitState(),
        RR.fold(
          constNull,
          () => (
            <Dialog dialogClass="text-center vspacer-l px-m py-l">
              <h4>{t('startingTrip', { name: controls.name() })}</h4>
              <Loader skin="primary" size="m" />
            </Dialog>
          ),
          (error) => (
            <Dialog dialogClass="p-m vspacer-m">
              <Failure error={error} onTryAgain={props.model.submit} />
              <hr />
              <footer class="flex justify-end">
                <button
                  class="discrete button"
                  onClick={props.model.resetSubmitState}
                >
                  {t('verb/close')}
                </button>
              </footer>
            </Dialog>
          ),
          constNull, // upload image dialog will be opened
        ),
      )}

      {pipe(
        uploadState(),
        O.fold(constNull, (picturesUploadState) => (
          <UploadPicturesDialog
            states={picturesUploadState}
            footer={
              <footer class="flex px-m justify-end">
                <button
                  class="discrete button"
                  onClick={props.model.resetUploadState}
                >
                  {t('verb/cancel')}
                </button>
              </footer>
            }
          />
        )),
      )}
    </>
  )
}
