import { solidState } from '@/solidjs/state'
import { SandboxApp as Model } from './client'
import { Router } from './client-router.solid-js'

type Props = {
  model: Model
}

export const SandboxApp = (props: Props) => {
  const route = solidState(props.model.route)
  return <Router route={route()} />
}
