import { solidState } from '@/solidjs/state'
import { pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { TripModel } from './main'

type Props = {
  model: TripModel
}

export const TripName = (props: Props) => {
  const fullTrip = solidState(props.model.fullTrip)
  return (
    <>
      {pipe(
        fullTrip(),
        RR.fold2(
          () => <div class="placeholder" />,
          () => '',
          ({ trip }) => trip.name,
        ),
      )}
    </>
  )
}
