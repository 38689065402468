import { createEffect, onCleanup } from 'solid-js'

export const createClickAwayEffect = (
  root: () => HTMLElement | undefined,
  onClickAway: () => void,
) => {
  const listener = (event: MouseEvent) => {
    if (event.target && !root()?.contains(event.target as Node)) onClickAway()
  }
  createEffect(() => {
    document.addEventListener('mousedown', listener)
    onCleanup(() => document.removeEventListener('mousedown', listener))
  })
}
