import { MergeDeep } from 'type-fest'
import { C } from '../codec'
import type { HttpMethod } from '../http/Method'
import { MimeType } from '../http/MimeType'
import { PathParameters } from './type-utils'

/** @deprecated */
export type ApiContract = {
  [Path in string]: {
    [Method in HttpMethod]?: {
      params?: C.Codec<any, PathParameters<Path>>
      searchParams?: C.Codec<any, any>
      headers?: C.Codec<any, Record<string, any>>
      response: ApiResponse & { headers?: C.Codec<any, Record<string, any>> }
    } & (Method extends 'POST' | 'PUT' | 'PATCH' | 'DELETE'
      ? {
          body?: {
            codec: C.Codec<any, any>
            contentType: MimeType
          }
        }
      : { body?: undefined })
  }
}

/** @deprecated */
export const ApiContract = <T extends ApiContract>(contract: T) => contract

export type ApiResponse<T = any> =
  | { status: 204; codec?: undefined; contentType?: undefined }
  | { status: 200 | 201; codec: C.Codec<T, any>; contentType: MimeType }
  | {
      status: 400 | 401 | 403 | 418 | 500
      codec: C.Codec<any, any>
      contentType: MimeType
    }

/** @deprecated */
export type ApiRouteShape = NonNullable<ApiContract[string][HttpMethod]>

/** @deprecated */
const merge2Contracts = <A extends ApiContract, B extends ApiContract>(
  a: A,
  b: B,
) => {
  const copy: any = { ...a }
  Object.entries(b).forEach(([path, obj]) => {
    if (!copy[path]) return (copy[path] = obj)
    Object.entries(obj).forEach(([method, descriptor]) => {
      if (copy[path][method])
        throw new Error(
          `defining twice the same path+method couple: ${method} ${path}`,
        )
      copy[path][method] = descriptor
    })
  })
  return copy as MergeDeep<A, B>
}

/** @deprecated */
export type MergeContracts<C> = C extends [infer A, infer B]
  ? MergeDeep<A, B>
  : C extends [infer A, ...infer B]
  ? MergeDeep<A, MergeContracts<B>>
  : C extends [infer A]
  ? A
  : never

/** @deprecated */
export const mergeContracts = <C extends ApiContract[]>(...contracts: C) =>
  contracts.slice(1).reduce(merge2Contracts, contracts[0]) as MergeContracts<C>

// const c1 = ApiContract({
//   '/path-1': {
//     GET: {
//       response: { status: 204 },
//     },
//   },
// })
// const c2 = ApiContract({
//   '/path-1': {
//     POST: {
//       response: { status: 204 },
//     },
//   },
// })
// const c3 = ApiContract({
//   '/path-2': {
//     POST: {
//       response: { status: 204 },
//     },
//   },
// })

// const merged = mergeContracts(c1, c2)
