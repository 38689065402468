import { Page } from '@travellr/client/ui-kit/Page.solid-js'
import { JSX } from 'solid-js'

type Props = {
  title: JSX.Element
  map: JSX.Element
  content: JSX.Element
  nav: JSX.Element
}

export const TripPageLayout = (props: Props) => {
  return (
    <Page
      map={props.map}
      nav={props.nav}
      content={
        <>
          <div class="absolute z-2 top left p-s w-100">{props.title}</div>
          {props.content}
        </>
      }
    />
  )
}
