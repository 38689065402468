export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'submit': 'Send me dat link 😋',
  'tagline': 'Enter your email to receive your login link',
} as const

const fr = {
  'submit': 'Aboule le lien 😋',
  'tagline': 'Balance ton email pour recevoir ton lien de connexion',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
