import { O } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Icon } from './Icon.solid-js'
import { Loader } from './Loader.solid-js'
import { Progress } from './Progress.solid-js'

type Props = {
  state: RR.RemoteResult<Error, unknown>
  retry: () => void
}
export const UploadProgress = (props: Props) => (
  <>
    {pipe(
      props.state,
      RR.fold(
        constNull,
        (_, event) =>
          pipe(
            event,
            O.fold(
              () => <Loader size="s" skin="inverted" />,
              (event) => (
                <div class="flex-1 px-m">
                  <Progress
                    class="rounded-l"
                    barClass="rounded-l"
                    max={event.total}
                    value={event.loaded}
                  />
                </div>
              ),
            ),
          ),
        () => (
          <button class="button" onClick={props.retry}>
            <Icon intent="refresh" />
          </button>
        ),
        () => (
          <span class="box inline-block p-l">
            <Icon intent="success" />
          </span>
        ),
      ),
    )}
  </>
)
