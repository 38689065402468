import { cx } from '@/std/classNames'
import { ComponentProps, splitProps } from 'solid-js'

export type ProgressProps = ComponentProps<'div'> & {
  max: number
  value: number
  height?: string
  barClass?: string
}

export const Progress = (props: ProgressProps) => {
  const [, rest] = splitProps(props, ['max', 'value', 'height'])
  return (
    <div
      {...rest}
      role="progressbar"
      aria-valuemax={props.max}
      aria-valuenow={props.value}
      class={cx('progress track', props.class)}
    >
      <div
        class={cx('bar', props.barClass)}
        style={{
          'max-width': `${(props.value / props.max) * 100}%`,
          height: props.height ?? '1rem',
        }}
      />
    </div>
  )
}
