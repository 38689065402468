import { Show } from 'solid-js'
import { JSX } from 'solid-js/jsx-runtime'
import { Page } from '../../../../../../client/ui-kit/Page.solid-js'

type Props = {
  onClose: () => void
  header: JSX.Element
  content: JSX.Element
  footer?: JSX.Element
}

export const NoteDialog = (props: Props) => (
  <div class="absolute top left w-100 h-100 z-3">
    <Page
      contentClass="p-m flex justify-center align-center"
      content={
        <>
          <div class="absolute top right p-m">
            <button class="button discrete close h4" onClick={props.onClose} />
          </div>
          <div class="vspacer-m w-100">
            <h5 class="p-m box text-center">{props.header}</h5>
            <div class="p-m box">{props.content}</div>
            <Show when={props.footer}>
              <div class="p-m box text-center">{props.footer}</div>
            </Show>
          </div>
        </>
      }
    />
  </div>
)
