import { JSX } from 'solid-js/jsx-runtime'

type TitleProps = {
  left?: JSX.Element
  main: JSX.Element
  right?: JSX.Element
}

export const TripPageTitle = (props: TitleProps) => {
  return (
    <div class="box flex justify-space-between align-center p-m hspacer-m">
      <div>{props.left}</div>

      <h3 class="text-center" style="min-width: 10rem">
        {props.main}
      </h3>

      <div>{props.right}</div>
    </div>
  )
}
