import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { O } from '@/std/data'
import { Cookie } from '@/std/data/cookies'
import { pipe } from '@/std/function'
import { State } from '@/std/reactivity/state'
import { RemoteAction, TR } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { Account } from '../../entity/Account'
import { Profile } from '../../entity/Profile'
import { contract } from './contract'

export type SignWithWithToken = ReturnType<typeof SignInWithToken>

type Deps = {
  account: State<O.Option<Account>>
  profile: State<O.Option<Profile>>
}
export const SignInWithToken = ({ account, profile }: Deps) => {
  const { xhr } = getClientContext()
  const client = XhrApiRouteClient(contract, xhr)
  const signInWithToken = () =>
    pipe(
      client({}),
      TR.tap(({ body }) => {
        account.set(O.Some(body.account))
        profile.set(body.profile)
      }),
    )
  const action = RemoteAction(signInWithToken)
  return {
    state: action.state,
    trySigninInWithToken: async () => {
      if (O.isSome(account()) || Cookie.has(document.cookie, 'token')) {
        await action.trigger()
      }
    },
  }
}
