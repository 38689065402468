import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { ClientContext, getClientContext } from '@travellr/client/context'
import { ImageStorage } from '@travellr/domain/shared/entity/ImageStorage'
import { contract } from './contract'

type Deps = {
  context?: ClientContext
  imageStorage?: ImageStorage
}
export const CreateTrip = ({
  context: { xhr, config } = getClientContext(),
  imageStorage = config.defaultImageStorage,
}: Deps) => {
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const createTrip = (name: string) => client({ body: { name, imageStorage } })

  return RemoteAction(createTrip)
}
