import { solidState } from '@/solidjs/state'
import { O, list } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { getClientContext } from '@travellr/client/context'
import { I18n } from '@travellr/client/i18n/I18n'
import { formatDateTimeShort } from '@travellr/client/i18n/date'
import { usePictureContext } from '@travellr/client/picture-store/context'
import { ConfirmActionDialog } from '@travellr/client/ui-kit/ConfirmActionDialog.solid'
import { Image } from '@travellr/client/ui-kit/Image.solid-js'
import { PickPictureModel } from './PickPicture'
import './PickPictureCarousel.css'
import { messages } from './PickPictureCarousel.i18n'

type Props = {
  model: PickPictureModel
}

export const PickPictureCarousel = (props: Props) => {
  const { t } = I18n(messages)
  const { config } = getClientContext()
  const pictureStore = usePictureContext()

  const pictures = solidState(props.model.pictures)
  const filter = solidState(props.model.filter)

  return (
    <div class="vspacer-m p-m">
      <div class="flex justify-center">
        <button
          type="button"
          class="button"
          onClick={() =>
            filter.update((value) => (value === 'all' ? 'unlocated' : 'all'))
          }
        >
          {filter() === 'all'
            ? t('filter/show-unlocated')
            : t('filter/show-all')}
        </button>
      </div>

      <ConfirmActionDialog
        action={props.model.action}
        confirmMessage={t('confirm/label')}
        yes={t('confirm/yes')}
        no={t('confirm/no')}
      />

      <div class="locate-picture-carousel">
        {pipe(
          pictures(),
          list.map((picture) => {
            return (
              <div class="py-m vspacer-m">
                <Image
                  class="pointer rounded"
                  src={pictureStore.url(picture)}
                  height={config.thumbnailHeightPx}
                  onClick={() => props.model.action.trigger(picture)}
                />
                {pipe(
                  picture.timestamp,
                  O.map(formatDateTimeShort),
                  O.fold(constNull, (timestamp) => (
                    <div class="text-center italic">{timestamp}</div>
                  )),
                )}
              </div>
            )
          }),
          list.toNonEmpty,
          O.unwrapOr(() => (
            <div class="text-center w-100">{t('no-pictures')}</div>
          )),
        )}
        <div class="empty-block" />
      </div>
    </div>
  )
}
