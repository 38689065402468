import { makeInfraError } from '@/std/error'
import { Except } from 'type-fest'
import { C } from '../codec'
import { R, list, record, struct } from '../data'
import { Cookie, CookieOptions } from '../data/cookies'
import { flow, pipe } from '../function'
import { HttpMethod } from '../http'
import { HttpError } from '../http/Error'
import { MimeType } from '../http/MimeType'
import { TR } from '../remote'
import { ApiContract, ApiRouteShape } from './contract'
import { ApiHandlers, DecodeBodyError, DecodeHeadersError } from './handlers'
import { ApiRouteHandlers, defaultErrorHandler } from './route-handler'

export const httpErrorToResponse = (err: HttpError) =>
  new Response(err.message, { status: err.code, headers: err.headers })

export const makeResponseForRoute =
  <Route extends ApiRouteShape>(def: Route) =>
  (res: {
    body: C.TypeOf<Route['response']['codec']>
    headers: C.TypeOf<Route['response']['headers']>
    cookies?: Record<string, Except<CookieOptions, 'name'>>
  }) => {
    const response = new Response(makeBodyInit(def.response, res.body), {
      headers: def.response.headers?.encode(res.headers),
      status: def.response.status,
    })
    Object.entries(res.cookies ?? {}).forEach(([name, options]) => {
      response.headers.append(
        'Set-Cookie',
        Cookie.serialize({ name, ...options }),
      )
    })
    return response
  }

export const makeBodyInit = <
  Def extends { codec?: C.Codec<any, any>; contentType?: MimeType },
>(
  def: Def | undefined,
  body: C.TypeOf<Def['codec']>,
): BodyInit | undefined => {
  if (!def) return undefined
  const bodyInit = def.codec?.encode(body) ?? body
  if (def.contentType === MimeType.Json) return JSON.stringify(bodyInit)
  return bodyInit
}

export const parseRequestBody = (
  req: Pick<Request, 'json' | 'text' | 'blob'>,
  definition: ApiRouteShape['body'],
) => {
  if (!definition) return TR.Ok(undefined)
  switch (definition.contentType) {
    case MimeType.Json:
      return pipe(
        TR.try(() => req.json(), makeInfraError('req.json')),
        TR.mapResult(flow(definition.codec.decode, R.mapErr(DecodeBodyError))),
      )
    case MimeType.Text:
    case MimeType.Csv:
      return pipe(
        TR.try(() => req.text(), makeInfraError('req.text')),
        TR.mapResult(flow(definition.codec.decode, R.mapErr(DecodeBodyError))),
      )
    case MimeType.OctetStream:
    case MimeType.Jpeg:
    case MimeType.Png:
      return pipe(
        TR.try(() => req.blob(), makeInfraError('req.blob')),
        TR.mapResult(flow(definition.codec.decode, R.mapErr(DecodeBodyError))),
      )
    default:
      throw new Error(`unhandled mimetype: ${definition.contentType}`)
  }
}

export const parseRequestHeaders = (
  req: Pick<Request, 'headers'>,
  codec: ApiRouteShape['headers'],
) => {
  if (!codec) return R.Ok(undefined)
  return pipe(
    req.headers.entries(),
    record.fromEntries,
    codec.decode,
    R.mapErr(DecodeHeadersError),
  )
}

/** @deprecated Use api route handlers directly */
export const apiHandlersToRouteHandlers = <C extends ApiContract>(
  contract: C,
  handlers: ApiHandlers<C>,
): ApiRouteHandlers[] =>
  pipe(
    contract,
    struct.entries,
    list.flatMap(([path, value]) =>
      pipe(
        value,
        struct.entries,
        list.map(([method, route]): ApiRouteHandlers => {
          type HandlersShape = ApiHandlers<ApiContract>[string][HttpMethod]
          const routeHandlers = (handlers as any)[path][method] as HandlersShape
          const handler =
            typeof routeHandlers === 'function'
              ? routeHandlers
              : routeHandlers.handler
          const errorHandler =
            typeof routeHandlers === 'function'
              ? defaultErrorHandler
              : routeHandlers.errorHandler
          return {
            contract: {
              ...route,
              method,
              path,
            },
            errorHandler,
            handler,
          } as any
        }),
      ),
    ),
  )
