import { solidState } from '@/solidjs/state'
import { O, date } from '@/std/data'
import { flow, pipe } from '@/std/function'
import { computed } from '@/std/reactivity'
import { getClientContext } from '@travellr/client/context'
import { I18n } from '@travellr/client/i18n/I18n'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { onCleanup } from 'solid-js'
import { JSX } from 'solid-js/jsx-runtime'
import { formatDate } from '../../i18n'
import { tripCrumbToDate } from './TripCrumbs'
import { Range, TripCrumbsFilterModel } from './TripRangeFilter'
import { messages } from './TripRangeFilter.i18n'

type Props = {
  model: TripCrumbsFilterModel
}
export const TripRangeFilter = (props: Props) => {
  onCleanup(props.model.dispose)
  return (
    <small class="hspacer-m flex justify-center align-center p-s pb-0 text-center">
      <RangePickerButton model={props.model} />
      <Heading model={props.model} />
    </small>
  )
}

const RangePickerButton = (props: Props) => {
  return (
    <span class="relative" role="combobox">
      <small>
        <button class="small discrete button">
          <Icon intent="parameters" />
        </button>
      </small>

      <div class="absolute middle top translate-self z-4 py-s text-center">
        <RangePickerOptions model={props.model} />
      </div>
    </span>
  )
}

const RangePickerOptions = (props: Props) => {
  const { t } = I18n(messages)
  const activeRange = solidState(props.model.range)
  const Option = (p: { range: Range; children: JSX.Element }) => (
    <button
      role="option"
      class="w-100 nowrap px-xl py-m pointer"
      onClick={() => props.model.range.set(p.range)}
      disabled={activeRange() === p.range}
    >
      {p.children}
    </button>
  )
  return (
    <div class="box options">
      <Option range="day">{t('day')}</Option>
      <Option range="week">{t('week')}</Option>
      <Option range="month">{t('month')}</Option>
      <Option range="full">{t('full')}</Option>
    </div>
  )
}

const Heading = (props: Props) => {
  const { t } = I18n(messages)
  const state = computed(
    [props.model.active, props.model.range],
    (active, range) =>
      pipe(
        active,
        O.map((active) => ({ active, range })),
      ),
  )
  const signal = solidState(state)

  return (
    <>
      {pipe(
        signal(),
        O.fold(
          () => (
            <h6
              class="placeholder"
              style={{ 'min-height': '1em', 'min-width': '7rem' }}
            />
          ),
          ({ active, range }) => {
            switch (range) {
              case 'day':
                return <DayHeading model={props.model} active={active} />
              case 'week':
                return <WeekHeading model={props.model} active={active} />
              case 'month':
                return <MonthHeading model={props.model} active={active} />
              case 'full':
                return <h6>{t('full')}</h6>
            }
          },
        ),
      )}
    </>
  )
}

const DayHeading = (props: Props & { active: Date }) => {
  return (
    <div class="flex align-center hspacer-l">
      <PreviousButton model={props.model} />
      <h6>{renderDate(props.active)}</h6>
      <NextButton model={props.model} />
    </div>
  )
}

const WeekHeading = (props: Props & { active: Date }) => {
  return (
    <div class="flex align-center hspacer-l">
      <PreviousButton model={props.model} />
      <h6>
        {renderDate(date.startOfWeek(props.active))}
        {' - '}
        {renderDate(date.endOfWeek(props.active))}
      </h6>
      <NextButton model={props.model} />
    </div>
  )
}

const MonthHeading = (props: Props & { active: Date }) => {
  return (
    <div class="flex align-center hspacer-l">
      <PreviousButton model={props.model} />
      <h6>
        {renderDate(date.startOfMonth(props.active))}
        {' - '}
        {renderDate(date.endOfMonth(props.active))}
      </h6>
      <NextButton model={props.model} />
    </div>
  )
}

const renderDate = (date: Date) => {
  const ctx = getClientContext()
  const locale = solidState(ctx.locale)
  return formatDate(locale())(date)
}

const PreviousButton = (props: Props) => {
  const previous = solidState(props.model.previousActive)

  return (
    <button
      class="p-m pointer"
      disabled={O.isNone(previous())}
      onClick={flow(previous, O.map(tripCrumbToDate), props.model.active.set)}
    >
      <Icon intent="previous" />
    </button>
  )
}

const NextButton = (props: Props) => {
  const next = solidState(props.model.nextActive)

  return (
    <button
      class="p-m pointer"
      disabled={O.isNone(next())}
      onClick={flow(next, O.map(tripCrumbToDate), props.model.active.set)}
    >
      <Icon intent="next" />
    </button>
  )
}
