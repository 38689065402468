import { Tabs } from '@/solidjs/tabs'
import { Page } from '@travellr/client/ui-kit/Page.solid-js'

export const Sandbox = () => {
  // const { width, height } = viewports[0]
  // const url = new URL(location.href)
  // url.pathname = '/embed/shared-trip/Ok'
  // return <iframe width={width} height={height} src={url.href} />
  return (
    <Page
      contentClass="flex justify-center align-center"
      content={
        <div class="shadowless box">
          <Tabs
            active="abc"
            onChange={console.info}
            panelClass="px-m py-xl"
            tabs={{
              abc: () => <div>A b c Pane, bla bla bla, you know.</div>,
              test: {
                heading: () => <span>Test</span>,
                panel: () => (
                  <div>This is the “Test Pane”, in case you wondered.</div>
                ),
              },
            }}
          />
        </div>
      }
    />
  )
}

// export const Sandbox = () => {
//   const mock = makeMock('<TestSharedTrip />')
//   const makeState = (
//     state: ReturnType<SharedTripState['sharedTrip']>,
//   ): SharedTripState => ({
//     sharedTrip: () => state,
//   })
//   const states: Record<string, SharedTripState> = {
//     // 'Not Asked': makeState(RR.NotAsked()),
//     // Pending: makeState(RR.Pending()),
//     // Error: makeState(RR.Err(new Error('Trip not found or whatever'))),
//     Ok: makeState(RR.Ok(mock.fullTrip())),
//   }
//   return (
//     <SharedTrip
//       token="fake-token"
//       state={states.Ok}
//       core={{
//         getImageUrl: mock.getImageUrl,
//         getSharedTrip: TR.Pending,
//       }}
//     />
//   )
// }

// export const Sandbox = () => {
//   const [count, setCount] = createSignal(0)
//   return (
//     <Page
//       nav={
//         <MainNav>
//           <NavItemBurgerMenu onSignOut={console.info} />
//         </MainNav>
//       }
//       // content={new Array(100).fill(() => (
//       //   <h1>Hello world!</h1>
//       // ))}
//       contentClass="flex justify-center align-center"
//       content={
//         <div class="vspacer-m">
//           <div class="flex hspacer-m">
//             <button
//               class="discrete button"
//               onClick={() => setCount((c) => c - 1)}
//             >
//               -
//             </button>
//             <button
//               class="discrete button"
//               onClick={() => setCount((c) => c + 1)}
//             >
//               +
//             </button>
//           </div>
//           <Frame class="box" watch={[count]} viewport={viewports[0]}>
//             <Page
//               contentClass="flex justify-center align-center"
//               content={
//                 <div class="vspacer-l">
//                   <div>
//                     <output class="input">{count()}</output>
//                   </div>
//                   <Loader />
//                 </div>
//               }
//             />
//           </Frame>
//         </div>
//       }
//       // map={<div>[Map]</div>}
//     />
//   )
// }
