import { O } from '@/std/data'
import { pipe } from '@/std/function'
import { usePictureContext } from '@travellr/client/picture-store/context'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { Image } from '@travellr/client/ui-kit/Image.solid-js'
import L from 'leaflet'
import { JSX } from 'solid-js'
import { Checkpoint } from '../../travellr/entity/Checkpoint'
import { Location } from '../../travellr/entity/Location'
import { PictureId } from '../../travellr/entity/Picture'
import { MakeTripCrumbMarker } from './TripCrumbs'
import { palette } from './TripLeafletMap.utils'

export const makeTripCrumbMarker: MakeTripCrumbMarker = (crumb) => {
  switch (crumb.type) {
    case 'checkpoint':
      return pipe(
        crumb.location,
        O.map((location) => {
          return createCheckpointMarker({
            id: crumb.id,
            tripId: crumb.tripId,
            location,
            timestamp: crumb.timestamp,
          })
        }),
      )
    case 'picture':
      return pipe(
        crumb.location,
        O.map((location) => {
          return createPictureMarker({
            id: crumb.id,
            location,
            url: usePictureContext().url(crumb),
          })
        }),
      )
    case 'note':
      return O.None()
  }
}

type CreateMarkerOptions = {
  location: L.LatLngExpression
  html: JSX.Element
}

const createMarker = ({ location, html }: CreateMarkerOptions) => {
  const marker = L.marker(location, {
    icon: L.divIcon({
      className: 'map-icon',
      html: html as HTMLElement,
    }),
  })
  return marker
}

type CreatePictureMarkerOptions = {
  id: PictureId
  location: Location
  url: string
}

const createPictureMarker = ({
  id,
  location,
  url,
}: CreatePictureMarkerOptions) =>
  createMarker({
    location,
    html: (
      <Image
        id={`map-crumb-${id}`}
        class="marker picture"
        src={url}
        loader={() => <div class="marker picture placeholder" />}
      />
    ),
  })

const createCheckpointMarker = (checkpoint: Checkpoint) =>
  createMarker({
    location: checkpoint.location,
    html: (
      <div
        class="step marker h1"
        id={`map-crumb-${checkpoint.id}`}
        style={{ transform: 'translate(-4px, -0.75em)' }}
      >
        <Icon intent="checkpoint" />
      </div>
    ),
  })

export type CreateContextMenuMarker = typeof createContextMenuMarker
export const createContextMenuMarker = (
  options: Pick<CreateMarkerOptions, 'location'>,
) =>
  createMarker({
    location: options.location,
    html: (
      <div
        class="step marker h1"
        style={{
          transform: 'translate(calc(-50% + 6px), -0.75em)',
          color: palette.blue,
        }}
      >
        <Icon intent="hereOnMap" />
      </div>
    ),
  })
