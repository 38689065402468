import { ComponentProps, splitProps } from 'solid-js'
import { UploadPicturesModel } from './model'

type Props = ComponentProps<'button'> & {
  model: UploadPicturesModel
}

export const AddPicturesToUploadButton = (props: Props) => {
  const [, rest] = splitProps(props, ['model'])
  let fileInput: HTMLInputElement | undefined
  return (
    <>
      <button {...rest} onClick={() => fileInput?.click()} />
      <input
        ref={fileInput}
        style="position: absolute; top: 0; height: 0; opacity: 0"
        type="file"
        // Commented out because it trims location metadata on Webkit-based browsers.
        // accept="image/*
        multiple
        onChange={(event) =>
          props.model.addPicturesToUpload(event.currentTarget.files)
        }
      />
    </>
  )
}
