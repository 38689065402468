// @ts-check
import { Eq } from './eq'
import { Ord } from './ord'
import { strictEquals } from './strict-equals'

export const string = {
  eq: Eq.fromEquals<string>(strictEquals),
  ord: Ord.fromCompare<string>((a, b) => a.localeCompare(b)),

  lower: (s: string) => s.toLowerCase(),
  lowerFirst: (s: string) => (s[0] ?? '').toLowerCase() + s.slice(1),

  upper: (s: string) => s.toUpperCase(),
  capitalize: (s: string) => string.upper(s[0] ?? '') + s.slice(1),
  trim: (s: string) => s.trim(),
  replaceFirst: (target: string, replacer: string) => (s: string) =>
    s.replace(target, replacer),
  replaceAll: (target: string | RegExp, replacer: string) => (s: string) =>
    s.replaceAll(target, replacer),

  split: (separator: string | RegExp, limit?: number) => (s: string) =>
    s.split(separator, limit),

  isEmpty: (value: string): value is '' => value === '',
  isNotEmpty: (value: string) => value !== '',
}
