import { C, Cd } from '@/std/codec'
import { O, R, date } from '@/std/data'
import { apply, pipe } from '@/std/function'
import { TR } from '@/std/remote'
import { decodeJwt, encodeJwt, verifyJwt } from '@borderless/web-jwt'
import {
  JWTFactory,
  JsonWebToken,
  JsonWebTokenError,
  JsonWebTokenExpired,
  Signable,
} from '.'

// ponyfill
if (typeof global !== 'undefined')
  globalThis.crypto = require('node:crypto').webcrypto

export const makeJWTFactory = <T extends Signable, O>(
  salt: string,
  codec: C.Codec<T, O, unknown>,
): JWTFactory<T> => {
  const key = getKey(salt)
  const PayloadCodec = C.struct({
    expires: C.Option(Cd.DateFromNumber),
    data: codec,
  })
  return {
    forgeToken: (data, options) =>
      TR.try(async () => {
        const result = await encodeJwt(
          { alg: 'HS256' },
          PayloadCodec.encode({
            data,
            expires: pipe(
              O.fromNullable(options?.expiresInS),
              O.map(date.addSeconds),
              O.map(apply(date.now())),
            ),
          }),
          await key,
        )
        return result as JsonWebToken
      }, JsonWebTokenError),

    verifyToken: (token) =>
      pipe(
        TR.try(async () => {
          const jwt = await decodeJwt(token)
          const isOk = await verifyJwt(jwt, await key)
          if (!isOk) throw JsonWebTokenError('invalid token')
          return jwt.payload
        }, JsonWebTokenError),
        TR.mapResult(PayloadCodec.decode),
        TR.mapResult(({ expires, data }) =>
          pipe(
            expires,
            O.fold(
              () => R.Ok(data),
              (expiryDate) =>
                pipe(date.now(), date.isAfter(expiryDate))
                  ? R.Err(JsonWebTokenExpired(token))
                  : R.Ok(data),
            ),
          ),
        ),
      ),
  }
}

const getKey = (salt: string) =>
  globalThis.crypto.subtle.importKey(
    'jwk',
    { kty: 'oct', k: salt, alg: 'HS256' },
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign', 'verify'],
  )
