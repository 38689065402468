import { LogLevel, Logger } from '.'
import { noop } from '../function'

export const applyLogLevel = (level: LogLevel, logger: Logger): Logger => ({
  debug: level === LogLevel.Debug ? logger.debug : noop,
  info:
    level === LogLevel.Debug || level === LogLevel.Info ? logger.info : noop,
  warn:
    level !== LogLevel.Error && level !== LogLevel.None ? logger.warn : noop,
  error: level !== LogLevel.None ? logger.error : noop,
})
