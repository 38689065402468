import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { MimeType } from '@/std/http'
import { FullTrip } from '@travellr/domain/core/travellr/entity/FullTrip'
import { ShareTokenCodec } from '@travellr/domain/core/travellr/entity/ShareTripOptions'

export const contract = ApiRouteContract('GET', '/shared-trip/:token', {
  params: C.struct({ token: ShareTokenCodec }),
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: FullTrip,
  },
})
