export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'header': 'Start trip',
  'instructions.heading': 'Good to know before actually starting the trip',
  'instructions.body': 'Long story short: The app can guess the points of your trip from your pictures metadata.\nTo make sure that your pictures are @Bold(properly located), your location must be @Bold(activated and precise each time you take a picture).\nAnd to make sure of that, @Bold(the simplest way) is to @Bold(keep your location active) during the whole trip.\n\nUnlike calls, SMS or the Internet, @Bold(keeping the location active is free), so @Bold(no worries) 🤗.\n\nEnjoy the road!',
  'instructions.gotIt': 'Got it chief 👌',
} as const

const fr = {
  'header': 'Démarrer un voyage',
  'instructions.heading': 'Bon à savoir avant de démarrer ton voyage',
  'instructions.body': 'Pour faire court: l’app peut deviner ton itinéraire via les métadonnées de tes images.\nPour t’assurer que tes images sont @Bold(bien localisées), ta localisation doit @Bold(être activée et précise chaque fois que tu prends une photo).\nEt pour s’en assurer, @Bold(le plus simple) est de @Bold(laisser ta localisation active) pendant toute la durée de ton voyage.\n\nContrairement aux appels, SMS ou Internet, @Bold(laisser ta localisation active est gratuit), donc @Bold(pas d’inquiétude) 🤗.\n\nBonne route !',
  'instructions.gotIt': 'Pigé 👌',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
