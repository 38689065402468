export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'heading': 'Undated pictures',
  'issuesTreated.1': 'Perfect, you’ve treated every issue!',
  'issuesTreated.2': 'Hit “Save” and you’re good to go 😎',
  're-edit': 'Continue to edit',
  'picture-n': 'Picture #{n}',
  'treated': 'Treated: {count}/{total}',
  'footer/error': 'Encountered errors while updating your pictures',
} as const

const fr = {
  'heading': 'Images sans date',
  'issuesTreated.1': 'Excellent!',
  'issuesTreated.2': 'Sauvegarde et t’es tout bon 😎',
  're-edit': 'Éditer à nouveau',
  'picture-n': 'Image #{n}',
  'treated': 'Traité: {count}/{total}',
  'footer/error': 'Des erreurs se sont produites lors de la sauvegarde',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
