import { O, R, set, struct } from '../data'
import { pipe } from '../function'
import { ReadonlyState, State, mapState } from '../reactivity'
import * as RR from './remote-result'
import * as TR from './task-result'
import { UploadAction } from './upload-action'

export const RemoteAction = <E, T, P extends any[]>(
  action: (...args: P) => TR.TaskResult<E, T>,
): RemoteAction<E, T, P> => UploadAction(() => action)

export type RemoteAction<E, T, P extends any[]> = {
  state: State<RR.RemoteResult<E, T>>
  trigger: (...args: P) => Promise<R.Result<E, T>>
  reset: () => void
}

export type RemoteActionToConfirm<E, T, P extends any[]> = {
  state: State<RR.RemoteResult<E, T>>
  toConfirm: ReadonlyState<O.Option<P>>
  trigger: (...args: P) => void
  confirm: () => Promise<void>
  cancel: () => void
}

export const RemoteActionToConfirm = <E, T, P extends any[]>(
  fn: (...args: P) => TR.TaskResult<E, T>,
): RemoteActionToConfirm<E, T, P> => {
  const action = RemoteAction(fn)
  const toConfirm = State(O.None<P>())
  return {
    trigger: (...args) => toConfirm.set(O.Some(args)),
    state: action.state,
    toConfirm,
    confirm: () =>
      pipe(
        toConfirm(),
        O.map((args) => action.trigger(...args)),
        O.unwrapOr(() => Promise.resolve()),
        (promise) => promise.then(() => toConfirm.set(O.None())),
      ),
    cancel: () => toConfirm.set(O.None()),
  }
}

export type DelayedRemoteAction<E, T, P extends any[]> = {
  state: State<RR.RemoteResult<E, T>>
  toArchive: ReadonlyState<O.Option<P>>
  archivingInBackground: ReadonlyState<Set<P>>
  trigger: (...args: P) => void
  cancel: () => void
}
export const DelayedRemoteAction = <E, T, P extends any[]>(
  fn: (...args: P) => TR.TaskResult<E, T>,
  delay: number,
): DelayedRemoteAction<E, T, P> => {
  const action = RemoteAction(fn)
  type Timeout = ReturnType<typeof setTimeout>
  const toArchive = State(O.None<{ args: P; timeout: Timeout }>())
  const archivingInBackground = State(new Set<P>())
  return {
    trigger: (...args) => {
      const current = toArchive()
      if (O.isSome(current)) {
        clearTimeout(current.value.timeout)
        archivingInBackground.update(set.add(current.value.args))
        action.trigger(...current.value.args).then(() => {
          archivingInBackground.update(set.remove(current.value.args))
        })
      }
      const timeout = setTimeout(() => {
        action.trigger(...args).then(() => toArchive.set(O.None()))
      }, delay)
      toArchive.set(O.Some({ args, timeout }))
    },
    state: action.state,
    toArchive: mapState(toArchive, O.map(struct.lookup('args'))),
    archivingInBackground,
    cancel: () => {
      const candidate = toArchive()
      if (O.isNone(candidate)) return
      clearTimeout(candidate.value.timeout)
      toArchive.set(O.None())
    },
  }
}

export const fakeRemoteAction = <E, T>(
  current: RR.RemoteResult<E, T>,
): RemoteAction<E, T, any[]> => {
  const state = State(current)
  const action: RemoteAction<E, T, any[]> = {
    state,
    trigger: () =>
      pipe(
        current,
        RR.fold2(
          () => new Promise<R.Result<E, T>>(() => {}),
          (err) => Promise.resolve(R.Err(err)),
          (value) => Promise.resolve(R.Ok(value)),
        ),
      ),
    reset: () => state.set(RR.NotAsked()),
  }
  return action
}
