// Base everything on the crumbs received as Deps

import { O, list } from '@/std/data'
import { pipe } from '@/std/function'
import L from 'leaflet'
import { TripCrumb } from './TripCrumbs'

// Only extra thing to export: a `makeMarker(crumb)` utility.
export const palette = {
  blueBright: 'var(--blueBrighter)',
  blue: 'var(--blueBright)',
  blueDark: 'var(--blue)',
  green: 'var(--green)',
  white: 'var(--white)',
  black: 'var(--greyDarker)',
  yellow: 'var(--yellow)',
  violet: 'blueviolet',
  red: 'var(--red)',
}

export const fitMapBounds = (map: L.Map, crumbs: TripCrumb[]) => {
  const points = pipe(
    crumbs,
    list.filterMap((step) =>
      pipe(
        step.marker,
        O.map((m) => m.getLatLng()),
      ),
    ),
  )
  if (points.length === 0) return setFakeView(map)

  const bounds = L.polyline(points).getBounds()
  map.fitBounds(bounds, { padding: new L.Point(100, 100) })
}

const setFakeView = (map: L.Map) =>
  map.setView({ lat: 48.8583736, lng: 2.2922926 }, 14)
