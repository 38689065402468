import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { Email } from '@/std/data'
import { FormControl, FormGroup, isEmail, nonEmpty } from '@/std/form-control'
import { flow, pipe } from '@/std/function'
import { RR, RemoteAction, TR } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { getTravellrContext } from '@travellr/domain/core/travellr/client/context'
import { contract } from './contract'

type ProfileFormValues = {
  name: string
  email: Email
}
type Init = {
  initialValues?: Partial<ProfileFormValues>
}

export type UpdateProfile = ReturnType<typeof UpdateProfile>
export const UpdateProfile = ({ initialValues }: Init) => {
  const { account, profile } = getTravellrContext()
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)

  const save = (body: ProfileFormValues) =>
    pipe(
      client({ body }),
      TR.tap((next) => {
        profile.set(next.profile)
        account.set(next.account)
      }),
    )

  const action = RemoteAction(save)
  const controls = FormGroup({
    name: FormControl(initialValues?.name ?? '', [nonEmpty('required')]),
    email: FormControl((initialValues?.email ?? '') as string, [
      nonEmpty('required'),
      isEmail('invalid'),
    ]),
  })
  const submit = async (event?: Event) => {
    event?.preventDefault()
    controls.markTouched()
    if (!controls.isValid()) return
    await action.trigger({
      name: controls.name(),
      email: controls.email() as Email,
    })
  }
  return {
    submitState: action.state,
    submit,
    reset: () => action.state.set(RR.NotAsked()),
    controls,
  }
}
