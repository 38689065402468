import { solidState } from '@/solidjs/state'
import { JSX } from 'solid-js'
import { GuestApp as Model } from './main'
import { Router } from './router.solid-js'

type Props = {
  model: Model
  loading?: () => JSX.Element
  fallback?: (error: Error) => JSX.Element
}
export const GuestApp = (props: Props) => {
  const route = solidState(props.model.route)
  return <Router route={route()} />
}
