import { StorageType } from '@/std/browser'
import { Email } from '@/std/data'
import { LogLevel } from '@/std/logger'
import { ImageStorage } from '@travellr/domain/shared/entity/ImageStorage'

export type Config = {
  env: 'dev' | 'staging' | 'prod'
  logLevel: LogLevel
  apiUrl: string
  thumbnailHeightPx: number
  demoEmails: Email[]
  defaultImageStorage: ImageStorage
  authStorage: StorageType
  telemetry: {
    traceEndpoint: string
    metricsEndpoint: string
  }
}

// @ts-ignore injected by bundler
export const config: Config = __CONFIG__
