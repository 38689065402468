import { O, boolean } from '@/std/data'
import { flow, pipe } from '@/std/function'
import { Accessor, ComponentProps, JSX, splitProps } from 'solid-js'
import { SolidFormControl } from './adapters'

export type InputFeedbackProps<E extends string> = ComponentProps<'small'> & {
  control: SolidFormControl<any, E>
  Error?: Record<E, JSX.Element>
}
export const InputFeedback = <E extends string>(
  props: InputFeedbackProps<E>,
) => {
  const mapError = (e: E) => props.Error?.[e] ?? e
  const error: Accessor<O.Option<JSX.Element>> = flow(
    props.control.isTouched,
    boolean.fold(O.None, flow(props.control.error, O.map(mapError))),
  )
  const [, rest] = splitProps(props, ['control'])
  return (
    <>
      {pipe(
        error(),
        O.fold(
          () => null,
          (error) => (
            <small
              role="alert"
              {...rest}
              class={props.class ?? 'feedback danger'}
            >
              {error}
            </small>
          ),
        ),
      )}
    </>
  )
}
