import { DateTimeInput, InputFeedback, solidFormGroup } from '@/solidjs/form'
import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { Control } from '@travellr/client/ui-kit/Control.solid-js'
import { FormSubmitStateOverlay } from '@travellr/client/ui-kit/FormSubmitStateOverlay.solid-js'
import { AddCheckpointFormModel } from './AddCheckpointForm'

type Props = {
  model: AddCheckpointFormModel
  class?: string
  onSuccess: () => void
  onCancel: () => void
}

export const AddCheckpointForm = (props: Props) => {
  const { reset, submit } = props.model
  const controls = solidFormGroup(props.model.controls)
  const submitState = solidState(props.model.state)

  return (
    <form onSubmit={submit}>
      <FormSubmitStateOverlay
        submitState={submitState()}
        onSuccess={props.onSuccess}
        onTryAgain={submit}
        onClose={reset}
      />

      <Control
        class={props.class}
        label="When was it?"
        control={<DateTimeInput control={controls.date} class="input" />}
        feedback={<InputFeedback control={controls.date} />}
      />

      <hr />

      <div class={cx('flex align-center justify-center gap-m', props.class)}>
        <button class="button" type="submit">
          {'Save'}
        </button>

        <button type="button" class="discrete button" onClick={props.onCancel}>
          {'Cancel'}
        </button>
      </div>
    </form>
  )
}
