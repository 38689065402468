export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'header': 'Import a trip',
} as const

const fr = {
  'header': 'Ajouter un voyage',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
