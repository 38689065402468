import { O, list } from '@/std/data'
import { flow, pipe } from '@/std/function'
import { State } from '@/std/reactivity'
import { RR, T, UploadAction } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { PictureStores } from '@travellr/client/picture-store/PictureStores'
import { ImageStorage } from '@travellr/domain/shared/entity/ImageStorage'
import { Picture } from '../../../entity/Picture'
import { TripId } from '../../../entity/Trip'

export type PictureToUpload = {
  file: Blob
  caption: O.Option<string>
}

export type UploadPicturesModel = ReturnType<typeof UploadPicturesModel>

export type UploadState = {
  picture: PictureToUpload
  state: State<RR.RemoteResult<Error, Picture>>
  retry: () => void
}

type Init = {
  onSubmitSuccess: (pictures: Picture[]) => void
  tripId: TripId
  imageDelayFactorMs?: number
  imageStorage?: ImageStorage
}

export const UploadPicturesModel = ({
  onSubmitSuccess,
  tripId,
  imageDelayFactorMs = 1000,
  ...init
}: Init) => {
  const { config } = getClientContext()
  const imageStorage = init.imageStorage ?? config.defaultImageStorage
  const pictureStores = PictureStores()
  const uploadState = State(O.None<UploadState[]>())

  const upload = (pictures: PictureToUpload[]) => {
    if (list.isEmpty(pictures)) return onSubmitSuccess([])

    const picturesUploadState = pictures.map((picture, index): UploadState => {
      const { state, trigger } = UploadAction(
        (onProgress) => () =>
          pipe(
            pictureStores[imageStorage].upload(
              { caption: picture.caption, tripId },
              picture.file,
              { onProgress },
            ),
            T.delay(imageDelayFactorMs * index),
          ),
      )
      trigger().then(() => triggerSubmitSuccessIfAllImagesAreUploaded())
      return { picture, state, retry: trigger }
    })
    uploadState.set(O.Some(picturesUploadState))
  }

  const model = {
    addPicturesToUpload: flow(picturesToUploadFromFileList, upload),
    uploadState,
    submit: upload,
    resetUploadState: () => uploadState.set(O.None()),
  }

  return model

  function triggerSubmitSuccessIfAllImagesAreUploaded() {
    pipe(
      model.uploadState(),
      O.map(list.map((item) => item.state())),
      O.flatMap(O.refine(list.every(RR.isOk))),
      O.map(list.map(RR.unwrap)),
      O.tap(onSubmitSuccess),
    )
  }
}

const picturesToUploadFromFileList = (fileList: FileList | null) =>
  pipe(
    fileList ? Array.from(fileList) : [],
    list.map((file): PictureToUpload => {
      return {
        file,
        caption: O.None(),
      }
    }),
  )
