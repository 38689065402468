export const ClickAwayEffect = (
  root: () => HTMLElement | undefined,
  onClickAway: () => void,
) => {
  const listener = (event: MouseEvent) => {
    if (event.target && !root()?.contains(event.target as Node)) onClickAway()
  }
  return {
    mount: () => document.addEventListener('mousedown', listener),
    dispose: () => document.removeEventListener('mousedown', listener),
  }
}
