import { cx } from '@/std/classNames'
import { createEffect } from 'solid-js'
import { JSX } from 'solid-js/jsx-runtime'
import { CarouselModel } from './Carousel'

type CarouselProps = {
  model: CarouselModel
  class?: string
  children: JSX.Element
}

export const Carousel = (props: CarouselProps) => {
  createEffect(props.model.adjustGutters)

  return (
    <div
      ref={props.model.refs.root}
      class={cx('x-scrollauto', props.class)}
      onScroll={props.model.onRootScroll}
    >
      <div class="grandpa" style={{ overflow: 'visible' }}>
        <div
          ref={props.model.refs.container}
          class="parent flex"
          style={{ 'flex-wrap': 'nowrap', overflow: 'visible' }}
        >
          {!props.model.noGutter && (
            <div
              ref={props.model.refs.padLeftElement}
              class="opacity-0"
              aria-hidden="true"
            >
              &nbsp;
            </div>
          )}
          {props.children}
          {!props.model.noGutter && (
            <div
              ref={props.model.refs.padRightElement}
              class="opacity-0"
              aria-hidden="true"
            >
              &nbsp;
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
