import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteResource } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { contract } from './contract'

const GetStorageSize = () => {
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  return () => client({})
}

export type StorageSizeResource = ReturnType<typeof StorageSizeResource>
export const StorageSizeResource = () => RemoteResource(GetStorageSize())
