export const uuid = () => globalThis.crypto.randomUUID()

export const generateId = (bytes: number): string => {
  const buffer = new Uint8Array(bytes)
  const populated = globalThis.crypto.getRandomValues(buffer)
  return Array.prototype.map
    .call(populated, (x) => ('00' + x.toString(16)).slice(-2))
    .join('')
}

export const generateTraceId = () => generateId(16)
