import { solidRemoteResource } from '@/solidjs/reactivity'
import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { constNull, matchTypeOr, pipe } from '@/std/function'
import { I18n } from '@travellr/client/i18n/I18n'
import { ConfirmActionDialog } from '@travellr/client/ui-kit/ConfirmActionDialog.solid'
import { MapContextMenu } from '@travellr/domain/core/travellr/client/components/MapContextMenu.solid'
import { TripPage } from '../../../../shared-components/trip'
import { AddCheckpointMapMenu } from '../../components/AddCheckpointMapMenu.solid'
import { MapMenuAtLocation } from '../../components/MapMenuAtLocation.solid'
import { PickPictureCarousel } from '../../components/PickPictureCarousel.solid'
import { UploadPicturesDialog } from '../../components/UploadPictures'
import { CotravellrsModal } from './CotravellrsModal.solid'
import { TripPageMainNav } from './TripMainNav.solid'
import { TripNoteFormDialog } from './TripNoteForm.solid'
import { TripPictureFormModal } from './TripPictureFormModal.solid'
import { TripTitle } from './TripTitle.solid'
import { TripModel } from './main'
import { messages } from './main.i18n'

type Props = {
  model: TripModel
}
export const Trip = (props: Props) => {
  solidRemoteResource(props.model.resource)
  const mapView = solidState(props.model.mapView)
  const noteForm = solidState(props.model.tripNoteForm)
  const uploadPictures = solidState(props.model.uploadPictures)
  const { t } = I18n(messages)

  return (
    <>
      <TripPage
        model={props.model.page}
        onArchivePicture={props.model.archivePictureAction.trigger}
        onEditPicture={props.model.showTripPictureForm}
        onArchiveCheckpoint={props.model.archiveCheckpointAction.trigger}
        onAddNote={props.model.showCreateTripNoteForm}
        onEditNote={props.model.showUpdateTripNoteForm}
        content={pipe(
          mapView(),
          matchTypeOr({
            'locate-picture': () => <>{`<_LocatePicture model={model} />`}</>,
            'pick-picture-at-location': ({ model }) => (
              <PickPictureCarousel model={model} />
            ),
            _: () => undefined, // no particular content.
          }),
        )}
        nav={<TripPageMainNav model={props.model} />}
        title={<TripTitle model={props.model} />}
      />

      <CotravellrsModal model={props.model} />

      <ConfirmActionDialog
        action={props.model.archivePictureAction}
        tone="danger"
        confirmMessage={t('archivePicture/confirmLabel')}
        yes={t('archivePicture/yes')}
        no={t('archivePicture/no')}
      />

      <ConfirmActionDialog
        action={props.model.archiveCheckpointAction}
        tone="danger"
        confirmMessage={t('archiveCheckpoint/confirmLabel')}
        yes={t('archiveCheckpoint/yes')}
        no={t('archiveCheckpoint/no')}
      />

      {pipe(
        uploadPictures(),
        O.fold(constNull, (model) => <UploadPicturesDialog model={model} />),
      )}

      <TripPictureFormModal model={props.model} />
      <TripNoteFormDialog model={props.model} />

      {pipe(
        // overlays
        mapView(),
        matchTypeOr({
          _: () => <></>, // no overlay
          'add-checkpoint': ({ model, position }) => (
            <MapContextMenu
              location={model.location}
              map={props.model.page.leafletMap.map}
              onClose={props.model.setDefaultView}
              position={position}
            >
              <AddCheckpointMapMenu
                model={model}
                onClose={() =>
                  props.model.setMenuAtLocationView({
                    location: model.location,
                    position,
                  })
                }
                onLocatePicture={props.model.setPickPictureAtLocationView}
              />
            </MapContextMenu>
          ),
          'menu-at-location': ({ location, position }) => (
            <MapContextMenu
              location={location}
              map={props.model.page.leafletMap.map}
              onClose={props.model.setDefaultView}
              position={position}
            >
              <MapMenuAtLocation
                location={location}
                onAddCheckpoint={() =>
                  props.model.setAddCheckpointView({ location, position })
                }
                onClose={props.model.setDefaultView}
                onLocatePicture={props.model.setPickPictureAtLocationView}
              />
            </MapContextMenu>
          ),
        }),
      )}
    </>
  )
}
