import { Link } from '@/solidjs/router'
import { I18n } from '@travellr/client/i18n/I18n'
import { Page } from '@travellr/client/ui-kit/Page.solid-js'
import { JSX } from 'solid-js'
import { MainNav } from '../../../ui-kit/MainNav.solid-js'
import { pathTo } from '../../router'
import { messages } from './page.i18n'

export const AddTrip = () => {
  const { t } = I18n(messages)

  return (
    <Page
      contentClass="flex justify-center align-center"
      content={
        <div class="vspacer-l w-100 p-m">
          <Card href={pathTo.startTrip} label={t('startTrip')} />
          <Card href={pathTo.importTrip} label={t('importTrip')} />
        </div>
      }
      nav={
        <MainNav.Slot>
          <MainNav.Home />
          <MainNav.Back />
          <MainNav.AddTrip />
          <MainNav.Burger alignOptions="right" />
        </MainNav.Slot>
      }
    />
  )
}

type CardProps = {
  href: string
  label: JSX.Element
}
const Card = (props: CardProps) => (
  <Link class="box block relative p-l" href={props.href}>
    <span class="absolute right middle px-m">»</span>
    <div class="vspacer-m pr-m">
      <h2 class="py-l">{props.label}</h2>
    </div>
  </Link>
)
