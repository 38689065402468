import { O } from '@/std/data'
import { panic, pipe } from '@/std/function'
import { JSX, createContext, useContext } from 'solid-js'

export const makeContext = <T extends any>(name: string) => {
  const Context = createContext(O.None<T>())
  const use = () =>
    pipe(
      Context,
      useContext,
      O.unwrapOr(() => panic(new Error(`no context provided for “${name}”`))),
    )

  const Provider = (props: { value: T; children: JSX.Element }) => (
    <Context.Provider value={O.Some(props.value)}>
      {props.children}
    </Context.Provider>
  )

  return [Provider, use] as const
}
