import { solidFormGroup } from '@/solidjs/form'
import { LazyPellEditor } from '@/solidjs/pell/LazyEditor'
import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { constVoid } from '@/std/function'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { Failure } from '@travellr/client/ui-kit/Failure.solid-js'
import { FormSubmitStateOverlay } from '@travellr/client/ui-kit/FormSubmitStateOverlay.solid-js'
import { ComponentProps, JSX, createUniqueId } from 'solid-js'
import { NoteFormModel } from './NoteForm'

type Props = {
  class?: string
  model: NoteFormModel
  onSuccess?: () => void
  // onCancel: () => void
  render: (options: {
    Form: (props: ComponentProps<'form'>) => JSX.Element
    SubmitButton: (props: ComponentProps<'button'>) => JSX.Element
  }) => JSX.Element
}

export const NoteForm = (props: Props) => {
  const formId = createUniqueId()
  const { reset, submit } = props.model
  const controls = solidFormGroup(props.model.controls)
  const submitState = solidState(props.model.submitState)

  return props.render({
    Form: (p) => (
      <form class={cx('vspacer-m', p.class)} onSubmit={submit} id={formId}>
        <FormSubmitStateOverlay
          submitState={submitState()}
          onSuccess={props.onSuccess ?? constVoid}
          onTryAgain={submit}
          onClose={reset}
        />
        <LazyPellEditor
          value={controls.content()}
          onChange={(value) => {
            controls.content.set(value)
            controls.content.isTouched.set(true)
          }}
          error={(refetch) => (error) =>
            <Failure error={error} onTryAgain={refetch} />}
          loading={() => <div class="placeholder" style="min-height: 10rem" />}
          config={{
            classes: {
              actionbar: 'text-center border-bottom',
              content: 'vspacer-s editor py-m',
            },
          }}
        />
      </form>
    ),
    SubmitButton: (p) => {
      const { t } = I18n()
      return (
        <button
          class="button"
          {...p}
          type="submit"
          form={formId}
          disabled={RR.isPending(submitState()) || !controls.isTouched()}
        >
          {p.children ?? t('verb/save')}
        </button>
      )
    },
  })
}
