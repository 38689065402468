import { C } from '../codec'
import { TR } from '../remote'

export type QueryShape = Record<string, any> | undefined

export type GenericOptions<T, Q extends QueryShape> = {
  url: string
  ResponseCodec: C.Codec<T, any>
  headers?: HeadersInit
  mode?: RequestInit['mode']
  credentials?: RequestInit['credentials']
} & QueryOptions<Q>

type URLSearchParamsInit = Record<string, string> // ConstructorParameters<typeof URLSearchParams>[0]
type QueryOptions<Q extends QueryShape> = Q extends undefined
  ? {}
  : { query: Q; QueryCodec: C.Codec<Q, URLSearchParamsInit, unknown> }

export type PayloadOptions<T> = {
  body: T
  BodyCodec: C.Codec<T, any>
  onProgress?: (event: ProgressEvent<any>) => void
}

type PatchQueryOptions<Q extends NonNullable<QueryShape>> =
  | {
      query: Q
      QueryCodec: C.Codec<Q, URLSearchParamsInit, unknown>
    }
  | {}

export const patchQuery = <Q extends NonNullable<QueryShape>>(
  path: string,
  options: PatchQueryOptions<Q>,
) => {
  if ('query' in options && 'QueryCodec' in options) {
    const { query, QueryCodec } = options
    const searchParams = new URLSearchParams(QueryCodec.encode(query))
    return `${path}?${searchParams.toString()}`
  }
  return path
}

export interface HttpClient<E extends Error = Error> {
  get: <T, Q extends QueryShape>(
    options: GenericOptions<T, Q>,
  ) => TR.TaskResult<E, T>

  post: <Payload, Response, Q extends QueryShape>(
    options: GenericOptions<Response, Q> & PayloadOptions<Payload>,
  ) => TR.TaskResult<E, Response>

  patch: <Payload, Response, Q extends QueryShape>(
    options: GenericOptions<Response, Q> & PayloadOptions<Payload>,
  ) => TR.TaskResult<E, Response>

  put: <Payload, Response, Q extends QueryShape>(
    options: GenericOptions<Response, Q> & PayloadOptions<Payload>,
  ) => TR.TaskResult<E, Response>

  delete: <Payload, Response, Q extends QueryShape>(
    options: GenericOptions<Response, Q> & (PayloadOptions<Payload> | {}),
  ) => TR.TaskResult<E, Response>
}
