import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { struct } from '@/std/data'
import { pipe } from '@/std/function'
import { MimeType } from '@/std/http'
import { Cotravellr, CotravellrStruct } from '../../entity/Cotravellr'

export type Params = C.TypeOf<typeof ParamsCodec>
const ParamsCodec = pipe(
  CotravellrStruct,
  struct.pick('selfTripId', 'peerTripId'),
  C.struct,
)

export const contract = ApiRouteContract('DELETE', '/cotravellr', {
  body: {
    contentType: MimeType.Json,
    codec: ParamsCodec,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: Cotravellr,
  },
})
