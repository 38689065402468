export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'archivePicture/confirmLabel': 'Archive picture?',
  'archivePicture/yes': 'Sure 👍',
  'archivePicture/no': 'Nope 😱',
  'archiveCheckpoint/confirmLabel': 'Archive checkpoint?',
  'archiveCheckpoint/yes': 'Sure 👍',
  'archiveCheckpoint/no': 'Nope 😱',
} as const

const fr = {
  'archivePicture/confirmLabel': 'Archiver cette image ?',
  'archivePicture/yes': 'Oui 😤',
  'archivePicture/no': 'Nope 😱',
  'archiveCheckpoint/confirmLabel': 'Archiver ce point d’étape ?',
  'archiveCheckpoint/yes': 'Oui 😤',
  'archiveCheckpoint/no': 'Nope 😱',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
