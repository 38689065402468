import { cx } from '@/std/classNames'
import { JSX } from 'solid-js/jsx-runtime'
import { Portal } from 'solid-js/web'
import './Dialog.css'

export type DialogProps = {
  onClose?: () => void
  root?: Node
  dialogClass?: string
  children: JSX.Element
}
export const Dialog = (props: DialogProps) => (
  <Portal mount={props.root ?? document.body}>
    <div class="fixed z-5 top middle w-100 h-100">
      <div
        class="absolute h-100 w-100 backdrop opacity-3"
        onClick={props.onClose}
        style={{ 'z-index': 10 }}
      />
      <div
        class="absolute top left h-100 w-100 opacity-10 flex align-center justify-center"
        style={{ 'z-index': 20 }}
      >
        <div class="dialog">
          {props.onClose && (
            <div class="pb-s text-right">
              <button
                class="button discrete close"
                style={{ 'font-size': '1.75rem', 'line-height': '1.25rem' }}
                onClick={props.onClose}
              />
            </div>
          )}

          <div
            class={cx('box', props.dialogClass)}
            style={{ overflow: 'auto' }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  </Portal>
)
