export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'success': 'All good',
} as const

const fr = {
  'success': 'Tout bon',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr };
