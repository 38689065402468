import { C, Cd } from '../codec'
import * as D from '../data'
import { curry2, pipe } from '../function'

export interface Type<T, O = unknown, I = unknown>
  extends C.Codec<T, O, I>,
    D.Eq<T> {
  (value: T): T
  is: (value: unknown) => value is T
}

export type OrdType<T, O = unknown, I = unknown> = Type<T, O, I> & D.Ord<T>

const makeIdentity =
  <T>() =>
  (value: T) =>
    value

export const string: OrdType<string, string> = Object.assign(
  makeIdentity<string>(),
  {
    ...C.string,
    ...D.string.eq,
    ...D.string.ord,
    is: C.is(C.string),
  },
)

export const number: OrdType<number, number> = Object.assign(
  makeIdentity<number>(),
  {
    ...C.number,
    ...D.number.eq,
    ...D.number.ord,
    is: C.is(C.number),
  },
)

export const boolean: OrdType<boolean, boolean> = Object.assign(
  makeIdentity<boolean>(),
  {
    ...C.bool,
    ...D.boolean.eq,
    ...D.boolean.ord,
    is: C.is(C.bool),
  },
)

export const unknown: Type<unknown, unknown, unknown> = Object.assign(
  makeIdentity<unknown>(),
  {
    ...C.unknown,
    equals: curry2((a, b) => a === b),
    is: (value): value is unknown => true,
  },
)

export const Date: OrdType<Date, Date> = Object.assign(makeIdentity<Date>(), {
  ...C.Date,
  ...D.date.eq,
  ...D.date.ord,
  is: C.is(C.Date),
})

export const numberFromString: OrdType<number, string> = Object.assign(
  makeIdentity<number>(),
  {
    ...Cd.numberFromString,
    ...D.number.eq,
    ...D.number.ord,
    is: C.is(C.number),
  },
)

type Struct = <S extends Record<string, Type<any, any, unknown>>>(
  st: S,
) => Type<
  { [Key in keyof S]: C.TypeOf<S[Key]> },
  { [Key in keyof S]: C.OutputOf<S[Key]> },
  unknown
> & {
  ord: {
    [Key in keyof S]: S[Key] extends D.Ord<any>
      ? D.Ord<C.TypeOf<S[Key]>>
      : never
  }
}
export const struct: Struct = (properties) => {
  const codec = C.struct(properties)
  return Object.assign(makeIdentity(), {
    ...codec,
    ...D.struct.eq(properties),
    ord: properties,
    is: C.is(codec),
  }) as any
}

type RecordFn = <Key extends PropertyKey, T, O>(
  key: Type<Key, PropertyKey, unknown>,
  value: Type<T, O, unknown>,
) => Type<Record<Key, T>, Record<Key, O>, unknown>
export const record: RecordFn = (key, value) => {
  const codec = C.record(key, value)
  return Object.assign(makeIdentity(), {
    ...codec,
    ...D.record.eq,
    is: C.is(codec),
  }) as any
}

type Array = {
  <T, O>(codec: OrdType<T, O, unknown>): OrdType<T[], O[], unknown>
  <T, O>(codec: Type<T, O, unknown>): Type<T[], O[], unknown>
}
export const array: Array = (type) => {
  const codec = C.array(type)
  return Object.assign(makeIdentity(), {
    ...codec,
    ...D.list.eq(type),
    ...(D.Ord.isOrd(type) && D.list.eq(type)),
    is: C.is(codec),
  }) as any
}

type Union = <T extends D.TupleOf<Type<any, any, any>>>(
  ...codecs: T
) => Type<
  { [Key in keyof T]: C.TypeOf<T[Key]> }[number],
  { [Key in keyof T]: C.OutputOf<T[Key]> }[number],
  { [Key in keyof T]: C.InputOf<T[Key]> }[number]
>
export const union: Union = (...types) => {
  const codec = C.union(...types)
  type T = C.TypeOf<typeof codec>
  return Object.assign(makeIdentity<T>(), {
    ...codec,
    ...D.Eq.union(...types),
    is: C.is(codec as any),
  }) as any
}

type Intersectable = Record<any, any>
type Intersect = <T2 extends Intersectable, O2 extends Intersectable, I2>(
  b: Type<T2, O2, I2>,
) => <T1 extends Intersectable, O1 extends Intersectable, I1>(
  a: Type<T1, O1, I1>,
) => Type<T1 & T2, O1 & O2, I1 & I2>
export const intersect: Intersect = (b) => (a) => {
  const codec = pipe(a, C.intersect(b))
  return Object.assign(makeIdentity(), {
    ...codec,
    ...D.Eq.intersect(b)(a),
    is: C.is(codec as any),
  }) as any
}

type Map = {
  <A, B, O, I>(f: (a: A) => B, g: (b: B) => A): (
    fa: OrdType<A, O, any>,
  ) => OrdType<B, O, I>
  <A, B, O, I>(f: (a: A) => B, g: (b: B) => A): (
    fa: Type<A, O, any>,
  ) => Type<B, O, I>
}
export const map: Map = (f, g) => (fa) => {
  const codec = pipe(fa, C.map(f, g))
  return Object.assign(makeIdentity(), {
    ...codec,
    ...D.Eq.fromEquals<any>((a, b) => fa.equals(g(a), g(b))),
    ...(D.Ord.isOrd(fa) &&
      D.Ord.fromCompare<any>((a, b) => fa.compare(g(a), g(b)))),
    is: C.is(codec, true),
  }) as any
}

type Tuple = <T extends D.TupleOf<Type<any, any, any>>>(
  tuple: T,
) => Type<
  { [Key in keyof T]: C.TypeOf<T[Key]> },
  { [Key in keyof T]: C.OutputOf<T[Key]> },
  unknown
>
export const tuple: Tuple = (types) => {
  const codec = C.tuple(types)
  return Object.assign(makeIdentity(), {
    ...codec,
    ...D.Eq.fromEquals<D.Tuple>((a, b) => {
      return types.every((type, index) => type.equals(a[index], b[index]))
    }),
    is: C.is(codec),
  }) as any
}

type EnumLike = { [k: string]: string | number; [nu: number]: string }
type Enum = <T extends EnumLike>(
  actualEnum: T,
) => Type<T[keyof T], string | number, unknown>
export const Enum: Enum = (enu) => {
  const codec = C.Enum(enu)
  return Object.assign(makeIdentity(), {
    ...codec,
    ...D.Eq.fromEquals((a, b) => a === b),
    is: C.is(codec),
  }) as any
}

type Refine = {
  <A, B extends A>(id: string, refinement: (a: A) => a is B): <I, O>(
    from: OrdType<A, O, I>,
  ) => OrdType<B, O, I>
  <A, B extends A>(id: string, refinement: (a: A) => a is B): <I, O>(
    from: Type<A, O, I>,
  ) => Type<B, O, I>
}
export const refine: Refine = (id, refinement) => (type) => {
  const codec = pipe(type, C.refine(id, refinement))
  return Object.assign(makeIdentity(), {
    ...type,
    ...codec,
    is: C.is(codec as any),
  })
}

type Literal<Literals extends D.TupleOf<C.Primitive>> = Type<
  Literals[number],
  Literals[number]
>
export const literal = <Literals extends D.TupleOf<C.Primitive>>(
  ...literals: Literals
): Literal<Literals> => {
  const codec = C.literal(...literals)
  return Object.assign(makeIdentity<Literals[number]>(), {
    ...codec,
    ...D.Eq.fromEquals((a, b) => a === b),
    is: C.is(codec),
  })
}

type Email = Type<D.Email, string>
export const Email: Email = Object.assign(makeIdentity<D.Email>(), {
  ...Cd.Email,
  ...D.Eq.fromEquals<D.Email>((a, b) => a === b),
  is: C.is(Cd.Email),
})

type DateFromString = Type<Date, string>
export const DateFromString: DateFromString = Object.assign(
  makeIdentity<Date>(),
  {
    ...Cd.DateFromString,
    ...D.date.eq,
    is: C.is(Cd.DateFromString),
  },
)

type Url = Type<URL, string>
export const Url: Url = Object.assign(makeIdentity<URL>(), {
  ...Cd.Url,
  ...D.Eq.fromEquals<URL>((a, b) => a.href === b.href),
  is: C.is(Cd.Url),
})

type OptionFn = {
  <T, Out>(type: OrdType<T, Out>): OrdType<D.O.Option<T>, Out | undefined>
  <T, Out>(type: Type<T, Out>): Type<D.O.Option<T>, Out | undefined>
}
export const option: OptionFn = (type) => {
  const codec = C.Option(type)
  return Object.assign(makeIdentity(), {
    ...codec,
    ...D.O.eq(type),
    ...(D.Ord.isOrd(type) && D.O.ord(type)),
    is: C.is(codec),
  }) as any
}
