import { History } from '@/std/browser/history'
import { flow } from '@/std/function'
import { ComponentProps } from 'solid-js'
import { makeContext } from './context'
import { solidState } from './state'

const [RouterProvider, useContext] =
  makeContext<Pick<History, 'push' | 'current'>>('router')

export { RouterProvider }
export const useRouter = flow(useContext, (history) => ({
  ...history,
  current: solidState(history.current),
}))

export const Link = (props: ComponentProps<'a'>) => {
  const router = useRouter()
  return (
    <a
      {...props}
      onClick={(event) => {
        event.preventDefault()
        const isDisabled =
          event.currentTarget.getAttribute('aria-disabled') === 'true'
        if (isDisabled) return // abort navigation

        router.push(event.currentTarget.href)
        if (props?.onClick)
          typeof props.onClick === 'function'
            ? props.onClick(event)
            : props.onClick[0](props.onClick[1], event)
      }}
    />
  )
}
