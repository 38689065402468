export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'changeEmail': 'Entered the wrong email? @Cta(try again)',
  'heading': 'Yey! 🎉',
  'loggingWithDemoAccount.confirm': 'Yup that’s intended, take me there already!',
  'loggingWithDemoAccount.message': 'You are currently logging into a demo account “@Bold({email})”',
  'loggingWithEmail': 'An email was sent to “@Bold({email})”, check your emails and click on the link or copy-paste the token directly here:',
  'success': 'Redirecting...',
  'unauthorized': 'This token is expired\nYou can get @Cta(get another)',
} as const

const fr = {
  'changeEmail': 'C’est pas ton email? @Cta(Change-le)',
  'heading': 'Coool ! 🎉',
  'loggingWithDemoAccount.confirm': 'Yup, c’est bien le but',
  'loggingWithDemoAccount.message': 'Attention, “@Bold({email})” correspond à un compte démo',
  'loggingWithEmail': 'Un email a été envoyé à “@Bold({email})”, ouvre-le puis clique sur le lien ou copie-colle le code',
  'success': 'Redirection...',
  'unauthorized': 'Ton token a expiré\nTu peux en @Cta(avoir un autre)',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
