import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'

export const contract = ApiRouteContract('GET', '/storage-size', {
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: T.struct({
      consumedStorageInBytes: T.number,
      storageLimitInBytes: T.number,
    }),
  },
})
