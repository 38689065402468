import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { PictureId } from '../../entity/Picture'
import { Payload, contract } from './contract'

export type UpdatePictureAction = ReturnType<typeof UpdatePictureAction>

export const UpdatePicture = () => {
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const update = (id: PictureId, body: Payload) =>
    client({ params: { id }, body })

  return update
}

export const UpdatePictureAction = () => {
  return RemoteAction(UpdatePicture())
}
