import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { struct } from '@/std/data'
import { pipe } from '@/std/function'
import { MimeType } from '@/std/http'
import { Checkpoint, CheckpointStruct } from '../../entity/Checkpoint'

export type Payload = C.TypeOf<typeof PayloadCodec>
const PayloadCodec = pipe(
  CheckpointStruct,
  struct.pick('id', 'tripId'),
  C.struct,
)

export const contract = ApiRouteContract('DELETE', '/checkpoint', {
  body: {
    contentType: MimeType.Json,
    codec: PayloadCodec,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: Checkpoint,
  },
})
