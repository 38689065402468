import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { MimeType } from '@/std/http'
import { FullTrip } from '../../entity/FullTrip'
import { TripId } from '../../entity/Trip'

export const contract = ApiRouteContract('GET', '/trips/:id', {
  params: C.struct({ id: TripId }),
  // searchParams: pipe(CotravellrStruct, struct.pick('from', 'until'), C.struct),
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: FullTrip,
  },
})

// type TripFilters = Record<'from' | 'until', Option<Date>>

// type GetTripOptions = {
//   id: TripId
//   includeCotravellrs: boolean
//   filters?: TripFilters
// }
