export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'reloadingMessage':
    'Hold on a sec, a newer version has been detected\n\nReloading...',
} as const

const fr = {
  'reloadingMessage':
    'Une nouvelle version a été détectée\n\nRechargement...',
} satisfies MessagesShape as unknown as Messages

export const messages = { fr, en }
