import { O, date, list, string } from '@/std/data'
import { cnst, flow, pipe } from '@/std/function'
import { ReadonlyState, State, computed, mapState } from '@/std/reactivity'
import { TripCrumb, tripCrumbToDate } from './TripCrumbs'

export type TripCrumbsFilterModel = ReturnType<typeof TripCrumbsFilterModel>

export type Range = 'day' | 'week' | 'month' | 'full'
export const TripCrumbsFilterModel = (init: {
  range: Range
  crumbs: ReadonlyState<TripCrumb[]>
}) => {
  const active = State(O.None<Date>(), { equals: O.eq(date.eq).equals })
  const range = State<Range>(init.range, { equals: string.eq.equals })
  const filter = mapState(range, (range) => rangeFilters[range])

  const previousActive = computed(
    [active, filter, init.crumbs],
    (active, currentFilter, crumbs) =>
      pipe(
        active,
        O.flatMap(getPreviousActive(currentFilter.isCrumbInRange, crumbs)),
      ),
  )
  const nextActive = computed(
    [active, filter, init.crumbs],
    (active, currentFilter, crumbs) =>
      pipe(
        active,
        O.flatMap(getNextActive(currentFilter.isCrumbInRange, crumbs)),
      ),
  )

  const crumbs = computed(
    [active, filter, init.crumbs],
    (active, currentFilter, crumbs) =>
      pipe(active, O.fold(cnst(crumbs), filterCrumbs(crumbs, currentFilter))),
  )

  const initActiveEffect = init.crumbs.onChange(
    flow(
      list.head,
      O.map(tripCrumbToDate),
      O.map((date) => {
        if (O.isNone(active())) active.set(O.Some(date))
        initActiveEffect.unlisten()
      }),
    ),
  )

  return {
    dispose: initActiveEffect.unlisten,
    active,
    previousActive,
    nextActive,
    range,
    crumbs,
  }
}

type IsCrumbInRange = (active: Date) => (crumb: TripCrumb) => boolean

type RangeFilter = {
  isCrumbInRange: IsCrumbInRange
  isSameActive: (nextActive: Date) => (active: Date) => boolean
}

const rangeFilters: Record<Range, RangeFilter> = {
  day: {
    isCrumbInRange: (active) => flow(tripCrumbToDate, date.isSameDay(active)),
    isSameActive: date.isSameDay,
  },
  week: {
    isCrumbInRange: (active) => flow(tripCrumbToDate, date.isSameWeek(active)),
    isSameActive: date.isSameWeek,
  },
  month: {
    isCrumbInRange: (active) => flow(tripCrumbToDate, date.isSameMonth(active)),
    isSameActive: date.isSameMonth,
  },
  full: {
    isCrumbInRange: () => () => true,
    isSameActive: () => () => true,
  },
}

const getPreviousActive =
  (isCrumbInActiveRange: IsCrumbInRange, sortedCrumbs: TripCrumb[]) =>
  (active: Date) =>
    pipe(
      sortedCrumbs,
      list.findLast((crumb) => {
        const crumbDate = tripCrumbToDate(crumb)
        return (
          date.isBefore(active)(crumbDate) &&
          !isCrumbInActiveRange(active)(crumb)
        )
      }),
    )

const getNextActive =
  (isCrumbInActiveRange: IsCrumbInRange, sortedCrumbs: TripCrumb[]) =>
  (active: Date) =>
    pipe(
      sortedCrumbs,
      list.findFirst((crumb) => {
        const crumbDate = tripCrumbToDate(crumb)
        return (
          date.isAfter(active)(crumbDate) &&
          !isCrumbInActiveRange(active)(crumb)
        )
      }),
    )

const filterCrumbs =
  (crumbs: TripCrumb[], filter: RangeFilter) => (active: Date) =>
    pipe(crumbs, list.filter(filter.isCrumbInRange(active)))
