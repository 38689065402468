import { O } from '@/std/data'
import { match, pipe } from '@/std/function'
import { AddTrip } from './pages/AddTrip/page.solid'
import { Home } from './pages/Home/page.solid'
import { ImportTrip } from './pages/ImportTrip/page.solid'
import { MyTrips } from './pages/MyTrips/page.solid'
import { NotFound } from './pages/NotFound.solid'
import { Profile } from './pages/Profile/page.solid'
import { StartTrip } from './pages/StartTrip/page.solid'
import { Trip } from './pages/Trip/main.solid'
import { TravellrAppRoute } from './router'

type Props = {
  route: O.Option<TravellrAppRoute>
}

export const Router = (props: Props) => (
  <>
    {pipe(
      props.route,
      O.map(
        match('route')({
          Home: ({ model }) => <Home model={model} />,
          AddTrip: () => <AddTrip />,
          ImportTrip: ({ model }) => <ImportTrip model={model()} />,
          MyTrips: ({ model }) => <MyTrips model={model()} />,
          Profile: ({ model }) => <Profile model={model()} />,
          StartTrip: ({ model }) => <StartTrip model={model()} />,
          Trip: ({ model }) => <Trip model={model()} />,
        }),
      ),
      O.unwrapOr(() => <NotFound />),
    )}
  </>
)
