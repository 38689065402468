import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { RemoteResource } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { RuntimeError } from '../../entity/RuntimeError'
import { contract } from './contract'

export type AddRuntimeErrorAction = ReturnType<typeof AddRuntimeErrorAction>
export const AddRuntimeErrorAction = () => {
  const { xhr } = getClientContext()
  const client = XhrApiRouteClient(contract, xhr)
  const send = (error: RuntimeError) => client({ body: error })
  return send
}

export type AddRuntimeErrorResource = ReturnType<typeof AddRuntimeErrorResource>
export const AddRuntimeErrorResource = (error: RuntimeError) => {
  const action = AddRuntimeErrorAction()
  return RemoteResource(() => action(error))
}
