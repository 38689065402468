import { debounce, isInstanceOf } from '@/std/function'

type Init = {
  noGutter?: boolean
  onActiveChange?: (isActive: boolean, node: HTMLElement, index: number) => void
  autoScrollDelay?: number
}

export type CarouselModel = ReturnType<typeof CarouselModel>
export const CarouselModel = ({
  autoScrollDelay = 500,
  noGutter = false,
  onActiveChange = () => {},
}: Init = {}) => {
  const scrollTo = (selector: string, behavior: ScrollBehavior = 'smooth') => {
    const element = root?.querySelector(selector)
    if (element instanceof HTMLElement) scrollToElement(element, behavior)
  }

  let root: HTMLDivElement | undefined
  let container: HTMLDivElement | undefined
  let padLeftElement: HTMLDivElement | undefined
  let padRightElement: HTMLDivElement | undefined
  let active: HTMLElement

  const getElements = () =>
    Array.from(container?.children ?? [])
      .filter(isInstanceOf(HTMLElement))
      .slice(1, -1)

  const onScroll = () => {
    if (!root) return
    const carouselCenter = root.scrollLeft + root.clientWidth / 2
    const nextActive = getElements().find((child) => {
      const bounds = {
        left: getOffsetLeft(child),
        right: getOffsetLeft(child) + child.clientWidth,
      }
      return carouselCenter >= bounds.left && carouselCenter <= bounds.right
    })
    scrollToElement(nextActive)
  }

  const scrollToElement = (
    nextActive: HTMLElement | undefined,
    behavior: ScrollBehavior = 'smooth',
  ) => {
    if (!nextActive || !root) return
    const left =
      getOffsetLeft(nextActive) -
      root.getBoundingClientRect().width / 2 +
      nextActive.clientWidth / 2
    root.scrollTo({ left, behavior })

    if (nextActive !== active) {
      onActiveChange?.(false, active, getElements().indexOf(active))
      active = nextActive
      onActiveChange?.(true, nextActive, getElements().indexOf(nextActive))
    }
  }

  const adjustGutters = () => {
    if (noGutter) return
    if (!container || !root || !padLeftElement || !padRightElement) return
    // const paddingLeft = Math.abs(
    //   getOffsetLeft(active) -
    //     template.getBoundingClientRect().width / 2 +
    //     active.clientWidth / 2,
    // )
    const padding = Math.round(container.clientWidth / 3)
    padLeftElement.style.minWidth = padding + 'px'
    padRightElement.style.minWidth = padding + 'px'
  }

  return {
    refs: {
      root,
      container,
      padLeftElement,
      padRightElement,
    },
    mount: () => {
      active = getElements()[0]
    },
    adjustGutters,
    onRootScroll: debounce(autoScrollDelay)(onScroll),
    scrollTo,
    noGutter,
  }
}

const getOffsetLeft = (child: HTMLElement) =>
  child.offsetLeft - (child.parentElement?.offsetLeft || 0)
