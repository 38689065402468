import { Page as UIKitPage } from '@travellr/client/ui-kit/Page.solid-js'
import { JSX } from 'solid-js'
import { Header } from './Header.solid-js'

export const Page = (props: { children: JSX.Element }) => (
  <UIKitPage
    contentClass="p-m flex column justify-space-between"
    content={
      <>
        <Header />
        <div class="flex-1 flex align-center justify-center maxw-100">
          {props.children}
        </div>
      </>
    }
  />
)
