export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'title': 'My trips',
  'noTripsMessage': 'You don’t have any trip at the moment.',
} as const

const fr = {
  'title': 'Mes voyages',
  'noTripsMessage': 'T’as aucun voyage pour le moment.',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
