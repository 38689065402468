import { solidRemoteAction } from '@/solidjs/reactivity'
import { RemoteAction } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { JSX } from 'solid-js'
import { FormSubmitStateOverlay } from './FormSubmitStateOverlay.solid-js'

export type ConfirmProps<E, T> = {
  heading?: JSX.Element
  message: JSX.Element
  confirmLabel?: JSX.Element
  cancelLabel?: JSX.Element
  action: RemoteAction<E, T, []>
  onSuccess: () => void
  onCancel: () => void
}

export const Confirm = <E extends Error, T>(props: ConfirmProps<E, T>) => {
  const { t } = I18n({ en: {}, fr: {} })
  const action = solidRemoteAction(props.action)

  return (
    <div>
      {props.heading && <div class="p-m">{props.heading}</div>}
      {props.heading && <hr />}

      <div class="p-m flex align-center" style={{ 'min-height': '4.5rem' }}>
        <div class="flex-1">
          <FormSubmitStateOverlay
            onClose={props.onCancel}
            onSuccess={props.onSuccess}
            submitState={action.state()}
            onTryAgain={action.reset}
          />
          {props.message}
        </div>
      </div>

      <hr />

      <div class="hspacer-m flex justify-end p-m">
        <button class="discrete button" onClick={props.onCancel}>
          {props.cancelLabel ?? t('verb/cancel')}
        </button>
        <button class="button" onClick={action.trigger}>
          {props.confirmLabel ?? t('verb/confirm')}
        </button>
      </div>
    </div>
  )
}
