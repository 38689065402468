import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteResource } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { TripId } from '../../entity/Trip'
import { contract } from './contract'

export type GetTripResource = ReturnType<typeof GetTripResource>
export const GetTripResource = (id: TripId) => {
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const get = () => client({ params: { id } })
  return RemoteResource(get)
}
