import { C, Cd } from './codec'
import { list as L, O, record, isEmail as stringIsEmail, struct } from './data'
import { apply, cnst, flow, identity, not, pipe } from './function'
import { State } from './reactivity/state'

export type ControlError = O.Option<string>

export type FormControl<T, Err extends string = string> = State<T> & {
  isTouched: State<boolean>
  isValid: () => boolean
  error: State<O.Option<Err>>
  reset: () => void
}

type Validator<T, Err extends string = string> = (value: T) => O.Option<Err>

const validate =
  <T, Err extends string = string>(validators: Validator<T, Err>[]) =>
  (value: T) =>
    pipe(validators, L.findFirstMap(apply(value)))

export const FormControl = <T, Err extends string = string>(
  initial: T,
  validators: Validator<T, Err>[] = [],
): FormControl<T, Err> => {
  const error = State(O.None<Err>())
  const control = Object.assign(State(initial), {
    error,
    isTouched: State(false),
    isValid: flow(error, O.isNone),
    reset: () => {
      control.isTouched.set(false)
      error.set(O.None())
    },
  })

  control.onChange(flow(validate(validators), error.set))
  control.isTouched.onChange(flow(control, validate(validators), error.set))
  return control
}
export const fakeControl = <T, Err extends string = string>(
  value: T,
  state?: {
    touched?: boolean
    error?: Err
  },
): FormControl<T, Err> =>
  Object.assign(State(value), {
    error: State(O.fromNullable(state?.error)),
    isTouched: State(state?.touched ?? false),
    isValid: () => !state?.error,
    reset: () => {},
  })

export type FormGroup<Controls extends Record<string, FormControl<any, any>>> =
  Controls & {
    isValid: () => boolean
    isTouched: () => boolean
    markTouched: () => void
  }
export const FormGroup = <T extends Record<string, FormControl<any, any>>>(
  controls: T,
): FormGroup<T> => ({
  ...controls,
  isValid: () => isValid(controls),
  isTouched: () => isTouched(controls),
  markTouched: () =>
    Object.values(controls).forEach(({ isTouched }) => isTouched.set(true)),
})

const everyControl = <Key extends string>(key: Key) =>
  flow(
    identity<Record<string | number, { [K in Key]: () => boolean }>>,
    record.values,
    L.every(flow(struct.lookup(key), (fn) => fn())),
  )
export const isTouched = everyControl('isTouched')
export const isValid = everyControl('isValid')

export const required = <T, Err extends string = string>(
  message: Err,
): Validator<O.Option<T>, Err> => O.fold(() => O.Some(message), O.None)

export const nonEmpty =
  <T extends { length: number }, Err extends string = string>(
    message: Err,
  ): Validator<T, Err> =>
  (value) =>
    value.length === 0 ? O.Some(message) : O.None()

export const notEqual =
  <T, Err extends string = string>(
    forbidden: T,
    message: Err,
  ): Validator<T, Err> =>
  (value) =>
    value === forbidden ? O.Some(message) : O.None()

export const isEmail = <Err extends string = string>(
  message: Err,
): Validator<string, Err> =>
  flow(O.fromPredicate(not(stringIsEmail)), O.map(cnst(message)))

export const isUrl = <Err extends string = string>(
  message: Err,
): Validator<string, Err> =>
  flow(O.fromPredicate(not(C.canDecode(Cd.Url))), O.map(cnst(message)))
