import { BoomOf, InfraError, makeBoom } from '@/std/error'
import { identity } from '@/std/function'
import { Except } from 'type-fest'
import { C } from '../codec'
import { CookieOptions } from '../data/cookies'
import { HttpError } from '../http/Error'
import { HttpMethod } from '../http/Method'
import { TR } from '../remote'
import { HasProp, If, NeverOrBindTo, NotNeverOr } from '../types'
import {
  ApiContract,
  ApiRouteShape,
  MergeContracts,
  mergeContracts,
} from './contract'
import { PathParameters } from './type-utils'

/** @deprecated */
export type ApiHandlerContext<
  Path extends string,
  Route extends ApiRouteShape,
> = {
  readonly searchParams: C.TypeOf<Route['searchParams']>
  readonly params: If<
    HasProp<Route, 'params'>,
    C.TypeOf<Route['params']>,
    PathParameters<Path>
  >
  readonly body: NotNeverOr<
    C.TypeOf<NonNullable<Route['body']>['codec']>,
    undefined
  >
  readonly headers: C.TypeOf<Route['headers']>
  readonly cookies: Record<string, string>
  getHeader: (name: string) => string | null | undefined
}

/** @deprecated */
export type ApiHandler<
  Path extends string = string,
  Route extends ApiRouteShape = ApiRouteShape,
> = (context: ApiHandlerContext<Path, Route>) => TR.TaskResult<
  HttpError,
  NeverOrBindTo<'body', C.TypeOf<Route['response']['codec']>, undefined> &
    NeverOrBindTo<
      'headers',
      C.TypeOf<Route['response']['headers']>,
      undefined
    > & {
      cookies?: Record<string, ResponseCookieOptions>
    }
>

export type ResponseCookieOptions = Except<CookieOptions, 'name'>
export const ResponseCookies = identity as typeof identity<
  Record<string, ResponseCookieOptions>
>

/** @deprecated */
type ApiErrorHandler<Route extends ApiRouteShape = ApiRouteShape> = (
  error:
    | InfraError
    | If<HasProp<Route, 'body'>, DecodeBodyError, never>
    | If<HasProp<Route, 'params'>, DecodeParamsError, never>
    | If<HasProp<Route, 'headers'>, DecodeHeadersError, never>
    | If<HasProp<Route, 'searchParams'>, DecodeSearchParamsError, never>,
) => HttpError

/** @deprecated */
export type ApiHandlers<Contract extends ApiContract> = {
  [Path in Extract<keyof Contract, string>]: {
    [Method in Extract<keyof Contract[Path], HttpMethod>]:
      | ApiHandler<Path, Extract<Contract[Path][Method], ApiRouteShape>>
      | {
          handler: ApiHandler<
            Path,
            Extract<Contract[Path][Method], ApiRouteShape>
          >
          errorHandler: ApiErrorHandler<
            Extract<Contract[Path][Method], ApiRouteShape>
          >
        }
  }
}

export type ApiCors = {
  origin?: string[] | ((referrer: string | undefined) => string | undefined)
  methods?: HttpMethod[]
  allowHeaders?: string[]
  exposeHeaders?: string[]
  credentials?: boolean
  maxAge?: number
}

/** @deprecated Use ApiRouteHandler */
export const ApiHandlers = <Contract extends ApiContract>(
  _contract: Contract,
  handlers: ApiHandlers<Contract>,
) => handlers

export const DecodeParamsError = makeBoom(
  'DecodeParamsError',
  (err: C.DecodeError) => err.message,
)
export type DecodeParamsError = BoomOf<typeof DecodeParamsError>

export const DecodeHeadersError = makeBoom(
  'DecodeHeadersError',
  (err: C.DecodeError) => err.message,
)
export type DecodeHeadersError = BoomOf<typeof DecodeHeadersError>

export const DecodeSearchParamsError = makeBoom(
  'DecodeSearchParamsError',
  (err: C.DecodeError) => err.message,
)
export type DecodeSearchParamsError = BoomOf<typeof DecodeSearchParamsError>

export const DecodeBodyError = makeBoom(
  'DecodeBodyError',
  (err: C.DecodeError) => err.message,
)
export type DecodeBodyError = BoomOf<typeof DecodeBodyError>

export type MergeHandlers<H> = MergeContracts<H>
/** @deprecated */
export const mergeHandlers = mergeContracts as <H extends ApiHandlers<any>[]>(
  ...handlers: H
) => MergeHandlers<H>

// const c1 = ApiContract({
//   '/path-1': {
//     GET: {
//       response: { status: 204 },
//     },
//   },
// })
// const c2 = ApiContract({
//   '/path-1': {
//     POST: {
//       response: { status: 204 },
//     },
//   },
// })
// const c3 = ApiContract({
//   '/path-2': {
//     POST: {
//       response: { status: 204 },
//     },
//   },
// })

// const h1 = ApiHandlers(c1, {
//   '/path-1': {
//     GET: () => TR.Ok({}),
//   },
// })
// const h2 = ApiHandlers(c2, {
//   '/path-1': {
//     POST: () => TR.Ok({}),
//   },
// })
// const h3 = ApiHandlers(c3, {
//   '/path-2': {
//     POST: () => TR.Ok({}),
//   },
// })

// const merged = mergeHandlers(h1, h2, h3)
