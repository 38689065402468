import { struct } from '@/std/data'
import { flow } from '@/std/function'
import { ComponentProps, JSX, splitProps } from 'solid-js'
import { Icon } from './Icon.solid-js'
import { Loader } from './Loader.solid-js'

export type ImageProps = ComponentProps<'img'> & {
  loader?: () => JSX.Element
  error?: () => JSX.Element
  containerClass?: string
}

export const Image = (props: ImageProps) => {
  const [, rest] = splitProps(props, ['loader', 'containerClass'])
  /**
   * NOTE to future self:
   * Image is also used in a Leaflet environment which - apparently - does not support signals
   * Since I am to lazy to dig, I'll leave that workaround as is.
   * Principle is simple, when an image is loaded, I consider there is no comeback to a loading state.
   * Therefore upon image 'load' event, we can simply remove the loader container element.
   */
  const removeLoader = (img: HTMLImageElement) =>
    img.nextElementSibling?.remove()

  const showImage = (img: HTMLImageElement) => {
    img.hidden = false
    removeLoader(img)
  }

  const showError = (img: HTMLImageElement) => {
    const errorElement = (
      props.error ??
      (() => <Icon intent="warn" title={'Failed to load image'} />)
    )()
    img.nextElementSibling?.replaceChildren(errorElement as HTMLElement)
  }

  return (
    <div class={props.containerClass}>
      <img
        {...rest}
        hidden
        onLoad={flow(struct.lookup('currentTarget'), showImage)}
        onError={flow(struct.lookup('currentTarget'), showError)}
      />
      <div class={rest.class}>{(props.loader ?? (() => <Loader />))()}</div>
    </div>
  )
}
