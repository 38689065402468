import { solidjsI18n } from '@/solidjs/i18n'
import { Storage } from '@/std/browser'
import { R, date } from '@/std/data'
import { pipe } from '@/std/function'
import { I18nOptions } from '@/std/i18n/i18n'
import { State } from '@/std/reactivity'
import { Locale } from '@travellr/domain/core/i18n'
import { getClientContext } from '../context'
import { messages as globalMessages } from './messages'

export const I18n = <
  Messages extends Record<string, string> = Record<string, never>,
>(
  messages: I18nOptions<Locale, Messages, any>['messages'] = {
    en: {} as Messages,
    fr: {} as Messages,
  },
) => {
  const { locale } = getClientContext()
  return solidjsI18n({
    locale,
    messages: {
      en: { ...globalMessages.en, ...messages.en },
      fr: { ...globalMessages.fr, ...messages.fr },
    },
  })
}

export const initLocale = (options: {
  storage: Storage<Locale>
  defaultLocale: Locale
}): State<Locale> => {
  const storageKey = 'locale'
  const initial = pipe(
    options.storage.get(storageKey),
    R.unwrapOr(() => options.defaultLocale),
  )
  const locale = State(initial)
  date.setGlobalFormatLocale(FormatDateOptions(locale()))
  locale.onChange((next) => {
    options.storage.set(storageKey, next)
    date.setGlobalFormatLocale(FormatDateOptions(next))
  })
  return locale
}

const FormatDateOptions = (locale: Locale): date.FormatOptions => ({
  timeZone: 'UTC',
  locale,
})
