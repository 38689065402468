import { TripId } from '../../../entity/Trip'
import { GetTripShareTokenResource } from '../../../use-case/get-share-trip-token/client'

type Init = {
  tripId: TripId
}
export type ShareTripModel = ReturnType<typeof ShareTripModel>
export const ShareTripModel = ({ tripId }: Init) => {
  return {
    tokenForTripWithCotravellrs: GetTripShareTokenResource(tripId, true),
    tokenForTripWithoutCotravellrs: GetTripShareTokenResource(tripId, false),
  }
}
