import 'urlpattern-polyfill'
import { PathParameters } from '../api-contract/type-utils'
import { O, struct } from '../data'
import { constFalse, pipe } from '../function'
import { ReadonlyState, computed } from '../reactivity'
import type { History } from './history'

export type Router<Route> = {
  active: ReadonlyState<O.Option<Route>>
  destroy: () => void
  push: History['push']
  replace: History['replace']
  back: History['back']
}
export type RouterChangeListener<Route> = (nextRoute: Route) => void

export const Router =
  <Route>({
    history,
    isSameRoute = constFalse,
    basePath = '',
  }: {
    history: History
    basePath?: string
    isSameRoute?: (a: Route, b: Route) => boolean
  }) =>
  <Paths extends string>(routes: {
    [Key in Paths]: (options: {
      url: URL
      pathname: string
      params: PathParameters<Key>
    }) => Route
  }) => {
    const resolve = (url: URL): O.Option<Route> => {
      return pipe(
        routes,
        struct.findFirstMap((makeRoute, pathname) => {
          return pipe(
            new URLPattern({ pathname: `${basePath}${pathname}` }).exec(url),
            (result) => result?.pathname.groups,
            O.fromNullable<any>,
            O.map((params) => makeRoute({ url, pathname, params })),
          )
        }),
      )
    }

    const isSameOptionRoute = (a: O.Option<Route>, b: O.Option<Route>) =>
      (O.isNone(a) && O.isNone(b)) ||
      (O.isSome(a) && O.isSome(b) && isSameRoute(a.value, b.value))

    const router: Router<Route> = {
      active: computed([history.current], resolve, isSameOptionRoute),
      push: (path) => history.push(`${basePath}${path}`),
      replace: (path) => history.replace(`${basePath}${path}`),
      back: history.back,
      destroy: () => {
        history.destroy()
      },
    }

    return router
  }
