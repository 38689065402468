import { solidState } from '@/solidjs/state'
import { boolean } from '@/std/data'
import { pipe } from '@/std/function'
import { I18n } from '@travellr/client/i18n/I18n'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { Page } from '@travellr/client/ui-kit/Page.solid-js'
import { MainNav } from '../../../ui-kit/MainNav.solid-js'
import { CreateTripWithPictures } from '../../components/CreateTripWithPictures/main.solid'
import { StartTrip as Model } from './page'
import { messages } from './page.i18n'

type Props = {
  model: Model
}

export const StartTrip = (props: Props) => {
  const instructionsHaveBeenRead = solidState(
    props.model.instructions.haveBeenRead,
  )
  return (
    <div>
      {pipe(
        instructionsHaveBeenRead(),
        boolean.fold(
          () => (
            <TripInstructions onRead={props.model.instructions.markAsRead} />
          ),
          () => <StartTripForm model={props.model} />,
        ),
      )}
    </div>
  )
}

const StartTripForm = (props: Props) => {
  const formId = 'startTripForm'

  return (
    <Page
      nav={<CustomMainNav formId={formId} />}
      content={
        <div class="minh-100 flex column">
          <header>
            <h3 class="p-m text-center">Start my trip</h3>
            <hr />
          </header>

          <div class="flex-1 flex align-center justify-center w-100">
            <CreateTripWithPictures
              class="flex-1 p-m maxw-100"
              id={formId}
              model={props.model.form}
            />
          </div>

          <div class="pb-xl">&nbsp;</div>
        </div>
      }
    />
  )
}

type CustomMainNavProps = {
  formId: string
}
const CustomMainNav = (props: CustomMainNavProps) => (
  <MainNav.Slot>
    <MainNav.Home />
    <MainNav.Back />
    <button class="navItem flex-1" form={props.formId} type="submit">
      <Icon intent="next" />
    </button>
    <MainNav.Burger alignOptions="right" />
  </MainNav.Slot>
)

type InstructionsProps = {
  onRead: () => void
}
const Instructions = (props: InstructionsProps) => {
  const { t } = I18n(messages)
  return (
    <div class="vspacer-m">
      <h3>{t('instructions.heading')}</h3>
      <hr />
      <p class="preline">
        {t('instructions.body', { Bold: (text) => <b>{text}</b> })}
      </p>
      <hr />
      <div class="flex justify-end">
        <button class="button" onClick={props.onRead}>
          {t('instructions.gotIt')}
        </button>
      </div>
    </div>
  )
}
const TripInstructions = (props: InstructionsProps) => (
  <Page
    contentClass="flex align-center justify-center p-m"
    content={
      <div class="box p-m w-100">
        <Instructions onRead={props.onRead} />
      </div>
    }
  />
)
