import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { struct } from '@/std/data'
import { pipe } from '@/std/function'
import { MimeType } from '@/std/http'
import { Picture, PictureId, PictureStruct } from '../../entity/Picture'

export type Payload = C.TypeOf<typeof PayloadCodec>
const PayloadCodec = pipe(
  PictureStruct,
  struct.pick('caption', 'location', 'timestamp'),
  C.struct,
)

export const contract = ApiRouteContract('PATCH', '/picture/:id', {
  params: C.struct({ id: PictureId }),
  body: {
    contentType: MimeType.Json,
    codec: PayloadCodec,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: Picture,
  },
})
