import { O, struct } from '@/std/data'
import { cnst, flow, pipe } from '@/std/function'
import { ComponentProps, splitProps } from 'solid-js'
import { Except } from 'type-fest'
import { SolidFormControl } from './adapters'

export type TimeInputProps = Except<
  ComponentProps<'input'>,
  'type' | 'value'
> & {
  control: SolidFormControl<O.Option<Date>>
}

export const TimeInput = (props: TimeInputProps) => {
  const [, inputProps] = splitProps(props, ['control'])
  return (
    <input
      {...inputProps}
      type="time"
      value={pipe(props.control(), O.fold(cnst(''), timeAsValue))}
      onBlur={() => props.control.isTouched.set(true)}
      onChange={flow(
        struct.lookup('currentTarget'),
        struct.lookup('valueAsDate'),
        O.fromNullable,
        props.control.set,
      )}
    />
  )
}
const timeAsValue = (date: Date) => date.toISOString().slice(11, 16)
