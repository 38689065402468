import { Router } from '@/std/browser/router'
import { Email, O } from '@/std/data'
import { flow } from '@/std/function'
import { State } from '@/std/reactivity/state'
import { getClientContext } from '@travellr/client/context'
import { Account, AccountId } from '../entity/Account'
import { Profile } from '../entity/Profile'
import { Register } from '../use-case/register/client'
import { SendLinkToSignin } from '../use-case/send-link-to-signin/client'
import { SigninWithCode } from '../use-case/signin-with-code/client'

export { Route as AuthAppRoute }

type Route =
  | { route: 'SendLinkToSignin'; model: SendLinkToSignin }
  | { route: 'SigninWithCode'; model: SigninWithCode }
  | { route: 'Register'; model: Register }

type Deps = {
  account: State<O.Option<Account>>
  profile: State<O.Option<Profile>>
}
export const AuthRouter = ({ account, profile }: Deps) => {
  const { history } = getClientContext()
  return Router<Route>({ history })({
    '/': () => ({
      route: 'SendLinkToSignin',
      model: SendLinkToSignin({
        onSuccess: flow(pathTo.signinWithCode, history.push),
      }),
    }),
    '/authenticate/code/:email/:accountId{/:code}?': ({ params }) => ({
      route: 'SigninWithCode',
      model: SigninWithCode({
        email: params.email as Email,
        accountId: params.accountId as AccountId,
        account,
        profile,
        initialCode: params.code,
      }),
    }),
    '/authenticate/register': () => ({
      route: 'Register',
      model: Register({ account }),
    }),
  })
}

export const pathTo = {
  sendLinkToSignin: (email = '') => `/${email ? `?email=${email}` : ''}`,
  signinWithCode: (accountId: string, code: string) =>
    `/authenticate/code/${accountId}/${code}`,
  register: '/authenticate/register',
}
