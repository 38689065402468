import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { preventDefault } from '@/std/browser'
import { O, struct } from '@/std/data'
import { FormControl, nonEmpty } from '@/std/form-control'
import { cnst } from '@/std/function'
import { ReadonlyState, computed } from '@/std/reactivity'
import { RR, RemoteAction } from '@/std/remote'
import { ClientContext, getClientContext } from '@travellr/client/context'
import { pathTo } from '../../client/client-router'
import { Account } from '../../entity/Account'
import { contract } from './contract'

type Deps = {
  account: ReadonlyState<O.Option<Account>>
  context?: ClientContext
}

export type Register = ReturnType<typeof Register>
export const Register = ({
  account,
  context: { xhr } = getClientContext(),
}: Deps) => {
  const client = XhrApiRouteClient(contract, xhr)
  const register = (name: string) => client({ body: { name } })
  const action = RemoteAction(register)

  const name = FormControl('' as string, [nonEmpty('required')])

  const email = computed([account], O.fold(cnst('...'), struct.lookup('email')))
  const submit = preventDefault(() => action.trigger(name()))

  return {
    submitState: action.state,
    submit,
    reset: () => action.state.set(RR.NotAsked()),
    email,
    name,
    link: {
      sendLinkToSignin: pathTo.sendLinkToSignin(),
    },
  }
}
