import { createRemoteAction } from '@/solidjs/reactivity'
import { O } from '@/std/data'
import { debounce, flow } from '@/std/function'
import { RR, TR } from '@/std/remote'
import {
  Accessor,
  ComponentProps,
  JSX,
  createEffect,
  createSignal,
} from 'solid-js'
import { Except } from 'type-fest'
import { SolidFormControl } from './adapters'

export type SearchInputProps<E, T extends any[]> = {
  search: (search: string) => TR.TaskResult<E, T>
  control: SolidFormControl<O.Option<T[number]>>
  optionToValue: (option: T) => string
  render: (options: {
    Input: (
      props: Except<ComponentProps<'input'>, 'type' | 'value'>,
    ) => JSX.Element
    results: Accessor<RR.RemoteResult<E, T[]>>
    isInputActive: Accessor<boolean>
    refetch: () => void
  }) => JSX.Element
}

export const SearchInput = <E, T extends any[]>(
  props: SearchInputProps<E, T>,
) => {
  const [isInputActive, setInputActive] = createSignal(false)
  const [value, setValue] = createSignal('')
  const [results, fetchResults] = createRemoteAction((query: string) =>
    query.length >= 3 ? props.search(query) : TR.Ok<T, E>([] as unknown as T),
  )
  const getResults = debounce(500)(fetchResults)
  createEffect(() => getResults(value()))
  createEffect(flow(props.control, O.map(props.optionToValue), O.map(setValue)))

  return props.render({
    refetch: () => fetchResults(value()),
    results,
    isInputActive,
    Input: (p) => (
      <input
        {...p}
        type="text"
        value={value()}
        onFocus={() => {
          setInputActive(true)
        }}
        onBlur={() => {
          props.control.isTouched.set(true)
          setTimeout(() => setInputActive(false), 500)
        }}
        onInput={(event) => {
          setValue(event.currentTarget.value)
        }}
      />
    ),
  })
}
