import { preventDefault } from '@/std/browser'
import { O, R } from '@/std/data'
import { FormControl, FormGroup, required } from '@/std/form-control'
import { constVoid, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { ClientContext } from '@travellr/client/context'
import { Checkpoint } from '../../entity/Checkpoint'
import { Location } from '../../entity/Location'
import { TripId } from '../../entity/Trip'
import { AddCheckpointAction } from '../../use-case/add-checkpoint/client'

export type CheckpointFormValues = {
  date: Date
}

type Init = {
  initialValues?: Partial<CheckpointFormValues>
  location: Location
  tripId: TripId
  onSuccess?: (checkpoint: Checkpoint) => unknown
  context?: ClientContext
}

export type AddCheckpointFormModel = ReturnType<typeof AddCheckpointFormModel>
export const AddCheckpointFormModel = ({
  initialValues,
  location,
  tripId,
  onSuccess = constVoid,
}: Init) => {
  const action = AddCheckpointAction()

  const controls = FormGroup({
    date: FormControl(O.fromNullable(initialValues?.date), [
      required('Required'),
    ]),
  })

  const submit = preventDefault(async () => {
    controls.markTouched()
    if (!controls.isValid()) return
    const result = await action.trigger({
      location,
      tripId,
      timestamp: O.unwrap(controls.date()),
    })
    pipe(result, R.tap(onSuccess))
  })

  return {
    controls,
    state: action.state,
    location,
    submit,
    reset: () => action.state.set(RR.NotAsked()),
  }
}
