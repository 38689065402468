import { preventDefault } from '@/std/browser'
import { O, list } from '@/std/data'
import { FormControl, FormGroup, nonEmpty } from '@/std/form-control'
import { defer, pipe } from '@/std/function'
import { State } from '@/std/reactivity'
import { RR, T, UploadAction } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { PictureStores } from '@travellr/client/picture-store/PictureStores'
import { ImageStorage } from '@travellr/domain/shared/entity/ImageStorage'
import { TripId } from '../../../entity/Trip'
import { CreateTrip } from '../../../use-case/create-trip/client'
import { Picture } from './Gallery'
import { UploadState } from './UploadPicturesDialog.solid'

export type CreateTripWithPictures = ReturnType<typeof CreateTripWithPictures>

type Init = {
  onSubmitSuccess: (tripId: TripId) => void
  picturesRequired?: boolean
  imageDelayFactorMs?: number
  imageStorage?: ImageStorage
}

export const CreateTripWithPictures = ({
  onSubmitSuccess,
  picturesRequired,
  imageDelayFactorMs = 1000,
  ...init
}: Init) => {
  const { config } = getClientContext()
  const imageStorage = init.imageStorage ?? config.defaultImageStorage
  const pictureStores = PictureStores()
  const submitted = State(false)
  const uploadState = State(O.None<UploadState[]>())
  const action = CreateTrip({ imageStorage })
  const controls = FormGroup({
    name: FormControl<string, 'name.required' | 'tripNameTaken'>('', [
      nonEmpty('name.required'),
    ]),
    pictures: FormControl<Picture[]>(
      [],
      picturesRequired ? [nonEmpty('pictures.required')] : [],
    ),
  })

  const checkTripNameUnicity = (tripName: string) => {
    controls.name.error.set(
      tripName === 'Toto' ? O.Some('tripNameTaken') : controls.name.error(),
    )
  }

  const effects = [
    controls.name.onChange(defer(checkTripNameUnicity)),
    action.state.onChange(
      RR.map(({ trip }) => {
        uploadImages(trip.id)
      }),
    ),
    uploadState.onChange(
      O.map((state) => {
        const all = RR.list(state.map(({ state }) => state()))
        if (RR.isOk(all)) onSubmitSuccess(all.value.value[0].tripId)
      }),
    ),
  ]

  const submit = preventDefault(() => {
    submitted.set(true)
    controls.markTouched()
    if (!controls.isValid()) return
    action.trigger(controls.name())
  })

  const uploadImages = (tripId: TripId) => {
    const pictures = controls.pictures()
    if (list.isEmpty(pictures)) return onSubmitSuccess(tripId)

    const picturesUploadState = pictures.map((picture, index): UploadState => {
      const { state, trigger } = UploadAction(
        (onProgress) => () =>
          pipe(
            pictureStores[imageStorage].upload(
              { caption: picture.caption, tripId },
              picture.file,
              { onProgress },
            ),
            T.delay(imageDelayFactorMs * index),
          ),
      )
      trigger().then(() => triggerSubmitSuccessIfAllImagesAreUploaded(tripId))
      return { picture, state, retry: trigger }
    })
    uploadState.set(O.Some(picturesUploadState))
  }

  const model = {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },
    controls,
    picturesRequired,
    addPictures: (pics: Picture[]) =>
      controls.pictures.update((current) => [...current, ...pics]),
    removePicture: (pic: Picture) =>
      controls.pictures.update(list.filter((p) => p !== pic)),
    setPictureCaption: (caption: string, pic: Picture) => {
      pic.caption = caption ? O.Some(caption) : O.None()
      controls.pictures.set([...controls.pictures()])
    },
    submitState: action.state,
    submit,
    resetSubmitState: () => action.state.set(RR.NotAsked()),
    uploadState,
    resetUploadState: () => uploadState.set(O.None()),
  }

  return model

  function triggerSubmitSuccessIfAllImagesAreUploaded(tripId: TripId) {
    pipe(
      model.uploadState(),
      O.map(list.every((item) => RR.isOk(item.state()))),
      O.flatMap(O.fromPredicate(Boolean)),
      O.tap(() => onSubmitSuccess(tripId)),
    )
  }
}
