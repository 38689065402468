import { C } from '@/std/codec'
import { BoomOf, makeBoom } from '@/std/error'
import { pipe } from '@/std/function'
import { TR } from '@/std/remote'
import { Opaque } from 'type-fest'

export type ForgeTokenOptions = {
  expiresInS?: number
}

export type JsonWebToken = Opaque<string, 'JsonWebToken'>
export const JsonWebTokenCodec = pipe(
  C.string,
  C.map((s) => s as JsonWebToken, String),
)

export const JsonWebTokenError = makeBoom('JsonWebTokenError', String)
export type JsonWebTokenError = BoomOf<typeof JsonWebTokenError>
export const JsonWebTokenExpired = makeBoom('JsonWebTokenExpired', String)
export type JsonWebTokenExpired = BoomOf<typeof JsonWebTokenExpired>

export type Signable = string | object
export type JWTFactory<T extends Signable> = {
  forgeToken: (
    data: T,
    options?: ForgeTokenOptions,
  ) => TR.TaskResult<JsonWebTokenError, JsonWebToken>
  verifyToken: (
    token: string,
  ) => TR.TaskResult<JsonWebTokenError | JsonWebTokenExpired | C.DecodeError, T>
}

export { makeJWTFactory } from './webapi'
