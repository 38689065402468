import { cx } from '@/std/classNames'
import { O } from '@/std/data'
import { cnst, pipe } from '@/std/function'
import { Image } from '@travellr/client/ui-kit/Image.solid-js'
import { ComponentProps, JSX } from 'solid-js'
import { Except } from 'type-fest'
import { Picture } from './Gallery'

export type ThumbnailProps = {
  picture: Picture
  id?: string
  class?: string
  style?: string
  imgClass?: string
  height?: string
  onImageClick?: () => void
  onCaptionChange: (caption: string, picture: Picture) => any
  onRemove?: (picture: Picture) => any
}
export const Thumbnail = (props: ThumbnailProps) => {
  const url = URL.createObjectURL(props.picture.file)
  return (
    <div
      class={cx('box relative', props.class)}
      id={props.id}
      style={props.style}
    >
      {props.onRemove && (
        <div class="absolute top right p-s">
          <button
            class="button discrete close shadowed"
            onClick={() => props.onRemove?.(props.picture)}
          />
        </div>
      )}
      <Image
        onClick={props.onImageClick}
        src={url}
        class={cx(props.imgClass, props.onImageClick && 'pointer')}
        height={props.height}
      />
      <div class="vspacer-s p-m">
        <textarea
          class="input"
          rows={1}
          value={pipe(props.picture.caption, O.unwrapOr(cnst('')))}
          onInput={(event) =>
            props.onCaptionChange(event.currentTarget.value, props.picture)
          }
          placeholder="Add a caption"
        />
      </div>
    </div>
  )
}

export type FilePickerProps = Except<ComponentProps<'input'>, 'type'> & {
  children: JSX.Element
}
export const FilePicker = (props: FilePickerProps) => {
  let inputRef: HTMLInputElement | undefined
  // const open = () => {
  //   if (!inputRef) return
  //   console.info('open file picker', inputRef)
  //   const oldListener = document.body.onfocus
  //   const onResult = () => {
  //     if (inputRef?.files) props.onClose(inputRef.files)
  //     document.body.onfocus = oldListener
  //   }
  //   inputRef.onchange = onResult
  //   document.body.onfocus = onResult
  //   inputRef?.click()
  // }
  return (
    <span onClick={() => inputRef?.click()}>
      <input {...props} ref={inputRef} type="file" hidden />
      {props.children}
    </span>
  )
}

// const showFilePicker = (): T.Task<FileList> => {
//   return T.new((resolve) => {
//     const input = (<input type="file" multiple accept="image/*" />) as HTMLInputElement
//     console.info('open file picker', input)
//     const oldListener = document.body.onfocus
//     function onResult() {
//       console.info('result!')
//       if (input.files) resolve(input.files)
//       document.body.onfocus = oldListener
//     }
//     input.onchange = onResult
//     document.body.onfocus = onResult
//     input.click()
//   })
// }
