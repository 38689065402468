import { State, computed } from '../reactivity'
import { makeTranslate } from './utils'

export type I18nOptions<
  Locale extends string,
  Messages extends Record<string, string>,
  Content,
> = {
  locale: State<Locale>
  messages: Record<Locale, Messages>
  revive: (parts: Array<string | Content>) => Content
  onValueMissing?: (fullKey: string) => void
}

export type I18n = ReturnType<typeof makeI18n>

export const makeI18n = <
  Locale extends string,
  Messages extends Record<string, string>,
  Content,
>({
  locale,
  messages,
  revive,
  onValueMissing,
}: I18nOptions<Locale, Messages, Content>) => {
  const makeT = (messages: Messages) =>
    makeTranslate({
      messages,
      revive,
      onValueMissing,
    })

  const state = {
    locale,
    t: computed([locale], (locale) => makeT(messages[locale])),
  }

  return state
}
