import { O, Ord, date, list, record } from '@/std/data'
import { pipe } from '@/std/function'
import { AccountId } from '@travellr/domain/core/authentication/entity/Account'
import { Except } from 'type-fest'
import { Checkpoint, CheckpointId } from '../../travellr/entity/Checkpoint'
import { FullTrip } from '../../travellr/entity/FullTrip'
import { Location } from '../../travellr/entity/Location'
import { Note, NoteId } from '../../travellr/entity/Note'
import { Picture, PictureId } from '../../travellr/entity/Picture'
import { TripId } from '../../travellr/entity/Trip'

/**
 * A trip crumb is any piece part of the trip that
 * can be rendered on the map and/or the carousel.
 * That includes pictures, checkpoints and notes (as of 15/02/2024)
 */

interface BaseTripCrumb {
  id: string
  location: O.Option<Location>
  marker: O.Option<L.Marker>
  belongsToSelf: boolean
}
export interface PictureCrumb extends BaseTripCrumb {
  type: 'picture'
  id: PictureId
  caption: Picture['caption']
  accountId: AccountId
  tripId: TripId
  storage: Picture['storage']
  timestamp: Date
}
export interface CheckpointCrumb extends BaseTripCrumb {
  type: 'checkpoint'
  id: CheckpointId
  tripId: TripId
  timestamp: Date
}

export interface NoteCrumb extends BaseTripCrumb {
  type: 'note'
  id: NoteId
  tripId: TripId
  from: Note['from']
  until: Note['until']
  content: Note['content']
}

export type TripCrumb = NoteCrumb | PictureCrumb | CheckpointCrumb

export type MakeTripCrumbMarker = (crumb: TripCrumb) => O.Option<L.Marker>

const tripCrumbOrd = Ord.fromCompare<TripCrumb>((a, b) => {
  return date.ord.compare(tripCrumbToDate(a), tripCrumbToDate(b))
})

export const tripCrumbToDate = (crumb: TripCrumb): Date => {
  switch (crumb.type) {
    case 'checkpoint':
    case 'picture':
      return crumb.timestamp
    case 'note':
      return crumb.from
  }
}

export const fullTripToCrumbs =
  (options: { makeMarker: MakeTripCrumbMarker }) =>
  (fullTrip: FullTrip): TripCrumb[] => {
    return pipe(
      fullTrip.cotravellrs,
      record.values,
      list.map(tripToCrumbs({ belongsToSelf: false })),
      list.flatten,
      list.concat(tripToCrumbs({ belongsToSelf: true })(fullTrip)),
      list.sort(tripCrumbOrd),
      list.map((crumb) => ({ ...crumb, marker: options.makeMarker(crumb) })),
    )
  }

const tripToCrumbs =
  ({ belongsToSelf }: { belongsToSelf: boolean }) =>
  (fullTrip: Except<FullTrip, 'cotravellrs'>): TripCrumb[] => {
    const { checkpoints, notes, pictures, trip } = fullTrip
    const { accountId } = trip

    const noteCrumbs = notes.map(noteToCrumb({ belongsToSelf }))
    const checkpointCrumbs = checkpoints.map(
      checkpointToCrumb({ belongsToSelf }),
    )
    const pictureCrumbs = pictures.map(
      pictureToMaybeCrumb({ accountId, belongsToSelf }),
    )
    return [...noteCrumbs, ...checkpointCrumbs, ...list.compact(pictureCrumbs)]
  }

const noteToCrumb =
  (options: { belongsToSelf: boolean }) =>
  (note: Note): NoteCrumb => ({
    type: 'note',
    belongsToSelf: options.belongsToSelf,
    content: note.content,
    from: note.from,
    id: note.id,
    tripId: note.tripId,
    location: note.location,
    marker: O.None(),
    until: note.until,
  })

const checkpointToCrumb =
  (options: { belongsToSelf: boolean }) =>
  (checkpoint: Checkpoint): CheckpointCrumb => ({
    type: 'checkpoint',
    belongsToSelf: options.belongsToSelf,
    id: checkpoint.id,
    tripId: checkpoint.tripId,
    timestamp: checkpoint.timestamp,
    location: O.Some(checkpoint.location),
    marker: O.None(),
  })

const pictureToMaybeCrumb =
  (options: { accountId: AccountId; belongsToSelf: boolean }) =>
  (picture: Picture): O.Option<PictureCrumb> =>
    pipe(
      picture.timestamp,
      O.map(
        (timestamp): PictureCrumb => ({
          type: 'picture',
          id: picture.id,
          accountId: options.accountId,
          caption: picture.caption,
          location: picture.location,
          marker: O.None(),
          tripId: picture.tripId,
          storage: picture.storage,
          belongsToSelf: options.belongsToSelf,
          timestamp,
        }),
      ),
    )
