// @ts-check
import {
  list as L,
  O,
  record as R,
  struct as S,
  TupleOf,
  R as result,
} from '../data'
import { makeBoom } from '../error'
import {
  AnyConstructor,
  constUndefined,
  constVoid,
  flow,
  identity,
  isInstanceOf,
  lazy as memo,
  pipe,
} from '../function'

/**
 * @module CodecModule
 * @deprecated use types instead -> `@std/types`
 */

export type Codec<T, O, I = unknown> = {
  tag: string
  decode: (value: I) => result.Result<DecodeError, T>
  encode: (value: T) => O
}

export type TypeOf<D> = D extends Codec<infer P, any, any> ? P : never
export type InputOf<D> = D extends Codec<any, any, infer I> ? I : never
export type OutputOf<D> = D extends Codec<any, infer O, any> ? O : never

export const DecodeError = makeBoom(
  'DecodeError',
  (detail: {
    tag: string
    actual: unknown
    path?: PropertyKey[]
    cause?: unknown
  }) => {
    return [
      `${detail.tag} ${detail.path?.join(' > ') ?? ''}`,
      JSON.stringify(getDecodeErrorRealActual(detail), null, 2),
    ].join('\n')
  },
)

const getDecodeErrorRealActual = (e: {
  path?: PropertyKey[]
  actual: unknown
}) => {
  try {
    return e.path?.reduce((acc, key) => acc[key], e.actual as any)
  } catch {
    return e.actual
  }
}
export type DecodeError = ReturnType<typeof DecodeError>

export const makeDecodeError = (
  tag: string,
  actual: unknown,
  at?: PropertyKey,
  cause?: unknown,
) =>
  DecodeError({
    tag,
    actual,
    ...(at === undefined ? {} : { path: [at] }),
    ...(cause ? { cause } : {}),
  })

const DecodeErrorAt =
  (actual: unknown, ...path: PropertyKey[]) =>
  (e: DecodeError) =>
    DecodeError({
      ...e.detail,
      actual,
      path: [...path, ...(e.detail.path ?? [])],
    })

const isString = (value: unknown): value is string => typeof value === 'string'
const isNumber = (value: unknown): value is number => typeof value === 'number'
const isBoolean = (value: unknown): value is boolean =>
  typeof value === 'boolean'
const isUndefined = (value: unknown): value is undefined => value === undefined
const isNull = (value: unknown): value is null => value === null
const isNil = (value: unknown): value is undefined | null =>
  isNull(value) || isUndefined(value)

export type Primitive = string | number | boolean
const isOneOf =
  <T extends TupleOf<L>, L extends Primitive>(expected: T) =>
  (value: unknown): value is T[number] =>
    expected.some((expected) => value === expected)

type Refinement<T extends From, From = unknown> = (value: From) => value is T
type UnknownRecord = Record<PropertyKey, unknown>
type AnyObj = Record<string, any>

const fromRefinement = <T>(
  tag: string,
  refiner: Refinement<T>,
): Codec<T, T, unknown> => ({
  tag,
  encode: (value) => {
    if (refiner(value)) return value
    throw new Error(`value is not ${tag}, cowardly refusing to encode`)
  },
  decode: (input) =>
    refiner(input) ? result.Ok(input) : result.Err(makeDecodeError(tag, input)),
})

export const string = fromRefinement('string', isString)
export const number = fromRefinement('number', isNumber)
export const bool = fromRefinement('boolean', isBoolean)
// const undefinedC = fromRefinement('undefined', isUndefined)
// const nullC = fromRefinement('null', isNull)
const DateC = fromRefinement('Date', isInstanceOf(Date))
export { DateC as Date }

type Literal = <Literals extends TupleOf<Primitive>>(
  ...expected: Literals
) => Codec<Literals[number], Literals[number], unknown>
export const literal: Literal = (...expected) =>
  fromRefinement(
    `literal(${expected.map((v) => JSON.stringify(v)).join(' | ')})`,
    isOneOf(expected),
  )

export const unknown: Codec<unknown, unknown> = {
  tag: 'unknown',
  decode: result.Ok,
  encode: identity,
}

const Void: Codec<void, undefined> = {
  tag: 'void',
  decode: () => result.Ok(constVoid()),
  encode: constUndefined,
}
export { Void as void }

type Refine = <A, B extends A>(
  id: string,
  refinement: Refinement<B, A>,
) => <I, O>(from: Codec<A, O, I>) => Codec<B, O, I>
export const refine: Refine = (id, refinement) => (from) => ({
  tag: id,
  decode: flow(
    from.decode,
    result.flatMap(
      result.refine(refinement, (value) => makeDecodeError(id, value)),
    ),
  ),
  encode: from.encode,
})

export const compose =
  <B, OB extends A, IB, A extends IB = IB>(to: Codec<B, OB, IB>) =>
  <OA, IA>(from: Codec<A, OA, IA>): Codec<B, OB, IA> => ({
    tag: to.tag,
    decode: (ia) => pipe(from.decode(ia), result.flatMap(to.decode)),
    encode: to.encode,
  })

type Map = <A, B, O, I>(
  f: (a: A) => B,
  g: (b: B) => A,
) => (fa: Codec<A, O, I>) => Codec<B, O, I>
export const map: Map = (f, g) => (fa) => ({
  tag: fa.tag,
  decode: flow(fa.decode, result.map(f)),
  encode: flow(g, fa.encode),
})

export const lazy = <T, O, I>(codec: () => Codec<T, O, I>): Codec<T, O, I> => {
  const get = memo(codec)
  return {
    get tag() {
      return get().tag
    },
    decode: (input) => get().decode(input),
    encode: (value) => get().encode(value),
  }
}

type InstanceOf = <T extends AnyConstructor>(
  Class: T,
) => Codec<InstanceType<T>, InstanceType<T>, unknown>
export const instanceOf: InstanceOf = (Class) =>
  fromRefinement(Class.name, isInstanceOf(Class))

export const unknownArray = instanceOf(Array)

type Array = <T, O>(codec: Codec<T, O, unknown>) => Codec<T[], O[], unknown>
export const array: Array = (codec) => {
  const tag = `Array<${codec.tag}>`
  return pipe(
    unknownArray,
    compose({
      tag,
      decode: (input) =>
        pipe(
          // @ts-ignore
          input,
          L.map((value, index) =>
            pipe(
              codec.decode(value),
              result.mapErr(DecodeErrorAt(input, index)),
            ),
          ),
          result.list,
        ),
      encode: L.map(codec.encode),
    }),
  )
}

type Tuple = <T extends TupleOf<Codec<any, any, any>>>(
  tuple: T,
) => Codec<
  { [Key in keyof T]: TypeOf<T[Key]> },
  { [Key in keyof T]: OutputOf<T[Key]> },
  unknown
>
export const tuple: Tuple = (tuple) =>
  // @ts-ignore
  pipe(
    unknownArray,
    compose({
      tag: `Tuple(${tuple.map((d) => d.tag).join(', ')})`,
      decode: (input) =>
        pipe(
          tuple,
          L.map((decoder, index) =>
            pipe(
              // @ts-ignore
              decoder.decode(input[index]),
              result.mapErr(DecodeErrorAt(input, index)),
            ),
          ),
          result.list,
        ),
      encode: L.map((value, index) => tuple[index].encode(value)),
    }),
  )

export const unknownRecord = fromRefinement(
  'UnknownRecord',
  (value: unknown): value is UnknownRecord =>
    value?.constructor === Object.prototype.constructor,
)

type RecordFn = <Key extends PropertyKey, T, O>(
  key: Codec<Key, PropertyKey, unknown>,
  value: Codec<T, O, unknown>,
) => Codec<Record<Key, T>, Record<Key, O>, unknown>
export const record: RecordFn = (key, value) =>
  pipe(
    unknownRecord,
    compose({
      tag: `Record<${key.tag}, ${value.tag}>`,
      decode: (input) => {
        const acc: any = {}
        // @ts-ignore
        for (const [k, v] of Object.entries(input)) {
          const kd = key.decode(k)
          if (result.isErr(kd)) return kd
          const vd = value.decode(v)
          if (result.isErr(vd)) return vd
          acc[kd.value] = vd.value
        }
        return result.Ok(acc)
      },
      encode: R.map(value.encode),
    }),
  )

type Struct = <S extends Record<string, Codec<any, any, unknown>>>(
  st: S,
) => Codec<
  { [Key in keyof S]: TypeOf<S[Key]> },
  { [Key in keyof S]: OutputOf<S[Key]> },
  unknown
>
export const struct: Struct = (st) =>
  // @ts-ignore
  pipe(
    unknownRecord,
    compose({
      tag: `Struct {\n${Object.keys(st)
        .map((key) => `  ${key}: ${st[key].tag}`)
        .join('\n')}\n}`,
      decode: (input) => {
        const acc: any = {}
        for (const key of S.keys(st)) {
          // @ts-ignore
          const decoded = st[key].decode(input[key])
          if (result.isErr(decoded))
            return result.Err(DecodeErrorAt(input, key)(decoded.error))
          acc[key] = decoded.value
        }
        return result.Ok(acc)
      },
      encode: (record) =>
        pipe(
          st,
          S.map((codec, key) => codec.encode(record[key])),
        ),
    }),
  )

type Union = <T extends TupleOf<Codec<any, any, any>>>(
  ...codecs: T
) => Codec<
  { [Key in keyof T]: TypeOf<T[Key]> }[number],
  { [Key in keyof T]: OutputOf<T[Key]> }[number],
  { [Key in keyof T]: InputOf<T[Key]> }[number]
>
export const union: Union = (...codecs) => {
  const tag = '| ' + codecs.map((d) => d.tag).join('\n| ')
  return {
    tag,
    decode: (input) => {
      for (const decoder of codecs) {
        const decoded = decoder.decode(input)
        if (result.isOk(decoded)) return decoded
      }
      return result.Err(makeDecodeError(tag, input))
    },
    encode: (value) =>
      pipe(
        codecs,
        L.findFirstMap((codec) =>
          pipe(
            result.try(() => codec.encode(value)),
            O.fromResult,
            O.flatMap(O.fromPredicate(flow(codec.decode, result.isOk))),
          ),
        ),
        O.unwrap,
      ),
  }
}

type Intersectable = Record<any, any>
type Intersect = <T2 extends Intersectable, O2 extends Intersectable, I2>(
  b: Codec<T2, O2, I2>,
) => <T1 extends Intersectable, O1 extends Intersectable, I1>(
  a: Codec<T1, O1, I1>,
) => Codec<T1 & T2, O1 & O2, I1 & I2>
export const intersect: Intersect = (b) => (a) => ({
  tag: `${a.tag} & ${b.tag}`,
  decode: (input) =>
    pipe(
      result.tuple([a.decode(input), b.decode(input)]),
      result.map(([a, b]) => ({ ...a, ...b })),
    ),
  encode: (value) => ({ ...a.encode(value), ...b.encode(value) }),
})

type MakeOption = <Fallback>(
  fallback: <A>(o: O.Option<A>) => A | Fallback,
) => <T, O, I>(c: Codec<T, O, I>) => Codec<O.Option<T>, Fallback | O, I>
const makeOption: MakeOption = (fallback) => (c) => ({
  tag: `Option<${c.tag}>`,
  decode: (input) =>
    isNil(input)
      ? result.Ok(O.None())
      : pipe(c.decode(input), result.map(O.Some)),
  encode: flow(O.map(c.encode), fallback),
})

export const Null = makeOption<null>(O.toNull)
export const Option = makeOption<undefined>(O.toUndefined)

type Renamed<T, K extends keyof T, N extends string> = Pick<
  T,
  Exclude<keyof T, K>
> & {
  [P in N]: T[K]
}
type Rename = <T, Property extends keyof T, NewProperty extends string, O, I>(
  property: Property,
  renamed: NewProperty,
) => (fa: Codec<T, O, I>) => Codec<Renamed<T, Property, NewProperty>, O, I>
export const rename: Rename = (property, renamed) =>
  map(
    ({ [property]: value, ...rest }) => ({ [renamed]: value, ...rest }),
    // @ts-ignore
    ({ [renamed]: value, ...rest }) => ({ [property]: value, ...rest }),
  )

type Lookup = <A extends AnyObj, K extends keyof A, O, I>(
  key: K,
) => (fa: Codec<Pick<A, K>, O, I>) => Codec<A[K], O, I>
export const lookup: Lookup = (key) =>
  map(
    (a) => a[key],
    // @ts-ignore
    (value) => ({ [key]: value }),
  )

type Is = <T>(
  codec: Codec<T, any, unknown>,
  debug?: boolean,
) => (value: unknown) => value is T
export const is: Is =
  (codec) =>
  // @ts-ignore
  (value) =>
    pipe(codec.encode(value as any), codec.decode, result.isOk)

type CanDecode = <T>(
  codec: Codec<T, any, unknown>,
) => (value: unknown) => boolean
export const canDecode: CanDecode = (codec) => flow(codec.decode, result.isOk)

type EnumLike = { [k: string]: string | number; [nu: number]: string }
type Enum = <T extends EnumLike>(
  actualEnum: T,
) => Codec<T[keyof T], string | number, unknown>
export const Enum: Enum = (actualEnum) => {
  const values = Object.values(actualEnum)
  return pipe(
    union(string, number),
    // @ts-ignore
    refine('Enum', (value) => values.includes(value)),
  )
}
