export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'confirmDeletion': 'Delete trip “{name}”?',
} as const

const fr = {
  'confirmDeletion': 'Supprimer le voyage “{name}”?',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr };
