export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'profile': 'Profile',
  'storage': 'Storage',
  'loadFailed': 'Load failed',
} as const

const fr = {
  'profile': 'Profil',
  'storage': 'Espace libre',
  'loadFailed': 'Échec du chargement'
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
