import { RouterProvider } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { match, pipe } from '@/std/function'
import { ErrorBoundary } from '@travellr/client/ErrorBoundary.solid'
import { getClientContext } from '@travellr/client/context'
import { AuthApp } from '@travellr/domain/core/authentication/client/client.solid-js'
import { GuestApp } from '@travellr/domain/core/guest/client/main.solid-js'
import { SandboxApp } from '@travellr/domain/core/sandbox/client.solid-js'
import { TravellrApp } from '@travellr/domain/core/travellr/client/main.solid-js'
import { App as Model } from './app'
import { VersionWatcher } from './version-watcher.solid'

type Props = {
  model: Model
}

export const App = (props: Props) => {
  const { history } = getClientContext()
  const subApp = solidState(props.model.subApp)
  return (
    <ErrorBoundary model={props.model.errorBoundary}>
      <VersionWatcher watcher={props.model.versionWatcher} />
      <RouterProvider value={history}>
        {pipe(
          subApp(),
          match('app')({
            auth: ({ model }) => <AuthApp model={model} />,
            guest: ({ model }) => <GuestApp model={model} />,
            sandbox: ({ model }) => <SandboxApp model={model} />,
            travellr: ({ model }) => <TravellrApp model={model()} />,
          }),
        )}
      </RouterProvider>
    </ErrorBoundary>
  )
}
