import { struct } from '@/std/data'
import { panic } from '@/std/function'
import { ComponentProps, JSX, mapArray } from 'solid-js'

export type Tab =
  | (() => JSX.Element)
  | { heading: () => JSX.Element; panel: () => JSX.Element }

export type TabsProps<
  TabName extends string,
  Active extends TabName = TabName,
> = {
  active: Active
  onChange: (next: TabName) => void
  tabs: { [Key in TabName]: Tab }
  panelClass?: string
  tablistClass?: string
  render?: (options: {
    TabList: (props: ComponentProps<'nav'>) => JSX.Element
    Separator: (props: ComponentProps<'hr'>) => JSX.Element
    TabPanel: (props: ComponentProps<'div'>) => JSX.Element
  }) => JSX.Element
}

export const Tabs = <Tab extends string, Init extends Tab>(
  props: TabsProps<Tab, Init>,
) => {
  const pane = () => {
    const tab = props.tabs[props.active]
    if (!tab) return panic(new Error(`no pane associated to ${props.active}`))
    if (typeof tab === 'function') return tab()
    return tab.panel()
  }

  return (props.render ?? defaultRender)({
    TabList: (p) => (
      <nav class={props.tablistClass} {...p} role="tablist">
        {mapArray(
          () => struct.entries(props.tabs),
          ([tabName, tab]) => (
            <div
              role="tab"
              aria-selected={props.active === tabName}
              onClick={() => props.onChange(tabName)}
            >
              {typeof tab === 'function' ? <b>{tabName}</b> : tab.heading()}
            </div>
          ),
        )()}
      </nav>
    ),
    TabPanel: (p) => (
      <div class={props.panelClass} {...p} role="tabpanel">
        {pane()}
      </div>
    ),
    Separator: (p) => <hr {...p} class="tab-separator" />,
  })
}
const defaultRender: Required<TabsProps<string>>['render'] = ({
  TabList,
  TabPanel,
  Separator,
}) => (
  <>
    <TabList />
    <Separator />
    <TabPanel />
  </>
)
