import { preventDefault } from '@/std/browser'
import { FormControl, FormGroup, nonEmpty } from '@/std/form-control'
import { RR, RemoteAction, TR } from '@/std/remote'

type FormValues = {
  content: string
}

type Init = {
  initialValues?: FormValues
  submit: (values: FormValues) => TR.TaskResult<unknown, unknown>
}

export type NoteFormModel = ReturnType<typeof NoteFormModel>
export const NoteFormModel = ({ initialValues, submit }: Init) => {
  const action = RemoteAction(submit)
  const controls = FormGroup({
    content: FormControl(initialValues?.content ?? '', [nonEmpty('Required')]),
  })

  return {
    controls,
    submitState: action.state,
    reset: () => action.state.set(RR.NotAsked()),
    submit: preventDefault(async () => {
      controls.markTouched()
      if (!controls.isValid()) return
      await action.trigger({ content: controls.content() })
    }),
  }
}
