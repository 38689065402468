import { O } from '@/std/data'
import { T } from '@/std/type'
import { TripId } from './Trip'

export type Cotravellr = {
  selfTripId: TripId
  peerTripId: TripId
  from: O.Option<Date>
  until: O.Option<Date>
}

export const CotravellrStruct = {
  selfTripId: TripId,
  peerTripId: TripId,
  from: T.option(T.DateFromString),
  until: T.option(T.DateFromString),
}
const type = T.struct(CotravellrStruct)
type Ord = { ord: typeof type.ord }

export const Cotravellr: T.Type<Cotravellr> & Ord = type
