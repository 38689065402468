import { solidState } from '@/solidjs/state'
import { JSX, onMount } from 'solid-js'
import { AuthApp as Model } from './client'
import { AuthRouter } from './client-router.solid-js'

type Props = {
  loading?: () => JSX.Element
  fallback?: (error: Error) => JSX.Element
  model: Model
}

export const AuthApp = (props: Props) => {
  onMount(props.model.init)
  const route = solidState(props.model.router.active)
  return <AuthRouter route={route()} />
}
