import { BoomOf, makeBoom } from '@/std/error'
import { TR } from '@/std/remote'

export const ImageResizeError = makeBoom('ImageResizeError', String)
export type ImageResizeError = BoomOf<typeof ImageResizeError>

export interface ImageToolbox<Input, Output = Input> {
  resize: (
    file: Input,
    height: number,
  ) => TR.TaskResult<ImageResizeError, Output>
}
