import { InfraError } from '@/std/error'
import { C } from '../codec'
import { R, list, record, struct } from '../data'
import { pipe } from '../function'
import { HttpError } from '../http/Error'
import type { HttpMethod } from '../http/Method'
import { MimeType } from '../http/MimeType'
import {
  makeXhrClient,
  type OnProgress,
  type XhrClient,
} from '../http/XmlHttpRequest'
import {
  FetchClient,
  fetchClient,
  parseJsonResponse,
  parseTextResponse,
} from '../http/fetch'
import { TR } from '../remote'
import type { HasProp, If, IsEmpty } from '../types'
import { makeBodyInit } from './adapters'
import type { ApiContract, ApiRouteShape } from './contract'
import { PathParameters } from './type-utils'

export const justBody = TR.map(struct.lookup('body')) as <E, T>(
  taskResult: TR.TaskResult<E, { body?: T }>,
) => TR.TaskResult<E, T>

/** @deprecated */
export type ApiClient<Contract extends ApiContract, ClientError> = <
  Path extends Extract<keyof Contract, string>,
  Method extends Extract<keyof Contract[Path], HttpMethod>,
  Route extends ApiRouteShape = Extract<Contract[Path][Method], ApiRouteShape>,
>(
  path: Path,
  method: Method,
  options: {
    onProgress?: OnProgress
  } & Pick<
    RequestInit,
    'cache' | 'signal' | 'credentials' | 'keepalive' | 'integrity' | 'mode'
  > &
    If<
      HasProp<Route, 'headers'>,
      { headers: C.TypeOf<Route['headers']> },
      { headers?: undefined }
    > &
    If<
      HasProp<Route, 'body'>,
      { body: C.TypeOf<NonNullable<Route['body']>['codec']> },
      { body?: undefined }
    > &
    If<
      HasProp<Route, 'params'>,
      { params: C.TypeOf<Route['params']> },
      If<
        IsEmpty<PathParameters<Path>>,
        { params?: undefined },
        { params: PathParameters<Path> }
      >
    > &
    If<
      HasProp<Route, 'searchParams'>,
      { searchParams: C.TypeOf<Route['searchParams']> },
      { searchParams?: undefined }
    >,
) => TR.TaskResult<
  ClientError | HttpError,
  { status: number } & If<
    HasProp<Route['response'], 'headers'>,
    { headers: C.TypeOf<Route['response']['headers']> },
    { headers?: undefined }
  > &
    If<
      HasProp<Route['response'], 'codec'>,
      { body: C.TypeOf<Route['response']['codec']> },
      { body?: undefined }
    >
>

/** @deprecated */
export type MakeApiClient<Client, Err> = <Contract extends ApiContract>(
  contract: Contract,
  client?: Client,
) => ApiClient<Contract, Err>

const injectPathParams = (path: string, params: Record<string, unknown>) =>
  pipe(
    record.entries(params),
    list.reduce(path, (acc, [param, value]) =>
      acc.replaceAll(`:${param}`, String(value)),
    ),
  )
const makePathWithSearch = (options: {
  route: ApiRouteShape
  path: string
  params: any
  searchParams?: any
}) => {
  const params =
    options.route.params?.encode(options.params) ?? options.params ?? {}
  const pathWithParams = injectPathParams(options.path, params)
  const searchParams =
    options.route.searchParams?.encode(options.searchParams) ??
    options.searchParams ??
    {}
  const search = pipe(
    searchParams,
    record.entries,
    list.reduce('', (acc, [key, value], index) => {
      return `${acc}${index === 0 ? '?' : '&'}${String(key)}=${value}`
    }),
  )
  return `${pathWithParams}${search}`
}

/** @deprecated use XhrApiRouteClient */
export const makeXhrApiClient: MakeApiClient<XhrClient, C.DecodeError> =
  (contract, xhr = makeXhrClient()) =>
  (path, method, options) => {
    const route = contract[path][method]
    if (!route) throw new Error(`api route not found: ${method} ${path}`)
    const url = makePathWithSearch({
      path,
      route,
      params: options.params,
      searchParams: options.searchParams,
    })
    const task = xhr(url, {
      credentials: options.credentials,
      method,
      mode: options.mode,
      onProgress: options.onProgress,
      body: makeBodyInit(route.body, options.body) as XMLHttpRequestBodyInit,
      headers: {
        ...route.headers?.encode(options.headers),
        ...(route.body && { 'Content-Type': route.body.contentType }),
        ...(route.response.contentType && {
          Accept: route.response.contentType,
        }),
      },
    })
    return pipe(
      task,
      TR.map((res) => {
        return route.response.contentType === MimeType.Json
          ? JSON.parse(res as string)
          : res
      }),
      TR.mapResult(route.response.codec?.decode ?? R.Ok<any>),
      TR.map((body) => ({
        headers: {} as any, // shim :/
        status: 200, // shim :/
        body,
      })),
    )
  }

/** @deprecated use FetchApiRouteClient */
export const makeFetchApiClient: MakeApiClient<
  FetchClient,
  InfraError | C.DecodeError
> =
  (contract, fetch = fetchClient) =>
  (path, method, options) => {
    const route = contract[path][method]
    if (!route) throw new Error(`api route not found: ${method} ${path}`)
    const url = makePathWithSearch({
      path,
      route,
      params: options.params,
      searchParams: options.searchParams,
    })
    const task = fetch(url, {
      method,
      body: makeBodyInit(route.body, options.body),
      cache: options.cache,
      credentials: options.credentials,
      integrity: options.integrity,
      keepalive: options.keepalive,
      mode: options.mode,
      signal: options.signal,
      headers: {
        ...(options.headers && route.headers?.encode(options.headers)),
        ...(route.body && { 'Content-Type': route.body.contentType }),
        ...(route.response.contentType && {
          Accept: route.response.contentType,
        }),
      },
    })
    return pipe(
      task,
      TR.flatMap((response) =>
        TR.struct({
          status: TR.Ok(response.status),
          headers: pipe(
            response.headers,
            route.response.headers?.decode ?? R.Ok,
            TR.fromResult,
          ),
          body: (() => {
            switch (route.response.contentType) {
              case MimeType.Json:
                return parseJsonResponse(route.response.codec)(response)
              case MimeType.Html:
              case MimeType.Csv:
              case MimeType.Text:
                return parseTextResponse(response)
              default:
                return TR.Ok(undefined)
            }
          })(),
        }),
      ),
    )
  }
