import Pool from 'promisedip'
import { list as L, TupleOf, record } from '../data'
import { apply, cnst, flow, pipe } from '../function'

export type Task<T> = () => Promise<T>
export type Infer<T> = T extends Task<infer P> ? P : never

// constructors
export const make =
  <T>(value: T): Task<T> =>
  () =>
    Promise.resolve(value)
const New =
  <T>(fn: (resolve: (value: T) => any) => any) =>
  () =>
    new Promise<T>(fn)
export { New as new }

// combinators
export const map =
  <A, B>(fn: (a: A) => B) =>
  (task: Task<A>): Task<B> =>
    flow(task, (promise) => promise.then(fn))
export const flatMap =
  <A, B>(fn: (a: A) => Task<B>) =>
  (task: Task<A>): Task<B> =>
    flow(task, (promise) => promise.then((value) => fn(value)()))
export const flatTap =
  <T>(fn: (value: T) => Task<any>) =>
  (task: Task<T>): Task<T> =>
    pipe(
      task,
      flatMap((value) => pipe(fn(value), map(cnst(value)))),
    )

export const tap = <T>(fn: (value: T) => any) =>
  map<T, T>((value) => (fn(value), value))

export const sequence =
  <Value>(tasks: Task<Value>[]): Task<Value[]> =>
  async () => {
    const thunk: Value[] = []
    for (const task of tasks) thunk.push(await task())
    return thunk
  }

export const list =
  <Value>(tasks: Task<Value>[]): Task<Value[]> =>
  () =>
    Promise.all(tasks.map(apply())) as any

type TaskTuple = <Tasks extends TupleOf<Task<any>> | Task<any>[]>(
  tasks: Tasks,
) => Task<{ [Key in keyof Tasks]: Infer<Tasks[Key]> }>
export const tuple = list as TaskTuple
export const tupleSeq = sequence as TaskTuple

const makeStruct =
  (tuple: TaskTuple) =>
  <S extends { [Key in string]: Task<any> }>(
    struct: S,
  ): Task<{
    [Key in keyof S]: Infer<S[Key]>
  }> =>
    pipe(
      struct,
      record.entries,
      L.map(([key, task]) => tuple([make(key), task])),
      tuple,
      map(record.fromEntries),
    ) as Task<{
      [Key in keyof S]: Infer<S[Key]>
    }>

export const struct = makeStruct(tuple)
export const structSeq = makeStruct(tupleSeq)

// utils
export const delay =
  <T>(ms: number) =>
  (task: Task<T>): Task<T> =>
  () =>
    sleep(ms).then(task)

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
export const pooledIn =
  (pool: Pool) =>
  <V>(task: Task<V>): Task<V> =>
  () =>
    pool.resolve(task)

// declare const f1: Task<string>;
// declare const f2: Task<number>;
// declare const f3: Task<boolean>;
// declare const list: Array<Task<string> | Task<number> | Task<boolean>>
// const test = Task.all([f1, f2, f3]);
// const a = Task.all(list);
