export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'cotravellrs': 'Cotravellrs'
} as const

const fr = {
  'cotravellrs': 'Co-voyageurs'
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
