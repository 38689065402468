import { Eq } from './eq'
import { Ord } from './ord'
import { strictEquals } from './strict-equals'

export const boolean = {
  eq: Eq.fromEquals(strictEquals<boolean>),
  ord: Ord.fromCompare<boolean>((a, b) => Number(a) - Number(b)),
  swap: (a: boolean) => !a,
  fold:
    <R1, R2>(onFalse: () => R1, onTrue: () => R2) =>
    (b: boolean) =>
      b ? onTrue() : onFalse(),
}
