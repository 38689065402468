import { createLeafletMap } from '@/solidjs/leaflet/createMap'
import { list, struct } from '@/std/data'
import { pipe } from '@/std/function'
import { ReadonlyState } from '@/std/reactivity'
import L, { LeafletMouseEventHandlerFn } from 'leaflet'
import { TripCrumb } from './TripCrumbs'
import { fitMapBounds, palette } from './TripLeafletMap.utils'

export type TripLeafletMapModel = ReturnType<typeof TripLeafletMapModel>

type Deps = {
  crumbs: ReadonlyState<TripCrumb[]>
  onMapLongClick: LeafletMouseEventHandlerFn
  onMapClick: LeafletMouseEventHandlerFn
}
export const TripLeafletMapModel = ({
  crumbs,
  onMapLongClick,
  onMapClick,
}: Deps) => {
  const mapElement = document.createElement('div')
  const map = createLeafletMap({
    element: mapElement,
    onLongClick: onMapLongClick,
    options: {
      zoom: 12,
      doubleClickZoom: true,
      touchZoom: 'center',
    },
  })
  map.on('click', onMapClick)

  const polyline = L.polyline([], {
    opacity: 1,
    dashArray: '15, 10',
    dashOffset: '0',
    weight: 1.5,
    color: palette.blueDark,
    // smoothing: 0.1,
  })
  polyline.addTo(map)

  const markersLayer = new L.LayerGroup()
  markersLayer.addTo(map)

  const effects = [
    crumbs.onChange((crumbs) => {
      const markers = pipe(crumbs, list.filterMap(struct.lookup('marker')))
      markersLayer.clearLayers()
      setPolylineLatLngs(markers, polyline)
      markers.forEach((marker) => marker.addTo(markersLayer))

      fitMapBounds(map, crumbs)
    }),
  ]

  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },
    init: () => {
      fitMapBounds(map, [])
    },
    map,
    mapElement,
  }
}

const setPolylineLatLngs = (markers: L.Marker[], polyline: L.Polyline) => {
  const latLngs = markers.map((marker): [number, number] => {
    const { lat, lng } = marker.getLatLng()
    return [lat, lng]
  })
  polyline.setLatLngs(latLngs)

  // const pathEl = polyline.getElement()
  // console.info('path', pathEl)
  // if (pathEl instanceof SVGPathElement) {
  //   const d = pathEl.getAttribute('d')
  //   if (typeof d === 'string') {
  //     const startD = d.substring(0, d.indexOf(' '))
  //     console.info('pathEl', pathEl, d, startD)
  //     const animateEl = (
  //       <animate
  //         attributeName="d"
  //         from={startD}
  //         to={d}
  //         dur="1s"
  //         repeatCount={1}
  //         values={d}
  //         keyTimes="0;1"
  //       />
  //     ) as SVGAnimateElement
  //     pathEl.replaceChildren(animateEl)
  //   }
  // }
}
