import { InputFeedback, TextInput, solidFormControl } from '@/solidjs/form'
import { Link } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { matchErrOr } from '@/std/error'
import { flow, noop } from '@/std/function'
import { matchHttpCode } from '@/std/http'
import { remoteResult } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { Control } from '@travellr/client/ui-kit/Control.solid-js'
import { FormSubmitStateOverlay } from '@travellr/client/ui-kit/FormSubmitStateOverlay.solid-js'
import { JSX } from 'solid-js/jsx-runtime'
import { Page } from '../../ui-kit/Page.solid-js'
import { Register as Model } from './client'
import { messages } from './client.i18n'

type Props = {
  model: Model
}

export const Register = (props: Props) => {
  const { t } = I18n(messages)
  const { submit, reset, link } = props.model
  const submitState = solidState(props.model.submitState)
  const email = solidState(props.model.email)
  const name = solidFormControl(props.model.name)
  const isPending = flow(submitState, remoteResult.isPending)

  return (
    <Page>
      <div>
        <FormSubmitStateOverlay
          submitState={submitState()}
          onSuccess={noop}
          onTryAgain={submit}
          onClose={reset}
          render={(defaultRender) =>
            matchErrOr({
              _: defaultRender,
              HttpError: matchHttpCode({
                _: defaultRender,
                401: () => (
                  <p class="text-center preline">
                    {t('unauthorized', { Cta: LinkToSignin })}
                  </p>
                ),
                403: () => (
                  <p class="text-center preline">
                    {t('forbidden', { Cta: LinkToSignin })}
                  </p>
                ),
              }),
            })
          }
        />
        <form onSubmit={submit} class="vspacer-l">
          <h1 class="text-center">{t('heading')}</h1>
          <h3 class="text-center">{t('tagline')}</h3>
          <Control
            label={<div>{t('control.name.label')}</div>}
            control={<TextInput class="input" control={name} />}
            hint={<>{name().length}/100</>}
            feedback={
              <InputFeedback
                control={name}
                Error={{ required: t('control.name.required') }}
              />
            }
          />
          <hr />
          <div class="text-center">{t('acceptTermsOfService')}</div>
          <hr />
          <div class="text-center">
            <button type="submit" class="button" disabled={isPending()}>
              {t('submit')}
            </button>
          </div>

          <div class="vpsacer-s text-center">
            <p class="block">
              {t('footer.registeringWithEmail', {
                email: email(),
                Underline: (text) => <u>{text}</u>,
              })}
            </p>
            <p>{t('footer.wrongEmail?', { Cta: LinkToSignin })}</p>
          </div>
        </form>
      </div>
    </Page>
  )

  function LinkToSignin(text: JSX.Element) {
    return (
      <Link class="a" href={link.sendLinkToSignin}>
        {text}
      </Link>
    )
  }
}
