import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { struct } from '@/std/data'
import { pipe } from '@/std/function'
import { MimeType } from '@/std/http'
import { FileAddress } from '@travellr/domain/shared/entity/FileAddress'
import { ImageS3Storage } from '@travellr/domain/shared/entity/ImageStorage'
import { Picture, PictureStruct } from '../../entity/Picture'

const UploadDataCodec = C.struct({
  address: FileAddress,
  url: C.string,
})

export type Payload = C.TypeOf<typeof PayloadCodec>
const PayloadCodec = pipe(
  PictureStruct,
  struct.pick('caption', 'tripId', 'location', 'timestamp', 'tripId'),
  C.struct,
  C.intersect(
    C.struct({
      storage: ImageS3Storage,
      contentType: C.string,
      sizeInBytes: C.number,
    }),
  ),
)

export const s3Contract = ApiRouteContract('POST', '/s3-picture', {
  body: {
    contentType: MimeType.Json,
    codec: PayloadCodec,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: C.struct({
      picture: Picture,
      original: UploadDataCodec,
      thumbnail: UploadDataCodec,
    }),
  },
})
