import { solidState } from '@/solidjs/state'
import { O, boolean, list } from '@/std/data'
import { constFalse, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { Page } from '@travellr/client/ui-kit/Page.solid-js'
import { MainNav } from '../../../ui-kit/MainNav.solid-js'
import { ListTrips } from '../../../use-case/list-trips/client.solid-js'
import { MyTrips as Model } from './page'
import { messages } from './page.i18n'

type Props = {
  model: Model
}
export const MyTrips = (props: Props) => {
  return (
    <Page
      nav={<Nav model={props.model} />}
      content={<ListTrips model={props.model.listTrips} />}
    />
  )
}

type CustomMainNavProps = {
  model: Model
}
const Nav = (props: CustomMainNavProps) => {
  const { t } = I18n(messages)
  const trips = solidState(props.model.listTrips.trips)
  return (
    <MainNav.Slot>
      <MainNav.Home />
      <MainNav.Back />
      <MainNav.AddTrip
        tooltip={pipe(
          trips(),
          RR.toOption,
          O.map(list.size),
          O.map(Boolean),
          O.unwrapOr(constFalse),
          boolean.fold(
            () => t('addTripTooltip'),
            () => undefined,
          ),
        )}
      />
      <MainNav.Burger alignOptions="right" />
    </MainNav.Slot>
  )
}
