import { JsonWebToken } from '@/jsonwebtoken'
import { C } from '@/std/codec'
import { cast, pipe } from '@/std/function'
import { TripId } from './Trip'

export type ShareOptions = C.TypeOf<typeof ShareTripOptionsCodec>

// export type ShareToken = Opaque<string, 'ShareToken'>
export type ShareToken = JsonWebToken
const make = cast<string, ShareToken>

export const ShareTokenCodec = pipe(C.string, C.map(make, String))

export const ShareTripOptionsCodec = C.struct({
  tripId: TripId,
  includeCotravelers: C.bool,
})
