import {
  DateTimeInput,
  InputFeedback,
  TextInput,
  solidFormGroup,
} from '@/solidjs/form'
import { solidState } from '@/solidjs/state'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { Control } from '@travellr/client/ui-kit/Control.solid-js'
import { FormSubmitStateOverlay } from '@travellr/client/ui-kit/FormSubmitStateOverlay.solid-js'
import { TripId } from '../../entity/Trip'
import { AddCotravellrModel as Model } from './client'
import { messages } from './client.i18n'

type Props = {
  model: Model
}

export const AddCotravellr = (props: Props) => {
  const { reset, submit, tripId } = props.model
  const { t } = I18n(messages)
  const controls = solidFormGroup(props.model.controls)
  const submitState = solidState(props.model.submitState)

  return (
    <form onSubmit={submit} class="vspacer-m relative">
      <HowItWorks tripId={tripId} />
      <Control
        label={<>{t('peerTripId.label')}*</>}
        control={<TextInput class="input" control={controls.peerTripId} />}
        feedback={
          <InputFeedback
            control={controls.peerTripId}
            Error={{
              ownToken: t('peerTripId.error.ownToken'),
              required: t('peerTripId.error.required'),
            }}
          />
        }
      />
      <Control
        label={t('from.label')}
        control={<DateTimeInput class="input" control={controls.from} />}
      />
      <Control
        label={t('until.label')}
        control={<DateTimeInput class="input" control={controls.until} />}
      />
      <div class="text-center">
        <button class="button" disabled={RR.isPending(submitState())}>
          {t('add')}
        </button>
      </div>
      <FormSubmitStateOverlay
        submitState={submitState()}
        onSuccess={reset}
        onTryAgain={submit}
        onClose={reset}
      />
    </form>
  )
}

const HowItWorks = (props: { tripId: TripId }) => {
  const { t } = I18n(messages)
  return (
    <div class="vspacer-m">
      <p>{t('HowItWorks/howToShareToken')}</p>
      <input
        value={props.tripId}
        readonly
        class="box code p-m block w-100 text-center"
      />
      <p>{t('HowItWorks/whenItWillBeActive')}</p>
      <p>{t('HowItWorks/optionalDates')}</p>
    </div>
  )
}
