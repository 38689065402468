import { solidState } from '@/solidjs/state'
import { Dialog } from '@travellr/client/ui-kit/Dialog.solid-js'
import { Show } from 'solid-js'
import { I18n } from './i18n/I18n'
import { VersionWatcher as Model } from './version-watcher'
import { messages } from './version-watcher.i18n'

type Props = {
  watcher: Model
}
export const VersionWatcher = (props: Props) => {
  const { t } = I18n(messages)
  const reloading = solidState(props.watcher.reloading)
  return (
    <Show when={reloading()}>
      <Dialog root={document.body}>
        <h3 class="body p-xl text-center preline">{t('reloadingMessage')}</h3>
      </Dialog>
    </Show>
  )
}
