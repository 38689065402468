import { makeBrowserHistory, makeStorage } from '@/std/browser'
import { makeXhrClient } from '@/std/http'
import { ConsoleLogger } from '@/std/logger/console'
import { setClientContext } from '@travellr/client/context'
import { LocaleCodec } from '@travellr/domain/core/i18n'
import { render } from 'solid-js/web'
import { App as AppModel } from './app'
import { App as AppComponent } from './app.solid'
import { config } from './config'
import { initLocale } from './i18n/I18n'

const isSameOrigin =
  location.origin === new URL(config.apiUrl, location.href).origin

const xhr = makeXhrClient(config.apiUrl, (base) => ({
  credentials: base.credentials ?? 'include',
  mode: base.mode ?? isSameOrigin ? 'same-origin' : 'cors',
}))

setClientContext({
  xhr,
  history: makeBrowserHistory(),
  logger: ConsoleLogger(config.logLevel),
  config,
  locale: initLocale({
    storage: makeStorage({ Codec: LocaleCodec, type: 'local' }),
    defaultLocale: 'fr',
  }),
})

const root = document.querySelector('#root')
if (!root) throw new Error('no root to mount app')

const model = AppModel()
render(() => <AppComponent model={model} />, root)

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/build/service-worker.js')
    .catch((error) =>
      console.log(`Service Worker registration failed: "${error}"`),
    )
}
