import { constNull, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { Failure } from './Failure.solid-js'
import { messages } from './FormSubmitState.i18n'
import { Icon } from './Icon.solid-js'
import { Loader } from './Loader.solid-js'

export type FormSubmitStateProps<E, T> = {
  onTryAgain?: () => void
  onSuccess: (result: T) => void
  triggerSuccessDelay?: number
  submitState: RR.RemoteResult<E, T>
}

export const defaultTriggerSuccessDelay = 1500

export const FormSubmitState = <E extends Error, T>(
  props: FormSubmitStateProps<E, T>,
) => {
  const { t } = I18n(messages)
  return (
    <>
      {pipe(
        props.submitState,
        RR.fold(
          constNull,
          () => (
            <div class="absolute top left h-100 w-100 flex align-center justify-center">
              <Loader />
            </div>
          ),
          (error) => <Failure error={error} onTryAgain={props.onTryAgain} />,
          (result) => {
            setTimeout(
              () => props.onSuccess(result),
              props.triggerSuccessDelay ?? defaultTriggerSuccessDelay,
            )
            return (
              <>
                {t('success')} <Icon intent="success" />
              </>
            )
          },
        ),
      )}
    </>
  )
}
