import { UpdateProfile } from '@travellr/domain/core/authentication/use-case/update-profile/client'
import { getTravellrContext } from '../../context'

export type Profile = ReturnType<typeof Profile>
export const Profile = () => {
  const { account, profile } = getTravellrContext()

  return {
    form: UpdateProfile({
      initialValues: {
        email: account().email,
        name: profile().name,
      },
    }),
  }
}
