import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { MimeType } from '@/std/http'
import { PublicProfile } from '@travellr/domain/core/authentication/entity/Profile'
import { Cotravellr } from '../../entity/Cotravellr'
import { TripId } from '../../entity/Trip'

export const contract = ApiRouteContract('GET', '/cotravellrs/:selfTripId', {
  params: C.struct({ selfTripId: TripId }),
  response: {
    status: 200,
    codec: C.array(
      C.struct({
        cotravellr: Cotravellr,
        profile: C.Option(PublicProfile),
      }),
    ),
    contentType: MimeType.Json,
  },
})
