import { O, R } from '../data'
import { pipe } from '../function'
import { OnProgress } from '../http'
import { isDeepEqual } from '../is-deep-equal'
import { State } from '../reactivity'
import * as RD from './remote-data'
import * as RR from './remote-result'
import * as TR from './task-result'

export const UploadAction = <E, T, P extends any[]>(
  action: (onProgress: OnProgress) => (...args: P) => TR.TaskResult<E, T>,
): UploadAction<E, T, P> => {
  const state = State(RR.NotAsked<E, T>(), { equals: isDeepEqual })
  const onProgress: OnProgress = (event) =>
    state.set(RR.Pending<E, T>(O.None(), event))

  const reset = () => state.set(RR.NotAsked())

  const trigger = (...args: P) => {
    pipe(state(), RD.toOption, RR.Pending, state.set)
    return action(onProgress)(...args)().then((result) => {
      state.set(RR.fromResult(result))
      return result
    })
  }
  return { state, trigger, reset }
}

export type UploadAction<E, T, P extends any[]> = {
  state: State<RR.RemoteResult<E, T>>
  trigger: (...args: P) => Promise<R.Result<E, T>>
  reset: () => void
}
