import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { preventDefault } from '@/std/browser'
import { Email, isEmail, string } from '@/std/data'
import { flow } from '@/std/function'
import { State, computed } from '@/std/reactivity/state'
import { RR, RemoteAction } from '@/std/remote'
import { ClientContext, getClientContext } from '@travellr/client/context'
import { AccountId } from '../../entity/Account'
import { contract } from './contract'

type Deps = {
  onSuccess: (email: string, accountId: AccountId) => void
  context?: ClientContext
}

export type SendLinkToSignin = ReturnType<typeof SendLinkToSignin>
export const SendLinkToSignin = ({
  onSuccess,
  context: { history, xhr } = getClientContext(),
}: Deps) => {
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const sendLinkToSignin = (email: Email) =>
    client({ body: { email }, headers: { locale: 'en' } })

  const action = RemoteAction(sendLinkToSignin)
  const initialEmail = history.current().searchParams.get('email') ?? ''
  const email = State(initialEmail)

  const submit = preventDefault(() => action.trigger(email() as Email))

  return {
    submitState: action.state,
    reset: () => action.state.set(RR.NotAsked()),
    email,
    typeMismatch: computed([email], (email) => email && !isEmail(email)),
    valueMissing: computed([email], string.isEmpty),
    onSuccess: (result: { accountId: AccountId }) =>
      onSuccess(email(), result.accountId),
    submit,
  }
}
