import { ComponentProps, Show, createSignal } from 'solid-js'
import { Except } from 'type-fest'
import { Confirm, ConfirmProps } from './Confirm.solid-js'
import { Dialog, DialogProps } from './Dialog.solid-js'

type ConfirmDialogProps<E, T> = ConfirmProps<E, T> &
  Except<DialogProps, 'onClose' | 'children'>
const ConfirmDialog = <E extends Error, T>(props: ConfirmDialogProps<E, T>) => (
  <Dialog {...props} onClose={props.onCancel}>
    <Confirm {...props} />
  </Dialog>
)

export const makeConfirmAction = <E extends Error, T>(
  options: Pick<ConfirmProps<E, T>, 'action'>,
) => {
  const [showConfirmDialog, setShowConfirmDialog] = createSignal(false)
  return {
    Button: (props: Except<ComponentProps<'button'>, 'onClick'>) => (
      <button {...props} onClick={() => setShowConfirmDialog(true)} />
    ),
    Dialog: (
      props: Except<
        ConfirmDialogProps<E, T>,
        'action' | 'onCancel' | 'onSuccess'
      > &
        Partial<Pick<ConfirmDialogProps<E, T>, 'onCancel' | 'onSuccess'>>,
    ) => (
      <Show when={showConfirmDialog()}>
        <ConfirmDialog
          {...props}
          action={options.action}
          onCancel={() => {
            setShowConfirmDialog(false)
            props.onCancel?.()
          }}
          onSuccess={() => {
            setShowConfirmDialog(false)
            props.onSuccess?.()
          }}
        />
      </Show>
    ),
  }
}
