import { lazyNamed } from '@/solidjs/lazy-named'
import { O } from '@/std/data'
import { pipe } from '@/std/function'
import { Component, lazy } from 'solid-js'
import { AuthAppRoute, pathTo } from './client-router'
import { NotFound } from './not-found.solid-js'

const RouteByName = {
  SendLinkToSignin: lazyNamed(
    () => import('../use-case/send-link-to-signin/client.solid-js'),
    'SendLinkToSignin',
  ),
  SigninWithCode: lazyNamed(
    () => import('../use-case/signin-with-code/client.solid-js'),
    'SigninWithCode',
  ),
  Register: lazyNamed(
    () => import('../use-case/register/client.solid-js'),
    'Register',
  ),
} satisfies Record<
  AuthAppRoute['route'],
  ReturnType<typeof lazy<Component<{ model: any }>>>
>

type Props = {
  route: O.Option<AuthAppRoute>
}
export const AuthRouter = (props: Props) => (
  <>
    {pipe(
      props.route,
      O.map((active) => {
        const Component = RouteByName[active.route]
        // @ts-ignore I know what I'm doing
        return <Component model={active.model} />
      }),
      O.unwrapOr(() => <NotFound redirectTo={pathTo.sendLinkToSignin()} />),
    )}
  </>
)
