import { T } from '@/std/type'
import { JsonObject } from '@/std/types'

export type Location = {
  lat: number
  lng: number
}

const type = T.struct({
  lat: T.number,
  lng: T.number,
})
type Ord = { ord: typeof type.ord }
export const Location: T.Type<Location, JsonObject> & Ord = type

export const mockLocation = (parts?: Partial<Location>): Location => ({
  lat: parts?.lat ?? 0.1,
  lng: parts?.lng ?? 0.2,
})
