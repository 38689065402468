import { O } from '@/std/data'
import { flow } from '@/std/function'
import { State, mapState } from '@/std/reactivity/state'
import { RR } from '@/std/remote'
import {
  PictureContext,
  clearPictureContext,
  setPictureContext,
} from '@travellr/client/picture-store/context'
import { TripPageModel } from '@travellr/domain/core/shared-components/trip'
import { FullTrip } from '@travellr/domain/core/travellr/entity/FullTrip'
import { Note } from '@travellr/domain/core/travellr/entity/Note'
import { ShareToken } from '@travellr/domain/core/travellr/entity/ShareTripOptions'
import { SharedTripResource } from '../../../use-case/get-shared-trip/client'

type Deps = {
  token: ShareToken
}
export type SharedTripModel = ReturnType<typeof SharedTripModel>
export const SharedTripModel = ({ token }: Deps) => {
  const sharedTrip = SharedTripResource(token)
  const fullTrip = mapState(sharedTrip, RR.toOption)

  const providePictureContext = flow(PictureContext, setPictureContext)
  const openedNote = State(O.None<Note>())

  return {
    resource: sharedTrip,
    page: mapState(
      sharedTrip,
      RR.map((fullTrip) => {
        providePictureContext(fullTrip.trip.accountId)
        const state = State(O.None<FullTrip>())
        setTimeout(() => state.set(O.Some(fullTrip)), 100)
        return {
          page: TripPageModel({ fullTrip: state }),
          fullTrip,
        }
      }),
    ),
    openedNote,
    providePictureContext,
    dispose: clearPictureContext,
  }
}
