import { tuple } from '@/std/data'
import { flow } from '@/std/function'
import { RR, RemoteAction, RemoteResource, UploadAction } from '@/std/remote'
import { createEffect } from 'solid-js'
import { solidState } from './state'

export const solidRemoteAction = <E, T, P extends any[]>({
  state,
  ...action
}: RemoteAction<E, T, P>) => ({
  state: solidState(state),
  trigger: action.trigger,
  reset: () => state.set(RR.NotAsked()),
})

export const solidRemoteResource = <E, T>(resource: RemoteResource<E, T>) => {
  const adapted = solidState(resource)
  createEffect(resource.fetch)
  return Object.assign(adapted, resource)
}

/** @deprecated */
export const createRemoteAction = flow(
  RemoteAction,
  solidRemoteAction,
  (action) => tuple(action.state, action.trigger, action.reset),
)
/** @deprecated */
export const createUploadAction = flow(
  UploadAction,
  solidRemoteAction,
  (action) => tuple(action.state, action.trigger, action.reset),
)
/** @deprecated */
export const createRemoteResource = flow(
  RemoteResource,
  solidRemoteResource,
  (resource) =>
    tuple(resource, { fetch: resource.fetch, mutate: resource.mutate }),
)
