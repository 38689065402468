import './Loader.css'

export type LoaderProps = {
  /** default is 'm' */
  size?: Size
  /** default is 'primary' */
  skin?: Skin
  /** in s, default is 1 */
  duration?: number
}

export const Loader = (props: LoaderProps) => (
  <div
    class={`loader ${props.skin ?? 'primary'}`}
    style={{
      '--size': sizes[props.size ?? 'm'],
      '--duration': `${props.duration ?? 1}s`,
    }}
  >
    <div class="container">
      {new Array(3).fill(null).map((_, index) => (
        <div class={`flex row-${index + 1}`}>
          <div class="square n1"></div>
          <div class="square n2"></div>
          <div class="square n3"></div>
        </div>
      ))}
    </div>
  </div>
)

type Size = keyof typeof sizes
const sizes = {
  s: '1rem',
  m: '1.5rem',
  l: '3rem',
}

type Skin = 'inverted' | 'primary' | 'text'
