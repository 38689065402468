import { makeInfraError } from '../error'
import * as R from './result'

export const parseJson = (json: string) =>
  R.try(() => JSON.parse(json) as unknown, makeInfraError('parseJson'))

export const stringifyJson =
  <T = unknown>(space?: string | number) =>
  (value: T) =>
    JSON.stringify(value, null, space)
