import { DateTimeInput, TextInput, solidFormGroup } from '@/solidjs/form'
import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { ComponentProps, splitProps } from 'solid-js'
import { PictureFormModel } from './PictureForm'
import { messages } from './PictureForm.i18n'

type Props = ComponentProps<'form'> & {
  model: PictureFormModel
  onPickLocation: () => unknown
  hideSubmitButton?: boolean
}
export const PictureForm = (props: Props) => {
  const [, rest] = splitProps(props, [
    'model',
    'onPickLocation',
    'hideSubmitButton',
  ])
  const controls = solidFormGroup(props.model.controls)
  const submitState = solidState(props.model.state)
  const { submit } = props.model
  const { t } = I18n(messages)

  return (
    <form {...rest} onSubmit={submit}>
      <div class="flex column gap-m">
        <div class="flex column gap-xs">
          <label for="pictureFormTimestamp">Date</label>
          <DateTimeInput
            id="pictureFormTimestamp"
            class="input"
            control={controls.timestamp}
          />
        </div>

        <div class="flex column gap-xs">
          <label for="pictureFormCaption">Caption</label>
          <TextInput
            id="pictureFormCaption"
            class="input"
            control={controls.caption}
            placeholder={String(t('captionPlaceholder'))}
          />
        </div>

        <div class="flex column gap-xs">
          <label for="pictureFormLocation">Location</label>
          <input
            id="pictureFormLocation"
            class="input"
            readonly
            placeholder={String(t('pickLocation'))}
            onClick={props.onPickLocation}
            value={pipe(
              controls.location(),
              O.map(({ lat, lng }) => `${lat}, ${lng}`),
              O.unwrapOr(() => ''),
            )}
          />
        </div>
      </div>
      {!props.hideSubmitButton && (
        <button
          class="button"
          type="submit"
          disabled={RR.isPending(submitState())}
        >
          {t('verb/save')}
        </button>
      )}
    </form>
  )
}
