export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'startTrip': 'I’m travelling 🗺️',
  'importTrip': 'Add a past trip 🛋️',
} as const

const fr = {
  'startTrip': 'Je suis en voyage 🗺️',
  'importTrip': 'Mon voyage est fini 🛋️',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
