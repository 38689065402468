import { O } from '@/std/data'
import { TR } from '@/std/remote'
import { uuid } from '@/std/uuid'
import { PictureId } from '@travellr/domain/core/travellr/entity/Picture'
import { PictureStore } from './PictureStores'

export const makePictureInMemoryStore = () => {
  const images = new Map<string, string>()
  const store: PictureStore = {
    get: (accountId, picture) =>
      images.get(`${picture.tripId}-${picture.id}`) ?? 'image not found',
    thumbnail: (accountId, picture) =>
      images.get(`${picture.tripId}-${picture.id}_thumbnail`) ??
      'thumbnail not found',
    upload: (payload, data, options) => {
      const id = uuid() as PictureId
      images.set(`${payload.tripId}-${id}`, URL.createObjectURL(data))
      images.set(`${payload.tripId}-${id}-thumbnail`, URL.createObjectURL(data))
      return TR.Ok({
        id,
        caption: payload.caption,
        location: O.None(),
        storage: 'inMemory',
        timestamp: O.None(),
        tripId: payload.tripId,
        sizeInBytes: data.size,
      })
    },
  }
  return store
}
