import { Storage } from '@/std/browser'
import { O, struct } from '@/std/data'
import { pipe } from '@/std/function'
import { State } from '@/std/reactivity/state'
import { Account } from '../../entity/Account'
import { Profile } from '../../entity/Profile'

export type PersistCredentials = ReturnType<typeof PersistCredentials>

type Deps = {
  storage: Storage<AuthData>
}
export const PersistCredentials = ({ storage }: Deps) => {
  const storageKey = 'auth'
  const init = O.fromResult(storage.get(storageKey))
  const account = State(pipe(init, O.map(struct.lookup('account'))))
  const profile = State(pipe(init, O.map(struct.lookup('profile'))))

  return {
    account,
    profile,
    persist: (data: AuthData) => storage.set(storageKey, data),
    clear: () => storage.remove(storageKey),
  }
}

export type AuthData = {
  account: Account
  profile: Profile
}
