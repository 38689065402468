import { flow } from '@/std/function'
import { getClientContext } from '@travellr/client/context'
import { CreateTripWithPictures } from '../../components/CreateTripWithPictures/main'
import { pathTo } from '../../router'

export type ImportTrip = ReturnType<typeof ImportTrip>
export const ImportTrip = () => {
  const { history } = getClientContext()
  return {
    form: CreateTripWithPictures({
      picturesRequired: true,
      onSubmitSuccess: flow(pathTo.trip, history.push),
    }),
  }
}
