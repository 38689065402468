import { O, date } from '@/std/data'
import { cnst, constVoid, flow, pipe } from '@/std/function'
import { ReadonlyState, computed } from '@/std/reactivity'
import { LeafletMouseEventHandlerFn } from 'leaflet'
import { FullTrip } from '../../travellr/entity/FullTrip'
import { MakeTripCrumbMarker, TripCrumb, fullTripToCrumbs } from './TripCrumbs'
import { TripCrumbsCarouselModel } from './TripCrumbsCarousel'
import { TripLeafletMapModel } from './TripLeafletMap'
import { makeTripCrumbMarker } from './TripMapMarker.solid'
import { TripCrumbsFilterModel } from './TripRangeFilter'

export type TripPageModel = ReturnType<typeof TripPageModel>

type Deps = {
  fullTrip: ReadonlyState<O.Option<FullTrip>>
  onMapClick?: LeafletMouseEventHandlerFn
  onMapLongClick?: LeafletMouseEventHandlerFn
}

export const TripPageModel = ({
  fullTrip,
  onMapClick = constVoid,
  onMapLongClick = constVoid,
}: Deps) => {
  const makeTripCrumbMarkerWithOnClick: MakeTripCrumbMarker = (crumb) =>
    pipe(
      makeTripCrumbMarker(crumb),
      O.tap((marker) => {
        marker.on('click', () => carousel.active.set(O.Some(crumb)))
      }),
    )

  const crumbs = computed(
    [fullTrip],
    flow(
      O.map(fullTripToCrumbs({ makeMarker: makeTripCrumbMarkerWithOnClick })),
      O.unwrapOr(cnst<TripCrumb[]>([])),
    ),
  )

  const filtered = TripCrumbsFilterModel({ crumbs, range: 'week' })
  const leafletMap = TripLeafletMapModel({
    crumbs: filtered.crumbs,
    onMapClick,
    onMapLongClick,
  })
  const carousel = TripCrumbsCarouselModel({
    crumbs: filtered.crumbs,
    onActiveChanged: (next) => {
      if (O.isNone(next.location)) return
      leafletMap.map.setView(next.location.value)
    },
  })

  const range = computed([filtered.range, filtered.active], (range, active) =>
    pipe(
      active,
      O.map((base) => {
        switch (range) {
          case 'day':
            return makeRange(base, date.startOfDay, date.endOfDay)
          case 'week':
            return makeRange(base, date.startOfWeek, date.endOfWeek)
          case 'month':
          case 'full':
            return makeRange(base, date.startOfMonth, date.endOfMonth)
        }
      }),
    ),
  )

  return {
    fullTrip,
    filtered,
    leafletMap,
    carousel,
    range,
  }
}

const makeRange = (
  base: Date,
  from: (date: Date) => Date,
  until: (date: Date) => Date,
) => ({
  from: from(base),
  until: until(base),
})
