import { TR } from '@/std/remote'
import { readAndCompressImage } from 'browser-image-resizer'
import { ImageResizeError, ImageToolbox } from './common'

const Toolbox: ImageToolbox<File | Blob, Blob> = {
  resize: (file, height) =>
    TR.try(
      () => readAndCompressImage(file as File, { maxHeight: height }),
      ImageResizeError,
    ),
}
export { ImageResizeError, Toolbox as ImageToolbox }
