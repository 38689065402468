import { lazyNamed } from '@/solidjs/lazy-named'
import { O } from '@/std/data'
import { match, panic, pipe } from '@/std/function'
import { SandboxAppRoute } from './client-router'

const Sandbox = lazyNamed(() => import('./Sandbox.solid-js'), 'Sandbox')

type Props = {
  route: O.Option<SandboxAppRoute>
}

export const Router = (props: Props) => (
  <>
    {pipe(
      props.route,
      O.map(
        match('route')({
          Sandbox: () => <Sandbox />,
        }),
      ),
      O.unwrapOr(() => panic('sandbox app has no not-found route')),
    )}
  </>
)
