import { ApiRouteContract } from '@/std/api-contract'
import { C, Cd } from '@/std/codec'
import { MimeType } from '@/std/http'
import { ShareTokenCodec } from '../../entity/ShareTripOptions'
import { TripId } from '../../entity/Trip'

export const contract = ApiRouteContract('GET', '/share-token/:tripId', {
  params: C.struct({ tripId: TripId }),
  searchParams: C.struct({ includeCotravelers: Cd.boolFromString }),
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: C.struct({ token: ShareTokenCodec }),
    headers: C.struct({ 'Cache-Control': C.string }),
  },
})
