import { HttpMethod } from '../http/Method'
import { ApiRouteHandlers, defaultErrorHandler } from './route-handler'

export type TraversedConfig = ApiRouteHandlers['contract'] &
  Pick<ApiRouteHandlers, 'errorHandler' | 'handler'> & {
    methodLower: Lowercase<HttpMethod>
  }

type RouteId = `${HttpMethod} ${string}`

export const traverseHandlers = <Handlers extends ApiRouteHandlers[]>(
  handlers: Handlers,
  mapper: (config: TraversedConfig) => void,
) => {
  const traversed = new Set<RouteId>()
  handlers.forEach((route) => {
    const id: RouteId = `${route.contract.method} ${route.contract.path}`
    if (traversed.has(id)) throw new Error(`${id} already defined`)
    traversed.add(id)
    mapper({
      ...route.contract,
      methodLower: route.contract.method.toLowerCase() as Lowercase<HttpMethod>,
      errorHandler: route.errorHandler ?? defaultErrorHandler,
      handler: route.handler,
    })
  })
}

// export const traverseApi = <Contract extends ApiContract>(
//   contract: Contract,
//   handlers: ApiHandlers<Contract>,
//   mapper: (config: TraversedConfig) => void,
// ) =>
//   pipe(
//     contract,
//     record.map((routes, path) => {
//       pipe(
//         routes,
//         struct.map((route, method) => {
//           if (!route) throw new Error(`route not found for ${method} ${path}`)
//           type HandlersShape = ApiHandlers<ApiContract>[string][HttpMethod]
//           const routeHandlers = (handlers[path] as any)[method] as HandlersShape
//           const handler =
//             typeof routeHandlers === 'function'
//               ? routeHandlers
//               : routeHandlers.handler
//           const errorHandler =
//             typeof routeHandlers === 'function'
//               ? defaultErrorHandler
//               : routeHandlers.errorHandler

//           mapper({
//             path,
//             method: method as unknown as HttpMethod,
//             get methodLower() {
//               return method.toLowerCase() as Lowercase<HttpMethod>
//             },
//             route,
//             errorHandler,
//             handler:
//               (handler as any) ??
//               panic(new Error(`handler not found for ${method} ${path}`)),
//           })
//         }),
//       )
//     }),
//   )
