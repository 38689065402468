import { TR } from '../remote'
import { ApiCors } from './handlers'
import { ApiRouteHandlers } from './route-handler'

export type ApiServerOptions = {
  path?: string
  port?: number
  host?: string
  signal?: AbortSignal
}

export type ApiServer = {
  listen: (options: ApiServerOptions) => TR.TaskResult<Error, void>
  unlisten: () => TR.TaskResult<Error, void>
}

export type MakeApiServer = (
  handlers: ApiRouteHandlers[],
  config?: ApiServerOptions & { cors?: ApiCors },
) => ApiServer
