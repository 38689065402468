import { Exif } from '@/exif/index.js'
import { ImageToolbox } from '@/image-toolbox/browser'
import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { struct } from '@/std/data'
import { flow, pipe } from '@/std/function'
import { T, TR } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { s3Contract } from '@travellr/domain/core/travellr/use-case/add-picture/s3.contract'
import { ImageS3Storage } from '@travellr/domain/shared/entity/ImageStorage'
import Pool from 'promisedip'
import { PictureStore } from './PictureStores'

export const makePictureS3Store = (options: { storage: ImageS3Storage }) => {
  const { xhr, config } = getClientContext()
  const client = flow(XhrApiRouteClient(s3Contract, xhr), justBody)
  const resizeImagePool = new Pool({ concurrency: 1 })
  const store = {
    get: (accountId, picture) =>
      `${config.apiUrl}/picture/original/${options.storage}/${accountId}/${picture.tripId}/${picture.id}`,

    thumbnail: (accountId, picture) =>
      `${config.apiUrl}/picture/thumbnail/${options.storage}/${accountId}/${picture.tripId}/${picture.id}`,

    upload: (payload, data, { onProgress, onThumbnailProgress }) =>
      pipe(
        TR.struct({
          resized: pipe(
            ImageToolbox.resize(data, config.thumbnailHeightPx),
            T.pooledIn(resizeImagePool),
          ),
          added: pipe(
            TR.struct({
              location: Exif.getLocation(data),
              timestamp: Exif.getTimestamp(data),
            }),
            TR.flatMap(({ location, timestamp }) =>
              client({
                onProgress,
                body: {
                  caption: payload.caption,
                  contentType: data.type,
                  sizeInBytes: data.size,
                  location,
                  timestamp,
                  storage: options.storage,
                  tripId: payload.tripId,
                },
              }),
            ),
          ),
        }),
        TR.flatTap(({ added: { original, thumbnail }, resized }) =>
          TR.structSeq({
            thumbnail: pipe(
              xhr(thumbnail.url, {
                method: 'PUT',
                body: resized,
                mode: 'cors',
                headers: { 'Content-Type': data.type },
                onProgress: onThumbnailProgress,
              }),
            ),
            original: xhr(original.url, {
              method: 'PUT',
              body: data,
              mode: 'cors',
              headers: { 'Content-Type': data.type },
              onProgress,
            }),
          }),
        ),
        TR.map(struct.lookup('added')),
        TR.map(struct.lookup('picture')),
      ),
  } satisfies PictureStore
  return store
}
