import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteResource } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { ShareToken } from '@travellr/domain/core/travellr/entity/ShareTripOptions'
import { contract } from './contract'

export type SharedTripResource = ReturnType<typeof SharedTripResource>
export const SharedTripResource = (token: ShareToken) => {
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const getSharedTrip = () => client({ params: { token } })
  return RemoteResource(getSharedTrip)
}
