import { solidState } from '@/solidjs/state'
import { O, list } from '@/std/data'
import { cnst, flow, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { Dialog } from '@travellr/client/ui-kit/Dialog.solid-js'
import { Image } from '@travellr/client/ui-kit/Image.solid-js'
import { Progress } from '@travellr/client/ui-kit/Progress.solid-js'
import { UploadProgress } from '@travellr/client/ui-kit/UploadState.solid-js'
import { For, JSX, Show, createEffect, mapArray, onCleanup } from 'solid-js'
import { messages } from './Dialog.i18n'
import { UploadPicturesModel } from './model'

type Props = {
  model: UploadPicturesModel
  dialogClass?: string
  footer?: JSX.Element
}

export const UploadPicturesDialog = (props: Props) => {
  const { t } = I18n(messages)
  const uploadState = solidState(props.model.uploadState)
  const states = mapArray(
    flow(uploadState, O.unwrapOr(cnst([]))),
    (upload) => ({ ...upload, state: solidState(upload.state) }),
  )
  const uploaded = () =>
    pipe(
      states(),
      list.filter((item) => RR.isOk(item.state())),
      list.size,
    )
  return (
    <Show when={O.isSome(uploadState())}>
      <Dialog dialogClass={props.dialogClass ?? 'vspacer-m py-m'}>
        <h4 class="text-center px-m">
          {t('heading')} - {uploaded()}/{states().length}
        </h4>
        <Progress value={uploaded()} max={states().length} />
        <div class="nowrap body x-scrollauto text-center">
          <For each={states()}>
            {(item) => {
              createEffect(() => {
                const state = item.state()
                if (
                  !RR.isPending(state) ||
                  O.isNone(state.event) ||
                  state.event.value.loaded !== state.event.value.total
                )
                  return

                // remove event after some time to avoid having a blocked progress indicator
                const timeout = setTimeout(
                  () => item.state.set(RR.Pending(state.stale)),
                  2500,
                )
                onCleanup(() => clearTimeout(timeout))
              })

              return (
                <div class="relative rounded p-m inline-block">
                  <Image
                    src={URL.createObjectURL(item.picture.file)}
                    class="rounded w-100"
                    height="300px"
                  />
                  <div class="px-xl rounded absolute top left h-100 w-100 flex justify-center align-center">
                    <UploadProgress state={item.state()} retry={item.retry} />
                  </div>
                </div>
              )
            }}
          </For>
        </div>
        {props.footer}
      </Dialog>
    </Show>
  )
}
