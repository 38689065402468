import { Metrics } from '@/opentelemetry/metrics'
import { O } from '@/std/data'
import { pipe } from '@/std/function'
import { getClientContext } from '@travellr/client/context'
import { AccountId } from '@travellr/domain/core/authentication/entity/Account'
import { untrack } from 'solid-js'

export const registerMetrics = (options: {
  accountId: () => O.Option<AccountId>
}) => {
  const { history } = getClientContext()
  const countPageView = Metrics.makeCounter('page', 'views')

  const pageView = (url: URL) => {
    const accountId = pipe(options.accountId, untrack, O.toUndefined)
    countPageView({ accountId, href: url.href })
  }

  pageView(history.current())
  history.current.onChange(pageView)
}
export type Metrics = ReturnType<typeof registerMetrics>
