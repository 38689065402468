import { O, struct } from '@/std/data'
import { cnst, flow, pipe } from '@/std/function'
import { ComponentProps, splitProps } from 'solid-js'
import { Except } from 'type-fest'
import { SolidFormControl } from './adapters'

export type DateInputProps = Except<
  ComponentProps<'input'>,
  'type' | 'value'
> & {
  control: SolidFormControl<O.Option<Date>, any>
}

export const DateInput = (props: DateInputProps) => {
  const [, inputProps] = splitProps(props, ['control'])
  return (
    <input
      {...inputProps}
      type="date"
      value={pipe(props.control(), O.fold(cnst(''), dateAsValue))}
      onBlur={() => props.control.isTouched.set(true)}
      onChange={flow(
        struct.lookup('currentTarget'),
        struct.lookup('valueAsDate'),
        O.fromNullable,
        props.control.set,
      )}
    />
  )
}
const dateAsValue = (date: Date) => date.toISOString().slice(0, 10)
