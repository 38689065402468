// @ts-check

export const tuple = <T extends Tuple>(...t: T): T => t

export type TupleOf<Base = any> = [Base, ...Base[]]
export type Tuple = TupleOf<any>

export type TupleIntersection<T> = T extends [infer A, infer B]
  ? A & B
  : T extends [infer A, ...infer B]
  ? A & TupleIntersection<B>
  : T extends [infer A]
  ? A
  : never
