import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { Trip } from '../../entity/Trip'
import { contract } from './contract'

export type ArchiveTrip = ReturnType<typeof ArchiveTrip>

type Deps = {
  trip: Pick<Trip, 'id' | 'name'>
}
export const ArchiveTrip = ({ trip }: Deps) => {
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const archive = () => client({ params: { id: trip.id } })

  return {
    action: RemoteAction(archive),
    trip,
  }
}
