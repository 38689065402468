import { pipe } from '@/std/function'
import { RR, TR } from '@/std/remote'
import { splitProps } from 'solid-js'
import { JSX } from 'solid-js/jsx-runtime'
import { createRemoteResource } from '../reactivity'
import { PellEditorProps } from './Editor'

export type LazyPellEditorProps = PellEditorProps & {
  loading: () => JSX.Element
  error: (refetch: () => void) => (error: Error) => JSX.Element
}

export const LazyPellEditor = (props: LazyPellEditorProps) => {
  const [module, { fetch }] = createRemoteResource(() =>
    TR.try(() => import('./Editor')),
  )
  const [, rest] = splitProps(props, ['loading', 'error'])

  return (
    <>
      {pipe(
        module(),
        RR.fold2(props.loading, props.error(fetch), ({ PellEditor }) => (
          <PellEditor {...rest} />
        )),
      )}
    </>
  )
}
