import { Context } from '@/std/context'
import { AccountId } from '@travellr/domain/core/authentication/entity/Account'
import { makeMock } from '@travellr/domain/core/mocks'
import { Picture } from '@travellr/domain/core/travellr/entity/Picture'
import { PictureStores } from './PictureStores'

export type PictureContext = ReturnType<typeof PictureContext>

export const [setPictureContext, usePictureContext, clearPictureContext] =
  Context<PictureContext>('PictureContext')

export const PictureContext = (accountId: AccountId) => {
  const stores = PictureStores()
  return {
    url: (picture: Pick<Picture, 'storage' | 'tripId' | 'id'>) =>
      stores[picture.storage].thumbnail(accountId, picture),
    original: (picture: Pick<Picture, 'storage' | 'tripId' | 'id'>) =>
      stores[picture.storage].get(accountId, picture),
  }
}

export const fakePictureContext = (
  seeed = 'PictureContext',
): PictureContext => {
  const mock = makeMock(seeed)
  const { chance } = mock
  const natural = (min: number, max: number) => chance.natural({ min, max })
  const seed = (picture: Pick<Picture, 'id' | 'tripId'>) =>
    `${picture.tripId}-${picture.id}`

  return {
    url: (picture) =>
      `https://picsum.photos/seed/${seed(picture)}/${natural(150, 400)}/250`,
    original: (picture) =>
      `https://picsum.photos/seed/${seed(picture)}/${natural(
        400,
        1200,
      )}/${natural(400, 1200)}`,
  }
}
