import { InputFeedback, TextInput } from '@/solidjs/form'
import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { Control } from '@travellr/client/ui-kit/Control.solid-js'
import { FormSubmitStateOverlay } from '@travellr/client/ui-kit/FormSubmitStateOverlay.solid-js'
import { createUniqueId } from 'solid-js'
import { UpdateProfile as Model } from './client'
import { messages } from './client.i18n'

type Props = {
  class?: string
  model: Model
}

export const UpdateProfile = (props: Props) => {
  const { t } = I18n(messages)
  const formId = createUniqueId()
  const { submit, reset, controls } = props.model
  const submitState = solidState(props.model.submitState)

  return (
    <>
      <form
        id={formId}
        class={cx('vspacer-m relative', props.class)}
        onSubmit={props.model.submit}
      >
        <FormSubmitStateOverlay
          submitState={submitState()}
          onTryAgain={submit}
          onSuccess={reset}
          onClose={reset}
        />

        <Control
          label={t('name.label')}
          control={<TextInput control={controls.name} class="input" />}
          feedback={
            <InputFeedback
              control={controls.name}
              Error={{ required: t('name.required') }}
            />
          }
        />

        <Control
          label={t('control/email.label')}
          control={<TextInput control={controls.email} class="input" />}
          feedback={
            <InputFeedback
              control={controls.email}
              Error={{
                required: t('control/email.required'),
                invalid: t('control/email.invalid'),
              }}
            />
          }
        />
      </form>

      <div class={cx('flex align-center justify-center', props.class)}>
        <button
          class="button"
          form={formId}
          type="submit"
          disabled={RR.isPending(submitState())}
        >
          {t('verb/save')}
        </button>
      </div>
    </>
  )
}
