import { C } from '../codec'
import { TupleOf } from '../data'
import { InfraError, matchErr } from '../error'
import { flow, identity } from '../function'
import { BadRequest, HttpError, ServerError } from '../http'
import { TR } from '../remote'
import { HasProp, If } from '../types'
import {
  DecodeBodyError,
  DecodeHeadersError,
  DecodeParamsError,
  DecodeSearchParamsError,
  ResponseCookieOptions,
} from './handlers'
import { ApiRouteContract } from './route-contract'
import { PathParameters } from './type-utils'

export const ApiRouteHandlers = <Route extends ApiRouteContract>(
  contract: Route,
  handlers: {
    errorHandler?: ApiRouteErrorHandler<Route>
    handler: ApiRouteHandler<Route>
  },
): ApiRouteHandlers<Route> => ({
  contract,
  errorHandler: handlers.errorHandler ?? defaultErrorHandler,
  handler: handlers.handler,
})

export type ApiRouteHandlers<
  Route extends ApiRouteContract = ApiRouteContract,
> = {
  contract: Route
  errorHandler: ApiRouteErrorHandler<Route>
  handler: ApiRouteHandler<Route>
}

export const handlers = <Handlers extends TupleOf<ApiRouteHandlers<any>>>(
  ...args: Handlers
) => args as TupleOf<ApiRouteHandlers>

type AllError =
  | InfraError
  | DecodeBodyError
  | DecodeParamsError
  | DecodeHeadersError
  | DecodeSearchParamsError
export const defaultErrorHandler = flow(
  identity<AllError>,
  matchErr({
    DecodeBodyError: (e) => BadRequest(e.id),
    DecodeHeadersError: (e) => BadRequest(e.id),
    DecodeParamsError: (e) => BadRequest(e.id),
    DecodeSearchParamsError: (e) => BadRequest(e.id),
    InfraError: (e) => ServerError(e.id),
  }),
)

export type ApiRouteHandlerContext<Route extends ApiRouteContract> = {
  readonly searchParams: C.TypeOf<Route['searchParams']>
  readonly params: If<
    HasProp<Route, 'params'>,
    C.TypeOf<Route['params']>,
    PathParameters<Route['path']>
  >
  readonly body: C.TypeOf<NonNullable<Route['body']>['codec']>
  readonly headers: C.TypeOf<Route['headers']>
  readonly cookies: Record<string, string>
  getHeader: (name: string) => string | null | undefined
}

export type ApiRouteErrorHandler<Route extends ApiRouteContract> = (
  error:
    | InfraError
    | If<HasProp<Route, 'body'>, DecodeBodyError, never>
    | If<HasProp<Route, 'params'>, DecodeParamsError, never>
    | If<HasProp<Route, 'headers'>, DecodeHeadersError, never>
    | If<HasProp<Route, 'searchParams'>, DecodeSearchParamsError, never>,
) => HttpError

export type ApiRouteHandler<Route extends ApiRouteContract> = (
  context: ApiRouteHandlerContext<Route>,
) => TR.TaskResult<HttpError, ApiRouteResponse<Route>>

export type ApiRouteResponse<Route extends ApiRouteContract> = {
  cookies?: Record<string, ResponseCookieOptions>
} & If<
  HasProp<Route['response'], 'codec'>,
  { body: C.TypeOf<Route['response']['codec']> },
  { body?: undefined }
> &
  If<
    HasProp<Route['response'], 'headers'>,
    { headers: C.TypeOf<Route['response']['headers']> },
    { headers?: undefined }
  >
