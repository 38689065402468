import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { I18n } from '@travellr/client/i18n/I18n'
import { Portal } from 'solid-js/web'
import { NoteForm } from '../../components/NoteForm.solid'
import './TripNoteForm.css'
import { messages } from './TripNoteForm.i18n'
import { TripModel } from './main'

type Props = {
  model: TripModel
}
export const TripNoteFormDialog = (props: Props) => {
  const { t } = I18n(messages)
  const form = solidState(props.model.tripNoteForm)

  return (
    <Portal>
      {pipe(
        form(),
        O.fold(constNull, (model) => (
          <NoteForm
            model={model.form}
            render={({ Form, SubmitButton }) => (
              <div class="trip-note-form-overlay">
                <h5 class="box p-m text-center">
                  {model.type === 'add'
                    ? t('addNoteTitle')
                    : t('editNoteTitle')}
                </h5>

                <Form class="box p-m" />

                <div class="box p-m flex justify-center gap-m">
                  <button
                    class="discrete button"
                    type="button"
                    onClick={() => form.set(O.None())}
                  >
                    {t('verb/cancel')}
                  </button>
                  <SubmitButton />
                </div>
              </div>
            )}
          />
        )),
      )}
    </Portal>
  )
}
