import { isOnline } from '@/solidjs/is-online'
import { solidRemoteResource } from '@/solidjs/reactivity'
import { solidState } from '@/solidjs/state'
import { O, boolean } from '@/std/data'
import { constNull, flow, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Dialog } from '@travellr/client/ui-kit/Dialog.solid-js'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { JSX, createEffect } from 'solid-js'
import { RuntimeError } from '../domain/core/admin/entity/RuntimeError'
import { AddRuntimeErrorResource } from '../domain/core/admin/use-case/add-runtime-error/client'
import { ErrorBoundary as Model } from './ErrorBoundary'

export const ErrorBoundary = (props: {
  model: Model
  children: JSX.Element
}) => {
  const report = solidState(props.model.report)

  return (
    <>
      {pipe(
        report(),
        O.fold(constNull, (r) => (
          <CaughtErrorDialog report={r} onReset={() => report.set(O.None())} />
        )),
      )}
      {props.children}
    </>
  )
}

const bugEmail = `bugs@${location.hostname}`

const CaughtErrorDialog = (props: {
  report: RuntimeError
  onReset: () => void
}) => {
  const addedError = solidRemoteResource(AddRuntimeErrorResource(props.report))
  const retry = addedError.fetch

  createEffect((wasOnline) => {
    const online = isOnline()
    const errored = RR.isErr(addedError())
    if (!wasOnline && online && errored) retry()
    return online
  }, isOnline())

  return (
    <Dialog dialogClass="p-m vspacer-m text-justify" root={document.body}>
      <header class="text-center">
        <h3>Boom 💣💥</h3>
      </header>
      <hr />
      <div class="vspacer-m">
        <h5>Le wild bug appeared 🐛</h5>
        <p>An unknown, unexpected and unrecoverable error occurred 🤕</p>
        {pipe(
          addedError(),
          RR.fold(
            constNull,
            () => (
              <p>
                Notifying the tech team <Icon intent="spinner" class="spin" />
              </p>
            ),
            flow(
              isOnline,
              boolean.fold(
                () => (
                  <p>You are offline, we could not notify the tech team 🤐</p>
                ),
                () => (
                  <p>
                    Even notifying the tech team failed 😵 <br />
                    If the bug persists, you can send an email at{' '}
                    <a class="a" href={`mailto:${bugEmail}`}>
                      {bugEmail}
                    </a>
                  </p>
                ),
              ),
            ),
            () => (
              <p>
                ✅ Tech team has been notified, we are very sorry for the
                inconvenience, please retry in a while
              </p>
            ),
          ),
        )}
        <details>
          <summary>Technical detail</summary>

          <pre>
            <code class="code">{JSON.stringify(props.report, null, 2)}</code>
          </pre>
        </details>
      </div>
      <hr />
      <footer class="flex justify-end align-center">
        <button class="discrete button" onClick={props.onReset}>
          Ok 😮‍💨
        </button>
      </footer>
    </Dialog>
  )
}
