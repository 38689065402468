import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteResource } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { TripId } from '../../entity/Trip'
import { contract } from './contract'

export type GetTripShareTokenResource = ReturnType<
  typeof GetTripShareTokenResource
>
export const GetTripShareTokenResource = (
  tripId: TripId,
  includeCotravelers: boolean,
) => {
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const get = () =>
    client({ params: { tripId }, searchParams: { includeCotravelers } })
  return RemoteResource(get)
}
