import { struct } from '@/std/data'
import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { JsonObject } from '@/std/types'
import { Opaque } from 'type-fest'
import { AccountId } from './Account'

export type Profile = {
  id: ProfileId
  accountId: AccountId
  name: string
}

export type PublicProfile = {
  name: string
}

export type ProfileId = Opaque<string, 'ProfileId'>
const fromString = cast<string, ProfileId>
export const ProfileId = pipe(T.string, T.map(fromString, String))

const Struct = {
  id: ProfileId,
  accountId: AccountId,
  name: T.string,
  // maybe enhance later with avatar or whatever.
}

const profileType = T.struct(Struct)
type ProfileOrd = { ord: typeof profileType.ord }
export const Profile: T.Type<Profile, JsonObject> & ProfileOrd = profileType

const publicProfileType = pipe(Struct, struct.pick('name'), T.struct)
type PublicProfileOrd = { ord: typeof publicProfileType.ord }

export const PublicProfile: T.Type<PublicProfile, JsonObject> &
  PublicProfileOrd = publicProfileType
