import { Link } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { identity, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { I18n } from '@travellr/client/i18n/I18n'
import { LocaleControl } from '@travellr/client/i18n/Input.solid-js'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { Progress } from '@travellr/client/ui-kit/Progress.solid-js'
import { ComponentProps, JSX, Show, mapArray, splitProps } from 'solid-js'
import { Except } from 'type-fest'
import { pathTo as guestPathTo } from '../../guest/client/router'
import { getTravellrContext } from '../client/context'
import { pathTo } from '../client/router'
import './MainNav.css'
import { messages } from './MainNav.i18n'

type MainNavProps = ComponentProps<'nav'>

const NavSlot = (props: MainNavProps) => (
  <nav {...props} class={cx('mainNav', props.class)} />
)

const isActive = (href: string) => (url: URL) =>
  url.pathname === new URL(href, url).pathname

type NavItemHomeProps = ComponentProps<'a'>
const NavItemHome = (props: NavItemHomeProps) => {
  const { history } = getClientContext()
  const url = solidState(history.current)
  const href = props.href ?? pathTo.myTrips
  return (
    <Link
      {...props}
      class={cx('navItem', props.class)}
      href={href}
      aria-disabled={pipe(url(), isActive(href))}
    >
      <Icon intent="home" />
    </Link>
  )
}

type NavItemBackProps = ComponentProps<'button'>
const NavItemBack = (props: NavItemBackProps) => {
  const { history } = getClientContext()
  return (
    <button
      {...props}
      class={cx('navItem', props.class)}
      onClick={history.back}
    >
      <Icon intent="back" />
    </button>
  )
}

type NavItemAddTripProps = Except<ComponentProps<'a'>, 'href'> & {
  tooltip?: JSX.Element
}
const NavItemAddTrip = (props: NavItemAddTripProps) => {
  const { history } = getClientContext()
  const url = solidState(history.current)
  const href = pathTo.addTrip
  return (
    <Link
      {...props}
      class="navItem relative"
      href={href}
      aria-disabled={pipe(url(), isActive(href))}
    >
      <Icon intent="add" />
      <Show when={props.tooltip}>
        <div class="tooltip top p-m" style={{ 'min-width': '7rem' }}>
          {props.tooltip}
        </div>
      </Show>
    </Link>
  )
}

type NavItemBurgerMenuProps = ComponentProps<'div'> & {
  optionsToAdd?: JSX.Element[]
  alignOptions?: 'right'
}

const NavItemBurgerMenu = (props: NavItemBurgerMenuProps) => {
  const { t } = I18n(messages)
  const { history } = getClientContext()
  const context = getTravellrContext()
  const url = solidState(history.current)
  const storageSize = solidState(context.storageSize)
  const [, rest] = splitProps(props, ['optionsToAdd', 'alignOptions'])
  return (
    <div
      {...rest}
      class={cx('navItem relative', props.class)}
      role="combobox"
      tabIndex={0}
    >
      <Icon intent="menu" />
      <div
        role="combobox"
        class={cx(
          'options absolute minw-100 z-2 rounded text-center overflow-hidden',
          props.alignOptions,
        )}
      >
        {mapArray(() => props.optionsToAdd, identity)()}
        <Link
          role="option"
          class="w-100 lg flex justify-space-between align-center hspacer-m"
          href={pathTo.profile}
          aria-disabled={pipe(url(), isActive(pathTo.profile))}
        >
          <Icon intent="profile" />
          <span>{t('profile')}</span>
          <span />
        </Link>
        <div
          role="option"
          class="w-100 lg flex justify-space-between align-center hspacer-m"
        >
          <Icon intent="language" />
          <LocaleControl
            role="option"
            class="w-100 flex-1 text-center rounded"
          />
        </div>
        <Link
          role="option"
          class="w-100 lg flex justify-space-between align-center hspacer-m"
          href={guestPathTo.credits}
        >
          <Icon intent="credits" />
          <span>Credits</span>
          <span />
        </Link>
        <button
          role="option"
          class="w-100 lg pointer flex justify-space-between align-center hspacer-m"
          style="font-family: inherit"
          onClick={context.signOut}
        >
          <Icon intent="signOut" />
          <span>{t('verb/signOut')}</span>
          <span />
        </button>

        {pipe(
          storageSize(),
          RR.fold2(
            () => <div class="placeholder" />,
            (error) => (
              <div class="flex align-center gap-s p-m">
                <div>{t('loadFailed')}</div>
                <button
                  class="button"
                  type="button"
                  onClick={context.storageSize.fetch}
                >
                  <Icon intent="refresh" />
                </button>
              </div>
            ),
            (storage) => {
              const free =
                storage.storageLimitInBytes - storage.consumedStorageInBytes
              const percent = Math.round(
                (free / storage.storageLimitInBytes) * 100,
              )
              return (
                <div class="vspacer-s p-m">
                  <div class="flex justify-space-between">
                    <span class="text-left">{t('storage')}</span>
                    <span>{percent}%</span>
                  </div>
                  <Progress
                    class="flex-1 rounded-s"
                    barClass="rounded-s"
                    value={storage.consumedStorageInBytes}
                    max={storage.storageLimitInBytes}
                    height="0.5rem"
                  />
                </div>
              )
            },
          ),
        )}
      </div>
    </div>
  )
}
export const NavItemBurgerOption = (
  props: Except<ComponentProps<'div'>, 'children'> & {
    icon: JSX.Element
    label: JSX.Element
  },
) => {
  const [, rest] = splitProps(props, ['icon', 'label'])
  return (
    <div
      {...rest}
      role="option"
      tabIndex={0}
      class={cx(
        'w-100 lg pointer flex justify-space-between align-center hspacer-m',
        props.class,
      )}
    >
      {props.icon}
      <span>{props.label}</span>
      <span />
    </div>
  )
}

export const MainNav = {
  Slot: NavSlot,
  Home: NavItemHome,
  Back: NavItemBack,
  AddTrip: NavItemAddTrip,
  Burger: NavItemBurgerMenu,
  BurgerOption: NavItemBurgerOption,
}
