import { T } from '@/std/type'
import { JsonObject } from '@/std/types'
import { AccountId } from '@travellr/domain/core/authentication/entity/Account'

export type FileAddress = {
  accountId: AccountId
  fileName: string
  folder: string
}

const type = T.struct({
  accountId: AccountId,
  fileName: T.string,
  folder: T.string,
})

type Encoded = JsonObject
type Ord = { ord: typeof type.ord }

export const FileAddress: T.Type<FileAddress, Encoded> & Ord = type
