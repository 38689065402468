import { solidRemoteResource } from '@/solidjs/reactivity'
import { list } from '@/std/data'
import { pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Failure } from '@travellr/client/ui-kit/Failure.solid-js'
import { Loader } from '@travellr/client/ui-kit/Loader.solid-js'
import { Page } from '@travellr/client/ui-kit/Page.solid-js'
import { onCleanup } from 'solid-js'
import { HomeModel } from './page'

type Props = {
  model: HomeModel
}
export const Home = (props: Props) => {
  onCleanup(props.model.dispose)
  const trips = solidRemoteResource(props.model.trips)
  return (
    <Page
      contentClass="flex justify-center align-center"
      content={pipe(
        trips(),
        RR.map(list.tail),
        RR.fold2(
          () => <Loader />,
          (error) => <Failure error={error} />,
          () => <>Redirecting...</>,
        ),
      )}
    />
  )
}
