import pell from 'pell'

export const defaultPellActions: pell.pellAction[] = [
  'heading1',
  'heading2',
  'paragraph',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'link',
  'olist',
  'ulist',
]
