import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { Payload, contract } from './contract'

export type AddCheckpointAction = ReturnType<typeof AddCheckpointAction>
export const AddCheckpointAction = () => {
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const save = (body: Payload) => client({ body })
  return RemoteAction(save)
}
