// NOTE: The carousel should be based on crumbs received as dep.

import { O, list, number, string, struct } from '@/std/data'
import { flow, isInstanceOf, pipe } from '@/std/function'
import { ReadonlyState, State } from '@/std/reactivity'
import { TripCrumb } from './TripCrumbs'

export type TripCrumbsCarouselModel = {
  crumbs: ReadonlyState<TripCrumb[]>
  active: State<O.Option<TripCrumb>>
  dispose: () => void
  onContainerScrollEnd: (container: HTMLElement) => void
}

type Deps = {
  crumbs: ReadonlyState<TripCrumb[]>
  onActiveChanged: (next: TripCrumb) => void
}

export const TripCrumbsCarouselModel = ({ crumbs, onActiveChanged }: Deps) => {
  const model: TripCrumbsCarouselModel = {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },
    crumbs,
    active: State(O.fromNullable(crumbs()[0]), {
      equals: O.eq(struct.eq<TripCrumb>({ id: string.eq })).equals,
    }),
    onContainerScrollEnd: (parent) => {
      const scrollLeft = parent.scrollLeft
      pipe(
        Array.from(parent.children),
        list.filter(isInstanceOf(HTMLElement)),
        list.map((child, index) => {
          const offsetLeft = child.offsetLeft
          const diff = Math.abs(scrollLeft - offsetLeft)
          return { diff, index }
        }),
        list.sort(struct.ord('diff', number.ord)),
        list.head,
        O.flatMap(({ index }) => O.fromNullable(crumbs()[index])),
        model.active.set,
      )
    },
  }

  const effects = [
    crumbs.onChange(flow(list.head, model.active.set)),
    model.active.onChange((next) => {
      const current = document.querySelector('#map .focused')
      current?.classList.remove('focused')
      if (O.isNone(next)) return

      const markerNode = document
        .querySelector(`#map-crumb-${next.value.id}`)
        ?.closest('.map-icon')
      markerNode?.classList.add('focused')

      const carouselNode = document.querySelector(
        `#carousel-crumb-${next.value.id}`,
      )
      carouselNode?.scrollIntoView({ behavior: 'smooth' })

      onActiveChanged(next.value)
    }),
  ]

  return model
}
