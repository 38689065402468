import { O } from '@/std/data'
import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { Opaque } from 'type-fest'
import { Location } from './Location'
import { TripId } from './Trip'

export type NoteId = Opaque<string, 'NoteId'>
export type Note = {
  id: NoteId
  tripId: TripId
  from: Date
  until: Date
  location: O.Option<Location>
  content: string
}

const makeId = cast<string, NoteId>
export const NoteId = pipe(T.string, T.map(makeId, String))

const type = T.struct({
  id: NoteId,
  tripId: TripId,
  from: T.union(T.Date, T.DateFromString),
  until: T.union(T.Date, T.DateFromString),
  location: T.option(Location),
  content: T.string,
})

type Ord = { ord: typeof type.ord }

export const Note: T.Type<Note> & Ord = type
