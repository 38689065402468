import { makeStorage } from '@/std/browser'
import { C } from '@/std/codec'
import { Cookie, O, struct } from '@/std/data'
import { flow, lazy, pipe } from '@/std/function'
import { computed } from '@/std/reactivity'
import { ErrorBoundary } from '@travellr/client/ErrorBoundary'
import { getClientContext } from '@travellr/client/context'
import { AuthApp } from '@travellr/domain/core/authentication/client/client'
import { pathTo } from '@travellr/domain/core/authentication/client/client-router'
import { Account } from '@travellr/domain/core/authentication/entity/Account'
import { Profile } from '@travellr/domain/core/authentication/entity/Profile'
import { PersistCredentials } from '@travellr/domain/core/authentication/use-case/persist-credentials/client'
import { GuestApp } from '@travellr/domain/core/guest/client/main'
import { SandboxApp } from '@travellr/domain/core/sandbox/client'
import { TravellrApp } from '@travellr/domain/core/travellr/client/main'
import { registerMetrics } from './telemetry.metrics'
import { VersionWatcher } from './version-watcher'

export type App = ReturnType<typeof App>

export const App = () => {
  const { config, history } = getClientContext()
  const { account, profile, persist, clear } = PersistCredentials({
    storage: makeStorage({
      type: config.authStorage,
      Codec: C.struct({ account: Account, profile: Profile }),
    }),
  })
  const authData = computed([account, profile], (account, profile) =>
    O.struct({ account, profile }),
  )
  const effects = [authData.onChange(O.map(persist))]

  const signOut = () => {
    account.set(O.None())
    profile.set(O.None())
    clear()
    document.cookie = Cookie.remove('token')
    history.replace(pathTo.sendLinkToSignin())
  }

  registerMetrics({
    accountId: flow(account, O.map(struct.lookup('id'))),
  })

  const guestApp = GuestApp()
  const sandboxApp = SandboxApp()

  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },
    errorBoundary: ErrorBoundary({ account }),
    versionWatcher: VersionWatcher(),
    subApp: computed(
      [guestApp.route, sandboxApp.route, authData],
      (guestRoute, sandboxRoute, authData): SubApp =>
        pipe(
          guestRoute,
          O.map((): SubApp => ({ app: 'guest', model: guestApp })),
          O.or(() =>
            pipe(
              sandboxRoute,
              O.map((): SubApp => ({ app: 'sandbox', model: sandboxApp })),
            ),
          ),
          O.unwrapOr(() =>
            pipe(
              authData,
              O.fold(
                (): SubApp => ({
                  app: 'auth',
                  model: AuthApp({ account, profile }),
                }),
                (init): SubApp => ({
                  app: 'travellr',
                  model: lazy(() => {
                    return TravellrApp({
                      account: init.account,
                      profile: init.profile,
                      onAccountChange: flow(O.Some, account.set),
                      onProfileChange: flow(O.Some, profile.set),
                      signOut,
                    })
                  }),
                }),
              ),
            ),
          ),
        ),
      (a, b) => a.app === b.app,
    ),
  }
}

type SubApp =
  | { app: 'guest'; model: GuestApp }
  | { app: 'sandbox'; model: SandboxApp }
  | { app: 'auth'; model: AuthApp }
  | { app: 'travellr'; model: () => TravellrApp }
