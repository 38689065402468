import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { Dialog } from '@travellr/client/ui-kit/Dialog.solid-js'
import { AddCotravellr } from '../../../use-case/add-cotravellr/client.solid-js'
import { Cotravellrs } from '../../../use-case/list-cotravellrs/client.solid-js'
import { TripModel } from './main'

type Props = {
  model: TripModel
}

export const CotravellrsModal = (props: Props) => {
  const model = solidState(props.model.cotravellrsModal)
  const hide = () => model.set(O.None())

  return (
    <>
      {pipe(
        model(),
        O.fold(constNull, (model) => (
          <Dialog onClose={hide}>
            <AddCotravellr model={model.add} />
            <hr />
            <Cotravellrs model={model.list} />
          </Dialog>
        )),
      )}
    </>
  )
}
