import { O, R } from '../data'
import { flow, identity, panic, pipe } from '../function'
import { PendingEvent } from '../http'
import * as RD from './remote-data'

export type RemoteResult<E, T> = RD.RemoteData<R.Result<E, T>>
export type Ok<T> = RD.Done<R.Ok<T>>
export type Err<E> = RD.Done<R.Err<E>>
// export type InferOk<T> = T extends Ok<infer U> ? U : never
// export type InferErr<T> = T extends Err<infer E> ? E : never
// export type Pending<E> = RD.Done<R.Err<E>>

export const Err: <E, T>(error: E) => RemoteResult<E, T> = flow(
  R.Err,
  RD.Done,
) as any
export const Ok: <E, T>(value: T) => RemoteResult<E, T> = flow(
  R.Ok,
  RD.Done,
) as any
export const NotAsked: <E, T>() => RemoteResult<E, T> = RD.NotAsked
export const Pending: <E, T>(
  stale?: R.Result<E, T> | O.Option<R.Result<E, T>>,
  event?: PendingEvent | O.Option<PendingEvent>,
) => RemoteResult<E, T> = RD.Pending as any
export const PendingOk = <E, T>(
  stale: T,
  event?: PendingEvent,
): RemoteResult<E, T> => Pending(R.Ok<T, E>(stale), event)
export const PendingErr = <E, T>(
  stale: E,
  event?: PendingEvent,
): RemoteResult<E, T> => Pending(R.Err<E, T>(stale), event)

type FromResult = <E, T>(result: R.Result<E, T>) => RemoteResult<E, T>
export const fromResult = R.fold(Err, Ok) as FromResult

export const isNotAsked = RD.isNotAsked
export const isPending = RD.isPending
export const isErr = <E, T>(rr: RemoteResult<E, T>) =>
  RD.isDone(rr) && R.isErr(rr.value)
export const isOk = <E, T>(rr: RemoteResult<E, T>): rr is Ok<T> =>
  RD.isDone(rr) && R.isOk(rr.value)

export const fold = <
  E,
  T,
  WhenNotAsked,
  WhenPending = WhenNotAsked,
  WhenErr = WhenNotAsked,
  WhenOk = WhenNotAsked,
>(
  notAsked: () => WhenNotAsked,
  pending: (
    stale: O.Option<R.Result<E, T>>,
    event: O.Option<PendingEvent>,
  ) => WhenPending,
  failure: (error: E) => WhenErr,
  success: (value: T) => WhenOk,
) =>
  RD.fold<R.Result<E, T>, WhenNotAsked, WhenPending, WhenErr | WhenOk>(
    notAsked,
    pending,
    R.fold(failure, success),
  )

export const fold2 = <E, T, WhenPendingAndEmpty, WhenFailure, WhenOkOrStale>(
  notAskedOrPendingAndEmpty: (pending: boolean) => WhenPendingAndEmpty,
  failure: (error: E) => WhenFailure,
  okOrStale: (value: T, isStale: boolean) => WhenOkOrStale,
) =>
  RD.fold2(
    () => notAskedOrPendingAndEmpty(false),
    () => notAskedOrPendingAndEmpty(true),
    (result: R.Result<E, T>, isStale) =>
      pipe(
        result,
        R.fold(failure, (value) => okOrStale(value, isStale)),
      ),
  )

export const map = <E, A, B>(fn: (a: A) => B) =>
  RD.map<R.Result<E, A>, R.Result<E, B>>(R.map(fn))
export const mapErr = <A, B, T>(fn: (a: A) => B) =>
  RD.map(R.mapErr<A, T, B>(fn))
export const flatMap = <E1, T1, E2, T2>(
  fn: (value: T1) => RemoteResult<E1 | E2, T2>,
) => RD.flatMap(fold(NotAsked, Pending, Err, fn))

export const mapResult = <E1, T1, E2 = E1, T2 = T1>(
  fn: (value: T1) => R.Result<E1 | E2, T2>,
) => RD.map(R.flatMap(fn))
export const or = <E1, T1, E2, T2>(
  fallback: (err: E1) => RemoteResult<E1 | E2, T2>,
) =>
  fold<E1, T1, RemoteResult<E1 | E2, T1 | T2>>(NotAsked, Pending, fallback, Ok)

export const unwrapOr = <T, U>(fallback: () => U) =>
  fold<any, T, T | U>(fallback, fallback, fallback, identity)
export const unwrap = unwrapOr(() =>
  panic(new Error('RemoteResult is not Ok')),
) as <T>(r: RemoteResult<any, T>) => T
export const unwrapErrOr = <E1, E2>(fallback: () => E2) =>
  fold(fallback, fallback, identity<E1>, fallback)
export const unwrapErr = unwrapErrOr(() =>
  panic(new Error('RemoteResult is not Err')),
)
export const toOption = fold2(O.None, O.None, O.Some) as <T>(
  rr: RemoteResult<any, T>,
) => O.Option<T>
export const toUndefined = flow(toOption, O.toUndefined)

export const tuple = <
  RemoteResults extends [RemoteResult<any, any>, ...RemoteResult<any, any>[]],
>(
  remoteResults: RemoteResults,
) => pipe(remoteResults, RD.tuple, RD.map(R.tuple))

export const list = tuple as <E, T>(
  remoteResults: RemoteResult<E, T>[],
) => RemoteResult<E, T[]>

const remoteResultEq = flow(R.eq, RD.eq)
export { remoteResultEq as eq }

// declare const r1: RemoteResult<string, string>;
// declare const r2: RemoteResult<Error, number>;
// declare const r3: RemoteResult<Error, boolean>;
// declare const list: Array<RemoteResult<string, string> | RemoteResult<Error, number> | RemoteResult<Error, boolean>>
// const test = RemoteResult.all([r1, r2, r3]);
// const a = RemoteResult.all(list)
