export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'pickLocation': 'Pick a location',
  'captionPlaceholder': 'By the pool, at the beach…',
} as const

const fr = {
  'pickLocation': 'Choisir un point sur la carte',
  'captionPlaceholder': 'À la piscine, à la plage…',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
