import { Email, O, date } from '@/std/data'
import { pipe } from '@/std/function'
import { PendingEvent } from '@/std/http'
import Chance from 'chance'
import { Account, AccountId } from './authentication/entity/Account'
import { Profile, ProfileId } from './authentication/entity/Profile'
import { Checkpoint, CheckpointId } from './travellr/entity/Checkpoint'
import { Cotravellr } from './travellr/entity/Cotravellr'
import { FullTrip } from './travellr/entity/FullTrip'
import { Location } from './travellr/entity/Location'
import { Note, NoteId } from './travellr/entity/Note'
import { Picture, PictureId } from './travellr/entity/Picture'
import { Trip, TripId } from './travellr/entity/Trip'

export type Mock = ReturnType<typeof makeMock>

export const makeMock = (seed: Chance.Seed) => {
  const chance = new Chance(seed)

  const id = <T extends string>() => chance.guid({ version: 4 }) as T
  const dateFromNowBetween = (daysMin: number, daysMax: number) =>
    chance.date({
      min: pipe(date.now(), date.addDays(daysMin)),
      max: pipe(date.now(), date.addDays(daysMax)),
    }) as Date

  const mocks = {
    chance,

    email: () => chance.email() as Email,

    account: (parts?: Partial<Account>): Account => ({
      createdAt: dateFromNowBetween(-60, -30),
      email: mocks.email(),
      id: id<AccountId>(),
      storageLimitInBytes: 1_000_000,
      lastUpdate: dateFromNowBetween(-30, -10),
      termsAcceptedAt: O.Some(dateFromNowBetween(-60, -30)),
      ...parts,
    }),

    profile: (parts?: Partial<Profile>): Profile => ({
      accountId: id<AccountId>(),
      id: id<ProfileId>(),
      name: chance.name(),
      ...parts,
    }),

    location: (parts?: Partial<Location>): Location => ({
      // Paris
      lat: chance.latitude({ min: 48.825821, max: 48.895415 }),
      lng: chance.longitude({ min: 2.269515, max: 2.405836 }),
      ...parts,
    }),

    checkpoint: (parts?: Partial<Checkpoint>): Checkpoint => ({
      id: id<CheckpointId>(),
      location: mocks.location(),
      timestamp: dateFromNowBetween(-15, -1),
      tripId: id<TripId>(),
      ...parts,
    }),

    cotravellr: (parts?: Partial<Cotravellr>): Cotravellr => ({
      selfTripId: id<TripId>(),
      peerTripId: id<TripId>(),
      from: O.None(),
      until: O.None(),
      ...parts,
    }),

    note: (parts?: Partial<Note>): Note => ({
      id: id<NoteId>(),
      tripId: id<TripId>(),
      from: dateFromNowBetween(-10, -5),
      until: dateFromNowBetween(-10, -1),
      location: O.Some(mocks.location()),
      content: chance.paragraph(),
      ...parts,
    }),

    picture: (parts?: Partial<Picture>): Picture => ({
      id: id<PictureId>(),
      tripId: id<TripId>(),
      timestamp: chance.bool({ likelihood: 90 })
        ? O.Some(dateFromNowBetween(-15, -1))
        : O.None(),
      location: chance.bool({ likelihood: 70 })
        ? O.Some(mocks.location())
        : O.None(),
      caption: chance.bool() ? O.None() : O.Some(chance.sentence({ words: 7 })),
      storage: 'inMemory',
      sizeInBytes: 0,
      ...parts,
    }),

    trip: (parts?: Partial<Trip>): Trip => ({
      id: id<TripId>(),
      accountId: id<AccountId>(),
      name: `${chance.city()}, ${chance.country()}`,
      createdAt: dateFromNowBetween(-17, -15),
      imageStorage: 'inMemory',
      ...parts,
    }),

    fullTrip: (options?: {
      pictures?: number
      checkpoints?: number
      notes?: number
      cotravellrs?: number
    }): FullTrip => {
      const countOf = {
        pictures: options?.pictures ?? chance.natural({ min: 0, max: 10 }),
        checkpoints: options?.checkpoints ?? chance.natural({ min: 0, max: 3 }),
        notes: options?.notes ?? chance.natural({ min: 0, max: 3 }),
        cotravellrs: options?.cotravellrs ?? chance.natural({ min: 0, max: 2 }),
      }
      // const account = mocks.account()
      // const profile = mocks.profile({ accountId: account.id })
      // const trip = mocks.trip({ accountId: account.id })
      const trip = mocks.trip()
      const pictures = new Array(countOf.pictures)
        .fill(null)
        .map(() => mocks.picture({ tripId: trip.id }))
      const checkpoints = new Array(countOf.checkpoints)
        .fill(null)
        .map(() => mocks.checkpoint({ tripId: trip.id }))
      const notes = new Array(countOf.notes)
        .fill(null)
        .map(() => mocks.note({ tripId: trip.id }))
      const cotravellrs = new Array(countOf.cotravellrs).fill(null).map(() => {
        const cotravellr = mocks.cotravellr({ selfTripId: trip.id })
        const { cotravellrs, ...fullTrip } = mocks.fullTrip({
          ...options,
          cotravellrs: 0,
        })
        return [
          chance.name(),
          { ...fullTrip, from: cotravellr.from, until: cotravellr.until },
        ] as const
      })

      return {
        trip,
        pictures,
        checkpoints,
        notes,
        cotravellrs: Object.fromEntries(cotravellrs),
      }
    },

    imageUrl: (seed: string) =>
      `https://picsum.photos/seed/${seed}/${chance.natural({
        min: 400,
        max: 1200,
      })}/${chance.natural({ min: 400, max: 1200 })}`,

    pendingEvent: () =>
      ({
        loaded: chance.natural({ min: 0, max: 100 }),
        total: 100,
      } as PendingEvent),
  }
  return mocks
}
