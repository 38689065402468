import { date } from '@/std/data'
import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { Opaque, PartialDeep } from 'type-fest'
import { Location, mockLocation } from './Location'
import { TripId } from './Trip'

export type CheckpointId = Opaque<string, 'CheckpointId'>
export type Checkpoint = {
  id: CheckpointId
  location: Location
  timestamp: Date
  tripId: TripId
}

const makeId = cast<string, CheckpointId>
export const CheckpointId = pipe(T.string, T.map(makeId, String))

export const CheckpointStruct = {
  id: CheckpointId,
  tripId: TripId,
  timestamp: T.union(T.Date, T.DateFromString),
  location: Location,
}
const type = T.struct(CheckpointStruct)
type Ord = { ord: typeof type.ord }
export const Checkpoint: T.Type<Checkpoint> & Ord = type

export const mockCheckpoint = (
  parts?: PartialDeep<Checkpoint>,
): Checkpoint => ({
  id: parts?.id ?? (`checkpoint-id` as CheckpointId),
  location: mockLocation(parts?.location),
  timestamp: parts?.timestamp ?? pipe(date.now(), date.addDays(-12)),
  tripId: parts?.tripId ?? ('trip-id' as TripId),
})
