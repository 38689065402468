import 'leaflet-spline'
import { onCleanup, onMount } from 'solid-js'
import { TripLeafletMapModel } from './TripLeafletMap'
import './TripLeafletMap.css'

type Props = {
  model: TripLeafletMapModel
}
export const TripLeafletMap = (props: Props) => {
  onCleanup(props.model.dispose)

  onMount(() => {
    props.model.mapElement.id = 'map'
    props.model.mapElement.className = 'h-100 w-100 overflow-hidden'
    props.model.init()
  })

  return props.model.mapElement
}
