// @ts-check
import { pipe } from '../function'
import * as list from './list'
import { string } from './string'

export type CookieOptions = {
  name: string
  value: string
  expires?: Date
  maxAgeInS?: number
  domain?: string
  path?: string
  secure?: boolean
  httpOnly?: boolean
  sameSite?: 'Strict' | 'Lax' | 'None'
}

export const Cookie = {
  serialize: ({ name, value, ...options }: CookieOptions) =>
    [
      `${name}=${value}`,
      options.expires && `Expires=${options.expires.toUTCString()}`,
      options.maxAgeInS && `Max-Age=${options.maxAgeInS}`,
      options.domain && `Domain=${options.domain}`,
      `Path=${options.path ?? '/'}`,
      options.sameSite && `SameSite=${options.sameSite}`,
      options.secure && 'Secure',
      options.httpOnly && 'HttpOnly',
    ]
      .filter(Boolean)
      .join(';'),

  parse: (cookies: string): Record<string, string> =>
    pipe(
      cookies,
      string.split('; '),
      list.map(string.split('=')),
      Object.fromEntries,
    ),

  has: (cookies: string, name: string) => cookies.includes(`${name}=`),

  remove: (name: string) =>
    Cookie.serialize({ name, value: '', expires: new Date(0) }),
}
