import { C } from '../codec'
import { HttpMethod, MimeType } from '../http'
import { ApiResponse } from './contract'
import { PathParameters } from './type-utils'

export type ApiRouteContract<
  Method extends HttpMethod = HttpMethod,
  Path extends string = string,
> = {
  readonly method: Method
  readonly path: Path
  readonly params?: C.Codec<any, PathParameters<Path>>
  readonly searchParams?: C.Codec<any, Record<string, any>>
  readonly headers?: C.Codec<any, Record<string, any>>
  readonly body?: {
    readonly codec: C.Codec<any, any>
    readonly contentType: MimeType
  }
  readonly response: ApiResponse<any> & {
    readonly headers?: C.Codec<any, Record<string, any>>
  }
}
export const ApiRouteContract = <
  Method extends HttpMethod,
  Path extends string,
  Config extends Omit<ApiRouteContract<Method, Path>, 'method' | 'path'>,
>(
  method: Method,
  path: Path,
  config: Config,
): {
  [Key in keyof ({ method: Method; path: Path } & Config)]: ({
    method: Method
    path: Path
  } & Config)[Key]
} =>
  ({
    method,
    path,
    ...config,
  } satisfies ApiRouteContract<Method, Path>)

// const client = FetchApiRouteClient(test2)
// client({
//   body: { test: 'toto' },
// })
