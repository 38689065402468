// @ts-check
import {
  constNull,
  constUndefined,
  flow,
  identity,
  panic,
  pipe,
} from '../function'
import { Eq } from './eq'
import { Ord } from './ord'
import type { Result } from './result'

export type Some<T> = { readonly tag: 'Some'; readonly value: T }
export type None = { readonly tag: 'None' }

export type Option<T> = None | Some<T>
export type Infer<O> = O extends None | infer S ? Flatten<S> : never
export type Flatten<O> = O extends Some<infer A> | Some<infer B> ? A | B : never

export const None = <T = never>(): Option<T> => ({ tag: 'None' })
export const Some = <T>(value: T): Option<T> => ({ tag: 'Some', value })

export const fromNullable = <T>(value: T): Option<NonNullable<T>> =>
  value === undefined || value === null ? None() : Some(value)

export const refine =
  <T extends Value, Value>(refiner: (value: Value) => value is T) =>
  (value: Value) =>
    refiner(value) ? Some(value) : None()

export const fromPredicate = refine as <T>(
  predicate: (value: T) => unknown,
) => (value: T) => Option<T>

export const fromResult = <T>(r: Result<any, T>) =>
  r.tag === 'Ok' ? Some(r.value) : None()

export const isOption = <T>(value: unknown): value is Option<T> =>
  !!value &&
  typeof value === 'object' &&
  'tag' in value &&
  (value.tag === 'None' || value.tag === 'Some')

export const isNone = <T>(o: Option<T>): o is None => o.tag === 'None'
export const isSome = <T>(o: Option<T>): o is Some<T> => o.tag === 'Some'

type Fold = <A, WhenNone, WhenSome = WhenNone>(
  onNone: () => WhenNone,
  onSome: (value: A) => WhenSome,
) => (o: Option<A>) => WhenNone | WhenSome
export const fold: Fold = (onNone, onSome) => (o) =>
  isSome(o) ? onSome(o.value) : onNone()

type UnwrapOr = <A, B>(fallback: () => B) => (o: Option<A>) => A | B
export const unwrapOr: UnwrapOr = (fallback) => fold(fallback, identity)
type Unwrap = <T>(option: Option<T>) => T
export const unwrap: Unwrap = unwrapOr(() => panic(new Error('Option is None')))
export const toUndefined = fold(constUndefined, identity)
export const toNull = fold(constNull, identity)

type Or = <A, B>(fallback: () => Option<B>) => (o: Option<A>) => Option<A | B>
export const or: Or = (fallback) => fold(fallback, Some)

type Map = <A, B = A>(fn: (value: A) => B) => (o: Option<A>) => Option<B>
export const map: Map = (fn) => fold(None, flow(fn, Some))

type FlatMap = <A, B>(
  fn: (value: A) => Option<B>,
) => (o: Option<A>) => Option<B>
export const flatMap: FlatMap = (fn) => fold(None, fn)

type ThenResult = <A, B>(
  fn: (value: A) => Result<any, B>,
) => (o: Option<A>) => Option<B>
export const mapResult: ThenResult = (mapper) =>
  flatMap(flow(mapper, fromResult))

type All = <Opts extends [Option<any>, ...Option<any>[]] | Option<any>[]>(
  options: Opts,
) => Option<{ [Key in keyof Opts]: Infer<Opts[Key]> }>
export const all: All = (options) =>
  options.reduce((acc, opt) => {
    if (isNone(acc)) return acc
    else if (isNone(opt)) return opt
    else
      return pipe(
        acc,
        map((options) => [...options, opt.value]),
      )
  }, Some([]))

type Struct = <Options extends Record<string, Option<any>>>(
  results: Options,
) => Option<{ [K in keyof Options]: Infer<Options[K]> }>
export const struct: Struct = (struct) =>
  pipe(
    Object.entries(struct).map(([key, option]) =>
      pipe(
        option,
        map((value) => [key, value]),
      ),
    ),
    all,
    map(Object.fromEntries),
  )

type FlattenFn = <T>(o: Option<T | Option<T>>) => Option<T>
export const flatten: FlattenFn = fold(None, (valueOrOption) =>
  isOption(valueOrOption) ? valueOrOption : Some(valueOrOption),
)

type Tap = <A>(fn: (value: A) => any) => (o: Option<A>) => Option<A>
export const tap: Tap = (fn) => map((value) => (fn(value), value))

export const ord = <T>(ord: Ord<T>) =>
  Ord.fromCompare<Option<T>>((a, b) => {
    if (isNone(a) && isNone(b)) return 0
    if (isNone(a) && isSome(b)) return -1
    if (isSome(a) && isNone(b)) return 1
    if (isSome(a) && isSome(b)) return ord.compare(a.value, b.value)
    return 0
  })

export const eq = <T>(eq: Eq<T>) =>
  Eq.fromEquals<Option<T>>(
    (a, b) =>
      (isNone(a) && isNone(b)) ||
      (isSome(a) && isSome(b) && eq.equals(a.value, b.value)),
  )
