import { O, list } from '@/std/data'
import { pipe } from '@/std/function'

export type Picture = {
  file: Blob
  caption: O.Option<string>
}

export const fileListToPictures = (fileList: FileList | null) =>
  pipe(
    fileList ? Array.from(fileList) : [],
    list.map(
      (file): Picture => ({
        file,
        caption: O.None<string>(),
      }),
    ),
  )
