import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { Show } from 'solid-js'
import { JSX } from 'solid-js/jsx-runtime'
import { CheckpointCrumb, NoteCrumb, PictureCrumb } from './TripCrumbs'
import { TripCrumbsCarousel } from './TripCrumbsCarousel.solid'
import { TripLeafletMap } from './TripLeafletMap.solid'
import { TripPageModel } from './TripPage'
import { TripPageLayout } from './TripPageLayout.solid'
import { TripRangeFilter } from './TripRangeFilter.solid'

type Props = {
  model: TripPageModel
  nav?: JSX.Element
  title: JSX.Element
  content?: JSX.Element
  onArchivePicture?: (crumb: PictureCrumb) => unknown
  onEditPicture?: (crumb: PictureCrumb) => unknown
  onArchiveCheckpoint?: (crumb: CheckpointCrumb) => unknown
  onAddNote?: (options: { from: Date; until: Date }) => unknown
  onEditNote?: (note: NoteCrumb) => unknown
  onViewNote?: (note: NoteCrumb) => unknown
}

export const TripPage = (props: Props) => {
  const range = solidState(props.model.range)
  return (
    <TripPageLayout
      content={
        <>
          <Show when={props.content}>{props.content}</Show>
          <Show when={!props.content}>
            <div class="flex column gap-s">
              <TripRangeFilter model={props.model.filtered} />
              <TripCrumbsCarousel
                model={props.model.carousel}
                range={O.toUndefined(range())}
                onArchivePicture={props.onArchivePicture}
                onEditPicture={props.onEditPicture}
                onArchiveCheckpoint={props.onArchiveCheckpoint}
                onAddNote={props.onAddNote}
                onEditNote={props.onEditNote}
                onViewNote={props.onViewNote}
              />
            </div>
          </Show>
        </>
      }
      map={<TripLeafletMap model={props.model.leafletMap} />}
      nav={props.nav}
      title={props.title}
    />
  )
}
