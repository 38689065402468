import { Exif } from '@/exif'
import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow, pipe } from '@/std/function'
import { TR } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { selfHostedContract } from '@travellr/domain/core/travellr/use-case/add-picture/self-hosted.contract'
import { config } from '../config'
import { PictureStore } from './PictureStores'

export const makePicturePostgresStore = () => {
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(selfHostedContract, xhr), justBody)
  const store = {
    get: (accountId, picture) =>
      `${config.apiUrl}/picture/original/postgres/${accountId}/${picture.tripId}/${picture.id}`,

    thumbnail: (accountId, picture) =>
      `${config.apiUrl}/picture/thumbnail/postgres/${accountId}/${picture.tripId}/${picture.id}`,

    upload: (payload, data, options) => {
      return pipe(
        TR.struct({
          location: Exif.getLocation(data),
          timestamp: Exif.getTimestamp(data),
        }),
        TR.flatMap(({ location, timestamp }) => {
          return client({
            body: data,
            searchParams: {
              ...payload,
              location,
              timestamp,
              storage: 'postgres',
            },
            headers: {
              'content-type': data.type,
              'content-length': data.size,
            },
            onProgress: options.onProgress,
          })
        }),
      )
    },
  } satisfies PictureStore
  return store
}
