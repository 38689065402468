import { JsonWebTokenCodec } from '@/jsonwebtoken'
import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { struct } from '@/std/data'
import { pipe } from '@/std/function'
import { MimeType } from '@/std/http'
import { Account } from '../../entity/Account'
import { AuthCodeStruct } from '../../entity/AuthCode'
import { Profile } from '../../entity/Profile'

export const contract = ApiRouteContract('POST', '/sign-in', {
  body: {
    contentType: MimeType.Json,
    codec: pipe(AuthCodeStruct, struct.pick('accountId', 'code'), C.struct),
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: C.struct({
      account: Account,
      profile: C.Option(Profile),
      token: JsonWebTokenCodec,
    }),
  },
})
