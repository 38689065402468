import { O, R, TupleOf } from '../data'
import { cnst, flow, pipe, toUnknownError } from '../function'
import * as T from './task'

export type TaskResult<E, T> = T.Task<R.Result<E, T>>

export type InferErr<T> = T extends TaskResult<infer E, any> ? E : never
export type InferOk<T> = T extends TaskResult<any, infer P> ? P : never

// Constructors
export const Ok = flow(R.Ok, T.make)
export type Ok<E, T> = typeof Ok<E, T>

export const Err = flow(R.Err, T.make)
export type Err<E, T> = typeof Err<E, T>

export const Pending = <E, T>() => T.new(() => {}) as TaskResult<E, T>
export type Pending<E, T> = typeof Pending<E, T>

const tryCatch =
  <T, E = Error>(
    fn: () => Promise<T>,
    mapErr: (err: unknown) => E = toUnknownError as any,
  ): TaskResult<E, T> =>
  async () => {
    try {
      return R.Ok(await fn())
    } catch (e) {
      return R.Err(mapErr(e))
    }
  }
export { tryCatch as try }

type PromiseFactory = (...args: any[]) => Promise<any>
export const tryFn = <F extends PromiseFactory>(fn: F) =>
  flow(fn, (p) => tryCatch(() => p)) as (
    ...args: Parameters<F>
  ) => TaskResult<unknown, Awaited<ReturnType<F>>>

export const fromResult = <E, T>(r: R.Result<E, T>): TaskResult<E, T> =>
  T.make(r)
export const fromOption = <E, T>(onNone: () => E) =>
  flow(R.fromOption<E, T>(onNone), fromResult)
export const fromNullable = <E, T>(onNone: () => E) =>
  flow(R.fromNullable(onNone), fromResult<E, NonNullable<T>>)

const New =
  <E, T>(
    fn: (resolve: (value: T) => void, reject: (err: E) => void) => void,
  ): TaskResult<E, T> =>
  () =>
    new Promise((resolve) => fn(flow(R.Ok, resolve), flow(R.Err, resolve)))
export { New as new }

// Combinators
export const map = <E, A, B>(fn: (value: A) => B) => T.map(R.map<E, A, B>(fn))
export const mapErr = <E1, T1, E2>(fn: (e: E1) => E2) =>
  T.map(R.mapErr<E1, T1, E2>(fn))

export const run =
  <T, E = never>(fn: () => T): TaskResult<E, T> =>
  () =>
    Promise.resolve(R.Ok(fn()))

export const flatMap = <E1, T1, E2, T2>(
  fn: (value: T1) => TaskResult<E2, T2>,
) =>
  T.flatMap<R.Result<E1, T1>, R.Result<E1 | E2, T2>>(
    R.fold(Err<E1 | E2, T2>, fn),
  )

export const mapResult = <T1, E2, T2>(fn: (value: T1) => R.Result<E2, T2>) =>
  T.map(R.flatMap(fn)) as <E1>(
    tr: TaskResult<E1, T1>,
  ) => TaskResult<E1 | E2, T2>

export const mapOption = <Value, E, E1, T1, E2, T2>(
  onNone: () => TaskResult<E1, T1>,
  onSome: (value: Value) => TaskResult<E2, T2>,
) =>
  flatMap(O.fold(onNone, onSome) as any) as (
    tr: TaskResult<E, O.Option<Value>>,
  ) => TaskResult<E | E1 | E2, T1 | T2>

export const flatTap = <E1, T1, E2>(fn: (value: T1) => TaskResult<E2, any>) =>
  flatMap<E1, T1, E2, T1>((value) => pipe(fn(value), map(cnst(value))))
export const flatTapErr = <E1, T1, E2>(
  fn: (value: E1) => TaskResult<E2, any>,
) => or<E1, T1, E1, T1>((error) => pipe(fn(error), mapErr(cnst(error))))
export const tap = <E, T>(fn: (value: T) => unknown) =>
  flatTap<E, T, E>(flow(fn, Ok))
export const tapErr = <E, T>(fn: (error: E) => unknown) =>
  flatTapErr<E, T, E>(flow(fn, Ok))

export const or = <E1, T1, E2, T2>(fn: (error: E1) => TaskResult<E2, T2>) =>
  T.flatMap<R.Result<E1, T1>, R.Result<E2, T1 | T2>>(
    R.fold(fn, Ok<T1 | T2, E2>),
  )

export const fold = <E, T, A extends T.Task<any>, B extends T.Task<any>>(
  onErr: (e: E) => A,
  onOk: (value: T) => B,
) => T.flatMap(R.fold(onErr, onOk))

export const match = <E, T, A, B>(onErr: (e: E) => A, onOk: (value: T) => B) =>
  T.map(R.fold(onErr, onOk))

export const sequence =
  <E, T>(tasks: TaskResult<E, T>[]): TaskResult<E, T[]> =>
  async () => {
    const thunk: T[] = []
    for (const task of tasks) {
      const result = await task()
      if (R.isOk(result)) thunk.push(result.value)
      else return R.Err(result.error)
    }
    return R.Ok(thunk)
  }

export const list = flow(T.list, T.map(R.list)) as <E, T>(
  tasks: TaskResult<E, T>[],
) => TaskResult<E, T[]>

export const tuple = list as <
  TaskResults extends TupleOf<TaskResult<any, any>>,
>(
  taskResults: TaskResults,
) => TaskResult<
  { [K in keyof TaskResults]: InferErr<TaskResults[K]> }[number],
  { [K in keyof TaskResults]: InferOk<TaskResults[K]> }
>

const makeStruct =
  (taskStruct: typeof T.struct) =>
  <S extends { [Key: string]: TaskResult<any, any> }>(
    struct: S,
  ): TaskResult<
    { [Key in keyof S]: InferErr<S[Key]> }[keyof S],
    { [Key in keyof S]: InferOk<S[Key]> }
  > =>
    pipe(taskStruct(struct), T.map(R.struct) as any)

export const struct = makeStruct(T.struct)
export const structSeq = makeStruct(T.structSeq)

// declare const f1: Future<Result<Error, string>>;
// declare const f2: Future<Result<Error, number>>;
// declare const f3: Future<Result<Error, boolean>>;
// const t = Future.all([f1, f2, f3]);

// declare const fr1: FutureResult<Error, string>;
// declare const fr2: FutureResult<Error, number>;
// declare const fr3: FutureResult<Error, boolean>;
// declare const list: Array<FutureResult<Error, string> | FutureResult<Error, number> | FutureResult<Error, boolean>>
// const test = FutureResult.all([f1, f2, f3])
// const a = FutureResult.all(list)
