import { O } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { I18n } from '@travellr/client/i18n/I18n'
import { Dialog } from '@travellr/client/ui-kit/Dialog.solid-js'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { Show, createSignal } from 'solid-js'
import { Trip } from '../../../entity/Trip'
import { DialogContent } from './DialogContent.solid'
import { messages } from './i18n'
import { ShareTripModel } from './model'

type Props = {
  trip: O.Option<Trip>
}

export const ShareTripButton = (props: Props) => {
  const { t } = I18n(messages)
  const [isShareDetailOpened, toggleShareDetailOpened] = createSignal(false)
  return (
    <>
      <button
        class="button h5"
        onClick={() => toggleShareDetailOpened(true)}
        disabled={O.isNone(props.trip)}
      >
        <Icon intent="share" />
      </button>

      {pipe(
        props.trip,
        O.fold(constNull, (trip) => (
          <Show when={isShareDetailOpened()}>
            <Dialog
              dialogClass="vspacer-l p-m"
              onClose={() => toggleShareDetailOpened(false)}
            >
              <header class="hspacer-m text-center">
                <h5 class="inline-block">{t('dialog.heading')}</h5>
                <Icon class="h5" intent="shareTrip" />
              </header>
              <hr />
              <DialogContent model={ShareTripModel({ tripId: trip.id })} />
              <hr />
              <footer class="flex justify-end">
                <button
                  class="button"
                  onClick={() => toggleShareDetailOpened(false)}
                >
                  {t('verb/close')}
                </button>
              </footer>
            </Dialog>
          </Show>
        )),
      )}
    </>
  )
}
