import { constNull, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { JSX } from 'solid-js'
import { Dialog } from './Dialog.solid-js'
import { Failure } from './Failure.solid-js'
import { defaultTriggerSuccessDelay } from './FormSubmitState.solid-js'
import { Icon } from './Icon.solid-js'
import { Loader } from './Loader.solid-js'

export type FormSubmitStateOverlayProps<E> = {
  onTryAgain?: () => void
  onClose: () => void
  onSuccess: () => void
  triggerSuccessDelay?: number
  submitState: RR.RemoteResult<E, unknown>
  render?: (
    defaultRender: (error: E) => JSX.Element,
  ) => (error: E) => JSX.Element
}

export const FormSubmitStateOverlay = <E extends unknown>(
  props: FormSubmitStateOverlayProps<E>,
) => {
  const { t } = I18n({ fr: {}, en: {} })
  const defaultRender = (error: E) => (
    <Failure error={error} onTryAgain={props.onTryAgain} />
  )
  return (
    <>
      {pipe(
        props.submitState,
        RR.fold(
          constNull,
          () => (
            <Overlay>
              <Loader skin="inverted" />
            </Overlay>
          ),
          (error) => (
            <Dialog dialogClass="p-m vspacer-m">
              {(props.render?.(defaultRender) ?? defaultRender)(error)}
              <hr />
              <footer class="flex justify-end">
                <button class="discrete button" onClick={props.onClose}>
                  {t('verb/close')}
                </button>
              </footer>
            </Dialog>
          ),
          () => {
            setTimeout(
              () => props.onSuccess(),
              props.triggerSuccessDelay ?? defaultTriggerSuccessDelay,
            )
            return (
              <Overlay>
                <Icon intent="success" class="h1 feedback inverted" />
              </Overlay>
            )
          },
        ),
      )}
    </>
  )
}

const Overlay = (props: { class?: string; children: JSX.Element }) => (
  <div class="absolute backdrop opacity-6 top left w-100 h-100 flex justify-center align-center p-m">
    {props.children}
  </div>
)
