import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteActionToConfirm } from '@/std/remote/remote-action'
import { getClientContext } from '@travellr/client/context'
import { Payload, contract } from './contract'

export const ArchivePicture = () => {
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const archive = (body: Payload) => client({ body })
  return archive
}

export type ArchivePictureAction = ReturnType<typeof ArchivePictureAction>
export const ArchivePictureAction = () => {
  return RemoteActionToConfirm(ArchivePicture())
}
