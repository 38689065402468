export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'filter/show-all': 'All',
  'filter/show-unlocated': 'Unlocated only',
  'no-pictures': 'No pictures',
  'confirm/label': 'Are you sure to update this picture?',
  'confirm/yes': 'I am',
  'confirm/no': 'No',
} as const

const fr = {
  "filter/show-all": 'Toutes les images',
  "filter/show-unlocated": 'Images non-localisées seulement',
  'no-pictures': 'Aucune image',
  'confirm/label': 'Sûr-e, on met à jour cette image?',
  'confirm/yes': 'Sûr-e',
  'confirm/no': 'Non',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
