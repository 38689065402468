import { solidState } from '@/solidjs/state'
import { RD } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { Control } from '@travellr/client/ui-kit/Control.solid-js'
import { FormSubmitState } from '@travellr/client/ui-kit/FormSubmitState.solid-js'
import { Page } from '../../ui-kit/Page.solid-js'
import { SendLinkToSignin as Model } from './client'
import { messages } from './client.i18n'

type Props = {
  model: Model
}

export const SendLinkToSignin = (props: Props) => {
  const { t } = I18n(messages)
  const { submit, onSuccess, reset } = props.model
  const submitState = solidState(props.model.submitState)
  const email = solidState(props.model.email)
  const typeMismatch = solidState(props.model.typeMismatch)
  const valueMissing = solidState(props.model.valueMissing)

  return (
    <Page>
      <div class="relative maxw-100 p-m">
        <form
          onSubmit={submit}
          hidden={RD.isDone(submitState())}
          class="vspacer-m"
        >
          <h1 class="text-center pb-l">{t('tagline')}</h1>
          <div class="box p-m">
            <Control
              label={t('control/email.label')}
              control={
                <input
                  class="input"
                  type="email"
                  onInput={(event) => email.set(event.currentTarget.value)}
                  value={email()}
                />
              }
              feedback={
                <>
                  {typeMismatch() && t('control/email.invalid')}
                  {valueMissing() && t('control/email.required')}
                </>
              }
            />
          </div>
          <div class="box p-m text-center">
            <button
              class="button"
              type="submit"
              disabled={RD.isPending(submitState())}
            >
              {t('submit')}
            </button>
          </div>
        </form>

        <FormSubmitState
          submitState={submitState()}
          onSuccess={onSuccess}
          onTryAgain={reset}
          triggerSuccessDelay={100}
        />
      </div>
    </Page>
  )
}
