import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { preventDefault } from '@/std/browser'
import { O } from '@/std/data'
import { FormControl, FormGroup, nonEmpty, notEqual } from '@/std/form-control'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { Cotravellr } from '../../entity/Cotravellr'
import { TripId } from '../../entity/Trip'
import { contract } from './contract'

type Init = {
  tripId: TripId
}

export type AddCotravellrModel = ReturnType<typeof AddCotravellrModel>
export const AddCotravellrModel = ({ tripId }: Init) => {
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const addCotravellr = (body: Cotravellr) => client({ body })
  const action = RemoteAction(addCotravellr)
  const controls = FormGroup({
    from: FormControl(O.None<Date>()),
    until: FormControl(O.None<Date>()),
    peerTripId: FormControl('' as string, [
      nonEmpty('required'),
      notEqual(tripId as string, 'ownToken'),
    ]),
  })

  const submit = preventDefault(() => {
    controls.markTouched()
    if (!controls.isValid()) return
    action.trigger({
      selfTripId: tripId,
      peerTripId: controls.peerTripId() as TripId,
      from: controls.from(),
      until: controls.until(),
    })
  })

  return {
    tripId,
    controls,
    submit,
    submitState: action.state,
    reset: action.reset,
  }
}
