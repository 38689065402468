import { makeStorage } from '@/std/browser'
import { C } from '@/std/codec'
import { R } from '@/std/data'
import { constFalse, flow, pipe } from '@/std/function'
import { State, readonly } from '@/std/reactivity'
import { getClientContext } from '@travellr/client/context'
import { CreateTripWithPictures } from '../../components/CreateTripWithPictures/main'
import { pathTo } from '../../router'

export type StartTrip = ReturnType<typeof StartTrip>
export const StartTrip = () => {
  const { history } = getClientContext()
  return {
    form: CreateTripWithPictures({
      picturesRequired: false,
      onSubmitSuccess: flow(pathTo.trip, history.push),
    }),
    instructions: Instructions(),
  }
}

const Instructions = () => {
  const key = 'hasReadInstructions'
  const storage = makeStorage({ type: 'session', Codec: C.bool })
  const stored = pipe(storage.get(key), R.unwrapOr(constFalse))
  const hasReadInstructions = State(stored)
  return {
    haveBeenRead: readonly(hasReadInstructions),
    markAsRead: () => {
      storage.set(key, true)
      hasReadInstructions.set(true)
    },
  }
}
