import { O } from './data'
import { panic, pipe } from './function'

export const Context = <T>(name: string) => {
  let context = O.None<T>()
  const provide = (provided: T) => {
    context = O.Some(provided)
  }

  const use = () =>
    pipe(
      context,
      O.unwrapOr(() => panic(`context "${name}" not provided`)),
    )

  const clear = () => {
    context = O.None()
  }
  return [provide, use, clear] as const
}
