export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  heading: 'Uploading Pictures 📸',
} as const

const fr = {
  heading: 'Téléchargement 📸',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
