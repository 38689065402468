import { getClientContext } from '@travellr/client/context'
import { Page } from '../../ui-kit/Page.solid-js'

export const Credits = () => {
  const { history } = getClientContext()
  return (
    <Page>
      <div class="vspacer-xl">
        <header>
          <button class="discrete button" onClick={() => history.back()}>
            Back
          </button>
        </header>

        <h1>Credits</h1>

        <div>
          <p>
            The implementation of this product has been made possible by the
            following technologies, which I thank very much and intend to fund
            whenever I make any money from this app:
          </p>
          <ul>
            <li>
              <ul>
                <h4>Development</h4>
                <li>SolidJS</li>
                <li>ESBuild</li>
              </ul>
            </li>
            <li>
              <ul>
                <h4>Design</h4>
                <li>The noun project (icons)</li>
                <li>Font Awesome (icons)</li>
              </ul>
            </li>
          </ul>
        </div>

        <footer>
          <button class="discrete button" onClick={() => history.back()}>
            Back
          </button>
        </footer>
      </div>
    </Page>
  )
}
