import { solidRemoteResource } from '@/solidjs/reactivity'
import { solidState } from '@/solidjs/state'
import { onCleanup } from 'solid-js'
import { TravellrApp as Model } from './main'
import { Router } from './router.solid-js'

type Props = {
  model: Model
}
export const TravellrApp = (props: Props) => {
  onCleanup(props.model.dispose)
  solidRemoteResource(props.model.storageSize)
  const route = solidState(props.model.route)
  return <Router route={route()} />
}
