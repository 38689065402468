import { justBody } from '@/std/api-contract'
import { XhrApiRouteClient } from '@/std/api-contract/route-client'
import { list } from '@/std/data'
import { flow } from '@/std/function'
import { RR, RemoteResource } from '@/std/remote'
import { getClientContext } from '@travellr/client/context'
import { ArchiveTrip } from '../archive-trip/client'
import { contract } from './contract'

export type ListTrips = ReturnType<typeof ListTrips>

export const ListTrips = () => {
  const { xhr } = getClientContext()
  const client = flow(XhrApiRouteClient(contract, xhr), justBody)
  const trips = RemoteResource(() => client({}))
  const onTripArchived = (tripId) => {
    trips.update(RR.map(list.filter((trip) => trip.id !== tripId)))
  }

  const effects: Array<{ unlisten: () => void }> = []
  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },
    trips,
    onTripArchived,
    ArchiveTrip: flow(ArchiveTrip, (model) => {
      const effect = model.action.state.onChange(
        RR.map(() => {
          onTripArchived(model.trip.id)
          effect.unlisten()
          effects.splice(effects.indexOf(effect), 1)
        }),
      )
      effects.push(effect)
      return model
    }),
  }
}
