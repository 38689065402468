import { Locale } from './Locale'

export const format =
  (options: Intl.DateTimeFormatOptions) => (locale: Locale) => (date: Date) =>
    new Intl.DateTimeFormat(locale, {
      timeZone: 'UTC',
      ...options,
    }).format(date)

export const formatDateTimeShort = format({
  dateStyle: 'short',
  timeStyle: 'short',
})
export const formatDate = format({ dateStyle: 'medium' })
