import { C } from '@/std/codec'
import { struct } from '@/std/data'
import { pipe } from '@/std/function'
import { T } from '@/std/type'
import { Checkpoint } from './Checkpoint'
import { CotravellrStruct } from './Cotravellr'
import { Note } from './Note'
import { Picture } from './Picture'
import { Trip } from './Trip'

const CotravellrOptions = pipe(
  CotravellrStruct,
  struct.omit('selfTripId', 'peerTripId'),
  T.struct,
)

const CotravellrName = T.string
const CotravellrTrip = pipe(
  T.struct({
    trip: Trip,
    pictures: T.array(Picture),
    notes: T.array(Note),
    checkpoints: T.array(Checkpoint),
  }),
  T.intersect(CotravellrOptions),
)
export type CotravellrTrip = C.TypeOf<typeof CotravellrTrip>
const CotravellrsTrips = T.record(CotravellrName, CotravellrTrip)
export type CotravellrsTrips = C.TypeOf<typeof CotravellrsTrips>

export type FullTrip = {
  trip: Trip
  pictures: Picture[]
  notes: Note[]
  checkpoints: Checkpoint[]
  cotravellrs: CotravellrsTrips
}

const type = T.struct({
  trip: Trip,
  pictures: T.array(Picture),
  notes: T.array(Note),
  checkpoints: T.array(Checkpoint),
  cotravellrs: CotravellrsTrips,
})

type Ord = { ord: typeof type.ord }

export const FullTrip: T.Type<FullTrip> & Ord = type
