import { Eq, O, TupleOf } from '../data'
import { constFalse, flow, identity, panic, pipe } from '../function'
import { PendingEvent } from '../http'

const Tag = {
  NotAsked: 'NotAsked',
  Pending: 'Pending',
  Done: 'Done',
} as const

export type RemoteData<T> = NotAsked | Pending<T> | Done<T>
export type NotAsked = { readonly tag: 'NotAsked' }
export type Pending<T> = {
  readonly tag: 'Pending'
  readonly stale: O.Option<T>
  readonly event: O.Option<PendingEvent>
}
export type Done<T> = { readonly tag: 'Done'; readonly value: T }

export type Infer<R> = R extends NotAsked | Pending<any> | infer Data
  ? Flatten<Data>
  : never
export type Flatten<Data> = Data extends Done<infer A> | Done<infer B>
  ? A | B
  : never

// constructors
export const NotAsked = <T>(): RemoteData<T> => ({ tag: Tag.NotAsked })
export const Pending = <T>(
  stale?: T | O.Option<T>,
  event?: PendingEvent | O.Option<PendingEvent>,
): RemoteData<T> => ({
  tag: Tag.Pending,
  stale: pipe(stale, O.fromNullable, O.flatten),
  event: pipe(event, O.fromNullable, O.flatten),
})
export const Done = <T>(value: T): RemoteData<T> => ({ tag: Tag.Done, value })
export const fromOption = O.fold(NotAsked, Done)

// predicates
export const isNotAsked = <T>(remote: RemoteData<T>): remote is NotAsked =>
  remote.tag === Tag.NotAsked
export const isPending = <T>(remote: RemoteData<T>): remote is Pending<T> =>
  remote.tag === Tag.Pending
export const isDone = <T>(remote: RemoteData<T>): remote is Done<T> =>
  remote.tag === Tag.Done

// destructors
export const fold =
  <T, WhenNotAsked, WhenPending = WhenNotAsked, WhenDone = WhenNotAsked>(
    notAsked: () => WhenNotAsked,
    pending: (stale: O.Option<T>, event: O.Option<PendingEvent>) => WhenPending,
    done: (value: T) => WhenDone,
  ) =>
  (remote: RemoteData<T>) => {
    switch (remote.tag) {
      case Tag.NotAsked:
        return notAsked()
      case Tag.Pending:
        return pending(remote.stale, remote.event)
      case Tag.Done:
        return done(remote.value)
    }
  }

/**
 * A more optimistic fold utility
 */
export const fold2 = <
  T,
  WhenNotAsked,
  WhenPendingAndEmpty = WhenNotAsked,
  WhenDoneOrStale = WhenNotAsked,
>(
  notAsked: () => WhenNotAsked,
  pendingAndEmpty: () => WhenPendingAndEmpty,
  doneOrStale: (value: T, isStale: boolean) => WhenDoneOrStale,
) =>
  fold(
    notAsked,
    O.fold<T, WhenPendingAndEmpty | WhenDoneOrStale>(pendingAndEmpty, (stale) =>
      doneOrStale(stale, true),
    ),
    (value) => doneOrStale(value, false),
  )

export const toOption: <T>(remote: RemoteData<T>) => O.Option<T> = fold(
  O.None,
  O.None,
  (value) => O.Some(value),
)
// type toOption<T> = typeof toOption<T>
export const unwrapOr = <T, U>(fallback: () => U) =>
  fold<T, T | U>(fallback, fallback, identity)
export const unwrap = unwrapOr(() =>
  panic(new Error('Remote is not asked or pending')),
) as <T>(r: RemoteData<T>) => T

export const or = <A, B>(fallback: () => RemoteData<B>) =>
  fold<A, RemoteData<A | B>>(fallback, fallback, Done)

// combinators
export const map = <A, B = A>(fn: (value: A) => B) =>
  fold<A, RemoteData<B>>(NotAsked, flow(O.map(fn), Pending), flow(fn, Done))
export const flatMap = <A, B>(fn: (value: A) => RemoteData<B>) =>
  fold<A, RemoteData<B>>(NotAsked, () => Pending(), fn)
export const mapOption = <A, B = A>(fn: (value: A) => O.Option<B>) =>
  flatMap(flow(fn, fromOption))

export const tuple = <Remotes extends TupleOf<RemoteData<any>>>(
  remotes: Remotes,
): RemoteData<{ [K in keyof Remotes]: Infer<Remotes[K]> }> =>
  remotes.reduce((acc, remote) => {
    if (isNotAsked(acc)) return acc
    else if (isNotAsked(remote)) return NotAsked()

    if (isPending(acc)) {
      return pipe(
        acc as RemoteData<any[]>,
        map((remotes) => [
          ...remotes,
          isDone(remote) ? O.Some(remote.value) : remote.stale,
        ]),
      )
    } else if (isPending(remote)) {
      return Pending([remote.stale])
    } else {
      return pipe(
        acc as RemoteData<any[]>,
        map((remotes) => [...remotes, remote.value]),
      )
    }
  }, Done<any[]>([]))

export const list = tuple as <T>(remotes: RemoteData<T>[]) => RemoteData<T[]>

// debugging
export const tap = <A>(fn: (value: A) => any) =>
  map<A>((value) => (fn(value), value))

const remoteEq = <T>(eq: Eq<T>) =>
  Eq.fromEquals<RemoteData<T>>((a, b) =>
    pipe(
      tuple([a, b]),
      map(([a, b]) => eq.equals(a)(b)),
      unwrapOr(constFalse),
    ),
  )
export { remoteEq as eq }

// declare const r1: Remote<string>;
// declare const r2: Remote<number>;
// declare const r3: Remote<boolean>;
// declare const list: Array<Remote<string> | Remote<number> | Remote<boolean>>
// const test = Remote.all([r1, r2, r3]);
// const a = Remote.all(list)
