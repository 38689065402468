// @ts-check
import { flow, pipe } from '../function'
import { Eq } from './eq'
import * as O from './option'
import * as struct from './struct'

type Lookup = <K extends PropertyKey, T>(
  key: K,
) => (record: Record<K, T>) => O.Option<Record<K, T>[K]>
export const lookup: Lookup = (key) => (record) => O.fromNullable(record[key])

export const map = struct.map as <K extends PropertyKey, V1, V2>(
  mapper: (value: V1, key: K) => V2,
) => (record: Record<K, V1>) => Record<K, V2>

export const some = struct.some as <K extends PropertyKey, V>(
  predicate: (value: V, key: K) => boolean,
) => (record: Record<K, V>) => boolean

export const keys = struct.keys as <K extends PropertyKey>(
  record: Record<K, any>,
) => K[]
export const values = struct.values as <V>(
  record: Record<PropertyKey, V>,
) => V[]

export const entries = struct.entries as <K extends PropertyKey, V>(
  record: Record<K, V>,
) => [K, V][]

export const fromEntries = struct.fromEntries as {
  <K extends PropertyKey, V>(entries: Iterable<[K, V]>): Record<K, V>
  <T>(entries: Iterable<[PropertyKey, T]>): Record<PropertyKey, T>
  (entries: Iterable<readonly any[]>): any
}
export const invert = struct.invert as <
  Key extends PropertyKey,
  Value extends PropertyKey,
>(
  r: Record<Key, Value>,
) => Record<Value, Key>

export const filter = struct.pickBy as {
  <K extends PropertyKey, V, U extends V>(
    predicate: (value: V, key: K) => value is U,
  ): (record: Record<K, V>) => Record<K, U>
  <K extends PropertyKey, V>(predicate: (value: V, key: K) => unknown): (
    record: Record<K, V>,
  ) => Record<K, V>
}

type KeyBy = <T extends Record<PropertyKey, any>, U extends PropertyKey>(
  mapKey: (struct: T) => U,
) => (structs: T[]) => Record<U, T>
export const keyBy: KeyBy = (mapKey) => (structs) =>
  pipe(
    structs.reduce((acc, value) => {
      return Object.assign(acc, { [mapKey(value)]: value })
    }, {} as any),
  )
type KeyByProp = <T extends Record<PropertyKey, any>, U extends keyof T>(
  prop: U,
) => (structs: T[]) => Record<U, T>
export const keyByProp: KeyByProp = (prop) =>
  keyBy(struct.lookup(prop as any) as any)

type Pick = <T extends Record<PropertyKey, any>, U extends keyof T>(
  keys: U[],
) => (record: T) => T
export const pick: Pick = (keys) => struct.pick(...(keys as any))

export const size = flow(keys, (arr) => arr.length)

// export const findFirst = <T, Key extends PropertyKey>(predicate: (value: T, key: Key) => boolean) => (rec: Record<Key, T>)

export const eq = <Value, Key extends PropertyKey = PropertyKey>(
  valueEq: Eq<Value>,
) =>
  Eq.fromEquals<Record<Key, Value>>((a, b) => {
    const aKeys = Object.keys(a)
    const bKeys = Object.keys(b)
    if (aKeys.length !== bKeys.length) return false
    return entries(a).every(([key, value]) => valueEq.equals(value, b[key]))
  })
