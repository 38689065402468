import { C } from '../codec'
import { R, list, record } from '../data'
import { InfraError } from '../error'
import { pipe } from '../function'
import {
  HttpError,
  MimeType,
  OnProgress,
  fetchClient,
  makeXhrClient,
  parseJsonResponse,
  parseTextResponse,
} from '../http'
import { TR } from '../remote'
import { HasProp, If, IsEmpty } from '../types'
import { makeBodyInit } from './adapters'
import { ApiRouteContract } from './route-contract'
import { PathParameters } from './type-utils'

export type ApiClient<Route extends ApiRouteContract, ClientError> = (
  options: ApiClientBaseOptions & ApiClientRouteOptions<Route>,
) => TR.TaskResult<ClientError | HttpError, ApiClientResponse<Route>>

export type MakeApiClient<Client, Err> = <Route extends ApiRouteContract>(
  contract: Route,
  client?: Client,
) => ApiClient<Route, Err>

export const FetchApiRouteClient =
  <Route extends ApiRouteContract>(route: Route, fetch = fetchClient) =>
  (
    options: ApiClientBaseOptions & ApiClientRouteOptions<Route>,
  ): TR.TaskResult<
    HttpError | InfraError | C.DecodeError,
    ApiClientResponse<Route>
  > => {
    const url = makePathWithSearch({
      path: route.path,
      route,
      params: options.params,
      searchParams: options.searchParams,
    })
    const task = fetch(url, {
      method: route.method,
      body: makeBodyInit(route.body, options.body),
      cache: options.cache,
      credentials: options.credentials,
      integrity: options.integrity,
      keepalive: options.keepalive,
      mode: options.mode,
      signal: options.signal,
      headers: makeHeaders(route, options),
    })
    return pipe(
      task,
      TR.flatMap((response) =>
        TR.struct({
          status: TR.Ok(response.status),
          headers: pipe(
            response.headers,
            route.response.headers?.decode ?? R.Ok,
            TR.fromResult,
          ),
          body: (() => {
            switch (route.response.contentType) {
              case MimeType.Json:
                return parseJsonResponse(route.response.codec)(response)
              case MimeType.Html:
              case MimeType.Csv:
              case MimeType.Text:
                return parseTextResponse(response)
              default:
                return TR.Ok(undefined)
            }
          })(),
          // route.response.contentType === MimeType.Json
          //   ? parseJsonResponse(route.response.codec)(response)
          //   : TR.Ok(undefined),
        }),
      ),
    ) as any
  }

export const XhrApiRouteClient =
  <Route extends ApiRouteContract>(route: Route, xhr = makeXhrClient()) =>
  (
    options: ApiClientBaseOptions & ApiClientRouteOptions<Route>,
  ): TR.TaskResult<HttpError | C.DecodeError, ApiClientResponse<Route>> => {
    const url = makePathWithSearch({
      path: route.path,
      route,
      params: options.params,
      searchParams: options.searchParams,
    })

    const task = xhr(url, {
      credentials: options.credentials,
      method: route.method,
      mode: options.mode,
      onProgress: options.onProgress,
      body: makeBodyInit(route.body, options.body) as XMLHttpRequestBodyInit,
      headers: makeHeaders(route, options),
    })
    return pipe(
      task,
      TR.map((res) => {
        return route.response.contentType === MimeType.Json
          ? JSON.parse(res as string)
          : res
      }),
      TR.mapResult(route.response.codec?.decode ?? R.Ok<any>),
      TR.map((body) => ({
        headers: {} as any, // shim :/
        status: 200, // shim :/
        body,
      })),
    )
  }

const injectPathParams = (path: string, params: Record<string, unknown>) =>
  pipe(
    record.entries(params),
    list.reduce(path, (acc, [param, value]) =>
      acc.replaceAll(`:${param}`, String(value)),
    ),
  )

const makeHeaders = <Route extends ApiRouteContract>(
  route: ApiRouteContract,
  options: ApiClientBaseOptions & ApiClientRouteOptions<Route>,
) => {
  const headers = new Headers(route.headers?.encode(options.headers) ?? {})
  if (route.body && !headers.has('content-type'))
    headers.set('content-type', route.body.contentType)
  if (route.response.contentType && !headers.has('accept'))
    headers.set('accept', route.response.contentType)
  return headers
}
const makePathWithSearch = (options: {
  route: ApiRouteContract
  path: string
  params: any
  searchParams?: any
}) => {
  const params =
    options.route.params?.encode(options.params) ?? options.params ?? {}
  const pathWithParams = injectPathParams(options.path, params)
  const searchParams =
    options.route.searchParams?.encode(options.searchParams) ??
    options.searchParams ??
    {}
  const search = pipe(
    searchParams,
    record.filter((value) => value !== null && value !== undefined),
    record.entries,
    list.reduce('', (acc, [key, value], index) => {
      return `${acc}${index === 0 ? '?' : '&'}${String(key)}=${value}`
    }),
  )
  return `${pathWithParams}${search}`
}

type ApiClientResponse<Route extends ApiRouteContract> = {
  status: number
} & If<
  HasProp<Route['response'], 'headers'>,
  { headers: C.TypeOf<Route['response']['headers']> },
  { headers?: undefined }
> &
  If<
    HasProp<Route['response'], 'codec'>,
    { body: C.TypeOf<Route['response']['codec']> },
    { body?: undefined }
  >

type ApiClientRouteOptions<Route extends ApiRouteContract> = If<
  HasProp<Route, 'headers'>,
  { headers: C.TypeOf<Route['headers']> },
  { headers?: undefined }
> &
  If<
    HasProp<Route, 'body'>,
    { body: C.TypeOf<NonNullable<Route['body']>['codec']> },
    { body?: undefined }
  > &
  If<
    HasProp<Route, 'params'>,
    { params: C.TypeOf<Route['params']> },
    If<
      IsEmpty<PathParameters<Route['path']>>,
      { params?: never },
      { params: PathParameters<Route['path']> }
    >
  > &
  If<
    HasProp<Route, 'searchParams'>,
    { searchParams: C.TypeOf<Route['searchParams']> },
    { searchParams?: undefined }
  >

type ApiClientBaseOptions = {
  onProgress?: OnProgress
} & Pick<
  RequestInit,
  'cache' | 'signal' | 'credentials' | 'keepalive' | 'integrity' | 'mode'
>
