import { Except } from 'type-fest'
import { C } from '../codec'
import { InfraError, makeInfraError } from '../error'
import { pipe } from '../function'
import { TR } from '../remote'
import { HttpError } from './Error'
import { HttpMethod } from './Method'
import { HttpClient, patchQuery } from './client'

type EnhancedInit = Except<RequestInit, 'body'> & {
  body?: RequestInit['body'] | object
}

const toHttpError = <Resp extends Response>(
  response: Resp,
): TR.TaskResult<InfraError | HttpError, Resp> => {
  if (response.status > 0 && response.status < 400) return TR.Ok(response)
  return pipe(
    response,
    parseTextResponse,
    TR.map((reason) => {
      return HttpError({
        code: response.status,
        status: response.statusText,
        cause: reason.replace(
          `${response.statusText} (${response.status}): `,
          '',
        ),
      })
    }),
    TR.flatMap(TR.Err),
  )
}

export const fetchClient = (
  input: string,
  init?: RequestInit | undefined,
): TR.TaskResult<HttpError | InfraError, Response> =>
  pipe(
    TR.try(() => globalThis.fetch(input, init), makeInfraError('fetch')),
    TR.flatMap(toHttpError),
  )

export const parseJsonResponse =
  <O, T>(Codec: C.Codec<T, O, unknown>) =>
  (response: Response) =>
    pipe(
      TR.try(
        () => response.json() as Promise<unknown>,
        makeInfraError('response.json'),
      ),
      TR.mapResult(Codec.decode),
    )
export const parseBlobResponse = (response: Response) =>
  pipe(TR.try(() => response.blob(), makeInfraError('response.blob')))

export const parseTextResponse = (response: Response) =>
  pipe(TR.try(() => response.text(), makeInfraError('response.text')))

export type FetchClient = typeof fetchClient
export type EnhancedFetchClient = ReturnType<typeof makeFetchClient>

export const makeFetchClient =
  (baseUrl: string, enhanceInit?: (init?: EnhancedInit) => RequestInit) =>
  (input: string, init?: EnhancedInit) => {
    const enhancedInit = enhanceInit?.(init)
    return fetchClient(`${baseUrl ?? ''}${input}`, {
      ...init,
      ...enhancedInit,
      body: enhancedInit?.body ?? (init?.body as BodyInit),
      headers: {
        ...init?.headers,
        ...enhancedInit?.headers,
      },
    })
  }

export type FetchHttpClient = HttpClient<HttpError | C.DecodeError | InfraError>

export const makeFetchHttpClient = (fetch: FetchClient): FetchHttpClient => {
  const requestWithPayload =
    (method: string): HttpClient['post'] =>
    ({ url, ResponseCodec, body, BodyCodec, ...rest }) =>
      pipe(
        fetch(patchQuery(url, rest), {
          ...rest,
          method,
          body: BodyCodec && JSON.stringify(BodyCodec.encode(body)),
        }),
        TR.flatMap(parseJsonResponse(ResponseCodec)),
      )
  return {
    get: ({ url, ResponseCodec, ...rest }) =>
      pipe(
        fetch(patchQuery(url, rest), rest),
        TR.flatMap(parseJsonResponse(ResponseCodec)),
      ),
    delete: requestWithPayload(HttpMethod.delete) as FetchHttpClient['delete'],
    patch: requestWithPayload(HttpMethod.patch) as FetchHttpClient['patch'],
    post: requestWithPayload(HttpMethod.post) as FetchHttpClient['post'],
    put: requestWithPayload(HttpMethod.put) as FetchHttpClient['put'],
  }
}
