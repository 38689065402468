import { DateTimeInput, InputFeedback, solidFormControl } from '@/solidjs/form'
import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { O, boolean, list } from '@/std/data'
import { pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { I18n } from '@travellr/client/i18n/I18n'
import { usePictureContext } from '@travellr/client/picture-store/context'
import { Dialog } from '@travellr/client/ui-kit/Dialog.solid-js'
import { Icon } from '@travellr/client/ui-kit/Icon.solid-js'
import { Image } from '@travellr/client/ui-kit/Image.solid-js'
import { Progress } from '@travellr/client/ui-kit/Progress.solid-js'
import { ComponentProps, Show, onCleanup } from 'solid-js'
import { Except } from 'type-fest'
import { FixUndatedPicturesDialogModel } from './FixUndatedPicturesDialog'
import { messages } from './FixUndatedPicturesDialog.i18n'

type ButtonProps = Except<ComponentProps<'button'>, 'onClick'> & {
  model: FixUndatedPicturesDialogModel
}
const Button = (props: ButtonProps) => {
  return (
    <button
      {...props}
      class={cx('discrete button', props.class)}
      onClick={() => props.model.dialogOpened.set(true)}
    >
      <Icon intent="warn" />
    </button>
  )
}

type DialogProps = {
  model: FixUndatedPicturesDialogModel
}

const _Dialog = (props: DialogProps) => {
  onCleanup(props.model.dispose)
  const { t } = I18n(messages)
  const PictureStore = usePictureContext()
  const current = solidState(props.model.current)
  const dateControl = solidFormControl(props.model.dateControl)
  const scheduledActionsStates = solidState(props.model.scheduledActionStates)
  const scheduledActionsCount = solidState(props.model.scheduledActionsCount)
  const isAtLeastOneScheduledActionSubmitted = solidState(
    props.model.isAtLeastOneScheduledActionSubmitted,
  )
  const successfulActionsCount = solidState(props.model.successfulActionsCount)
  const dialogOpened = solidState(props.model.dialogOpened)

  return (
    <Show when={dialogOpened()}>
      {pipe(
        isAtLeastOneScheduledActionSubmitted(),
        boolean.fold(
          () => (
            <Dialog dialogClass="p-m vspacer-m">
              <header>
                <h3 class="text-center">{t('heading')}</h3>
              </header>

              <hr />

              {pipe(
                props.model.pictures[current()],
                O.fromNullable,
                O.fold(
                  () => (
                    <div class="vspacer-l text-center">
                      <p>{t('issuesTreated.1')}</p>
                      <p>{t('issuesTreated.2')}</p>
                      <div class="hspacer-m">
                        <button
                          class="discrete button"
                          onClick={() =>
                            current.set(props.model.pictures.length - 1)
                          }
                        >
                          {t('re-edit')}
                        </button>
                        <button
                          class="button"
                          onClick={props.model.submitActions}
                        >
                          {t('verb/save')}
                        </button>
                      </div>
                    </div>
                  ),
                  (picture) => (
                    <>
                      <div class="vspacer-l">
                        <div class="flex justify-space-between align-center hspacer-l">
                          <h5>{t('picture-n', { n: current() + 1 })}</h5>
                          <div class="flex nowrap hspacer-m">
                            <button
                              class="small button"
                              disabled={current() === 0}
                              onClick={props.model.goToPreviousPicture}
                            >
                              <Icon intent="previous" />
                            </button>
                            <button
                              class="small button"
                              disabled={
                                current() >= props.model.pictures.length - 1
                              }
                              onClick={props.model.goToNextPicture}
                            >
                              <Icon intent="next" />
                            </button>
                          </div>

                          <div class="nowrap">
                            {t('treated', {
                              count: scheduledActionsCount(),
                              total: props.model.pictures.length,
                            })}
                          </div>
                        </div>

                        <Image
                          src={PictureStore.url(picture)}
                          class="maxw-100 rounded"
                          containerClass="text-center"
                          height="300px"
                        />

                        <div class="flex align-center justify-space-between nowrap hspacer-m">
                          <div class="relative w-100">
                            <DateTimeInput
                              class="input"
                              control={dateControl}
                            />
                            <InputFeedback
                              control={dateControl}
                              class="absolute bottom left w-100 p-s box feedback danger"
                              style="transform: translateY(100%)"
                            />
                          </div>
                          <button
                            class="button"
                            onClick={() => {
                              pipe(
                                dateControl(),
                                O.map((date) =>
                                  props.model.scheduleToSaveDate(picture, date),
                                ),
                              )
                              props.model.goToNextPicture()
                            }}
                          >
                            <Icon intent="next" />
                          </button>

                          <hr style="height: 1rem; width: 1px" />

                          <button
                            class="danger button"
                            onClick={() => {
                              props.model.scheduleToArchive(picture)
                              props.model.goToNextPicture()
                            }}
                          >
                            <Icon intent="remove" />
                          </button>
                        </div>
                      </div>
                      <hr />
                      <footer class="flex align-center justify-end hspacer-m">
                        <button
                          class="discrete button"
                          onClick={props.model.closeDialog}
                        >
                          {t('verb/cancel')}
                        </button>
                        <button
                          class="button"
                          onClick={props.model.submitActions}
                        >
                          {t('verb/save')}
                        </button>
                      </footer>
                    </>
                  ),
                ),
              )}
            </Dialog>
          ),
          () => (
            <Dialog dialogClass="p-m vspacer-m">
              <header>
                <h3 class="text-center">{t('heading')}</h3>
              </header>

              <hr />

              <div class="vspacer-l">
                <div>
                  <Progress
                    value={successfulActionsCount()}
                    max={scheduledActionsCount()}
                    height="0.5rem"
                  />
                </div>

                <div class="vspacer-m">
                  {pipe(
                    scheduledActionsStates(),
                    list.map((state, index) => {
                      return (
                        <div class="flex align-center hspacer-l">
                          <h5>{t('picture-n', { n: index + 1 })}</h5>
                          {pipe(
                            state,
                            RR.fold2(
                              () => <Icon intent="spinner" class="spin" />,
                              () => <Icon intent="errored" />,
                              () => <Icon intent="success" />,
                            ),
                          )}
                        </div>
                      )
                    }),
                  )}
                </div>
              </div>

              <hr />

              {pipe(
                scheduledActionsStates(),
                RR.list,
                RR.fold2(
                  () => (
                    <footer class="flex align-center justify-end hspacer-m">
                      <Icon intent="spinner" class="spin" />
                      <button
                        class="discrete button"
                        onClick={props.model.closeDialog}
                      >
                        {t('verb/abort')}
                      </button>
                    </footer>
                  ),
                  () => (
                    <footer class="flex align-center justify-end hspacer-m">
                      <div class="prewrap">
                        <Icon intent="warn" /> {t('footer/error')}
                      </div>
                      <div class="flex align-center hspacer-m">
                        <button
                          class="discrete button"
                          onClick={props.model.closeDialog}
                        >
                          {t('verb/cancel')}
                        </button>
                        <button class="button" onClick={props.model.retry}>
                          {t('verb/retry')}
                        </button>
                      </div>
                    </footer>
                  ),
                  () => {
                    setTimeout(() => props.model.closeDialog(), 2500)
                    return (
                      <div class="text-center">
                        <Icon intent="success" />
                      </div>
                    )
                  },
                ),
              )}
            </Dialog>
          ),
        ),
      )}
    </Show>
  )
}

export const FixUndatedPictures = {
  Button,
  Dialog: _Dialog,
}
