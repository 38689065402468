export type Duration = {
  ms: (ms: number) => number
  seconds: (seconds: number) => number
  minutes: (minutes: number) => number
  hours: (hours: number) => number
  days: (days: number) => number
}

export type DurationUnit = keyof typeof units

const units = {
  ms: 1,
  seconds: 1000,
  minutes: 60000,
} as const

export const durationIn = (unit: DurationUnit): Duration => {
  const ms = 1 / units[unit]
  const duration = {
    ms: (n) => n * ms,
    seconds: (n) => n * duration.ms(1000),
    minutes: (n) => n * duration.seconds(60),
    hours: (n) => n * duration.minutes(60),
    days: (n) => n * duration.hours(24),
  }
  return duration
}
