import { cx } from '@/std/classNames'
import { Accessor, ComponentProps, JSX, splitProps } from 'solid-js'

type Props = ComponentProps<'div'> & {
  control: JSX.Element | Accessor<JSX.Element>
  label: JSX.Element | Accessor<JSX.Element>
  hint?: JSX.Element | Accessor<JSX.Element>
  feedback?: JSX.Element | Accessor<JSX.Element>
}

export const Control = (props: Props) => {
  const [, rest] = splitProps(props, ['control', 'label', 'hint', 'feedback'])
  return (
    <div {...rest} class={cx('vspacer-s', rest.class)}>
      <label class="block vspacer-s">
        <span class="bold block">{toElement(props.label)}</span>
        {toElement(props.control)}
      </label>
      {toElement(props.feedback)}
      {toElement(props.hint)}
    </div>
  )
}

const toElement = (node: JSX.Element | Accessor<JSX.Element>) =>
  typeof node === 'function' ? node() : node
