import { TripId } from '../../../entity/Trip'
import { AddCotravellrModel } from '../../../use-case/add-cotravellr/client'
import { ListCotravellrsModel } from '../../../use-case/list-cotravellrs/client'

export type CotravellrsModalModel = ReturnType<typeof CotravellrsModalModel>
type Deps = {
  tripId: TripId
}
export const CotravellrsModalModel = ({ tripId }: Deps) => {
  return {
    add: AddCotravellrModel({ tripId }),
    list: ListCotravellrsModel({ tripId }),
  }
}
