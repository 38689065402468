export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'locate-on-map/title': 'Pick a location on the map',
  'pick-picture-at-location/title': 'Choose a picture to locate here',
} as const

const fr = {
  'locate-on-map/title': 'Choisir un point sur la carte',
  'pick-picture-at-location/title': 'Choisis une image à localiser ici',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
