import { C, Cd } from '../codec'
import { Cookie, O, R, date, parseJson, record, stringifyJson } from '../data'
import { flow, matchType, pipe } from '../function'

type StorageOption = {
  expires?: Date
}

export type Storage<T> = {
  set: (key: string, value: T, options?: StorageOption) => R.Result<Error, void>
  get: (key: string) => R.Result<Error, T>
  find: (key: string) => R.Result<Error, O.Option<T>>
  remove: (key: string) => R.Result<Error, void>
}

export type StorageType = 'session' | 'local' | 'cookie' | 'inmemory'
type StorageOptions<T> = {
  Codec: C.Codec<T, any, any>
  type: StorageType
}

export const makeStorage = <T>(options: StorageOptions<T>): Storage<T> =>
  pipe(
    options,
    matchType({
      cookie: ({ Codec }) => new CookieStorage(Codec),
      local: ({ Codec }) =>
        new LocalOrSessionStorage(globalThis.localStorage, Codec),
      session: ({ Codec }) =>
        new LocalOrSessionStorage(globalThis.sessionStorage, Codec),

      inmemory: () => new InMemoryStorage<T>(),
    }),
  )

class LocalOrSessionStorage<T> implements Storage<T> {
  private ItemCodec: C.Codec<
    { expires: O.Option<Date>; item: T },
    { expires: string | null | undefined; item: any },
    unknown
  >
  constructor(
    private storage: typeof globalThis.localStorage,
    private Codec: C.Codec<T, any, any>,
  ) {
    this.ItemCodec = C.struct({
      expires: C.Option(Cd.DateFromString),
      item: this.Codec,
    })
  }

  set: Storage<T>['set'] = (key, value, options) =>
    pipe(
      this.ItemCodec.encode({
        item: value,
        expires: O.fromNullable(options?.expires),
      }),
      stringifyJson(),
      (item) => R.try(() => this.storage.setItem(key, item)),
    )
  get: Storage<T>['get'] = (key) =>
    pipe(
      this.find(key),
      R.flatMap(
        R.fromOption(() => new Error(`item ${key} not found or expired`)),
      ),
    )

  find: Storage<T>['find'] = (key) =>
    pipe(
      this.storage.getItem(key),
      O.fromNullable,
      O.map(parseJson),
      O.map(R.flatMap(this.ItemCodec.decode)),
      O.fold(
        () => R.Ok(O.None()),
        R.map((item) =>
          this.isExpired(item) ? O.None<T>() : O.Some(item.item),
        ),
      ),
    )
  remove: Storage<T>['remove'] = (key) =>
    pipe(R.try(() => this.storage.removeItem(key)))

  private isExpired = (a: { expires: O.Option<Date> }) =>
    O.isSome(a.expires) && a.expires.value.valueOf() < Date.now()
}

class CookieStorage<T> implements Storage<T> {
  constructor(
    private Codec: C.Codec<T, string, any>,
    private defaultExpires = flow(date.now, date.addMonths(12)),
  ) {}

  set: Storage<T>['set'] = (key, value, options) =>
    R.try(() =>
      pipe(
        this.Codec.encode(value),
        stringifyJson(),
        (value) =>
          Cookie.serialize({
            name: key,
            value,
            expires: options?.expires ?? this.defaultExpires(),
            sameSite: 'Lax',
          }),
        (cookie) => {
          document.cookie = cookie
        },
      ),
    )

  get: Storage<T>['get'] = (key) =>
    pipe(
      this.find(key),
      R.flatMap(
        R.fromOption(() => new Error(`cookie ${key} not found or expired`)),
      ),
    )

  find: Storage<T>['find'] = (key) =>
    pipe(
      document.cookie,
      Cookie.parse,
      record.lookup(key),
      O.map(parseJson),
      O.map(R.flatMap(this.Codec.decode)),
      O.fold(() => R.Ok(O.None()), R.map(O.Some)),
    )

  remove: Storage<T>['remove'] = (key) =>
    R.try(() => {
      document.cookie = Cookie.remove(key)
    })
}

class InMemoryStorage<T> implements Storage<T> {
  private storage = new Map<string, { expires: O.Option<Date>; item: T }>()

  set: Storage<T>['set'] = (key, value, options) =>
    R.Ok(
      void this.storage.set(key, {
        item: value,
        expires: O.fromNullable(options?.expires),
      }),
    )

  get: Storage<T>['get'] = (key) =>
    pipe(
      this.find(key),
      R.flatMap(
        R.fromOption(() => new Error(`item ${key} not found or expired`)),
      ),
    )

  find: Storage<T>['find'] = (key) =>
    pipe(
      this.storage.get(key),
      (v) => v,
      O.fromNullable,
      O.fold(
        () => O.None(),
        (item) => (this.isExpired(item) ? O.None<T>() : O.Some(item.item)),
      ),
      R.Ok,
    )
  remove: Storage<T>['remove'] = (key) =>
    pipe(R.try(() => void this.storage.delete(key)))

  private isExpired = (a: { expires: O.Option<Date> }) =>
    O.isSome(a.expires) && a.expires.value.valueOf() < Date.now()
}
