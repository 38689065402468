// @ts-check
import {
  cnst,
  constFalse,
  constNull,
  constUndefined,
  flow,
  identity,
  panic,
  pipe,
  toUnknownError,
} from '../function'
import { Eq } from './eq'
import * as O from './option'
import { TupleOf } from './tuple'

export type Result<E, T> = Err<E> | Ok<T>
export type Err<E> = { readonly tag: 'Err'; readonly error: E }
export type Ok<T> = { readonly tag: 'Ok'; readonly value: T }

export type InferOk<O> = O extends Err<any> | infer Oks ? FlattenOk<Oks> : never
type FlattenOk<O> = O extends Ok<infer A> | Ok<infer B> ? A | B : never

export type InferErr<R> = R extends Ok<any> | infer Errs
  ? FlattenErr<Errs>
  : never
type FlattenErr<E> = E extends Err<infer A> | Err<infer B> ? A | B : never

export const Err = <E, T = never>(error: E): Result<E, T> => ({
  tag: 'Err',
  error,
})
export const Ok = <T, E = never>(value: T): Result<E, T> => ({
  tag: 'Ok',
  value,
})

type FromNullable = <E, T>(
  err: () => E,
) => (value: T) => Result<E, NonNullable<T>>
export const fromNullable: FromNullable = (err) => (value) =>
  value === undefined || value === null ? Err(err()) : Ok(value)

type FromOption = <E, T>(onNone: () => E) => (o: O.Option<T>) => Result<E, T>
export const fromOption: FromOption = (onNone) => O.fold(flow(onNone, Err), Ok)

type FromPredicate = <E, T>(
  predicate: (value: T) => boolean,
  error: (value: T) => E,
) => (value: T) => Result<E, T>
export const fromPredicate: FromPredicate = (predicate, error) => (value) =>
  predicate(value) ? Ok(value) : Err(error(value))

type Refine = <E, T extends Value, Value>(
  predicate: (value: Value) => value is T,
  error: (value: Value) => E,
) => (value: Value) => Result<E, T>
export const refine = fromPredicate as Refine

type Try = <T, E = Error>(
  unsafe: () => T,
  mapErr?: (e: unknown) => E,
) => Result<E, T>
// @ts-ignore
const tryCatch: Try = (unsafe, mapErr = toUnknownError) => {
  try {
    return Ok(unsafe())
  } catch (error) {
    return Err(mapErr(error))
  }
}
export { tryCatch as try }

export const isErr = <E, T>(r: Result<E, T>): r is Err<E> => r.tag === 'Err'
export const isOk = <E, T>(r: Result<E, T>): r is Ok<T> => r.tag === 'Ok'

type Fold = <E, T, WhenErr, WhenOk = WhenErr>(
  onError: (error: E) => WhenErr,
  onOk: (value: T) => WhenOk,
) => (r: Result<E, T>) => WhenErr | WhenOk
export const fold: Fold = (onError, onOk) => (r) =>
  isOk(r) ? onOk(r.value) : onError(r.error)

type UnwrapOr = <E, T1, T2>(
  fallback: (error: E) => T2,
) => (r: Result<E, T1>) => T1 | T2
export const unwrapOr: UnwrapOr = (fallback) => fold(fallback, identity)

type Unwrap = <T>(r: Result<any, T>) => T
export const unwrap: Unwrap = unwrapOr(panic)
type UnwrapErr = <E>(r: Result<E, unknown>) => E
export const unwrapErr: UnwrapErr = fold(identity, () =>
  panic(new Error('Result is Ok<T>')),
)

export const toUndefined = fold(constUndefined, identity)
export const toNull = fold(constNull, identity)
type OkFn = <T>(r: Result<any, T>) => O.Option<T>
export const ok: OkFn = (r) => fold(O.None, O.Some)(r)

type ErrFn = <E>(r: Result<E, any>) => O.Option<E>
export const err: ErrFn = (r) => fold(O.Some, O.None)(r)

type Swap = <E, T>(result: Result<E, T>) => Result<T, E>
export const swap: Swap = fold(Ok, Err)

type Map = <E, A, B = A>(
  fn: (value: A) => B,
) => (r: Result<E, A>) => Result<E, B>
export const map: Map = (fn) => fold(Err, (v) => Ok(fn(v)))

type MapErr = <E1, A, E2 = E1>(
  fn: (value: E1) => E2,
) => (r: Result<E1, A>) => Result<E2, A>
export const mapErr: MapErr = (fn) => fold((e) => Err(fn(e)), Ok)

type FlatMap = <T1, E2, T2>(
  fn: (value: T1) => Result<E2, T2>,
) => <E1>(r1: Result<E1, T1>) => Result<E2 | E1, T2>
export const flatMap: FlatMap = (fn) => fold(Err, fn)

type FlatTap = <T1, E2>(
  fn: (value: T1) => Result<E2, any>,
) => <E1>(r1: Result<E1, T1>) => Result<E2 | E1, T1>
export const flatTap: FlatTap = (fn) =>
  flatMap((value) => pipe(fn(value), map(cnst(value))))

type MapOption = <T1, E2, T2>(
  fn: (value: T1) => O.Option<T2>,
  onNone: () => E2,
) => <E1>(r1: Result<E1, T1>) => Result<E2 | E1, T2>
export const mapOption: MapOption = (fn, onNone) =>
  flatMap((value) => pipe(fn(value), fromOption(onNone)))

type Or = <E1, T1, E2, T2>(
  fallback: (err: E1) => Result<E2, T2>,
) => (r: Result<E1, T1>) => Result<E1 | E2, T1 | T2>
export const or: Or = (fallback) => fold(fallback, Ok)

type Flatten = <E1, E2, T>(r: Result<E1, Result<E2, T>>) => Result<E1 | E2, T>
export const flatten: Flatten = fold(Err, fold(Err, Ok))

type List = <E, T>(results: Result<E, T>[]) => Result<E, T[]>
export const list: List = (results) =>
  // @ts-ignore
  results.reduce((acc, result) => {
    if (isErr(acc)) return acc
    else if (isErr(result)) return result
    return pipe(
      acc,
      map((options) => [...options, result.value]),
    )
  }, Ok([]))

type TupleFn = <Results extends TupleOf<Result<any, any>>>(
  results: Results,
) => Result<
  { [K in keyof Results]: InferErr<Results[K]> }[number],
  { [K in keyof Results]: InferOk<Results[K]> }
>
export const tuple = list as TupleFn

type Struct = <Results extends Record<string, Result<any, any>>>(
  results: Results,
) => Result<
  { [K in keyof Results]: InferErr<Results[K]> }[keyof Results],
  { [K in keyof Results]: InferOk<Results[K]> }
>
export const struct: Struct = (struct) =>
  pipe(
    Object.entries(struct).map(([key, result]) =>
      pipe(
        result,
        map((value) => [key, value]),
      ),
    ),
    list,
    map(Object.fromEntries),
  )

type Tap = <E, T>(fn: (value: T) => any) => (r: Result<E, T>) => Result<E, T>
export const tap: Tap = (fn) => map((value) => (fn(value), value))

type TapErr = <E, T>(fn: (error: E) => any) => (r: Result<E, T>) => Result<E, T>
export const tapErr: TapErr = (fn) => mapErr((err) => (fn(err), err))

export const eq = <T>(eq: Eq<T>) =>
  Eq.fromEquals<Result<unknown, T>>((a, b) =>
    pipe(
      tuple([a, b]),
      fold(constFalse, ([a, b]) => eq.equals(a)(b)),
    ),
  )
