import { cx } from '@/std/classNames'
import { getClientContext } from '@travellr/client/context'
import { I18n } from '@travellr/client/i18n/I18n'
import { ComponentProps, splitProps } from 'solid-js'
import { messages } from './Failure.i18n'

type Props = ComponentProps<'div'> & {
  error: unknown
  summary?: string
  onTryAgain?: () => void
}

export const Failure = (props: Props) => {
  const { t } = I18n(messages)
  const { logger } = getClientContext()
  const [, rest] = splitProps(props, ['error', 'summary', 'onTryAgain'])
  logger.error(props.error)
  return (
    <div {...rest} class={cx('vspacer-m', props.class)}>
      <h3 class="preline">
        {t('unexpected', { message: String(props.error) })}
      </h3>
      {props.onTryAgain && (
        <div>
          <button class="button" onClick={props.onTryAgain}>
            {t('tryAgain')}
          </button>
        </div>
      )}

      <details class="vspacer-m">
        <summary>{props.summary ?? t('details')}</summary>

        <p style={{ 'word-wrap': 'break-word' }}>{String(props.error)}</p>

        {props.error instanceof Error && (
          <>
            {props.error.stack && (
              <pre
                class="box inverted px-m py-l vspacer-m x-scrollauto y-scrollauto"
                style="max-height: 15rem;"
              >
                {props.error.stack.split('\n').map((line) => (
                  <code class="nowrap">{line}</code>
                ))}
              </pre>
            )}
          </>
        )}
      </details>
    </div>
  )
}
