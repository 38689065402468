import { Accessor, createSignal, onCleanup } from 'solid-js'
import { ReadonlyState, State } from '../std/reactivity'

interface SolidState {
  <T>(state: State<T>): Accessor<T> & Pick<State<T>, 'set' | 'update'>
  <T>(state: ReadonlyState<T>): Accessor<T>
}

export const solidState: SolidState = (state: any) => {
  const [get, set] = createSignal(state())
  const listener = state.onChange((next) => set(() => next))
  onCleanup(listener.unlisten)
  return Object.assign(get, { set: state.set, update: state.update })
}
