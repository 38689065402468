import { OnProgress } from '@/std/http'
import { TR } from '@/std/remote'
import { AccountId } from '@travellr/domain/core/authentication/entity/Account'
import { Picture } from '@travellr/domain/core/travellr/entity/Picture'
import { ImageStorage } from '@travellr/domain/shared/entity/ImageStorage'
import { makePictureInMemoryStore } from './PictureStore.in-memory'
import { makePicturePostgresStore } from './PictureStore.postgres'
import { makePictureS3Store } from './PictureStore.s3'

export interface PictureStore {
  get: (accountId: AccountId, picture: Pick<Picture, 'tripId' | 'id'>) => string
  thumbnail: (
    accountId: AccountId,
    picture: Pick<Picture, 'tripId' | 'id'>,
  ) => string
  upload: (
    picture: Pick<Picture, 'tripId' | 'caption'>,
    data: Blob,
    options: { onProgress: OnProgress; onThumbnailProgress?: OnProgress },
  ) => TR.TaskResult<Error, Picture>
}

export type PictureStores = Record<ImageStorage, PictureStore>

export const PictureStores = (): PictureStores => ({
  inMemory: makePictureInMemoryStore(),
  postgres: makePicturePostgresStore(),
  storj: makePictureS3Store({ storage: 'storj' }),
})
