import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { struct } from '@/std/data'
import { pipe } from '@/std/function'
import { MimeType } from '@/std/http'
import { FullTrip } from '../../entity/FullTrip'
import { TripStruct } from '../../entity/Trip'

export type Payload = C.TypeOf<typeof PayloadCodec>
const PayloadCodec = pipe(
  TripStruct,
  struct.pick('name', 'imageStorage'),
  C.struct,
)

export const contract = ApiRouteContract('POST', '/trips', {
  body: {
    contentType: MimeType.Json,
    codec: PayloadCodec,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: FullTrip,
  },
})
