export type Messages = typeof en
export type MessagesShape = Record<keyof Messages, string>

const en = {
  'dialog.heading': 'Share your trip',
  'cotravellrsIncluded.label': 'Cotravellrs included',
  'cotravellrsIncluded.hint': 'Friendly tip: don’t use this one for your parents or grandparents 😉',
  'cotravellrsExcluded.label': 'Cotravellrs excluded',
  'cotravellrsExcluded.hint': 'This one is safe (#safetyFirst)',
} as const

const fr = {
  'dialog.heading': 'Partager ton voyage',
  'cotravellrsIncluded.label': 'Covoyageurs inclus',
  'cotravellrsIncluded.hint': 'Évite peut-être de partager ce lien avec ta famille 😉',
  'cotravellrsExcluded.label': 'Covoyageurs exclus',
  'cotravellrsExcluded.hint': 'Celui-là est safe (#safetyFirst)',
} satisfies MessagesShape as unknown as Messages

export const messages = { en, fr }
